import { useMediaQuery, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import { GET_BUSINESS_INVITES_QUERY_KEY } from './useBusinessInviteList';
import useWalletsClient from './useWalletsClient';

export default function useResendBusinessInvite() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  const walletsClient = useWalletsClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['resend-business-invite'],
    mutationFn: (inviteId: string) => {
      return walletsClient.resendBusinessInvite({
        xWalletId: walletId,
        inviteId,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Resend Failed',
        description: 'Something went wrong resending the invite.',
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [GET_BUSINESS_INVITES_QUERY_KEY],
      });
      toast({
        status: 'success',
        title: 'Invite Resent',
        description: 'The invite was resent to the user',
      });
    },
  });
}
