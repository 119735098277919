/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DeactivateAccountResponse
 */
export interface DeactivateAccountResponse {
  /**
   *
   * @type {string}
   * @memberof DeactivateAccountResponse
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof DeactivateAccountResponse
   */
  walletId: string;
  /**
   *
   * @type {boolean}
   * @memberof DeactivateAccountResponse
   */
  isDeactivated: boolean;
}

/**
 * Check if a given object implements the DeactivateAccountResponse interface.
 */
export function instanceOfDeactivateAccountResponse(
  value: object
): value is DeactivateAccountResponse {
  if (!('message' in value) || value['message'] === undefined) return false;
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  if (!('isDeactivated' in value) || value['isDeactivated'] === undefined)
    return false;
  return true;
}

export function DeactivateAccountResponseFromJSON(
  json: any
): DeactivateAccountResponse {
  return DeactivateAccountResponseFromJSONTyped(json, false);
}

export function DeactivateAccountResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeactivateAccountResponse {
  if (json == null) {
    return json;
  }
  return {
    message: json['message'],
    walletId: json['walletId'],
    isDeactivated: json['isDeactivated'],
  };
}

export function DeactivateAccountResponseToJSON(
  value?: DeactivateAccountResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    message: value['message'],
    walletId: value['walletId'],
    isDeactivated: value['isDeactivated'],
  };
}
