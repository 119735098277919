/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Non-balance affecting transactions.
 * @export
 * @interface NonBalanceAffectingTransaction
 */
export interface NonBalanceAffectingTransaction {
  /**
   *
   * @type {string}
   * @memberof NonBalanceAffectingTransaction
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof NonBalanceAffectingTransaction
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof NonBalanceAffectingTransaction
   */
  amount: string;
}

/**
 * Check if a given object implements the NonBalanceAffectingTransaction interface.
 */
export function instanceOfNonBalanceAffectingTransaction(
  value: object
): value is NonBalanceAffectingTransaction {
  if (!('date' in value) || value['date'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  return true;
}

export function NonBalanceAffectingTransactionFromJSON(
  json: any
): NonBalanceAffectingTransaction {
  return NonBalanceAffectingTransactionFromJSONTyped(json, false);
}

export function NonBalanceAffectingTransactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NonBalanceAffectingTransaction {
  if (json == null) {
    return json;
  }
  return {
    date: json['date'],
    description: json['description'],
    amount: json['amount'],
  };
}

export function NonBalanceAffectingTransactionToJSON(
  value?: NonBalanceAffectingTransaction | null
): any {
  if (value == null) {
    return value;
  }
  return {
    date: value['date'],
    description: value['description'],
    amount: value['amount'],
  };
}
