/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The command to accept an invite.
 * @export
 * @interface AcceptInviteRequest
 */
export interface AcceptInviteRequest {
  /**
   *
   * @type {string}
   * @memberof AcceptInviteRequest
   */
  inviteCode: string;
  /**
   *
   * @type {string}
   * @memberof AcceptInviteRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AcceptInviteRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AcceptInviteRequest
   */
  addressLine1: string;
  /**
   *
   * @type {string}
   * @memberof AcceptInviteRequest
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof AcceptInviteRequest
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof AcceptInviteRequest
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof AcceptInviteRequest
   */
  zip: string;
  /**
   *
   * @type {Date}
   * @memberof AcceptInviteRequest
   */
  dob: Date;
}

/**
 * Check if a given object implements the AcceptInviteRequest interface.
 */
export function instanceOfAcceptInviteRequest(
  value: object
): value is AcceptInviteRequest {
  if (!('inviteCode' in value) || value['inviteCode'] === undefined)
    return false;
  if (!('firstName' in value) || value['firstName'] === undefined) return false;
  if (!('lastName' in value) || value['lastName'] === undefined) return false;
  if (!('addressLine1' in value) || value['addressLine1'] === undefined)
    return false;
  if (!('city' in value) || value['city'] === undefined) return false;
  if (!('state' in value) || value['state'] === undefined) return false;
  if (!('zip' in value) || value['zip'] === undefined) return false;
  if (!('dob' in value) || value['dob'] === undefined) return false;
  return true;
}

export function AcceptInviteRequestFromJSON(json: any): AcceptInviteRequest {
  return AcceptInviteRequestFromJSONTyped(json, false);
}

export function AcceptInviteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AcceptInviteRequest {
  if (json == null) {
    return json;
  }
  return {
    inviteCode: json['inviteCode'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    addressLine1: json['addressLine1'],
    addressLine2:
      json['addressLine2'] == null ? undefined : json['addressLine2'],
    city: json['city'],
    state: json['state'],
    zip: json['zip'],
    dob: new Date(json['dob']),
  };
}

export function AcceptInviteRequestToJSON(
  value?: AcceptInviteRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    inviteCode: value['inviteCode'],
    firstName: value['firstName'],
    lastName: value['lastName'],
    addressLine1: value['addressLine1'],
    addressLine2: value['addressLine2'],
    city: value['city'],
    state: value['state'],
    zip: value['zip'],
    dob: value['dob'].toISOString().substring(0, 10),
  };
}
