import { Flex, Text, VStack } from '@chakra-ui/react';
import currency from 'currency.js';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';

export const ChooseAmount = () => {
  const { send } = LoanPaymentContext.useActorRef();
  const { loanDetails, lastUpdated } = LoanPaymentContext.useSelector(
    (state) => state.context
  );

  const handleClick = (value: string) => {
    send({ type: 'NEXT', paymentAmount: value });
  };

  return (
    <Flex flexDirection="column" width="100%" fontFamily="inter">
      <VStack width="100%" mt="2rem" spacing="1rem">
        <Flex
          direction="column"
          width="100%"
          p="24px"
          borderRadius="8px"
          border="solid 1px #CBD5E0"
          cursor="pointer"
          _hover={{ background: 'gray.50' }}
          onClick={() =>
            handleClick(loanDetails?.currentBalance?.toString() ?? '')
          }
        >
          <Text fontSize="16px" fontWeight="bold">
            {currency(loanDetails?.currentBalance?.toString() ?? '').format()}
          </Text>
          <Text color="gray.500">Current Principal Balance</Text>
        </Flex>
        <Flex
          width="100%"
          p="24px"
          direction="column"
          borderRadius="8px"
          border="solid 1px #CBD5E0"
          cursor="pointer"
          onClick={() =>
            handleClick(loanDetails?.interestAccruedToDate?.toString() ?? '')
          }
          _hover={{ background: 'gray.50' }}
        >
          <Text fontSize="16px" fontWeight="bold">
            {currency(
              loanDetails?.interestAccruedToDate?.toString() ?? ''
            ).format()}
          </Text>
          <Text color="gray.500">Interest Only</Text>
        </Flex>
        <Flex
          width="100%"
          p="24px"
          direction="column"
          borderRadius="8px"
          border="solid 1px #CBD5E0"
          cursor="pointer"
          _hover={{ background: 'gray.50' }}
          onClick={() => send({ type: 'CUSTOM_AMOUNT' })}
        >
          <Text fontSize="16px" fontWeight="bold">
            Custom Amount
          </Text>
        </Flex>
      </VStack>
      <Text
        fontSize="14px"
        fontFamily="inter"
        textAlign="center"
        mt="1rem"
        color="gray.500"
      >
        Loan data updated {lastUpdated}
      </Text>
    </Flex>
  );
};
