import { useMutation } from '@tanstack/react-query';
import useWalletsClient from './useWalletsClient';
import { useWalletStore } from 'stores';
import { LinkWalletRequest } from 'api/wallet-app';
import { v4 as uuid } from 'uuid';
import { useKeycloak } from '@react-keycloak/web';
import { useToast } from '@chakra-ui/react';

export default function useLinkWallet(redirectUri: string) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const walletsClient = useWalletsClient();
  const { keycloak } = useKeycloak();
  const toast = useToast();
  return useMutation({
    mutationKey: ['link-wallet'],
    mutationFn: (payload: LinkWalletRequest) => {
      return walletsClient.linkWallet({
        xWalletId: walletId,
        linkWalletRequest: payload,
        idempotencyKey: uuid(),
      });
    },
    onError: () => {
      // due to there not being any SSO, we want to ensure that if a user comes to wallet
      // with an existing session and tries to link anything, we log them out first
      toast({
        title: 'Please Log In',
        description: 'You will need to log in again to complete this action.',
        status: 'error',
        position: 'top',
        duration: 3500,
        isClosable: false,
        onCloseComplete: () => {
          keycloak.logout();
        },
      });
    },
    onSuccess: () => {
      window.location.href = redirectUri;
    },
  });
}
