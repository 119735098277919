/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  CancelPaymentRequestRequest,
  CancelPaymentRequestResponse,
  CreatePaymentRequest,
  CreatePaymentRequestResponse,
  DeclinePaymentRequestRequest,
  DeclinePaymentRequestResponse,
  DefaultErrorResponse,
  NotAcceptablePaymentRequestResponse,
  PaymentRequestDetails,
  PendingPaymentRequests,
  ViolationsError,
} from '../models/index';
import {
  CancelPaymentRequestRequestFromJSON,
  CancelPaymentRequestRequestToJSON,
  CancelPaymentRequestResponseFromJSON,
  CancelPaymentRequestResponseToJSON,
  CreatePaymentRequestFromJSON,
  CreatePaymentRequestToJSON,
  CreatePaymentRequestResponseFromJSON,
  CreatePaymentRequestResponseToJSON,
  DeclinePaymentRequestRequestFromJSON,
  DeclinePaymentRequestRequestToJSON,
  DeclinePaymentRequestResponseFromJSON,
  DeclinePaymentRequestResponseToJSON,
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  NotAcceptablePaymentRequestResponseFromJSON,
  NotAcceptablePaymentRequestResponseToJSON,
  PaymentRequestDetailsFromJSON,
  PaymentRequestDetailsToJSON,
  PendingPaymentRequestsFromJSON,
  PendingPaymentRequestsToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
} from '../models/index';

export interface CancelPaymentRequestOperationRequest {
  xWalletId: string;
  cancelPaymentRequestRequest: CancelPaymentRequestRequest;
}

export interface DeclinePaymentRequestOperationRequest {
  xWalletId: string;
  declinePaymentRequestRequest: DeclinePaymentRequestRequest;
}

export interface GetPaymentRequestDetailsRequest {
  xWalletId: string;
  paymentRequestId: string;
}

export interface GetPendingPaymentRequestsRequest {
  xWalletId: string;
}

export interface RequestPaymentRequest {
  xWalletId: string;
  createPaymentRequest: CreatePaymentRequest;
}

export interface SendReminderPaymentRequestRequest {
  xWalletId: string;
  paymentRequestId: string;
}

/**
 *
 */
export class PaymentRequestApi extends runtime.BaseAPI {
  /**
   * Cancel a payment request.
   */
  async cancelPaymentRequestRaw(
    requestParameters: CancelPaymentRequestOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CancelPaymentRequestResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling cancelPaymentRequest().'
      );
    }

    if (requestParameters['cancelPaymentRequestRequest'] == null) {
      throw new runtime.RequiredError(
        'cancelPaymentRequestRequest',
        'Required parameter "cancelPaymentRequestRequest" was null or undefined when calling cancelPaymentRequest().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/cancel-payment-request`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CancelPaymentRequestRequestToJSON(
          requestParameters['cancelPaymentRequestRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CancelPaymentRequestResponseFromJSON(jsonValue)
    );
  }

  /**
   * Cancel a payment request.
   */
  async cancelPaymentRequest(
    requestParameters: CancelPaymentRequestOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CancelPaymentRequestResponse> {
    const response = await this.cancelPaymentRequestRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Decline a payment request.
   */
  async declinePaymentRequestRaw(
    requestParameters: DeclinePaymentRequestOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DeclinePaymentRequestResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling declinePaymentRequest().'
      );
    }

    if (requestParameters['declinePaymentRequestRequest'] == null) {
      throw new runtime.RequiredError(
        'declinePaymentRequestRequest',
        'Required parameter "declinePaymentRequestRequest" was null or undefined when calling declinePaymentRequest().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/decline-payment-request`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeclinePaymentRequestRequestToJSON(
          requestParameters['declinePaymentRequestRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DeclinePaymentRequestResponseFromJSON(jsonValue)
    );
  }

  /**
   * Decline a payment request.
   */
  async declinePaymentRequest(
    requestParameters: DeclinePaymentRequestOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DeclinePaymentRequestResponse> {
    const response = await this.declinePaymentRequestRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get payment request details.
   */
  async getPaymentRequestDetailsRaw(
    requestParameters: GetPaymentRequestDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PaymentRequestDetails>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getPaymentRequestDetails().'
      );
    }

    if (requestParameters['paymentRequestId'] == null) {
      throw new runtime.RequiredError(
        'paymentRequestId',
        'Required parameter "paymentRequestId" was null or undefined when calling getPaymentRequestDetails().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/payment-request/{paymentRequestId}`.replace(
          `{${'paymentRequestId'}}`,
          encodeURIComponent(String(requestParameters['paymentRequestId']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaymentRequestDetailsFromJSON(jsonValue)
    );
  }

  /**
   * Get payment request details.
   */
  async getPaymentRequestDetails(
    requestParameters: GetPaymentRequestDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PaymentRequestDetails> {
    const response = await this.getPaymentRequestDetailsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get pending payment requests.
   */
  async getPendingPaymentRequestsRaw(
    requestParameters: GetPendingPaymentRequestsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PendingPaymentRequests>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getPendingPaymentRequests().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/pending-payment-requests`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PendingPaymentRequestsFromJSON(jsonValue)
    );
  }

  /**
   * Get pending payment requests.
   */
  async getPendingPaymentRequests(
    requestParameters: GetPendingPaymentRequestsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PendingPaymentRequests> {
    const response = await this.getPendingPaymentRequestsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create a payment request.
   */
  async requestPaymentRaw(
    requestParameters: RequestPaymentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreatePaymentRequestResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling requestPayment().'
      );
    }

    if (requestParameters['createPaymentRequest'] == null) {
      throw new runtime.RequiredError(
        'createPaymentRequest',
        'Required parameter "createPaymentRequest" was null or undefined when calling requestPayment().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/request-payment`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreatePaymentRequestToJSON(
          requestParameters['createPaymentRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreatePaymentRequestResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create a payment request.
   */
  async requestPayment(
    requestParameters: RequestPaymentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreatePaymentRequestResponse> {
    const response = await this.requestPaymentRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Send reminder for payment request.
   */
  async sendReminderPaymentRequestRaw(
    requestParameters: SendReminderPaymentRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling sendReminderPaymentRequest().'
      );
    }

    if (requestParameters['paymentRequestId'] == null) {
      throw new runtime.RequiredError(
        'paymentRequestId',
        'Required parameter "paymentRequestId" was null or undefined when calling sendReminderPaymentRequest().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/payment-request/{paymentRequestId}/remind`.replace(
          `{${'paymentRequestId'}}`,
          encodeURIComponent(String(requestParameters['paymentRequestId']))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send reminder for payment request.
   */
  async sendReminderPaymentRequest(
    requestParameters: SendReminderPaymentRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.sendReminderPaymentRequestRaw(requestParameters, initOverrides);
  }
}
