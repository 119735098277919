import { HStack, IconButton, Image } from '@chakra-ui/react';
import { IoMenu } from 'react-icons/io5';
import logo from 'images/bushel-logo.png';

type NavProps = {
  toggleMenu: () => void;
  hideNavToggler: boolean;
};

export function Header({ toggleMenu, hideNavToggler }: NavProps) {
  return (
    <HStack
      justifyContent={['center', 'space-between']}
      alignItems="center"
      p={4}
      width="100%"
      borderBottom="solid 1px"
      borderColor="border"
      position={{ base: 'fixed', sm: 'initial' }}
      zIndex={2}
      background="white"
    >
      {!hideNavToggler && (
        <IconButton
          icon={<IoMenu size={22} />}
          aria-label="menu-button"
          bg="white"
          onClick={toggleMenu}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              toggleMenu();
            }
          }}
          minW="32px"
          minWidth="32px"
          w="32px"
          maxH="32px"
          borderRadius="100%"
          tabIndex={0}
          color="black"
          data-testid="toggle-menu"
          _hover={{
            boxShadow: '0px 1px 6px rgba(45, 55, 72, 0.5)',
          }}
          _active={{
            backgroundColor: 'gray.400',
            color: 'white',
          }}
          _focus={{
            border: 'blue.500',
          }}
        />
      )}
      <Image src={logo} height="23px" />
      <div />
    </HStack>
  );
}
