/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AptoTransactionMetadata } from './AptoTransactionMetadata';
import {
  AptoTransactionMetadataFromJSON,
  AptoTransactionMetadataFromJSONTyped,
  AptoTransactionMetadataToJSON,
} from './AptoTransactionMetadata';
import type { TransactionDetailsDto } from './TransactionDetailsDto';
import {
  TransactionDetailsDtoFromJSON,
  TransactionDetailsDtoFromJSONTyped,
  TransactionDetailsDtoToJSON,
} from './TransactionDetailsDto';
import type { Link } from './Link';
import { LinkFromJSON, LinkFromJSONTyped, LinkToJSON } from './Link';

/**
 * The transaction metadata response.
 * @export
 * @interface TransactionMetadataResponse
 */
export interface TransactionMetadataResponse {
  /**
   *
   * @type {TransactionDetailsDto}
   * @memberof TransactionMetadataResponse
   */
  transactionDetails: TransactionDetailsDto;
  /**
   *
   * @type {Link}
   * @memberof TransactionMetadataResponse
   */
  link?: Link;
  /**
   *
   * @type {AptoTransactionMetadata}
   * @memberof TransactionMetadataResponse
   */
  debit?: AptoTransactionMetadata;
}

/**
 * Check if a given object implements the TransactionMetadataResponse interface.
 */
export function instanceOfTransactionMetadataResponse(
  value: object
): value is TransactionMetadataResponse {
  if (
    !('transactionDetails' in value) ||
    value['transactionDetails'] === undefined
  )
    return false;
  return true;
}

export function TransactionMetadataResponseFromJSON(
  json: any
): TransactionMetadataResponse {
  return TransactionMetadataResponseFromJSONTyped(json, false);
}

export function TransactionMetadataResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TransactionMetadataResponse {
  if (json == null) {
    return json;
  }
  return {
    transactionDetails: TransactionDetailsDtoFromJSON(
      json['transactionDetails']
    ),
    link: json['link'] == null ? undefined : LinkFromJSON(json['link']),
    debit:
      json['debit'] == null
        ? undefined
        : AptoTransactionMetadataFromJSON(json['debit']),
  };
}

export function TransactionMetadataResponseToJSON(
  value?: TransactionMetadataResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    transactionDetails: TransactionDetailsDtoToJSON(
      value['transactionDetails']
    ),
    link: LinkToJSON(value['link']),
    debit: AptoTransactionMetadataToJSON(value['debit']),
  };
}
