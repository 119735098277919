import React from 'react';
import { Body, Header, Footer } from 'components/layout/onboarding';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { SubmitButton } from 'components/submitButton';
import { useSelector } from '@xstate/react';
import { PersonDto, PersonDtoRolesEnum } from 'api/wallet-app';
import { PersonalInfoForm } from 'pages/Onboarding/sharedComponents/PersonalInfo';

export type PersonInputs = {
  id: string;
  firstName: string;
  lastName: string;
  day: string;
  month: string;
  year: string;
  phone: string;
  email: string;
  roles: PersonDtoRolesEnum[];
  jobTitle?: string;
};

export type PersonalInfoType = Partial<PersonDto> & {
  month?: string;
  day?: string;
  year?: string;
};

export const PersonalInfo = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);

  // if bday data exists, we need to mask bday inputs
  // and not check them on submission unless a person dirties them

  const existingDateOfBirth = React.useRef(false);

  const getDefaultValues = () => {
    if (!context?.people) return;

    const person: PersonalInfoType = context.people[0];

    //@ts-ignore
    if (person && typeof person?.dateOfBirth !== 'undefined') {
      existingDateOfBirth.current = true;
    }

    return person;
  };

  const handleSubmit: SubmitHandler<Partial<PersonInputs>> = (data) => {
    if (!onboardingActor) return;
    const birthdateToString = `${data.year}-${data.month}-${data.day}`;

    const payload: Partial<PersonDto> = {
      ...data,
      dateOfBirth: birthdateToString,
      roles: [PersonDtoRolesEnum.SoleProprietor],
    };

    if (existingDateOfBirth.current) {
      delete payload.dateOfBirth;
    }

    onboardingActor.send({
      type: 'submitInfo',
      value: {
        payload,
        target: 'controller',
      },
    });
  };

  const methods = useForm<Partial<PersonInputs>>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
  });
  const {
    handleSubmit: hookFormSubmit,
    formState: { errors },
    register,
    resetField,
    setValue,
  } = useForm<Partial<PersonInputs>>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
  });

  return (
    <FormProvider {...methods}>
      <Header title="Personal Info" />
      <Body>
        <form
          id="personal-info"
          data-testid="personal-info"
          onSubmit={hookFormSubmit(handleSubmit)}
          noValidate
        >
          <PersonalInfoForm
            register={register}
            errors={errors}
            hasDateOfBirth={existingDateOfBirth.current}
            resetField={resetField}
            setValue={setValue}
          />
        </form>
      </Body>
      <Footer>
        <SubmitButton form="personal-info" />
      </Footer>
    </FormProvider>
  );
};
