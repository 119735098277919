import {
  Table as ChakraTable,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from '@chakra-ui/react';
import { numberToPercent } from '../../utils/number-utils';

export type TableData<T extends Record<string, any> = Record<string, any>> = T;
export type ColumnProps = Record<string, any> & {
  heading: string;
  id: string;
  width?: number;
  emptyStateValue?: string;
};

export default function Table({
  columns = [],
  tableData = [],
}: {
  columns?: ColumnProps[];
  tableData?: TableData[];
}) {
  return (
    <ChakraTable style={{ tableLayout: 'fixed', width: '100%' }}>
      {columns.length > 0 && (
        <Thead>
          <Tr>
            {columns.map(({ heading, width }, headingKey) => {
              return (
                <Th
                  key={`heading-${headingKey}`}
                  w={numberToPercent(width ? width : 1 / columns.length)}
                  px={3}
                >
                  {heading}
                </Th>
              );
            })}
          </Tr>
        </Thead>
      )}
      <Tbody>
        {tableData.length === 0 && (
          <Tr>
            {columns.map(({ id, emptyStateValue }) => {
              return (
                <Td key={`row-${id}`} px={3}>
                  {emptyStateValue ?? '-'}
                </Td>
              );
            })}
          </Tr>
        )}
        {tableData?.map((rowData, rowKey) => {
          return (
            <Tr key={`row-${rowKey}`}>
              {columns.length > 0
                ? columns.map(({ id, width }) => {
                    return (
                      <Td
                        key={`row-${rowKey}-${id}`}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        maxW={numberToPercent(
                          width ? width : 1 / columns.length
                        )}
                        px={3}
                      >
                        {!!rowData[id] ? rowData[id] : '-'}
                      </Td>
                    );
                  })
                : Object.entries(rowData).map(([cellKey, value]) => (
                    <Td
                      key={`row-${rowKey}-${cellKey}`}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      maxW={numberToPercent(1 / columns.length)}
                    >
                      {value}
                    </Td>
                  ))}
            </Tr>
          );
        })}
      </Tbody>
    </ChakraTable>
  );
}
