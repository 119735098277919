import React from 'react';
import {
  Flex,
  Button,
  Box,
  HStack,
  Heading,
  Icon,
  Image,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import { FundingSourceContextMenu } from './FundingSourceContextMenu';
import { LiabilityContextMenu } from './LiabilityContextMenu';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import BankIcon from 'images/bank-icon.svg';
import { FundingSourceDto, FundingSourceDtoTypeEnum } from 'api/wallet-app';

export const FundingSource = ({
  fundingSource,
  setAsDefault,
  isPreferred,
  handleRenameSource,
  handleRemoveSource,
  isOnlyFundingSource,
}: {
  fundingSource: FundingSourceDto;
  setAsDefault: (fundingSourceId: string) => void;
  isPreferred: boolean;
  handleRenameSource: (sourceId: string, name: string) => void;
  handleRemoveSource: (sourceId: string) => void;
  isOnlyFundingSource: boolean;
}) => {
  const [isEditingName, setIsEditingName] = React.useState(false);
  const [controlledName, updateName] = React.useState(fundingSource.name);

  const isLiability = fundingSource.type === FundingSourceDtoTypeEnum.Liability;

  const handleSetDefault = () => {
    setAsDefault(fundingSource.fundingSourceId);
  };

  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleEditName = () => {
    setIsEditingName(true);
    const source = inputRef.current;
    window.setTimeout(() => {
      source && source.focus();
    }, 1);

    return false;
  };

  const removeSource = () => {
    handleRemoveSource(fundingSource.fundingSourceId);
  };

  const setNewName = (name: string) => {
    setIsEditingName(false);
    updateName(name);
    handleRenameSource(fundingSource.fundingSourceId, name);
  };

  const {
    onOpen: onLiabilityOpen,
    onClose: onLiabilityClose,
    isOpen: isLiabilityOpen,
  } = useDisclosure();

  return (
    <Flex
      backgroundColor="white"
      flex="1"
      width="100%"
      padding="1.5rem 1rem"
      _hover={isLiability ? { bgColor: 'gray.50', cursor: 'pointer' } : {}}
      onClick={isLiability ? onLiabilityOpen : undefined}
    >
      <Flex
        width={['35px', '65px']}
        mr={['20px', 'auto']}
        alignItems="center"
        justifyContent="center"
      >
        {fundingSource.icon ? (
          <Image
            width="35px"
            objectFit="contain"
            height="35px"
            src={atob(fundingSource.icon)}
          />
        ) : (
          <Image width="30px" height="30px" src={BankIcon} />
        )}
      </Flex>
      <Flex flexDirection="column" flex="10">
        <HStack spacing="8px" width="100%">
          <Input
            defaultValue={controlledName}
            ref={inputRef}
            onBlur={(e) => {
              setNewName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setNewName(e.currentTarget.value);
              }
            }}
            display={isEditingName ? 'inline' : 'none'}
            mb="4px"
            maxLength={50}
          />
          <Heading
            as="h4"
            fontSize="18px"
            ml="0"
            pl="0"
            marginInlineStart="0!important"
            display={isEditingName ? 'none' : 'auto'}
            maxWidth={['180px', '300px', '500px', '500px', '900px']}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {controlledName}
          </Heading>
        </HStack>
        <HStack spacing="8px" mt="5px">
          <Box
            width={['85px', 'auto']}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontStyle="bold"
          >
            {fundingSource.financialInstitutionName}
          </Box>
          <Box color="gray.500">{fundingSource.accountNumber}</Box>
          <Flex>
            {isPreferred && (
              <>
                <Box mr="8px">&#8226;</Box>
                <Box color="blue.400">{t('wallet.accounts.preferred')}</Box>
              </>
            )}
          </Flex>
        </HStack>
      </Flex>
      <Flex
        flex="1"
        minHeight="100%"
        justifyContent="flex-end"
        alignItems="center"
      >
        {fundingSource.type === 'PLAID' ? (
          <FundingSourceContextMenu
            isPreferred={isPreferred}
            isOnlyFundingSource={isOnlyFundingSource}
            handleEditName={handleEditName}
            handleRemoveSource={removeSource}
            handleSetDefault={handleSetDefault}
          />
        ) : (
          <LiabilityContextMenu
            fundingSource={fundingSource}
            onClose={onLiabilityClose}
            isOpen={isLiabilityOpen}
          />
        )}
      </Flex>
    </Flex>
  );
};

export const DisabledFundingSource = (params: FundingSourceDto) => {
  const { t } = useTranslation();
  return (
    <Flex
      padding="24px"
      backgroundColor="#f9fafb"
      _first={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
      _last={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
      boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
      color="gray.400"
      fontStyle="italic"
    >
      <Flex flexDirection="column">
        <Heading as="h4" color="gray.400" fontSize="1.5rem" mb="8px">
          {params.name}
        </Heading>
        <HStack spacing="8px">
          <Box mr="8px">{params.financialInstitutionName}</Box>
          <Box fontWeight="bold">{t('wallet.accounts.pending')}</Box>
        </HStack>
      </Flex>
      <Flex flex="6" alignItems="center" justifyContent="flex-end">
        <Button variant="link">{t('button.verifyAccount')}</Button>
      </Flex>
      <Flex flex="1" justifyContent="flex-end" alignItems="center">
        <Icon as={IoEllipsisVerticalSharp} _hover={{ cursor: 'pointer' }} />
      </Flex>
    </Flex>
  );
};
