/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CompeerLoanFundingSourceDetailsDto } from './CompeerLoanFundingSourceDetailsDto';
import {
  instanceOfCompeerLoanFundingSourceDetailsDto,
  CompeerLoanFundingSourceDetailsDtoFromJSON,
  CompeerLoanFundingSourceDetailsDtoFromJSONTyped,
  CompeerLoanFundingSourceDetailsDtoToJSON,
} from './CompeerLoanFundingSourceDetailsDto';
import type { PlaidFundingSourceDetailsDto } from './PlaidFundingSourceDetailsDto';
import {
  instanceOfPlaidFundingSourceDetailsDto,
  PlaidFundingSourceDetailsDtoFromJSON,
  PlaidFundingSourceDetailsDtoFromJSONTyped,
  PlaidFundingSourceDetailsDtoToJSON,
} from './PlaidFundingSourceDetailsDto';

/**
 * @type FundingSourceDetailsDto
 * Funding source details.
 * @export
 */
export type FundingSourceDetailsDto =
  | ({ type: 'COMPEER' } & CompeerLoanFundingSourceDetailsDto)
  | ({ type: 'PLAID' } & PlaidFundingSourceDetailsDto);

export function FundingSourceDetailsDtoFromJSON(
  json: any
): FundingSourceDetailsDto {
  return FundingSourceDetailsDtoFromJSONTyped(json, false);
}

export function FundingSourceDetailsDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundingSourceDetailsDto {
  if (json == null) {
    return json;
  }
  switch (json['type']) {
    case 'COMPEER':
      return Object.assign(
        {},
        CompeerLoanFundingSourceDetailsDtoFromJSONTyped(json, true),
        { type: 'COMPEER' } as const
      );
    case 'PLAID':
      return Object.assign(
        {},
        PlaidFundingSourceDetailsDtoFromJSONTyped(json, true),
        { type: 'PLAID' } as const
      );
    default:
      throw new Error(
        `No variant of FundingSourceDetailsDto exists with 'type=${json['type']}'`
      );
  }
}

export function FundingSourceDetailsDtoToJSON(
  value?: FundingSourceDetailsDto | null
): any {
  if (value == null) {
    return value;
  }
  switch (value['type']) {
    case 'COMPEER':
      return CompeerLoanFundingSourceDetailsDtoToJSON(value);
    case 'PLAID':
      return PlaidFundingSourceDetailsDtoToJSON(value);
    default:
      throw new Error(
        `No variant of FundingSourceDetailsDto exists with 'type=${value['type']}'`
      );
  }
}
