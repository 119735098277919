/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The command to invite a user.
 * @export
 * @interface InviteUserCmd
 */
export interface InviteUserCmd {
  /**
   *
   * @type {string}
   * @memberof InviteUserCmd
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserCmd
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserCmd
   */
  email: string;
}

/**
 * Check if a given object implements the InviteUserCmd interface.
 */
export function instanceOfInviteUserCmd(value: object): value is InviteUserCmd {
  if (!('firstName' in value) || value['firstName'] === undefined) return false;
  if (!('lastName' in value) || value['lastName'] === undefined) return false;
  if (!('email' in value) || value['email'] === undefined) return false;
  return true;
}

export function InviteUserCmdFromJSON(json: any): InviteUserCmd {
  return InviteUserCmdFromJSONTyped(json, false);
}

export function InviteUserCmdFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteUserCmd {
  if (json == null) {
    return json;
  }
  return {
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
  };
}

export function InviteUserCmdToJSON(value?: InviteUserCmd | null): any {
  if (value == null) {
    return value;
  }
  return {
    firstName: value['firstName'],
    lastName: value['lastName'],
    email: value['email'],
  };
}
