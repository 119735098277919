/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Sector } from './Sector';
import { SectorFromJSON, SectorFromJSONTyped, SectorToJSON } from './Sector';

/**
 *
 * @export
 * @interface GetSectorClassificationCodesResponse
 */
export interface GetSectorClassificationCodesResponse {
  /**
   *
   * @type {Array<Sector>}
   * @memberof GetSectorClassificationCodesResponse
   */
  sectorClassifications: Array<Sector>;
}

/**
 * Check if a given object implements the GetSectorClassificationCodesResponse interface.
 */
export function instanceOfGetSectorClassificationCodesResponse(
  value: object
): value is GetSectorClassificationCodesResponse {
  if (
    !('sectorClassifications' in value) ||
    value['sectorClassifications'] === undefined
  )
    return false;
  return true;
}

export function GetSectorClassificationCodesResponseFromJSON(
  json: any
): GetSectorClassificationCodesResponse {
  return GetSectorClassificationCodesResponseFromJSONTyped(json, false);
}

export function GetSectorClassificationCodesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSectorClassificationCodesResponse {
  if (json == null) {
    return json;
  }
  return {
    sectorClassifications: (json['sectorClassifications'] as Array<any>).map(
      SectorFromJSON
    ),
  };
}

export function GetSectorClassificationCodesResponseToJSON(
  value?: GetSectorClassificationCodesResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    sectorClassifications: (value['sectorClassifications'] as Array<any>).map(
      SectorToJSON
    ),
  };
}
