import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Panel } from './panel';
import { background } from 'utils/helpers';

export const PanelWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <Box
      position="fixed"
      height="100%"
      width="100%"
      overflow="scroll"
      className="panelwrapper"
      id="content-container"
    >
      <Flex
        className="content"
        flexDirection="column"
        width={['auto', '100%']}
        height="100%"
        minHeight="100%"
        backgroundImage={['null', 'null', 'null', background]}
        backgroundRepeat="no-repeat"
        mt={[0, '3rem']}
      >
        <Panel>{children}</Panel>
      </Flex>
    </Box>
  );
};
