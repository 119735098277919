/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DebitCardAmount
 */
export interface DebitCardAmount {
  /**
   *
   * @type {string}
   * @memberof DebitCardAmount
   */
  amount?: string;
  /**
   *
   * @type {string}
   * @memberof DebitCardAmount
   */
  currency: string;
}

/**
 * Check if a given object implements the DebitCardAmount interface.
 */
export function instanceOfDebitCardAmount(
  value: object
): value is DebitCardAmount {
  if (!('currency' in value) || value['currency'] === undefined) return false;
  return true;
}

export function DebitCardAmountFromJSON(json: any): DebitCardAmount {
  return DebitCardAmountFromJSONTyped(json, false);
}

export function DebitCardAmountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DebitCardAmount {
  if (json == null) {
    return json;
  }
  return {
    amount: json['amount'] == null ? undefined : json['amount'],
    currency: json['currency'],
  };
}

export function DebitCardAmountToJSON(value?: DebitCardAmount | null): any {
  if (value == null) {
    return value;
  }
  return {
    amount: value['amount'],
    currency: value['currency'],
  };
}
