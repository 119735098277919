/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActionItem } from './ActionItem';
import {
  ActionItemFromJSON,
  ActionItemFromJSONTyped,
  ActionItemToJSON,
} from './ActionItem';

/**
 *
 * @export
 * @interface VerifyMicroDeposits
 */
export interface VerifyMicroDeposits extends ActionItem {
  /**
   *
   * @type {string}
   * @memberof VerifyMicroDeposits
   */
  fundingSourceId: string;
}

/**
 * Check if a given object implements the VerifyMicroDeposits interface.
 */
export function instanceOfVerifyMicroDeposits(
  value: object
): value is VerifyMicroDeposits {
  if (!('fundingSourceId' in value) || value['fundingSourceId'] === undefined)
    return false;
  return true;
}

export function VerifyMicroDepositsFromJSON(json: any): VerifyMicroDeposits {
  return VerifyMicroDepositsFromJSONTyped(json, false);
}

export function VerifyMicroDepositsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VerifyMicroDeposits {
  if (json == null) {
    return json;
  }
  return {
    ...ActionItemFromJSONTyped(json, ignoreDiscriminator),
    fundingSourceId: json['fundingSourceId'],
  };
}

export function VerifyMicroDepositsToJSON(
  value?: VerifyMicroDeposits | null
): any {
  if (value == null) {
    return value;
  }
  return {
    ...ActionItemToJSON(value),
    fundingSourceId: value['fundingSourceId'],
  };
}
