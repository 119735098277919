/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NonBalanceAffectingTransaction } from './NonBalanceAffectingTransaction';
import {
  NonBalanceAffectingTransactionFromJSON,
  NonBalanceAffectingTransactionFromJSONTyped,
  NonBalanceAffectingTransactionToJSON,
} from './NonBalanceAffectingTransaction';
import type { BalanceAffectingTransaction } from './BalanceAffectingTransaction';
import {
  BalanceAffectingTransactionFromJSON,
  BalanceAffectingTransactionFromJSONTyped,
  BalanceAffectingTransactionToJSON,
} from './BalanceAffectingTransaction';

/**
 *
 * @export
 * @interface TransactionStatementData
 */
export interface TransactionStatementData {
  /**
   *
   * @type {string}
   * @memberof TransactionStatementData
   */
  beginningBalance: string;
  /**
   *
   * @type {string}
   * @memberof TransactionStatementData
   */
  endingBalance: string;
  /**
   *
   * @type {string}
   * @memberof TransactionStatementData
   */
  creditsTotal: string;
  /**
   *
   * @type {string}
   * @memberof TransactionStatementData
   */
  debitsTotal: string;
  /**
   *
   * @type {Array<BalanceAffectingTransaction>}
   * @memberof TransactionStatementData
   */
  transactions: Array<BalanceAffectingTransaction>;
  /**
   *
   * @type {Array<NonBalanceAffectingTransaction>}
   * @memberof TransactionStatementData
   */
  nonBalanceAffecting: Array<NonBalanceAffectingTransaction>;
}

/**
 * Check if a given object implements the TransactionStatementData interface.
 */
export function instanceOfTransactionStatementData(
  value: object
): value is TransactionStatementData {
  if (!('beginningBalance' in value) || value['beginningBalance'] === undefined)
    return false;
  if (!('endingBalance' in value) || value['endingBalance'] === undefined)
    return false;
  if (!('creditsTotal' in value) || value['creditsTotal'] === undefined)
    return false;
  if (!('debitsTotal' in value) || value['debitsTotal'] === undefined)
    return false;
  if (!('transactions' in value) || value['transactions'] === undefined)
    return false;
  if (
    !('nonBalanceAffecting' in value) ||
    value['nonBalanceAffecting'] === undefined
  )
    return false;
  return true;
}

export function TransactionStatementDataFromJSON(
  json: any
): TransactionStatementData {
  return TransactionStatementDataFromJSONTyped(json, false);
}

export function TransactionStatementDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TransactionStatementData {
  if (json == null) {
    return json;
  }
  return {
    beginningBalance: json['beginningBalance'],
    endingBalance: json['endingBalance'],
    creditsTotal: json['creditsTotal'],
    debitsTotal: json['debitsTotal'],
    transactions: (json['transactions'] as Array<any>).map(
      BalanceAffectingTransactionFromJSON
    ),
    nonBalanceAffecting: (json['nonBalanceAffecting'] as Array<any>).map(
      NonBalanceAffectingTransactionFromJSON
    ),
  };
}

export function TransactionStatementDataToJSON(
  value?: TransactionStatementData | null
): any {
  if (value == null) {
    return value;
  }
  return {
    beginningBalance: value['beginningBalance'],
    endingBalance: value['endingBalance'],
    creditsTotal: value['creditsTotal'],
    debitsTotal: value['debitsTotal'],
    transactions: (value['transactions'] as Array<any>).map(
      BalanceAffectingTransactionToJSON
    ),
    nonBalanceAffecting: (value['nonBalanceAffecting'] as Array<any>).map(
      NonBalanceAffectingTransactionToJSON
    ),
  };
}
