import { AlertDto, AlertDtoAlertTypeEnum } from 'api/wallet-app';
import { WALLET_ID } from './wallets-fixtures';

export const alertList: AlertDto[] = [
  {
    alertType: AlertDtoAlertTypeEnum.CanceledPayer,
    amount: '1.23',
    dba: 'Moo Moo Cow',
    dismissible: true,
    id: '08cac792-c408-4ecc-89b2-cdd18d6fcb78',
    resourceId: '827e9bca-b4e7-43c8-a859-d66015c5885f',
    message: 'Moo Moo Cow canceled your request for $1.23',
    walletId: WALLET_ID,
  },
  {
    id: '85fb2e48-8c8a-4148-8482-c2353df8df4e',
    walletId: WALLET_ID,
    alertType: AlertDtoAlertTypeEnum.DeclinedPayee,
    message: "Moo Moo Cow's canceled your request for $65.00",
    resourceId: 'ad663917-7477-4c03-9593-744c7bffe515',
    dismissible: true,
    dba: 'Moo Moo Cow',
    amount: '650.00',
  },
  {
    id: 'a273078c-08b1-4d6f-b83a-f0db78c4eaed',
    alertType: AlertDtoAlertTypeEnum.CreatedPayer,
    amount: '330.00',
    dba: 'Moo Moo Cow',
    dismissible: false,
    message: "Moo Moo Cow's requested payment for $33.00",
    walletId: WALLET_ID,
    resourceId: '550b158a-e5c9-4828-955e-803c9122c31c',
  },
  {
    id: '5dd3ceae-7895-4961-97c4-a235e44c407e',
    alertType: AlertDtoAlertTypeEnum.CreatedPayer,
    amount: '33.00',
    dba: 'Moo Moo Cow',
    dismissible: false,
    message: "Moo Moo Cow's requested payment for $33.00",
    walletId: WALLET_ID,
    resourceId: 'c15df94b-7da5-4ebc-b217-0f1c708c5721',
  },
  {
    id: '55c660a3-9ce5-40cf-9c84-048df92f3193',
    alertType: AlertDtoAlertTypeEnum.CreatedPayer,
    amount: '100.00',
    dba: 'Moo Moo Cow',
    dismissible: false,
    message: "Moo Moo Cow's requested payment for $33.00",
    walletId: WALLET_ID,
    resourceId: '8af9e83f-9d84-4419-8dea-04e735033b6f',
  },
  {
    id: '420303c5-aa8a-4680-a84e-0e7c79501b43',
    alertType: AlertDtoAlertTypeEnum.CreatedPayer,
    amount: '200.00',
    dba: 'Moo Moo Cow',
    dismissible: false,
    message: "Moo Moo Cow's requested payment for $33.00",
    walletId: WALLET_ID,
    resourceId: 'f4659d7f-97bc-4996-a91b-411496c0a590',
  },
];
