import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMediaQuery, useToast } from '@chakra-ui/react';
import { InviteBusinessRequest, ResponseError } from 'api/wallet-app';
import { useWalletStore } from 'stores';
import useWalletsClient from './useWalletsClient';
import { GET_BUSINESS_INVITES_QUERY_KEY } from './useBusinessInviteList';

export default function useInviteBusiness() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const walletsClient = useWalletsClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  const queryClient = useQueryClient();
  return useMutation<void, ResponseError, InviteBusinessRequest>({
    mutationKey: ['invite-business'],
    mutationFn: (payload) => {
      return walletsClient.inviteBusiness({
        xWalletId: walletId,
        inviteBusinessRequest: payload,
      });
    },
    onError: (error) => {
      if (error.response.status === 422) {
        toast({
          status: 'error',
          title: 'Invite Failed',
          description:
            'This email is associated with an existing wallet account.',
        });
      } else {
        toast({
          status: 'error',
          title: 'Invite Failed',
          description: 'Something went wrong sending your invite.',
        });
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [GET_BUSINESS_INVITES_QUERY_KEY],
      });
      toast({
        status: 'success',
        title: 'Invite Sent',
        description:
          'Successfully sent notification to join the Bushel Wallet network.',
      });
    },
  });
}
