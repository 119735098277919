/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface WalletProfileDto
 */
export interface WalletProfileDto {
  /**
   *
   * @type {string}
   * @memberof WalletProfileDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof WalletProfileDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof WalletProfileDto
   */
  handle: string;
  /**
   *
   * @type {string}
   * @memberof WalletProfileDto
   */
  doingBusinessAs?: string;
  /**
   *
   * @type {string}
   * @memberof WalletProfileDto
   */
  location: string;
}

/**
 * Check if a given object implements the WalletProfileDto interface.
 */
export function instanceOfWalletProfileDto(
  value: object
): value is WalletProfileDto {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('handle' in value) || value['handle'] === undefined) return false;
  if (!('location' in value) || value['location'] === undefined) return false;
  return true;
}

export function WalletProfileDtoFromJSON(json: any): WalletProfileDto {
  return WalletProfileDtoFromJSONTyped(json, false);
}

export function WalletProfileDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WalletProfileDto {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    handle: json['handle'],
    doingBusinessAs:
      json['doingBusinessAs'] == null ? undefined : json['doingBusinessAs'],
    location: json['location'],
  };
}

export function WalletProfileDtoToJSON(value?: WalletProfileDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    name: value['name'],
    handle: value['handle'],
    doingBusinessAs: value['doingBusinessAs'],
    location: value['location'],
  };
}
