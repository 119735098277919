import {
  Flex,
  HStack,
  Icon,
  Spacer,
  Spinner,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { UserListItemTypeEnum } from 'api/wallet-app';
import { AuthorizedUserTooltip } from 'components/AuthorizedUserTooltip';
import { useUserInfo } from 'hooks/api';
import { useMemo } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';

export const LinkAccounts = ({
  chooseLinkPath,
}: {
  chooseLinkPath: () => void;
}) => {
  const { data: userInfo, isLoading: userInfoIsLoading } = useUserInfo();

  const [isMobile] = useMediaQuery('(max-width: 480px)');

  const isAuthorizedUser = useMemo(
    () => userInfo?.type === UserListItemTypeEnum.AuthorizedUser,
    [userInfo?.type]
  );

  // TODO: refactor the thing inside of the tooltip to be a IconButton
  return (
    <HStack spacing={0}>
      <AuthorizedUserTooltip placement="top" shouldWrapChildren={false}>
        <Flex
          flex={1}
          justify="center"
          boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
          borderRadius="lg"
          backgroundColor="white"
          minWidth="330px"
          padding={6}
          color="blue.400"
          fontFamily="inter"
          fontWeight="semibold"
          onClick={
            isAuthorizedUser && !userInfoIsLoading
              ? () => {
                  return;
                }
              : chooseLinkPath
          }
          cursor={isAuthorizedUser ? 'inherit' : 'pointer'}
        >
          {userInfoIsLoading ? (
            <Spinner />
          ) : (
            <Flex opacity={isAuthorizedUser ? 0.4 : 1}>
              <Icon
                as={IoAddCircleOutline}
                width="24px"
                height="24px"
                fontWeight="bold"
              />
              <Text ml="8px">Link Accounts</Text>
            </Flex>
          )}
        </Flex>
      </AuthorizedUserTooltip>
      {!isMobile && <Spacer flex={1} />}
    </HStack>
  );
};
