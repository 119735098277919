import React from 'react';
import { Header, Footer } from 'components/layout/onboarding';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import {
  SocialSecurityForm,
  SSN_TEMP_VAL,
} from 'pages/Onboarding/sharedComponents/SocialSecurity';
import { InfoButton } from 'pages/Onboarding/sharedComponents/SocialSecurity/infoButton';
import { useSelector } from '@xstate/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SubmitButton } from 'components/submitButton';
import { cleanupSSN } from 'utils/helpers';
import { Waiting } from 'pages/Onboarding/sharedComponents/Waiting';

type SocialSecurityFormValues = {
  ssn: string;
};

export const SocialSecurity = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const { context } = useSelector(onboardingActor, (state) => state);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<SocialSecurityFormValues>({
    mode: 'onBlur',
    defaultValues: {
      ssn: context?.people.length ? context.people[0].ssn : '',
    },
  });

  const handleSubmit: SubmitHandler<SocialSecurityFormValues> = (data) => {
    const { ssn } = data;

    if (ssn === SSN_TEMP_VAL) {
      onboardingActor?.send({ type: 'skipSSN', value: {} });
      return;
    }

    setIsSubmitting(true);

    onboardingActor?.send({
      type: 'submitSSN',
      value: { payload: cleanupSSN(ssn) },
    });
  };

  const infoButton = <InfoButton />;

  return isSubmitting ? (
    <Waiting />
  ) : (
    <>
      <form
        id="ssn"
        data-testid="ssn"
        onSubmit={hookFormSubmit(handleSubmit)}
        noValidate
      >
        <Header title="Social Security Number" customButton={infoButton} />
        <SocialSecurityForm
          isEditing={context?.isEditing || false}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
        />
      </form>
      <Footer>
        <SubmitButton form="ssn" />
      </Footer>
    </>
  );
};
