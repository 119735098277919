/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreatePaymentRequestResponse
 */
export interface CreatePaymentRequestResponse {
  /**
   *
   * @type {string}
   * @memberof CreatePaymentRequestResponse
   */
  paymentId: string;
}

/**
 * Check if a given object implements the CreatePaymentRequestResponse interface.
 */
export function instanceOfCreatePaymentRequestResponse(
  value: object
): value is CreatePaymentRequestResponse {
  if (!('paymentId' in value) || value['paymentId'] === undefined) return false;
  return true;
}

export function CreatePaymentRequestResponseFromJSON(
  json: any
): CreatePaymentRequestResponse {
  return CreatePaymentRequestResponseFromJSONTyped(json, false);
}

export function CreatePaymentRequestResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreatePaymentRequestResponse {
  if (json == null) {
    return json;
  }
  return {
    paymentId: json['paymentId'],
  };
}

export function CreatePaymentRequestResponseToJSON(
  value?: CreatePaymentRequestResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    paymentId: value['paymentId'],
  };
}
