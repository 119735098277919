import {
  Button,
  Heading,
  Stack,
  TabList,
  Tab,
  Tabs,
  Text,
  TabPanel,
  TabPanels,
  useDisclosure,
  Center,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react';
import { useBusinessInviteList } from 'hooks/api';
import { IoMailOutline } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import { BackButton } from '../components/back-button';
import CompletedInviteTable from './completed-invite-table';
import InviteABusinessModal from './invite-a-business-modal';
import SentInviteTable from './sent-invites-table';

export const TAB_LIST_QUERY_KEY = 'tab';
export const SENT_QUERY_PARAM = 'sent';
const COMPLETED_QUERY_PARAM = 'completed';

export default function InviteCenter() {
  const { data, isLoading, isRefetching } = useBusinessInviteList();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  function getDefaultTabIndex() {
    switch (searchParams.get(TAB_LIST_QUERY_KEY)) {
      case SENT_QUERY_PARAM:
        return 0;
      case COMPLETED_QUERY_PARAM:
        return 1;
    }
  }

  return (
    <>
      <Stack spacing={6} pb={isMobile ? 16 : 6}>
        <BackButton to={'/settings'} />
        <Stack
          direction={['column', 'row']}
          spacing={6}
          p={4}
          boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
          borderRadius="lg"
          backgroundColor="white"
        >
          <Stack spacing={4} flex={1}>
            <Heading as="h4" fontSize="2xl">
              Sending an invite
            </Heading>
            <Button
              leftIcon={<IoMailOutline />}
              w="fit-content"
              onClick={onOpen}
            >
              Send Invite
            </Button>
          </Stack>
          <Stack
            spacing={0}
            flex={1}
            borderRadius="4px"
            border="1px solid #CBD5E0"
            p={2}
            bgColor="#F7FAFC"
          >
            <Text fontWeight="bold">
              Send or request money with more businesses
            </Text>
            <Text>
              Send an invite and once they sign up with Bushel Wallet you can
              start transferring money!
            </Text>
          </Stack>
        </Stack>
        <Stack
          p={4}
          boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
          borderRadius="lg"
          backgroundColor="white"
        >
          {isLoading || isRefetching ? (
            <Center p={6}>
              <Spinner color="blue.400" size="xl" />
            </Center>
          ) : (
            <Stack>
              <Heading as="h5" size="md">
                My Invites
              </Heading>
              <Tabs defaultIndex={getDefaultTabIndex()}>
                <TabList>
                  <Tab
                    fontWeight="semibold"
                    fontStyle="robotoFlex"
                    _selected={{ color: 'blue.400', borderColor: 'blue.400' }}
                    _focus={{}}
                    _active={{}}
                    onClick={() => {
                      searchParams.set(TAB_LIST_QUERY_KEY, SENT_QUERY_PARAM);
                      setSearchParams(searchParams);
                    }}
                  >
                    Sent ({data?.sent.length})
                  </Tab>
                  <Tab
                    fontWeight="semibold"
                    fontStyle="robotoFlex"
                    _selected={{ color: 'blue.400', borderColor: 'blue.400' }}
                    _focus={{}}
                    _active={{}}
                    onClick={() => {
                      searchParams.set(
                        TAB_LIST_QUERY_KEY,
                        COMPLETED_QUERY_PARAM
                      );
                      setSearchParams(searchParams);
                    }}
                  >
                    Completed ({data?.completed.length})
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel px={[0, 3]}>
                    <SentInviteTable invites={data?.sent ?? []} />
                  </TabPanel>
                  <TabPanel px={[0, 3]}>
                    <CompletedInviteTable invites={data?.completed ?? []} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          )}
        </Stack>
      </Stack>
      <InviteABusinessModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
