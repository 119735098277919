/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReviewStateDto } from './ReviewStateDto';
import {
  ReviewStateDtoFromJSON,
  ReviewStateDtoFromJSONTyped,
  ReviewStateDtoToJSON,
} from './ReviewStateDto';

/**
 * Under review state
 * @export
 * @interface UnderReviewState
 */
export interface UnderReviewState {
  /**
   *
   * @type {ReviewStateDto}
   * @memberof UnderReviewState
   */
  reviewState: ReviewStateDto;
}

/**
 * Check if a given object implements the UnderReviewState interface.
 */
export function instanceOfUnderReviewState(
  value: object
): value is UnderReviewState {
  if (!('reviewState' in value) || value['reviewState'] === undefined)
    return false;
  return true;
}

export function UnderReviewStateFromJSON(json: any): UnderReviewState {
  return UnderReviewStateFromJSONTyped(json, false);
}

export function UnderReviewStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UnderReviewState {
  if (json == null) {
    return json;
  }
  return {
    reviewState: ReviewStateDtoFromJSON(json['reviewState']),
  };
}

export function UnderReviewStateToJSON(value?: UnderReviewState | null): any {
  if (value == null) {
    return value;
  }
  return {
    reviewState: ReviewStateDtoToJSON(value['reviewState']),
  };
}
