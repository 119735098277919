import { PropsWithChildren } from 'react';
import { Card, CardBody, CardHeader, Heading, Stack } from '@chakra-ui/react';

export default function SettingsCard({
  heading,
  children,
}: PropsWithChildren<{
  heading: string;
}>) {
  return (
    <Card
      borderRadius="xl"
      boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
    >
      <CardHeader pb={2}>
        <Heading as="h6" fontSize="lg">
          {heading}
        </Heading>
      </CardHeader>
      <CardBody pt={0}>
        <Stack spacing={4}>{children}</Stack>
      </CardBody>
    </Card>
  );
}
