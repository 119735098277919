import { GetTransactionHistoryRequest } from 'api/wallet-app';
import { useQuery } from '@tanstack/react-query';
import useTransactionsClient from './useTransactionsClient';
import { useWalletStore } from 'stores';

export const TRANSACTIONS_LIST_QUERY_KEY = 'transaction-list';

export default function useTransactionsList(
  payload: Omit<GetTransactionHistoryRequest, 'xWalletId'> = {}
) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const transactionClient = useTransactionsClient();
  return useQuery({
    queryKey: [TRANSACTIONS_LIST_QUERY_KEY],
    queryFn: () => {
      return transactionClient.getTransactionHistory({
        xWalletId: walletId,
        ...payload,
      });
    },
  });
}
