/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The command to validate invite.
 * @export
 * @interface ValidateBusinessInviteRequest
 */
export interface ValidateBusinessInviteRequest {
  /**
   *
   * @type {string}
   * @memberof ValidateBusinessInviteRequest
   */
  inviteCode: string;
}

/**
 * Check if a given object implements the ValidateBusinessInviteRequest interface.
 */
export function instanceOfValidateBusinessInviteRequest(
  value: object
): value is ValidateBusinessInviteRequest {
  if (!('inviteCode' in value) || value['inviteCode'] === undefined)
    return false;
  return true;
}

export function ValidateBusinessInviteRequestFromJSON(
  json: any
): ValidateBusinessInviteRequest {
  return ValidateBusinessInviteRequestFromJSONTyped(json, false);
}

export function ValidateBusinessInviteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ValidateBusinessInviteRequest {
  if (json == null) {
    return json;
  }
  return {
    inviteCode: json['inviteCode'],
  };
}

export function ValidateBusinessInviteRequestToJSON(
  value?: ValidateBusinessInviteRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    inviteCode: value['inviteCode'],
  };
}
