import {
  CompeerLoanFundingSourceDetailsDto,
  FundingSourceDto,
} from 'api/wallet-app';
import { setup, assign } from 'xstate';

type LoanPaymentMachine = {
  paymentAmount: string;
  paymentSource: FundingSourceDto | undefined;
  loanDetails: CompeerLoanFundingSourceDetailsDto | undefined;
  walletBalance: string;
  walletId: string;
  fundingSourceId: string;
  allFundingSources: FundingSourceDto[];
  lastUpdated: string;
};

export const LoanPaymentMachine = setup({
  actions: {
    setPaymentAmount: assign({
      paymentAmount: ({ event }) => event.paymentAmount,
    }),
    setPaymentSource: assign({
      paymentSource: ({ event }) => event.paymentSource,
    }),
    setLoanDetails: assign({
      loanDetails: ({ event }) => event.loanDetails,
    }),
    setWalletBalance: assign({
      walletBalance: ({ event }) => event.walletBalance,
    }),
    setWalletId: assign({
      walletId: ({ event }) => event.walletId,
    }),
    setFundingSourceId: assign({
      fundingSourceId: ({ event }) => event.fundingSourceId,
    }),
    setAllFundingSources: assign({
      allFundingSources: ({ event }) => event.allFundingSources,
    }),
    setLastUpdated: assign({
      lastUpdated: ({ event }) => event.lastUpdated,
    }),
  },
  types: {
    context: {} as LoanPaymentMachine,
  },
}).createMachine({
  id: 'LoanPayments',
  initial: 'overview',
  context: {
    loanDetails: undefined,
    paymentAmount: '0.00',
    paymentSource: undefined,
    walletBalance: '',
    walletId: '',
    fundingSourceId: '',
    allFundingSources: [],
    lastUpdated: '',
  } as LoanPaymentMachine,
  on: {
    RESET: {
      target: '.overview',
      actions: ['setPaymentAmount', 'setPaymentSource'],
    },
    SET_LAST_UPDATED: {
      actions: 'setLastUpdated',
    },
  },
  states: {
    overview: {
      on: {
        SET_WALLET_ID: {
          actions: 'setWalletId',
        },
        SET_LOAN_DETAILS: {
          actions: 'setLoanDetails',
        },
        SET_WALLET_BALANCE: {
          actions: 'setWalletBalance',
        },
        SET_FUNDING_SOURCE_ID: {
          actions: 'setFundingSourceId',
        },
        SET_ALL_FUNDING_SOURCES: {
          actions: 'setAllFundingSources',
        },
        CHOOSE_AMOUNT: 'chooseAmount',
        CHOOSE_FUNDING_SOURCE: 'chooseFundingSource',
        SUBMIT_PAYMENT: 'submitPayment',
      },
    },
    chooseAmount: {
      on: {
        BACK: 'overview',
        CUSTOM_AMOUNT: 'customAmount',
        NEXT: {
          target: 'overview',
          actions: 'setPaymentAmount',
        },
      },
    },
    customAmount: {
      on: {
        SET_PAYMENT_AMOUNT: {
          actions: 'setPaymentAmount',
        },
        BACK: 'chooseAmount',
        NEXT: {
          target: 'overview',
        },
      },
    },
    chooseFundingSource: {
      on: {
        BACK: 'overview',
        NEXT: {
          target: 'overview',
          actions: 'setPaymentSource',
        },
      },
    },
    submitPayment: {
      on: {
        SUCCESS: 'success',
        FAIL: 'fail',
      },
    },
    success: {},
    fail: {
      on: {
        RETRY: 'overview',
      },
    },
  },
});
