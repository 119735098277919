import { GetActiveFundingSourcesRequest } from 'api/wallet-app';
import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useFundingSourceClient from './useFundingSourcesClient';

export const FUNDING_SOURCE_LIST_QUERY_KEY = 'funding-source-list';

export default function useFundingSourceList(
  filters: Omit<GetActiveFundingSourcesRequest, 'xWalletId'> = {
    includeLiabilityFundsAvailable: false,
    transactional: false,
  }
) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const fundingSourceClient = useFundingSourceClient();
  return useQuery({
    queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY, filters],
    queryFn: () => {
      return fundingSourceClient.getActiveFundingSources({
        xWalletId: walletId,
        ...filters,
      });
    },
  });
}
