export function mockedRequestsRestApiBasePath({
  path,
  version = 'v1',
}: {
  path: string;
  version?: string;
}) {
  return `*/api/${version}/${path}`;
}

export function walletBasePath(path: string) {
  return mockedRequestsRestApiBasePath({ path: `wallet/${path}` });
}

export function commandsBasePath(path: string) {
  return mockedRequestsRestApiBasePath({ path: `commands/${path}` });
}

export function onboardingClientBasePath(path: string = 'wallet') {
  return mockedRequestsRestApiBasePath({ path: `onboarding/${path}` });
}

export function keycloakRestApiBasePath(path: string) {
  return `*/openid-connect/${path}`;
}
