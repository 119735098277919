import { Flex } from '@chakra-ui/react';
import { EnterValue } from './components/EnterValue';
import { SelectFundingSource } from './components/SelectFundingSource';
import { Confirm } from './components/Confirm';
import { Success } from './components/Success';
import { NumberFormatValues } from 'react-number-format';
import { FundingSourceDto } from 'api/wallet-app';

export type TransferContext = {
  transferAmount: string;
  handleMoneyInput: (amount: NumberFormatValues) => void;
  send: any;
  state: any;
  type: 'LOAD' | 'UNLOAD';
  fundingSource?: FundingSourceDto;
  setFundingSource: (source: FundingSourceDto) => void;
  walletBalance: string | undefined;
  setWalletBalance: (balance: string) => void;
};

export const TransferFlow = ({ context }: { context: TransferContext }) => {
  const { state } = context;

  switch (state.value) {
    case 'enterAmount':
      return <EnterValue context={context} />;
    case 'selectSource':
      return <SelectFundingSource context={context} />;
    case 'confirm':
      return <Confirm context={context} />;
    case 'success':
      return <Success context={context} />;
    default:
      return <Flex />;
  }
};
