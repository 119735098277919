/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FundingSourceDto } from './FundingSourceDto';
import {
  FundingSourceDtoFromJSON,
  FundingSourceDtoFromJSONTyped,
  FundingSourceDtoToJSON,
} from './FundingSourceDto';

/**
 *
 * @export
 * @interface GetActiveFundingSourceResponse
 */
export interface GetActiveFundingSourceResponse {
  /**
   *
   * @type {Array<FundingSourceDto>}
   * @memberof GetActiveFundingSourceResponse
   */
  fundingSources?: Array<FundingSourceDto>;
}

/**
 * Check if a given object implements the GetActiveFundingSourceResponse interface.
 */
export function instanceOfGetActiveFundingSourceResponse(
  value: object
): value is GetActiveFundingSourceResponse {
  return true;
}

export function GetActiveFundingSourceResponseFromJSON(
  json: any
): GetActiveFundingSourceResponse {
  return GetActiveFundingSourceResponseFromJSONTyped(json, false);
}

export function GetActiveFundingSourceResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetActiveFundingSourceResponse {
  if (json == null) {
    return json;
  }
  return {
    fundingSources:
      json['fundingSources'] == null
        ? undefined
        : (json['fundingSources'] as Array<any>).map(FundingSourceDtoFromJSON),
  };
}

export function GetActiveFundingSourceResponseToJSON(
  value?: GetActiveFundingSourceResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSources:
      value['fundingSources'] == null
        ? undefined
        : (value['fundingSources'] as Array<any>).map(FundingSourceDtoToJSON),
  };
}
