/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The Micro Deposit Submitted Request
 * @export
 * @interface MicroDepositSubmittedRequest
 */
export interface MicroDepositSubmittedRequest {
  /**
   *
   * @type {string}
   * @memberof MicroDepositSubmittedRequest
   */
  plaidAccountId: string;
}

/**
 * Check if a given object implements the MicroDepositSubmittedRequest interface.
 */
export function instanceOfMicroDepositSubmittedRequest(
  value: object
): value is MicroDepositSubmittedRequest {
  if (!('plaidAccountId' in value) || value['plaidAccountId'] === undefined)
    return false;
  return true;
}

export function MicroDepositSubmittedRequestFromJSON(
  json: any
): MicroDepositSubmittedRequest {
  return MicroDepositSubmittedRequestFromJSONTyped(json, false);
}

export function MicroDepositSubmittedRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MicroDepositSubmittedRequest {
  if (json == null) {
    return json;
  }
  return {
    plaidAccountId: json['plaidAccountId'],
  };
}

export function MicroDepositSubmittedRequestToJSON(
  value?: MicroDepositSubmittedRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    plaidAccountId: value['plaidAccountId'],
  };
}
