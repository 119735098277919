import { SinglePanelView } from './components/SinglePanelView';
import { Flex } from '@chakra-ui/react';
import { Menu } from './pages/Menu';
import { AddFunds } from './pages/load-unload/AddFunds';
import { WithdrawFunds } from './pages/load-unload/WithdrawFunds';
import { Routes, Route } from 'react-router-dom';
import { FindProfile } from './pages/findProfile';
import { Send } from './pages/send-and-request/send';
import { Request } from './pages/send-and-request/request';
import { ManageLoan } from './pages/manageLoan/ManageLoan';
import { Loan } from './pages/manageLoan/Loan';

export const Transfers = () => {
  return (
    <SinglePanelView>
      <Flex
        flexDirection="column"
        padding="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
      >
        <Routes>
          <Route index element={<Menu />} />
          <Route path="/manage-loan" element={<ManageLoan />} />
          <Route path="/manage-loan/:loanId" element={<Loan />} />

          <Route path="/deposit" element={<AddFunds />} />
          <Route path="/withdraw" element={<WithdrawFunds />} />
          <Route path="/send-and-request" element={<FindProfile />} />
          <Route path="/send-and-request/request" element={<Request />} />
          <Route path="/send-and-request/send" element={<Send />} />
        </Routes>
      </Flex>
    </SinglePanelView>
  );
};
