import { createActorContext } from '@xstate/react';
import { PanelWrapper } from 'components/layout/onboarding/panelWrapper';
import { onboardingMachine } from './onboardingMachine';

import { BusinessDetails } from './NoController/BusinessDetails';
import { Overview } from './NoController/Overview';
import { PersistData } from './NoController/PersistData';
import { PersonalInfo } from './NoController/PersonalInfo';
import { ResidentialAddress } from './NoController/ResidentialAddress';
import { SocialSecurity } from './NoController/SocialSecurity';

import { AreYouABeneficialOwner } from './ControllerRequired/AreYouABeneficialOwner';
import { AreYouAController } from './ControllerRequired/AreYouAController';
import { BeneficialOwnerAddress } from './ControllerRequired/BeneficialOwnerAddress';
import { BeneficialOwnerInfo } from './ControllerRequired/BeneficialOwnerInfo';
import { BeneficialOwnerSocialSecurity } from './ControllerRequired/BeneficialOwnerSocialSecurity';
import { BusinessAddress } from './ControllerRequired/BusinessAddress';
import { ControllerAddress } from './ControllerRequired/ControllerAddress';
import { ControllerInfo } from './ControllerRequired/ControllerInfo';
import { ControllerSocialSecurity } from './ControllerRequired/ControllerSocialSecurity';
import { DeadEnd } from './ControllerRequired/DeadEnd';
import { FetchUpdatedData } from './ControllerRequired/FetchUpdatedData';
import { LLCBusinessDetails } from './ControllerRequired/LLCBusinessDetails';
import { LLCOverview } from './ControllerRequired/LLCOverview';
import { TwentyFivePercentOrMore } from './ControllerRequired/TwentyFivePercentOrMore';
import { UnderReview } from './UnderReview';
import { TypeOfBusiness } from './TypeOfBusiness';
import { ConfirmModal } from './sharedComponents/ConfirmModal';
import { Waiting } from './sharedComponents/Waiting';
import { Submit } from './Submit';

const OnboardingPages = {
  BusinessDetails,
  Overview,
  PersistData,
  PersonalInfo,
  ResidentialAddress,
  SocialSecurity,
  AreYouABeneficialOwner,
  AreYouAController,
  BeneficialOwnerAddress,
  BeneficialOwnerInfo,
  BeneficialOwnerSocialSecurity,
  BusinessAddress,
  ControllerAddress,
  ControllerInfo,
  ControllerSocialSecurity,
  DeadEnd,
  FetchUpdatedData,
  LLCBusinessDetails,
  LLCOverview,
  TwentyFivePercentOrMore,
  UnderReview,
  TypeOfBusiness,
  ConfirmModal,
  Waiting,
  Submit,
};

export const OnboardingContext = createActorContext(onboardingMachine, {
  inspect: (i) => {
    if (i.type === '@xstate.snapshot') {
      // console.log('event: ', i.event);
      // console.log('snapshot: ', i.snapshot);
    }
  },
});

const pathify = (obj: any, prefix = '.'): string => {
  if (typeof obj === 'string') return obj;

  let path = '';
  Object.keys(obj).forEach((key) => {
    path += key + prefix + pathify(obj[key]);
  });
  return path;
};

export const Onboarding = () => {
  const initialStep = onboardingMachine.config.initial || undefined;
  return initialStep ? (
    <OnboardingContext.Provider>
      <ChildComponent />
    </OnboardingContext.Provider>
  ) : (
    <div>loading</div>
  );
};

const ChildComponent = () => {
  const machineValue = OnboardingContext.useSelector((state) => state.value);
  const pathArr = pathify(machineValue).split('.');
  const component = pathArr[pathArr.length - 1];

  // const Component = OnboardingPages[component as keyof typeof OnboardingPages];
  //@ts-ignore
  const Component = OnboardingPages[component];
  return (
    <PanelWrapper>
      <Component />
    </PanelWrapper>
  );
};
