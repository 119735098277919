import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { BackButton } from 'components/backButton';
import BushelLogo from 'images/Logo/Product/Wallet.svg';
import { IoLogOutOutline } from 'react-icons/io5';
import { useKeycloak } from '@react-keycloak/web';

type HeaderProps = {
  children?: React.ReactNode;
  title?: string;
  backButton?: boolean;
  logoutButton?: boolean;
  customButton?: React.ReactNode;
};
export const Header = React.memo(
  ({
    children,
    title,
    backButton = true,
    logoutButton = false,
    customButton,
  }: HeaderProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef(null);
    const { keycloak } = useKeycloak();

    const handleLogout = () => {
      keycloak.logout();
    };

    if (customButton && logoutButton) {
      throw new Error(
        "You can't have both a customButton and a logoutButton in the same spot"
      );
    }

    return (
      <Flex
        className="content__panel__header"
        flexDirection="column"
        width="100%"
      >
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <Flex mb="0">{backButton == true && <BackButton />}</Flex>
          <Image src={BushelLogo} height={18} />
          <Flex justifyContent="center">
            {logoutButton && (
              <Icon fontSize="24px" cursor="pointer" onClick={onOpen}>
                <IoLogOutOutline />
              </Icon>
            )}
            {customButton && <>{customButton}</>}
          </Flex>
        </Flex>
        {title && (
          <Heading mb={4} fontFamily="heading" fontSize={26}>
            {title}
          </Heading>
        )}
        {children && <>{children}</>}

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader fontFamily="heading">
              Exit Onboarding?
            </AlertDialogHeader>
            <AlertDialogBody>
              If you exit now, you'll be logged out.
            </AlertDialogBody>
            <AlertDialogFooter width="60%" alignSelf="flex-end">
              <Button
                ref={cancelRef.current}
                onClick={onClose}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button variant="danger" onClick={handleLogout} ml={3}>
                Log Out
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </Flex>
    );
  }
);
