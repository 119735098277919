/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
  AddressFromJSON,
  AddressFromJSONTyped,
  AddressToJSON,
} from './Address';

/**
 * Data related to the business
 * @export
 * @interface BusinessDataResponse
 */
export interface BusinessDataResponse {
  /**
   * Business name.
   * @type {string}
   * @memberof BusinessDataResponse
   */
  businessName?: string;
  /**
   * Doing Business As.
   * @type {string}
   * @memberof BusinessDataResponse
   */
  doingBusinessAs?: string;
  /**
   * Business handle.
   * @type {string}
   * @memberof BusinessDataResponse
   */
  handle?: string;
  /**
   *
   * @type {Address}
   * @memberof BusinessDataResponse
   */
  businessAddress?: Address;
}

/**
 * Check if a given object implements the BusinessDataResponse interface.
 */
export function instanceOfBusinessDataResponse(
  value: object
): value is BusinessDataResponse {
  return true;
}

export function BusinessDataResponseFromJSON(json: any): BusinessDataResponse {
  return BusinessDataResponseFromJSONTyped(json, false);
}

export function BusinessDataResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessDataResponse {
  if (json == null) {
    return json;
  }
  return {
    businessName:
      json['businessName'] == null ? undefined : json['businessName'],
    doingBusinessAs:
      json['doingBusinessAs'] == null ? undefined : json['doingBusinessAs'],
    handle: json['handle'] == null ? undefined : json['handle'],
    businessAddress:
      json['businessAddress'] == null
        ? undefined
        : AddressFromJSON(json['businessAddress']),
  };
}

export function BusinessDataResponseToJSON(
  value?: BusinessDataResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    businessName: value['businessName'],
    doingBusinessAs: value['doingBusinessAs'],
    handle: value['handle'],
    businessAddress: AddressToJSON(value['businessAddress']),
  };
}
