import { useQuery } from '@tanstack/react-query';
import { useTransactionClient } from '.';
import { GetStatementDetailsRequest } from 'api/wallet-app';
import { useWalletStore } from 'stores';

export const STATEMENT_DETAILS_QUERY_KEY = 'statement-details';

export default function useStatementDetails(
  payload: Omit<GetStatementDetailsRequest, 'xWalletId'>
) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const transactionsClient = useTransactionClient();
  return useQuery({
    queryKey: [STATEMENT_DETAILS_QUERY_KEY, payload],
    queryFn: () => {
      return transactionsClient.getStatementDetails({
        ...payload,
        xWalletId: walletId,
      });
    },
  });
}
