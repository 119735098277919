import { useState } from 'react';
import {
  Badge,
  Button,
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { CompletedBusinessInvitation } from 'api/wallet-app';
import { ColumnProps, Table } from 'components/Table';
import { twoDigitMMDDYY } from 'utils/date-utils';
import { ProfileOverlay } from 'components/ProfileOverlay';

const columns: ColumnProps[] = [
  { heading: 'Name', id: 'name', width: 12 / 60 },
  { heading: 'Email', id: 'email', width: 12 / 60 },
  { heading: 'Phone Number', id: 'phoneNumber', width: 8 / 60 },
  { heading: 'Date Invited', id: 'dateInvited', width: 8 / 60 },
  {
    heading: 'Account Status',
    id: 'accountStatus',
    width: 8 / 60,
    emptyStateValue: '',
  },
  { heading: '', id: 'resendInvite', width: 12 / 60, emptyStateValue: '' },
];

export default function CompletedInviteTable({
  invites,
}: {
  invites: CompletedBusinessInvitation[];
}) {
  const [selectedInvite, setSelectedInvite] =
    useState<CompletedBusinessInvitation>();
  const [breakToMobileDesign] = useMediaQuery('(max-width: 900px)');
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {breakToMobileDesign ? (
        <Stack spacing={8} divider={<Divider borderColor="gray.400" />}>
          {invites.map((invite) => {
            return (
              <Stack key={invite.id} spacing={6}>
                <Heading as="h6" size="sm">
                  {invite.name}
                </Heading>
                <Stack divider={<Divider borderColor="gray.400" />}>
                  <HStack justify="space-between">
                    <Text>Name</Text>
                    <Text
                      fontWeight="bold"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      title={invite.email}
                    >
                      {invite.name}
                    </Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text>Email</Text>
                    <Text
                      fontWeight="bold"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      title={invite.email}
                    >
                      {invite.email}
                    </Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text>Phone Number</Text>
                    <Text fontWeight="bold">{invite.phoneNumber ?? '-'}</Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text>Date Invited</Text>
                    <Text fontWeight="bold">
                      {twoDigitMMDDYY(new Date(invite.dateInvited))}
                    </Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text>Account Status</Text>
                    <Badge colorScheme="green">Signed Up</Badge>
                  </HStack>
                </Stack>
                <Button
                  variant="outline"
                  w="fit-content"
                  size="sm"
                  alignSelf="center"
                  onClick={() => {
                    setSelectedInvite(invite);
                    onOpen();
                  }}
                >
                  Open Profile
                </Button>
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <Table
          columns={columns}
          tableData={invites.map((invite) => {
            return {
              name: invite.name,
              email: invite.email,
              phoneNumber: invite.phoneNumber,
              dateInvited: twoDigitMMDDYY(new Date(invite.dateInvited)),
              accountStatus: <Badge colorScheme="green">Signed Up</Badge>,
              resendInvite: (
                <Button
                  variant="outline"
                  w="fit-content"
                  onClick={() => {
                    setSelectedInvite(invite);
                    onOpen();
                  }}
                >
                  Open Profile
                </Button>
              ),
            };
          })}
        />
      )}
      {selectedInvite && (
        <ProfileOverlay
          isOpen={isOpen}
          onClose={onClose}
          walletId={selectedInvite.walletId}
        />
      )}
    </>
  );
}
