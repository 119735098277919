import { Button, Flex, Image, Spinner, Portal } from '@chakra-ui/react';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import splash from '../../images/splash.svg';
import Keycloak from 'keycloak-js';

export const AuthLoading = ({ keycloak }: { keycloak: Keycloak }) => {
  const handleRedirect = () => {
    keycloak.createLoginUrl();
    const keycloakUrl = keycloak.createLoginUrl();
    Browser.open({ url: keycloakUrl });
  };
  return Capacitor.isNativePlatform() ? (
    <Flex
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Image src={splash} alt="placeholder" height="75px" mb="2rem" />
      <Button width="auto" colorScheme="blue.400" onClick={handleRedirect}>
        Please Log in to continue
      </Button>
    </Flex>
  ) : (
    <Portal>
      <Flex width="100%" height="100vh" overflow="hidden">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="#fff"
          zIndex="99999"
          overflow="hidden"
          height="100vh"
          position="fixed"
        >
          <Spinner
            position="fixed"
            thickness="4px"
            size="xl"
            emptyColor="gray.300"
            color="blue.500"
          />
        </Flex>
      </Flex>
    </Portal>
  );
};
