/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The reorder funding sources response.
 * @export
 * @interface ReorderFundingSourcesResponse
 */
export interface ReorderFundingSourcesResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof ReorderFundingSourcesResponse
   */
  fundingSourceIds?: Array<string>;
}

/**
 * Check if a given object implements the ReorderFundingSourcesResponse interface.
 */
export function instanceOfReorderFundingSourcesResponse(
  value: object
): value is ReorderFundingSourcesResponse {
  return true;
}

export function ReorderFundingSourcesResponseFromJSON(
  json: any
): ReorderFundingSourcesResponse {
  return ReorderFundingSourcesResponseFromJSONTyped(json, false);
}

export function ReorderFundingSourcesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReorderFundingSourcesResponse {
  if (json == null) {
    return json;
  }
  return {
    fundingSourceIds:
      json['fundingSourceIds'] == null ? undefined : json['fundingSourceIds'],
  };
}

export function ReorderFundingSourcesResponseToJSON(
  value?: ReorderFundingSourcesResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSourceIds: value['fundingSourceIds'],
  };
}
