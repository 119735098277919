import { MoneyInput } from 'pages/WalletApp/Transfers/components';
import { Button, Text, HStack, Stack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { NumberFormatValues } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { WalletProfileResponse } from 'api/wallet-app';

export const EnterValue = ({
  recipient,
  amount,
  handleMoneyInput,
  send,
}: {
  recipient: WalletProfileResponse;
  amount: string;
  handleMoneyInput: (v: NumberFormatValues) => void;
  send: any;
}) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    send('NEXT', { amount: amount, recipient: recipient });
  };

  return (
    <Stack spacing={4}>
      <HStack justifyContent="space-between">
        <ArrowBackIcon
          boxSize={6}
          cursor="pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <Text>Send to {recipient.name}</Text>
        <div />
      </HStack>
      <MoneyInput
        amount={amount}
        onChange={handleMoneyInput}
        showBorder={false}
      />
      <Button isDisabled={parseFloat(amount) <= 0} onClick={handleSubmit}>
        Continue
      </Button>
    </Stack>
  );
};
