/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The rename funding sources response
 * @export
 * @interface RenameFundingSourceResponse
 */
export interface RenameFundingSourceResponse {
  /**
   *
   * @type {string}
   * @memberof RenameFundingSourceResponse
   */
  fundingSourceId?: string;
  /**
   *
   * @type {string}
   * @memberof RenameFundingSourceResponse
   */
  friendlyName?: string;
}

/**
 * Check if a given object implements the RenameFundingSourceResponse interface.
 */
export function instanceOfRenameFundingSourceResponse(
  value: object
): value is RenameFundingSourceResponse {
  return true;
}

export function RenameFundingSourceResponseFromJSON(
  json: any
): RenameFundingSourceResponse {
  return RenameFundingSourceResponseFromJSONTyped(json, false);
}

export function RenameFundingSourceResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RenameFundingSourceResponse {
  if (json == null) {
    return json;
  }
  return {
    fundingSourceId:
      json['fundingSourceId'] == null ? undefined : json['fundingSourceId'],
    friendlyName:
      json['friendlyName'] == null ? undefined : json['friendlyName'],
  };
}

export function RenameFundingSourceResponseToJSON(
  value?: RenameFundingSourceResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSourceId: value['fundingSourceId'],
    friendlyName: value['friendlyName'],
  };
}
