import React from 'react';
import { Card } from './Card';
import { Flex, Heading } from '@chakra-ui/react';
import { IoPencilSharp } from 'react-icons/io5';
import { useSelector } from '@xstate/react';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { BadgeValue } from './Card';
import { BusinessDto } from 'api/wallet-app';

export const BusinessInfo = ({
  clickHandler,
  business,
  submissionState,
}: {
  clickHandler: () => void;
  business: BusinessDto;
  submissionState: BadgeValue;
}) => {
  const getBusinessCardTitle = () => {
    return business?.businessName
      ? business.businessName
      : 'Business Information';
  };

  const title = getBusinessCardTitle();

  return (
    <>
      <Flex direction="column" mb={5}>
        <Heading size="sm" mb={3}>
          Business Info
        </Heading>
        <Card
          title={title}
          value={submissionState}
          handler={clickHandler}
          icon={IoPencilSharp}
          iconText="Edit"
        />
      </Flex>
    </>
  );
};
