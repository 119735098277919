/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents an address.
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * Street address Line 1 and building number (no PO Boxes).
   * @type {string}
   * @memberof Address
   */
  streetNumber1: string;
  /**
   * Street address Line 2 and building number (no PO Boxes).
   * @type {string}
   * @memberof Address
   */
  streetNumber2?: string;
  /**
   * City of the address.
   * @type {string}
   * @memberof Address
   */
  city: string;
  /**
   * State or province.
   * @type {string}
   * @memberof Address
   */
  stateProvinceRegion: string;
  /**
   * Postal or zipcode.
   * @type {string}
   * @memberof Address
   */
  postalCode: string;
  /**
   * Country.
   * @type {string}
   * @memberof Address
   */
  country: string;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): value is Address {
  if (!('streetNumber1' in value) || value['streetNumber1'] === undefined)
    return false;
  if (!('city' in value) || value['city'] === undefined) return false;
  if (
    !('stateProvinceRegion' in value) ||
    value['stateProvinceRegion'] === undefined
  )
    return false;
  if (!('postalCode' in value) || value['postalCode'] === undefined)
    return false;
  if (!('country' in value) || value['country'] === undefined) return false;
  return true;
}

export function AddressFromJSON(json: any): Address {
  return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Address {
  if (json == null) {
    return json;
  }
  return {
    streetNumber1: json['streetNumber1'],
    streetNumber2:
      json['streetNumber2'] == null ? undefined : json['streetNumber2'],
    city: json['city'],
    stateProvinceRegion: json['stateProvinceRegion'],
    postalCode: json['postalCode'],
    country: json['country'],
  };
}

export function AddressToJSON(value?: Address | null): any {
  if (value == null) {
    return value;
  }
  return {
    streetNumber1: value['streetNumber1'],
    streetNumber2: value['streetNumber2'],
    city: value['city'],
    stateProvinceRegion: value['stateProvinceRegion'],
    postalCode: value['postalCode'],
    country: value['country'],
  };
}
