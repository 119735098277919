import React from 'react';
import { Header, Body, Footer } from 'components/layout/onboarding';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { RadioButtonGroup } from 'components/formComponents';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useSelector } from '@xstate/react';

export const AreYouABeneficialOwner = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const [selection, setSelection] = React.useState<string | undefined>(
    sessionStorage.getItem('wallet.isBeneficialOwner') || undefined
  );
  const machineState = useSelector(onboardingActor, (state) => state?.context);

  React.useEffect(() => {
    if (!machineState) return;
    if (typeof machineState.isBeneficialOwner === 'undefined') return;
    setSelection(machineState.isBeneficialOwner ? 'yes' : 'no');
  }, [machineState]);

  const handleSubmit = () => {
    if (!selection || !onboardingActor) {
      return;
    }

    sessionStorage.setItem('wallet.isBeneficialOwner', selection);
    onboardingActor.send({
      type: selection === 'yes' ? 'yes' : 'no',
      value: '',
    });
  };

  return (
    <Flex minHeight="500px" direction="column">
      <Header title="Ownership Details" />
      <Text>Are you considered a beneficial owner at this organization?</Text>
      <Body>
        <RadioButtonGroup
          name="beneficialOwner"
          options={[
            { value: 'yes', label: 'I own 25% or more' },
            { value: 'no', label: "I don't own 25% or more" },
          ]}
          defaultValue={selection}
          onChange={(val) => setSelection(val)}
        />
        <Box mt={6} fontSize={14} background="gray.50" p={4}>
          To open this account, you must either own 25% or more of the company
          or be the controller of the company.
        </Box>
      </Body>
      <Footer>
        <Button
          onClick={handleSubmit}
          isDisabled={!selection}
          colorScheme="primary"
          size="lg"
          width="100%"
        >
          Next
        </Button>
      </Footer>
    </Flex>
  );
};
