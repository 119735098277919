import { useMutation, useQueryClient } from '@tanstack/react-query';
import useFundingSourcesClient from './useFundingSourcesClient';
import { ReorderFundingSourcesRequest } from 'api/wallet-app';
import { v4 as uuid } from 'uuid';
import { useWalletStore } from 'stores';
import { FUNDING_SOURCE_LIST_QUERY_KEY } from './useFundingSourceList';
import { useMediaQuery, useToast } from '@chakra-ui/react';

export default function useReorderFundingSources() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    position: isMobile ? 'top' : 'bottom',
    isClosable: true,
  });
  const queryClient = useQueryClient();
  const fundingSourcesClient = useFundingSourcesClient();
  return useMutation({
    mutationKey: ['reorder-funding-sources'],
    mutationFn: (payload: ReorderFundingSourcesRequest) => {
      return fundingSourcesClient.reorderFundingSources({
        reorderFundingSourcesRequest: payload,
        idempotencyKey: uuid(),
        xWalletId: walletId,
      });
    },
    onSuccess: () => {
      toast({ status: 'success', title: 'Successfully Set as Preferred' });
      queryClient.refetchQueries({
        queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY],
      });
    },
  });
}
