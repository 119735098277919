/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Recorded Screen Views
 * @export
 * @interface ViewedScreen
 */
export interface ViewedScreen {
  /**
   * Slug for the screen name
   * @type {string}
   * @memberof ViewedScreen
   */
  screenName: ViewedScreenScreenNameEnum;
  /**
   * Viewed date time.
   * @type {Date}
   * @memberof ViewedScreen
   */
  viewedAt: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum ViewedScreenScreenNameEnum {
  CompeerWelcomeScreen = 'compeer-welcome-screen',
}

/**
 * Check if a given object implements the ViewedScreen interface.
 */
export function instanceOfViewedScreen(value: object): value is ViewedScreen {
  if (!('screenName' in value) || value['screenName'] === undefined)
    return false;
  if (!('viewedAt' in value) || value['viewedAt'] === undefined) return false;
  return true;
}

export function ViewedScreenFromJSON(json: any): ViewedScreen {
  return ViewedScreenFromJSONTyped(json, false);
}

export function ViewedScreenFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ViewedScreen {
  if (json == null) {
    return json;
  }
  return {
    screenName: json['screenName'],
    viewedAt: new Date(json['viewedAt']),
  };
}

export function ViewedScreenToJSON(value?: ViewedScreen | null): any {
  if (value == null) {
    return value;
  }
  return {
    screenName: value['screenName'],
    viewedAt: value['viewedAt'].toISOString(),
  };
}
