import { Link as NavLink } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text, HStack, Icon, Link } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export default function LinkItem({
  icon,
  title,
  linkTarget,
  newTab = true,
}: {
  icon?: IconType;
  title: string;
  linkTarget?: string;
  newTab?: boolean;
}) {
  return (
    <Link
      as={NavLink}
      target={newTab ? '_blank' : '_self'}
      to={linkTarget}
      alignItems="center"
      width="100%"
      _hover={{ color: 'blue.500' }}
      tabIndex={0}
      display="flex"
      justifyContent="space-between"
    >
      <HStack spacing={4}>
        {icon && <Icon as={icon} boxSize={6} />}
        <Text>{title}</Text>
      </HStack>
      <Icon as={ChevronRightIcon} boxSize={6} />
    </Link>
  );
}
