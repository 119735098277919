import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Code,
  Flex,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import { useDeactivateWallet } from 'hooks/api';
import { SettingsCard } from 'pages/WalletApp/settings/components/index';

export default function AccountSettings() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <SettingsCard heading={t('wallet.settings.accountSettings')}>
      <HStack
        justifyContent="space-between"
        width="100%"
        _hover={{ cursor: 'pointer', color: 'blue.500' }}
        onClick={onOpen}
      >
        <Flex>{t('wallet.settings.deactivateAccount')}</Flex>
        <Icon as={ChevronRightIcon} boxSize={6} />
      </HStack>
      <DeactivateModal isOpen={isOpen} onClose={onClose} />
    </SettingsCard>
  );
}

const DeactivateModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [inputValue, setInputValue] = useState('');

  const { mutate: deactivateWallet, isPending: deactivateWalletIsPending } =
    useDeactivateWallet();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="1rem">
        <ModalHeader>
          <Flex
            fontWeight="normal"
            fontSize="1rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <Icon
              as={CloseIcon}
              fontSize="1rem"
              fontWeight="normal"
              width="10px"
              onClick={onClose}
              cursor="pointer"
              boxSizing="border-box"
            />
            <Text>Deactivate Account</Text>
            <div />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Stack spacing={4}>
            <Text>
              This will deactivate your account. You will lose access to
              transactions and transaction history.
            </Text>
            <Text>
              To proceed, please type <Code>DEACTIVATE</Code> into the following
              text field:{' '}
            </Text>
            <Input
              type="text"
              onChange={(e) => {
                setInputValue(e.currentTarget.value);
              }}
            />
            <Button
              size="lg"
              fontSize="1rem"
              textTransform="none"
              onClick={() => {
                deactivateWallet(inputValue);
                onClose();
              }}
              variant="danger"
              disabled={inputValue !== 'DEACTIVATE'}
              isLoading={deactivateWalletIsPending}
            >
              Deactivate
            </Button>
            <Button
              size="lg"
              fontSize="1rem"
              textTransform="none"
              variant="secondary"
              onClick={onClose}
              isDisabled={deactivateWalletIsPending}
            >
              Cancel
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
