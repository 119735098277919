import React from 'react';
import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  HStack,
  Text,
  useDisclosure,
  Stack,
  Center,
  ModalHeader,
  useMediaQuery,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { PlaidLinkError, usePlaidLink } from 'react-plaid-link';
import MicrodepositScreenshot from 'images/microdeposit-screenshot.png';
import BankIcon from 'images/bank-icon.svg';
import { useQueryClient } from '@tanstack/react-query';
import { IoTrashOutline } from 'react-icons/io5';
import {
  FUNDING_SOURCE_LIST_QUERY_KEY,
  useGeneratePlaidToken,
  useLogPlaidEvent,
  useRemoveFundingSource,
} from 'hooks/api';
import { FundingSourceDto } from 'api/wallet-app';
import { convertMetadataToStringOrNull } from 'utils';

const PlaidLink = ({
  linkToken,
  onSuccess,
  onExit,
  fundingSource,
}: {
  linkToken: string;
  onSuccess: (_publicToken: string, metadata: any) => void;
  onExit: (error: null | PlaidLinkError, metadata: any) => void;
  fundingSource: FundingSourceDto;
}) => {
  const { mutate: logPlaidEvent } = useLogPlaidEvent();
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: onSuccess,
    onExit: onExit,
    onEvent: (e, m) => {
      logPlaidEvent({
        eventName: e,
        plaidEvent: convertMetadataToStringOrNull(m),
      });
    },
  });

  const {
    onOpen: onInstructionOpen,
    onClose: onInstructionClose,
    isOpen: isInstructionOpen,
  } = useDisclosure();

  const { t } = useTranslation();

  const handleOpen = () => {
    onInstructionOpen();
  };

  const handleContinue = () => {
    onInstructionClose();
    open();
  };

  return (
    <>
      <Flex
        alignItems="center"
        textTransform="uppercase"
        color="blue.400"
        cursor="pointer"
        onClick={handleOpen}
        fontSize="14px"
      >
        {ready && (
          <Text fontWeight="bold">{t('wallet.accounts.verifyAccount')}</Text>
        )}
      </Flex>
      <Modal isOpen={isInstructionOpen} onClose={onInstructionClose}>
        <ModalOverlay background="gray.50" />
        <ModalContent padding="1rem" margin="auto">
          <Flex justifyContent="space-between" alignItems="center" px="24px">
            <CloseIcon
              fontSize="12px"
              cursor="pointer"
              onClick={onInstructionClose}
            />
            <Text fontFamily="inter" fontSize="14px" color="gray.500">
              Microdeposit Verification
            </Text>
            <Flex />
          </Flex>
          <ModalBody mt="3rem" maxWidth="400px">
            <Flex direction="column">
              <Flex
                background="black"
                color="white"
                width="30px"
                alignItems="center"
                justifyContent="center"
                borderRadius="100px"
                fontWeight="bold"
                fontSize="20px"
                fontFamily="heading"
              >
                1
              </Flex>
              <Text mt="12px" mb="1.5rem" fontFamily="inter" fontSize="16px">
                Check your bank account ending in {fundingSource.accountNumber}.
              </Text>
              <Flex
                background="black"
                color="white"
                width="30px"
                alignItems="center"
                justifyContent="center"
                borderRadius="100px"
                fontWeight="bold"
                fontSize="20px"
                fontFamily="heading"
              >
                2
              </Flex>
              <Text mt="12px" fontFamily="inter" fontSize="16px">
                Find the $0.01 microdeposit we sent and look for the 3-letter
                code in the description
              </Text>
              <Image mb="2rem" mt="8px" src={MicrodepositScreenshot} />
              <Flex
                background="black"
                color="white"
                width="30px"
                alignItems="center"
                justifyContent="center"
                borderRadius="100px"
                fontWeight="bold"
                fontSize="20px"
                fontFamily="heading"
              >
                3
              </Flex>
              <Text mt="12px" mb="1.5rem" fontFamily="inter" fontSize="16px">
                If you know your{' '}
                <span style={{ color: 'red' }}>3-letter code</span>, continue to
                the next page
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter mt="2rem">
            <Button _focus={{ outline: 'none' }} onClick={handleContinue}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const PendingFundingSource = ({
  fundingSource,
  onSuccess,
  removeFailedFundingSource,
}: {
  fundingSource: FundingSourceDto;
  onSuccess: (_publicToken: string, metadata: any) => void;
  removeFailedFundingSource: (fundingSource: FundingSourceDto) => void;
}) => {
  const { t } = useTranslation();
  const [linkToken, setLinkToken] = React.useState('');
  const queryClient = useQueryClient();

  const [isMobile] = useMediaQuery('(max-width: 480px)');

  const { mutate: generatePlaidToken } = useGeneratePlaidToken();
  const {
    mutateAsync: removeFundingSourceAsync,
    isPending: removeFundingSourceIsPending,
  } = useRemoveFundingSource();

  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    if (fundingSource.needsUserValidation) {
      generatePlaidToken(
        { fundingSourceId: fundingSource.fundingSourceId },
        {
          onSuccess: (data) => {
            setLinkToken(data.linkToken!);
          },
        }
      );
    }

    if (fundingSource.verificationFailed) {
      removeFailedFundingSource(fundingSource);
    }
  }, []);

  const onExit = (error: null | PlaidLinkError) => {
    queryClient.invalidateQueries({
      queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY],
    });
    if (!error) return;

    if (error.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
      removeFailedFundingSource(fundingSource);
    }
  };

  return (
    <>
      <Flex
        backgroundColor="gray.50"
        padding={['1rem 1rem 1rem 10px', '1rem']}
        fontFamily="inter"
      >
        <Stack spacing={4} w="100%">
          <HStack>
            <HStack w="100%" spacing={[6, 0]}>
              <Flex width={['30px', '65px']} opacity="40%">
                {fundingSource.icon ? (
                  <Image
                    src={atob(fundingSource.icon)}
                    width="35px"
                    height="35px"
                    objectFit="contain"
                  />
                ) : (
                  <Image width="30px" height="30px" src={BankIcon} />
                )}
              </Flex>
              <Stack color="gray.400" fontStyle="italic">
                <Heading
                  as="h4"
                  fontSize="18px"
                  maxWidth={['180px', '300px', '500px', '500px', '900px']}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {fundingSource.name}
                </Heading>
                <Text fontWeight="bold">{t('wallet.accounts.pending')}</Text>
              </Stack>
            </HStack>
            <HStack spacing={4} minW="fit-content">
              {!isMobile && linkToken && (
                <PlaidLink
                  linkToken={linkToken}
                  onSuccess={onSuccess}
                  onExit={(error) => {
                    queryClient.refetchQueries({
                      queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY],
                    });

                    if (
                      error &&
                      error.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS'
                    ) {
                      removeFailedFundingSource(fundingSource);
                    }
                  }}
                  fundingSource={fundingSource}
                />
              )}
              <IconButton
                icon={<IoTrashOutline size={18} />}
                aria-label="Trash Icon"
                variant="ghost"
                size="icon"
                color="black"
                w="fit-content"
                p={2}
                onClick={onOpen}
              />
            </HStack>
          </HStack>
          {isMobile && linkToken && (
            <Center>
              <PlaidLink
                linkToken={linkToken}
                onSuccess={onSuccess}
                onExit={onExit}
                fundingSource={fundingSource}
              />
            </Center>
          )}
        </Stack>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={!removeFundingSourceIsPending}
        size="xs"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Account</ModalHeader>
          <ModalBody>
            This account will be permanently removed from your wallet
          </ModalBody>
          <ModalFooter>
            <HStack spacing={3}>
              <Button
                variant="secondary"
                onClick={onClose}
                isDisabled={removeFundingSourceIsPending}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                isLoading={removeFundingSourceIsPending}
                onClick={async () => {
                  await removeFundingSourceAsync(
                    fundingSource.fundingSourceId
                  ).finally(() => {
                    onClose();
                  });
                }}
              >
                Remove
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
