/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Request for making a liability payment.
 * @export
 * @interface MakeLiabilityPaymentRequest
 */
export interface MakeLiabilityPaymentRequest {
  /**
   *
   * @type {string}
   * @memberof MakeLiabilityPaymentRequest
   */
  fromFundingSourceId?: string;
  /**
   *
   * @type {string}
   * @memberof MakeLiabilityPaymentRequest
   */
  toFundingSourceId: string;
  /**
   *
   * @type {string}
   * @memberof MakeLiabilityPaymentRequest
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof MakeLiabilityPaymentRequest
   */
  memo: string;
}

/**
 * Check if a given object implements the MakeLiabilityPaymentRequest interface.
 */
export function instanceOfMakeLiabilityPaymentRequest(
  value: object
): value is MakeLiabilityPaymentRequest {
  if (
    !('toFundingSourceId' in value) ||
    value['toFundingSourceId'] === undefined
  )
    return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  if (!('memo' in value) || value['memo'] === undefined) return false;
  return true;
}

export function MakeLiabilityPaymentRequestFromJSON(
  json: any
): MakeLiabilityPaymentRequest {
  return MakeLiabilityPaymentRequestFromJSONTyped(json, false);
}

export function MakeLiabilityPaymentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MakeLiabilityPaymentRequest {
  if (json == null) {
    return json;
  }
  return {
    fromFundingSourceId:
      json['fromFundingSourceId'] == null
        ? undefined
        : json['fromFundingSourceId'],
    toFundingSourceId: json['toFundingSourceId'],
    amount: json['amount'],
    memo: json['memo'],
  };
}

export function MakeLiabilityPaymentRequestToJSON(
  value?: MakeLiabilityPaymentRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fromFundingSourceId: value['fromFundingSourceId'],
    toFundingSourceId: value['toFundingSourceId'],
    amount: value['amount'],
    memo: value['memo'],
  };
}
