import alertsHandlers from './alerts-handlers';
import appHandlers from './app-handlers';
import fundingSourcesHandlers from './funding-sources-handlers';
import keycloakHandlers from './keycloak-handlers';
import onboardingHandlers from './onboarding-handlers';
import paymentRequestsHandlers from './payment-request-handlers';
import plaidHandlers from './plaid-handlers';
import transactionsHandlers from './transactions-handlers';
import walletProfilesHandlers from './wallet-profiles-handlers';
import walletsHandlers from './wallets-handlers';

export const handlers = [
  ...alertsHandlers,
  ...appHandlers,
  ...fundingSourcesHandlers,
  ...keycloakHandlers,
  ...onboardingHandlers,
  ...paymentRequestsHandlers,
  ...plaidHandlers,
  ...transactionsHandlers,
  ...walletProfilesHandlers,
  ...walletsHandlers,
];
