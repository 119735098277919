import { useQuery } from '@tanstack/react-query';
import useWalletsClient from './useWalletsClient';

export const REFERRAL_SEARCH_PARAM_KEY = 'referral';
export const REFERRAL_SESSION_STORAGE_KEY = 'referralToken';

export default function useCheckBusinessInvite(referralCode: string | null) {
  const walletsClient = useWalletsClient(false);
  return useQuery({
    queryKey: ['check-business-invite'],
    queryFn: () => {
      return walletsClient.checkBusinessInvite({
        checkBusinessInviteRequest: { inviteCode: referralCode! },
      });
    },
    enabled: !!referralCode,
  });
}
