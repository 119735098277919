/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompletedBusinessInvitation } from './CompletedBusinessInvitation';
import {
  CompletedBusinessInvitationFromJSON,
  CompletedBusinessInvitationFromJSONTyped,
  CompletedBusinessInvitationToJSON,
} from './CompletedBusinessInvitation';
import type { BusinessInvitation } from './BusinessInvitation';
import {
  BusinessInvitationFromJSON,
  BusinessInvitationFromJSONTyped,
  BusinessInvitationToJSON,
} from './BusinessInvitation';

/**
 *
 * @export
 * @interface BusinessInvitationListResponse
 */
export interface BusinessInvitationListResponse {
  /**
   *
   * @type {Array<BusinessInvitation>}
   * @memberof BusinessInvitationListResponse
   */
  sent: Array<BusinessInvitation>;
  /**
   *
   * @type {Array<CompletedBusinessInvitation>}
   * @memberof BusinessInvitationListResponse
   */
  completed: Array<CompletedBusinessInvitation>;
}

/**
 * Check if a given object implements the BusinessInvitationListResponse interface.
 */
export function instanceOfBusinessInvitationListResponse(
  value: object
): value is BusinessInvitationListResponse {
  if (!('sent' in value) || value['sent'] === undefined) return false;
  if (!('completed' in value) || value['completed'] === undefined) return false;
  return true;
}

export function BusinessInvitationListResponseFromJSON(
  json: any
): BusinessInvitationListResponse {
  return BusinessInvitationListResponseFromJSONTyped(json, false);
}

export function BusinessInvitationListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessInvitationListResponse {
  if (json == null) {
    return json;
  }
  return {
    sent: (json['sent'] as Array<any>).map(BusinessInvitationFromJSON),
    completed: (json['completed'] as Array<any>).map(
      CompletedBusinessInvitationFromJSON
    ),
  };
}

export function BusinessInvitationListResponseToJSON(
  value?: BusinessInvitationListResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    sent: (value['sent'] as Array<any>).map(BusinessInvitationToJSON),
    completed: (value['completed'] as Array<any>).map(
      CompletedBusinessInvitationToJSON
    ),
  };
}
