import {
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputLeftAddon,
  Input,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { phoneRegExp } from 'utils/helpers';

export type PhoneNumber = {
  name?: string;
  label?: string;
  isRequired?: boolean;
};

export default function PhoneNumberInput({
  name = 'phoneNumber',
  label = 'Phone Number',
  isRequired = true,
}: PhoneNumber) {
  const {
    formState: { errors },
  } = useFormContext<{ phoneNumber: string }>();
  const defaultRules = {
    pattern: {
      value: phoneRegExp,
      message: 'Please enter a valid phone number',
    },
  };
  return (
    <Controller
      name={name}
      rules={
        isRequired
          ? {
              required: {
                value: true,
                message: `${label} is required`,
              },
              ...defaultRules,
            }
          : defaultRules
      }
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl isInvalid={!!errors?.phoneNumber}>
            <FormLabel>
              {isRequired ? (
                label
              ) : (
                <HStack spacing={1}>
                  <Text>{label}</Text>
                  <Text color="textSecondary" fontSize="xs">
                    Optional
                  </Text>
                </HStack>
              )}
            </FormLabel>
            <InputGroup>
              <InputLeftAddon children="+1" />
              <Input
                as={NumberFormat}
                format="(###) ###-####"
                type="tel"
                onChange={onChange}
                value={value}
              />
            </InputGroup>
            <FormErrorMessage>{errors?.phoneNumber?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
}
