import {
  AttachCompeerLoanRequest,
  RecordScreenViewRequestScreenNameEnum,
} from 'api/wallet-app';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useRecordAppView from '../app/useRecordAppView';
import useFundingSourceClient from './useFundingSourcesClient';
import { v4 as uuid } from 'uuid';
import { FUNDING_SOURCE_LIST_QUERY_KEY } from './useFundingSourceList';

export default function useAttachCompeerLoan() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const queryClient = useQueryClient();
  const fundingSourceClient = useFundingSourceClient();
  const { mutate: recordAppView } = useRecordAppView();
  return useMutation({
    mutationKey: ['attach-compeer-loan'],
    mutationFn: (payload: Omit<AttachCompeerLoanRequest, 'walletId'>) => {
      return fundingSourceClient.attachCompeerLoan({
        attachCompeerLoanRequest: payload,
        xWalletId: walletId,
        idempotencyKey: uuid(),
      });
    },
    onSuccess: () => {
      /*
       Calling recordAppView('debitCard') makes it so that the
       src/pages/WalletApp/Notifications/LineOfCredit/index.tsx component
       doesn't get opened when the the user enters the app.
      */
      recordAppView(RecordScreenViewRequestScreenNameEnum.CompeerWelcomeScreen);
      queryClient.refetchQueries({
        queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY],
      });
    },
  });
}
