import { CountryCode, Industry, Sector } from 'api/wallet-app';

export const industryClassificationCodeList: Industry[] = [
  {
    code: 'f957b0da-8e22-49ab-a308-77f22d69b920',
    description: 'Accounting',
    // isDefault: false,
  },
  {
    code: '18210181-2d4a-45e1-bd07-d819db3a603c',
    description: 'Administration and environmental quality programs',
    // isDefault: false,
  },
  {
    code: '2f0e10e1-33f2-400d-855c-ac39b20b7d82',
    description: 'Administration of economic programs',
    // isDefault: false,
  },
  {
    code: '9ed3f682-7d6f-11e3-925c-5404a6144203',
    description:
      'Administration of housing programs, urban planning, and community development',
    // isDefault: false,
  },
  {
    code: '20d516ef-4a01-4019-a8d0-05525cf19241',
    description: 'Administration of human resource programs',
    // isDefault: false,
  },
  {
    code: '00d5eba8-656a-420a-bae6-bfdfc420b235',
    description: 'Advertising and public relations',
    // isDefault: false,
  },
  {
    code: '4ad7b5eb-f29c-4a9b-999c-01094912c1ee',
    description: 'Advertising and public relations',
    // isDefault: false,
  },
  {
    code: '81ba2ae7-5853-4143-bbca-0e368bd6868e',
    description: 'Agriculture - animal production and aquaculture',
    // isDefault: true,
  },
  {
    code: '9ed38134-7d6f-11e3-8529-5404a6144203',
    description: 'Agriculture - fishing, hunting, and trapping',
    // isDefault: true,
  },
  {
    code: '87527db4-2c00-4600-9dcb-a19660fbf519',
    description: 'Agriculture - forestry and logging',
    // isDefault: false,
  },
  {
    code: '0b8e411b-1343-4c95-a4b5-6e0f2586a7df',
    description: 'Agriculture - fruit, vegetable, and crop production',
    // isDefault: true,
  },
  {
    code: 'c985893a-8ca2-4e87-a664-960414f69880',
    description: 'Airline',
    // isDefault: false,
  },
  {
    code: '0e2343e1-2502-4b5a-9b7e-dc11195c55d6',
    description: 'Alcoholic beverage drinking places',
    // isDefault: false,
  },
  {
    code: '268e9704-ddb9-4bcb-8fe8-de999f873108',
    description: 'All other personal services',
    // isDefault: false,
  },
];

export const sectorClassificationCodeList: Sector[] = [
  {
    code: 'Sector Code 1',
    description: 'Sector Description 1',
  },
  {
    code: 'Sector Code 2',
    description: 'Sector Description 2',
  },
  {
    code: 'Sector Code 3',
    description: 'Sector Description 3',
  },
  {
    code: 'Sector Code 4',
    description: 'Sector Description 4',
  },
  {
    code: 'Sector Code 5',
    description: 'Sector Description 5',
  },
  {
    code: 'Sector Code 6',
    description: 'Sector Description 6',
  },
  {
    code: 'Sector Code 7',
    description: 'Sector Description 7',
  },
  {
    code: 'Sector Code 8',
    description: 'Sector Description 8',
  },
];

export const countryList: CountryCode[] = [
  {
    code: 'Country Code 1',
    isActive: true,
    name: 'Country Code Name 1',
  },
];
