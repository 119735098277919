export default async function disableMfa({
  baseUrl,
  realm,
  method,
  token,
}: {
  baseUrl?: string;
  realm?: string;
  method: string;
  token?: string;
}) {
  const response = await fetch(
    `${baseUrl}/realms/${realm}/multi-factor?method=${method}&enabled=false`,
    {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }
  );
  return response.json();
}
