/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SdkUploadAppStateDto } from './SdkUploadAppStateDto';
import {
  SdkUploadAppStateDtoFromJSON,
  SdkUploadAppStateDtoFromJSONTyped,
  SdkUploadAppStateDtoToJSON,
} from './SdkUploadAppStateDto';

/**
 * SDK upload application state
 * @export
 * @interface SdkUploadAppState
 */
export interface SdkUploadAppState {
  /**
   *
   * @type {SdkUploadAppStateDto}
   * @memberof SdkUploadAppState
   */
  appState: SdkUploadAppStateDto;
}

/**
 * Check if a given object implements the SdkUploadAppState interface.
 */
export function instanceOfSdkUploadAppState(
  value: object
): value is SdkUploadAppState {
  if (!('appState' in value) || value['appState'] === undefined) return false;
  return true;
}

export function SdkUploadAppStateFromJSON(json: any): SdkUploadAppState {
  return SdkUploadAppStateFromJSONTyped(json, false);
}

export function SdkUploadAppStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SdkUploadAppState {
  if (json == null) {
    return json;
  }
  return {
    appState: SdkUploadAppStateDtoFromJSON(json['appState']),
  };
}

export function SdkUploadAppStateToJSON(value?: SdkUploadAppState | null): any {
  if (value == null) {
    return value;
  }
  return {
    appState: SdkUploadAppStateDtoToJSON(value['appState']),
  };
}
