export const RadioButton = {
  parts: ['buttonGroup', 'button'],
  baseStyle: {
    buttonGroup: {
      spacing: '2',
      width: '100%',
    },
    button: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
      fontFamily: 'body',
      border: 'solid 1px #e2e8f0',
      borderRadius: '0.25rem',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      _hover: {
        bg: 'green.50',
      },
      _checked: {
        borderColor: 'green.500',
        borderWidth: '2px',
        color: 'green.500',
        bg: 'green.50',
      },
    },
  },
  sizes: {
    sm: {
      button: {
        height: 20,
      },
    },
    md: {
      buttonGroup: {
        mt: '1rem',
      },
    },
    lg: {
      button: {
        display: 'flex',
        flexDirection: 'column',
        height: '8rem',
        padding: '1.2rem',
        mb: '0.5rem',
      },
      iconContainer: {
        width: '2.5rem',
        height: '2.5rem',
        bg: 'blue.500',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
