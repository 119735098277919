import { AppStateResponse, RecordScreenViewRequest } from 'api/wallet-app';
import {
  normalAppState,
  onboardingAppState,
  reviewAppState,
  sdkUploadState,
} from 'mocks/fixtures';
import { mockedRequestsRestApiBasePath } from 'mocks/helpers';
import { http, HttpResponse, PathParams } from 'msw';

const appHandlers = [
  /*
   * GET /api/v1/app/state
   * Generated API Endpoint Name: getAppState
   */
  http.get<PathParams, undefined, AppStateResponse>(
    mockedRequestsRestApiBasePath({ path: 'app/state' }),
    () => {
      return HttpResponse.json(sdkUploadState);
    }
  ),

  /*
   * POST /api/v1/screen
   * Generated API Endpoint Name: recordAppView
   */
  http.post<PathParams, RecordScreenViewRequest, undefined>(
    mockedRequestsRestApiBasePath({ path: 'screen' }),
    async ({ request }) => {
      const { screenName } = await request.json();

      // if (
      //   normalAppState.type === 'NORMAL' &&
      //   normalAppState.appState.viewedScreens.some(
      //     (viewedScreen) => viewedScreen.screenName !== screenName
      //   )
      // ) {
      //   normalAppState.appState.viewedScreens.push({
      //     screenName,
      //     viewedAt: new Date(),
      //   });
      // }

      return new HttpResponse(null, { status: 204 });
    }
  ),
];

export default appHandlers;
