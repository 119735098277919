import { Flex, Heading } from '@chakra-ui/react';
import { Card } from './Card';
import { IoPencilSharp } from 'react-icons/io5';
import { PersonDto } from 'api/wallet-app';
import { BadgeValue } from 'pages/Onboarding/sharedComponents/OverviewCard/Card';

export const ControllerCardInfo = ({
  clickHandler,
  controller,
  submissionState,
}: {
  clickHandler: () => void;
  controller: PersonDto | undefined;
  submissionState: BadgeValue;
}) => {
  const getCardTitle = () => {
    if (controller?.firstName && controller?.lastName) {
      return `${controller.firstName} ${controller.lastName}`;
    } else return 'Personal Information';
  };

  const title = getCardTitle();

  return (
    <Flex direction="column" mb={6}>
      <Heading size="sm" mb={3}>
        Your Personal Info
      </Heading>
      <Flex direction="column">
        <Card
          title={title}
          value={submissionState}
          handler={clickHandler}
          icon={IoPencilSharp}
          iconText="Edit"
        />
      </Flex>
    </Flex>
  );
};
