import { Button, Stack, Box, HStack, Text, Image } from '@chakra-ui/react';
import { NavItems } from './navItems';
import { Link } from 'react-router-dom';
import DollarSign from 'images/dollarsign.svg';

type MenuProps = {
  toggleMenu: () => void;
  isOpen: boolean;
};

export function TabletMenu({ toggleMenu, isOpen }: MenuProps) {
  return isOpen ? (
    <Stack
      width="240px"
      backgroundColor="white"
      borderRight="solid 1px"
      borderColor="border"
      zIndex={2}
      position="absolute"
      transition="all 0.5s"
      spacing={20}
      pt={{ base: 6, lg: 12 }}
      h="100%"
      data-testid="tablet-menu"
    >
      <NavItems isOpen={isOpen} onClick={toggleMenu} />
      <Box px={isOpen ? 3 : 0} transition="all 0.3s">
        <Button
          as={Link}
          to="/transfers"
          minW="56px"
          minWidth="56px"
          borderRadius={isOpen ? '4px' : 0}
          onClick={() => {
            toggleMenu();
          }}
        >
          <HStack spacing={isOpen ? 3 : 0}>
            <Text
              width={isOpen ? 'auto' : '0'}
              lineHeight={isOpen ? 'auto' : 0}
              overflow={isOpen ? 'auto' : 'hidden'}
            >
              Transfer & Pay
            </Text>
            <Image src={DollarSign} width="14px" height="21px" />
          </HStack>
        </Button>
      </Box>
    </Stack>
  ) : (
    <></>
  );
}
