/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  DefaultErrorResponse,
  LogPlaidEventRequest,
  ViolationsError,
} from '../models/index';
import {
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  LogPlaidEventRequestFromJSON,
  LogPlaidEventRequestToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
} from '../models/index';

export interface LogPlaidEventOperationRequest {
  xWalletId: string;
  logPlaidEventRequest: LogPlaidEventRequest;
}

/**
 *
 */
export class PlaidApi extends runtime.BaseAPI {
  /**
   * Logs a plaid event.
   */
  async logPlaidEventRaw(
    requestParameters: LogPlaidEventOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling logPlaidEvent().'
      );
    }

    if (requestParameters['logPlaidEventRequest'] == null) {
      throw new runtime.RequiredError(
        'logPlaidEventRequest',
        'Required parameter "logPlaidEventRequest" was null or undefined when calling logPlaidEvent().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/log-plaid-event`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LogPlaidEventRequestToJSON(
          requestParameters['logPlaidEventRequest']
        ),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Logs a plaid event.
   */
  async logPlaidEvent(
    requestParameters: LogPlaidEventOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.logPlaidEventRaw(requestParameters, initOverrides);
  }
}
