/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Deposit } from './Deposit';
import {
  instanceOfDeposit,
  DepositFromJSON,
  DepositFromJSONTyped,
  DepositToJSON,
} from './Deposit';
import type { Withdrawal } from './Withdrawal';
import {
  instanceOfWithdrawal,
  WithdrawalFromJSON,
  WithdrawalFromJSONTyped,
  WithdrawalToJSON,
} from './Withdrawal';

/**
 * @type BalanceAffectingTransaction
 * Balance affecting transaction.
 * @export
 */
export type BalanceAffectingTransaction =
  | ({ type: 'DEPOSIT' } & Deposit)
  | ({ type: 'WITHDRAWAL' } & Withdrawal);

export function BalanceAffectingTransactionFromJSON(
  json: any
): BalanceAffectingTransaction {
  return BalanceAffectingTransactionFromJSONTyped(json, false);
}

export function BalanceAffectingTransactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BalanceAffectingTransaction {
  if (json == null) {
    return json;
  }
  switch (json['type']) {
    case 'DEPOSIT':
      return Object.assign({}, DepositFromJSONTyped(json, true), {
        type: 'DEPOSIT',
      } as const);
    case 'WITHDRAWAL':
      return Object.assign({}, WithdrawalFromJSONTyped(json, true), {
        type: 'WITHDRAWAL',
      } as const);
    default:
      throw new Error(
        `No variant of BalanceAffectingTransaction exists with 'type=${json['type']}'`
      );
  }
}

export function BalanceAffectingTransactionToJSON(
  value?: BalanceAffectingTransaction | null
): any {
  if (value == null) {
    return value;
  }
  switch (value['type']) {
    case 'DEPOSIT':
      return DepositToJSON(value);
    case 'WITHDRAWAL':
      return WithdrawalToJSON(value);
    default:
      throw new Error(
        `No variant of BalanceAffectingTransaction exists with 'type=${value['type']}'`
      );
  }
}
