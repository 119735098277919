import { ResponseError, ValidateUserInviteResponse } from 'api/wallet-app';
import { useQuery } from '@tanstack/react-query';
import useWalletsClient from './useWalletsClient';
import { v4 as uuid } from 'uuid';

export default function useValidateUserInvite(inviteCode: string | null) {
  const walletsClient = useWalletsClient();

  return useQuery<unknown, ResponseError, ValidateUserInviteResponse>({
    queryKey: ['validate-user-invite'],
    queryFn: () => {
      return walletsClient.validateInvite({
        validateUserInviteRequest: {
          inviteCode: inviteCode!,
        },
        idempotencyKey: uuid(),
      });
    },
    enabled: !!inviteCode,
  });
}
