/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The Transaction's Link
 * @export
 * @interface Link
 */
export interface Link {
  /**
   *
   * @type {string}
   * @memberof Link
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof Link
   */
  title?: string;
}

/**
 * Check if a given object implements the Link interface.
 */
export function instanceOfLink(value: object): value is Link {
  return true;
}

export function LinkFromJSON(json: any): Link {
  return LinkFromJSONTyped(json, false);
}

export function LinkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Link {
  if (json == null) {
    return json;
  }
  return {
    url: json['url'] == null ? undefined : json['url'],
    title: json['title'] == null ? undefined : json['title'],
  };
}

export function LinkToJSON(value?: Link | null): any {
  if (value == null) {
    return value;
  }
  return {
    url: value['url'],
    title: value['title'],
  };
}
