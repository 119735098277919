/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SdkParametersDto } from './SdkParametersDto';
import {
  SdkParametersDtoFromJSON,
  SdkParametersDtoFromJSONTyped,
  SdkParametersDtoToJSON,
} from './SdkParametersDto';

/**
 *
 * @export
 * @interface SdkUploadAppStateDto
 */
export interface SdkUploadAppStateDto {
  /**
   *
   * @type {string}
   * @memberof SdkUploadAppStateDto
   */
  walletId: string;
  /**
   *
   * @type {SdkParametersDto}
   * @memberof SdkUploadAppStateDto
   */
  sdkParams?: SdkParametersDto;
}

/**
 * Check if a given object implements the SdkUploadAppStateDto interface.
 */
export function instanceOfSdkUploadAppStateDto(
  value: object
): value is SdkUploadAppStateDto {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  return true;
}

export function SdkUploadAppStateDtoFromJSON(json: any): SdkUploadAppStateDto {
  return SdkUploadAppStateDtoFromJSONTyped(json, false);
}

export function SdkUploadAppStateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SdkUploadAppStateDto {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
    sdkParams:
      json['sdkParams'] == null
        ? undefined
        : SdkParametersDtoFromJSON(json['sdkParams']),
  };
}

export function SdkUploadAppStateDtoToJSON(
  value?: SdkUploadAppStateDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
    sdkParams: SdkParametersDtoToJSON(value['sdkParams']),
  };
}
