/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressDtoResponse } from './AddressDtoResponse';
import {
  AddressDtoResponseFromJSON,
  AddressDtoResponseFromJSONTyped,
  AddressDtoResponseToJSON,
} from './AddressDtoResponse';

/**
 * The address validation response
 * @export
 * @interface AddressValidationResponse
 */
export interface AddressValidationResponse {
  /**
   *
   * @type {boolean}
   * @memberof AddressValidationResponse
   */
  isValid: boolean;
  /**
   *
   * @type {AddressDtoResponse}
   * @memberof AddressValidationResponse
   */
  enteredAddress: AddressDtoResponse;
  /**
   *
   * @type {AddressDtoResponse}
   * @memberof AddressValidationResponse
   */
  suggestedAddress: AddressDtoResponse;
}

/**
 * Check if a given object implements the AddressValidationResponse interface.
 */
export function instanceOfAddressValidationResponse(
  value: object
): value is AddressValidationResponse {
  if (!('isValid' in value) || value['isValid'] === undefined) return false;
  if (!('enteredAddress' in value) || value['enteredAddress'] === undefined)
    return false;
  if (!('suggestedAddress' in value) || value['suggestedAddress'] === undefined)
    return false;
  return true;
}

export function AddressValidationResponseFromJSON(
  json: any
): AddressValidationResponse {
  return AddressValidationResponseFromJSONTyped(json, false);
}

export function AddressValidationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddressValidationResponse {
  if (json == null) {
    return json;
  }
  return {
    isValid: json['isValid'],
    enteredAddress: AddressDtoResponseFromJSON(json['enteredAddress']),
    suggestedAddress: AddressDtoResponseFromJSON(json['suggestedAddress']),
  };
}

export function AddressValidationResponseToJSON(
  value?: AddressValidationResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    isValid: value['isValid'],
    enteredAddress: AddressDtoResponseToJSON(value['enteredAddress']),
    suggestedAddress: AddressDtoResponseToJSON(value['suggestedAddress']),
  };
}
