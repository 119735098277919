import { AppStateResponse } from 'api/wallet-app';
import { WALLET_ID } from './wallets-fixtures';

export const onboardingAppState: AppStateResponse = {
  type: 'ONBOARDING',
  appState: {
    onboardingData: {
      people: [],
      business: {},
      meta: {},
    },
  },
};

export const normalAppState: AppStateResponse = {
  type: 'NORMAL',
  appState: {
    actionItems: [],
    // viewedScreens: [{ screenName: 'debitCard', viewedAt: new Date() }],
    viewedScreens: [],
    walletId: WALLET_ID,
  },
};

export const reviewAppState: AppStateResponse = {
  type: 'UNDER_REVIEW',
  reviewState: {
    walletId: WALLET_ID,
  },
};

export const sdkUploadState: AppStateResponse = {
  type: 'SDK_UPLOAD',
  appState: {
    walletId: WALLET_ID,
    sdkParams: {
      journeyApplicationId: 'mocked-journey-application-id',
      journeyToken: 'mocked-journey-token',
      sdkKey: 'mocked-alloy-sdk-token',
    },
  },
};
