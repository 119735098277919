import React from 'react';
import { PanelWrapper, Header, Body } from 'components/layout/onboarding';
import {
  Button,
  Heading,
  Icon,
  Image,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IoCallOutline, IoMailOutline } from 'react-icons/io5';
import accountLocked from 'images/accountLocked.svg';
import { useTranslation } from 'react-i18next';

const Suspended = () => {
  const { t } = useTranslation();
  const handleMailLink = () => {
    window.location.href = 'mailto:support@bushelwallet.com';
  };

  const handlePhoneLink = () => {
    window.location.href = 'tel:18664392888';
  };

  return (
    <PanelWrapper>
      <Header backButton={false}>
        <Flex alignItems="center" justifyContent="center" mt="30px">
          <Image src={accountLocked} alt="" />
        </Flex>
      </Header>

      <Body>
        <Heading fontSize="32px" mb="1rem" textAlign="center">
          {t('errors.accountLocked')}
        </Heading>
        <Text mb="2rem" textAlign="center">
          {t('errors.accountLockedPleaseContactSupport')}
        </Text>
        <Text textAlign="center">{t('errors.helpUsProtectYourAccount')}</Text>
        <Flex width="100%" alignItems="center" flexDirection="column">
          <Stack mt="2rem">
            <Button
              variant="secondary"
              textTransform="none"
              onClick={handlePhoneLink}
            >
              <Icon as={IoCallOutline} mr="0.5rem" fontSize="1.3rem" />
              <Text alignSelf="center">1-866-439-2888</Text>
            </Button>

            <Button
              data-testid="mail-link"
              variant="secondary"
              textTransform="none"
              onClick={handleMailLink}
            >
              <Icon as={IoMailOutline} mr="0.5rem" fontSize="1.3rem" />
              <Text alignSelf="center">support@bushelwallet.com</Text>
            </Button>
          </Stack>
        </Flex>
      </Body>
    </PanelWrapper>
  );
};

export default Suspended;
