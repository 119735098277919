/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CheckInvitationRequest
 */
export interface CheckInvitationRequest {
  /**
   *
   * @type {string}
   * @memberof CheckInvitationRequest
   */
  inviteCode: string;
}

/**
 * Check if a given object implements the CheckInvitationRequest interface.
 */
export function instanceOfCheckInvitationRequest(
  value: object
): value is CheckInvitationRequest {
  if (!('inviteCode' in value) || value['inviteCode'] === undefined)
    return false;
  return true;
}

export function CheckInvitationRequestFromJSON(
  json: any
): CheckInvitationRequest {
  return CheckInvitationRequestFromJSONTyped(json, false);
}

export function CheckInvitationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CheckInvitationRequest {
  if (json == null) {
    return json;
  }
  return {
    inviteCode: json['inviteCode'],
  };
}

export function CheckInvitationRequestToJSON(
  value?: CheckInvitationRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    inviteCode: value['inviteCode'],
  };
}
