/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingMetaDataDto } from './OnboardingMetaDataDto';
import {
  OnboardingMetaDataDtoFromJSON,
  OnboardingMetaDataDtoFromJSONTyped,
  OnboardingMetaDataDtoToJSON,
} from './OnboardingMetaDataDto';
import type { PersonDto } from './PersonDto';
import {
  PersonDtoFromJSON,
  PersonDtoFromJSONTyped,
  PersonDtoToJSON,
} from './PersonDto';
import type { BusinessDto } from './BusinessDto';
import {
  BusinessDtoFromJSON,
  BusinessDtoFromJSONTyped,
  BusinessDtoToJSON,
} from './BusinessDto';

/**
 * All details regarding a wallet account while onboarding.
 * @export
 * @interface OnboardingDataDto
 */
export interface OnboardingDataDto {
  /**
   *
   * @type {Array<PersonDto>}
   * @memberof OnboardingDataDto
   */
  people: Array<PersonDto>;
  /**
   *
   * @type {BusinessDto}
   * @memberof OnboardingDataDto
   */
  business?: BusinessDto;
  /**
   *
   * @type {OnboardingMetaDataDto}
   * @memberof OnboardingDataDto
   */
  meta?: OnboardingMetaDataDto;
}

/**
 * Check if a given object implements the OnboardingDataDto interface.
 */
export function instanceOfOnboardingDataDto(
  value: object
): value is OnboardingDataDto {
  if (!('people' in value) || value['people'] === undefined) return false;
  return true;
}

export function OnboardingDataDtoFromJSON(json: any): OnboardingDataDto {
  return OnboardingDataDtoFromJSONTyped(json, false);
}

export function OnboardingDataDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OnboardingDataDto {
  if (json == null) {
    return json;
  }
  return {
    people: (json['people'] as Array<any>).map(PersonDtoFromJSON),
    business:
      json['business'] == null
        ? undefined
        : BusinessDtoFromJSON(json['business']),
    meta:
      json['meta'] == null
        ? undefined
        : OnboardingMetaDataDtoFromJSON(json['meta']),
  };
}

export function OnboardingDataDtoToJSON(value?: OnboardingDataDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    people: (value['people'] as Array<any>).map(PersonDtoToJSON),
    business: BusinessDtoToJSON(value['business']),
    meta: OnboardingMetaDataDtoToJSON(value['meta']),
  };
}
