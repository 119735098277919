/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The find funding sources by wallet id request
 * @export
 * @interface FundingSourceDto
 */
export interface FundingSourceDto {
  /**
   *
   * @type {string}
   * @memberof FundingSourceDto
   */
  fundingSourceId: string;
  /**
   *
   * @type {string}
   * @memberof FundingSourceDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FundingSourceDto
   */
  financialInstitutionName: string;
  /**
   *
   * @type {string}
   * @memberof FundingSourceDto
   */
  accountNumber: string;
  /**
   *
   * @type {boolean}
   * @memberof FundingSourceDto
   */
  activeTransactions: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FundingSourceDto
   */
  isVerified: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FundingSourceDto
   */
  needsUserValidation: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FundingSourceDto
   */
  verificationFailed: boolean;
  /**
   *
   * @type {string}
   * @memberof FundingSourceDto
   */
  icon?: string;
  /**
   *
   * @type {string}
   * @memberof FundingSourceDto
   */
  type: FundingSourceDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FundingSourceDto
   */
  availableFunds?: string;
  /**
   *
   * @type {string}
   * @memberof FundingSourceDto
   */
  status: FundingSourceDtoStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum FundingSourceDtoTypeEnum {
  Plaid = 'PLAID',
  Liability = 'LIABILITY',
}
/**
 * @export
 * @enum {string}
 */
export enum FundingSourceDtoStatusEnum {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Removed = 'REMOVED',
  Denied = 'DENIED',
}

/**
 * Check if a given object implements the FundingSourceDto interface.
 */
export function instanceOfFundingSourceDto(
  value: object
): value is FundingSourceDto {
  if (!('fundingSourceId' in value) || value['fundingSourceId'] === undefined)
    return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (
    !('financialInstitutionName' in value) ||
    value['financialInstitutionName'] === undefined
  )
    return false;
  if (!('accountNumber' in value) || value['accountNumber'] === undefined)
    return false;
  if (
    !('activeTransactions' in value) ||
    value['activeTransactions'] === undefined
  )
    return false;
  if (!('isVerified' in value) || value['isVerified'] === undefined)
    return false;
  if (
    !('needsUserValidation' in value) ||
    value['needsUserValidation'] === undefined
  )
    return false;
  if (
    !('verificationFailed' in value) ||
    value['verificationFailed'] === undefined
  )
    return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function FundingSourceDtoFromJSON(json: any): FundingSourceDto {
  return FundingSourceDtoFromJSONTyped(json, false);
}

export function FundingSourceDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundingSourceDto {
  if (json == null) {
    return json;
  }
  return {
    fundingSourceId: json['fundingSourceId'],
    name: json['name'],
    financialInstitutionName: json['financialInstitutionName'],
    accountNumber: json['accountNumber'],
    activeTransactions: json['activeTransactions'],
    isVerified: json['isVerified'],
    needsUserValidation: json['needsUserValidation'],
    verificationFailed: json['verificationFailed'],
    icon: json['icon'] == null ? undefined : json['icon'],
    type: json['type'],
    availableFunds:
      json['availableFunds'] == null ? undefined : json['availableFunds'],
    status: json['status'],
  };
}

export function FundingSourceDtoToJSON(value?: FundingSourceDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSourceId: value['fundingSourceId'],
    name: value['name'],
    financialInstitutionName: value['financialInstitutionName'],
    accountNumber: value['accountNumber'],
    activeTransactions: value['activeTransactions'],
    isVerified: value['isVerified'],
    needsUserValidation: value['needsUserValidation'],
    verificationFailed: value['verificationFailed'],
    icon: value['icon'],
    type: value['type'],
    availableFunds: value['availableFunds'],
    status: value['status'],
  };
}
