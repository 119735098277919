import React from 'react';
import { TextInput } from 'components/formComponents';
import {
  UseFormRegister,
  FieldErrors,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';
import { Birthdate } from 'components/formComponents/Birthdate';
import { PersonInputs } from 'pages/Onboarding/NoController/PersonalInfo';
import { noNumbersRegex, emailRegex } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { useHookFormMask } from 'use-mask-input';

type FormProps = {
  errors: FieldErrors;
  register: UseFormRegister<Partial<PersonInputs>>;
  includeJobTitle?: boolean;
  hasDateOfBirth?: boolean;
  resetField: UseFormResetField<Partial<PersonInputs>>;
  setValue: UseFormSetValue<Partial<PersonInputs>>;
};

export const PersonalInfoForm = ({
  errors,
  register,
  includeJobTitle = false,
  hasDateOfBirth = false,
  resetField,
  setValue,
}: FormProps) => {
  const { t } = useTranslation();
  const registerWithMask = useHookFormMask(register);
  return (
    <>
      <TextInput
        label="Legal Full First Name"
        {...register('firstName', {
          required: {
            value: true,
            message: t('onboarding.ownerDetails.firstNameRequired'),
          },
          minLength: {
            value: 1,
            message: t('onboarding.ownerDetails.firstNameRequired'),
          },
          maxLength: {
            value: 50,
            message: t('onboarding.ownerDetails.maxChar50'),
          },
          pattern: { value: noNumbersRegex, message: 'No numbers allowed' },
        })}
        data-testid="firstName"
        required={true}
        errors={errors}
        type="text"
      />
      <TextInput
        label="Legal Full Last Name"
        {...register('lastName', {
          required: {
            value: true,
            message: t('onboarding.ownerDetails.lastNameRequired'),
          },
          maxLength: {
            value: 50,
            message: t('onboarding.ownerDetails.maxChar50'),
          },
          pattern: {
            value: noNumbersRegex,
            message: 'No numbers allowed',
          },
        })}
        type="text"
        required={true}
        data-testid="lastName"
        errors={errors}
      />
      <Birthdate
        maskedDateOfBirth={hasDateOfBirth}
        register={register}
        day={errors.day}
        month={errors.month}
        year={errors.year}
        resetField={resetField}
        setValue={setValue}
      />
      <TextInput
        label="Phone Number"
        {...registerWithMask('phone', ['(999) 999-9999'], {
          autoUnmask: true,
          required: { value: true, message: 'Phone number is required' },
          minLength: {
            value: 10,
            message: 'Phone number must be at least 10 numbers',
          },
        })}
        type="text"
        inputMode="numeric"
        required={true}
        data-testid="phoneNumber"
        errors={errors}
      />
      <TextInput
        label="Email"
        {...register('email', {
          required: { value: true, message: 'Email is required' },
          minLength: {
            value: 3,
            message: 'Email must be at least 3 characters',
          },
          pattern: {
            value: emailRegex,
            message: 'Please enter a valid email',
          },
        })}
        required={true}
        data-testid="email"
        errors={errors}
      />
      {includeJobTitle && (
        <TextInput
          label="Job Title"
          {...register('jobTitle', {
            required: {
              value: true,
              message: t('onboarding.ownerDetails.jobTitleRequired'),
            },
            maxLength: {
              value: 50,
              message: t('onboarding.ownerDetails.maxChar50'),
            },
          })}
          required={true}
          data-testid="jobTitle"
          errors={errors}
        />
      )}
    </>
  );
};
