import { HStack, Link, Stack, Image, Text } from '@chakra-ui/react';
import { IoMailOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import PiggyBank from 'images/Pig.svg';

export default function InviteCenterLink() {
  return (
    <Link
      as={NavLink}
      to="/settings/invite-center"
      _hover={{
        bgColor: 'gray.50',
        borderRadius: 'md',
      }}
    >
      <HStack border="1px solid" borderColor="border" borderRadius="md" p={2}>
        <Image src={PiggyBank} />
        <Stack fontSize="sm">
          <Text>
            Not finding who you're looking for? Invite them to the Bushel Wallet
            network today!
          </Text>
          <HStack color="blue.400">
            <IoMailOutline size={20} />
            <Text fontWeight="semibold">Send Invite</Text>
          </HStack>
        </Stack>
      </HStack>
    </Link>
  );
}
