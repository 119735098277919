import { MoneyInput } from 'pages/WalletApp/Transfers/components';
import { Button, Flex } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { TransferContext } from '../TransferFlow';

export const EnterValue = ({ context }: { context: TransferContext }) => {
  const { send, transferAmount, handleMoneyInput } = context;
  const navigate = useNavigate();
  const handleSubmit = () => {
    send({ type: 'NEXT', amount: transferAmount });
  };

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" mb="1rem">
        <ArrowBackIcon
          fontSize="1.5rem"
          cursor="pointer"
          onClick={() => navigate(-1)}
        />
        {context.type === 'LOAD' ? 'Deposit in Wallet' : 'Transfer To Bank'}
        <Flex width="24px" />
      </Flex>
      <MoneyInput
        amount={transferAmount}
        onChange={handleMoneyInput}
        showBorder={false}
      />
      <Button
        textTransform="capitalize"
        mt="2rem"
        isDisabled={parseFloat(transferAmount) <= 0}
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </Flex>
  );
};
