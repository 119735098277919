/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReviewStateDto
 */
export interface ReviewStateDto {
  /**
   *
   * @type {string}
   * @memberof ReviewStateDto
   */
  walletId: string;
}

/**
 * Check if a given object implements the ReviewStateDto interface.
 */
export function instanceOfReviewStateDto(
  value: object
): value is ReviewStateDto {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  return true;
}

export function ReviewStateDtoFromJSON(json: any): ReviewStateDto {
  return ReviewStateDtoFromJSONTyped(json, false);
}

export function ReviewStateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReviewStateDto {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
  };
}

export function ReviewStateDtoToJSON(value?: ReviewStateDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
  };
}
