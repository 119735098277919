import React from 'react';
import {
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Overview } from './index';
import { ChooseAmount } from './ChooseAmount';
import { CustomAmount } from './CustomAmount';
import { ChooseFundingSource } from './ChooseFundingSource';
import { SubmitPayment } from './SubmitPayment';
import { Success } from './Success';
import { Fail } from './Fail';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';
import { Capacitor } from '@capacitor/core';
import { useFundingSourceList, useWalletBalance } from 'hooks';

export const LoanPaymentModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [currentComponent, setCurrentComponent] = React.useState<JSX.Element>();
  const [currentHeader, setCurrentHeader] = React.useState<JSX.Element>();
  const { data: fundingSources } = useFundingSourceList({
    transactional: true,
    includeLiabilityFundsAvailable: true,
  });
  const { data: walletBalance } = useWalletBalance();
  const { send } = LoanPaymentContext.useActorRef();
  const value = LoanPaymentContext.useSelector((state) => state.value);

  React.useEffect(() => {
    if (walletBalance) {
      send({
        type: 'SET_WALLET_BALANCE',
        walletBalance: walletBalance?.balance,
      });
    }

    if (fundingSources?.fundingSources) {
      send({
        type: 'SET_ALL_FUNDING_SOURCES',
        allFundingSources: fundingSources?.fundingSources,
      });
    }
  }, [walletBalance]);

  React.useEffect(() => {
    if (value) {
      const component = getCurrentContent();
      const header = getCurrentHeader();
      setCurrentComponent(component);
      setCurrentHeader(header);
    }
  }, [value]);

  const handleClose = () => {
    onClose();
    send({ type: 'RESET', paymentAmount: '0.00', paymentSource: undefined });
  };

  const getCurrentContent = () => {
    switch (value) {
      case 'overview':
        return <Overview />;
      case 'chooseAmount':
        return <ChooseAmount />;
      case 'customAmount':
        return <CustomAmount />;
      case 'chooseFundingSource':
        return <ChooseFundingSource />;
      case 'submitPayment':
        return <SubmitPayment />;
      case 'success':
        return <Success onClose={handleClose} />;
      case 'fail':
        return <Fail handleClose={handleClose} />;
    }
  };

  const getCurrentHeader = () => {
    switch (value) {
      case 'overview':
        return <DefaultHeader />;
      case 'chooseAmount':
        return <ChooseAmountHeader />;
      case 'customAmount':
        return <ChooseAmountHeader />;
      case 'chooseFundingSource':
        return <ChooseFundingSourceHeader />;
      case 'submitPayment':
        return <></>;
      case 'success':
        return <div></div>;
      case 'fail':
        return <div></div>;
    }
  };

  const ChooseAmountHeader = () => {
    return (
      <>
        <Icon
          as={ArrowBackIcon}
          onClick={() => send({ type: 'BACK' })}
          cursor="pointer"
        />
        <Flex>Choose Amount</Flex>
        <Flex></Flex>
      </>
    );
  };

  const ChooseFundingSourceHeader = () => {
    return (
      <>
        <Icon
          as={ArrowBackIcon}
          onClick={() => send({ type: 'BACK' })}
          cursor="pointer"
        />
        <Flex>Select Account</Flex>
        <Flex></Flex>
      </>
    );
  };

  const DefaultHeader = () => {
    return (
      <>
        <Icon as={ArrowBackIcon} onClick={handleClose} cursor="pointer" />
        <Flex>Make a Payment</Flex>
        <Flex></Flex>
      </>
    );
  };

  const isNative = Capacitor.isNativePlatform();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay backgroundColor="gray.50" />
      <ModalContent
        p={
          !isNative
            ? '1rem 2rem 2rem'
            : 'env(safe-area-inset-top) 2rem env(safe-area-inset-bottom)'
        }
        mt={[0, '6rem']}
        height={['100%', 'auto']}
      >
        <Flex justifyContent="space-between" alignItems="center">
          {currentHeader}
        </Flex>
        {isOpen && (
          <ModalBody fontFamily="inter" padding="0">
            {currentComponent}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
