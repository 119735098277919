/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Withdrawal statement details.
 * @export
 * @interface Withdrawal
 */
export interface Withdrawal {
  /**
   *
   * @type {string}
   * @memberof Withdrawal
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof Withdrawal
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Withdrawal
   */
  withdrawal: string;
  /**
   *
   * @type {string}
   * @memberof Withdrawal
   */
  balance: string;
  /**
   *
   * @type {string}
   * @memberof Withdrawal
   */
  amount: string;
}

/**
 * Check if a given object implements the Withdrawal interface.
 */
export function instanceOfWithdrawal(value: object): value is Withdrawal {
  if (!('date' in value) || value['date'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  if (!('withdrawal' in value) || value['withdrawal'] === undefined)
    return false;
  if (!('balance' in value) || value['balance'] === undefined) return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  return true;
}

export function WithdrawalFromJSON(json: any): Withdrawal {
  return WithdrawalFromJSONTyped(json, false);
}

export function WithdrawalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Withdrawal {
  if (json == null) {
    return json;
  }
  return {
    date: json['date'],
    description: json['description'],
    withdrawal: json['withdrawal'],
    balance: json['balance'],
    amount: json['amount'],
  };
}

export function WithdrawalToJSON(value?: Withdrawal | null): any {
  if (value == null) {
    return value;
  }
  return {
    date: value['date'],
    description: value['description'],
    withdrawal: value['withdrawal'],
    balance: value['balance'],
    amount: value['amount'],
  };
}
