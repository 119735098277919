/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CheckInvitationResponse
 */
export interface CheckInvitationResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckInvitationResponse
   */
  isValid: boolean;
}

/**
 * Check if a given object implements the CheckInvitationResponse interface.
 */
export function instanceOfCheckInvitationResponse(
  value: object
): value is CheckInvitationResponse {
  if (!('isValid' in value) || value['isValid'] === undefined) return false;
  return true;
}

export function CheckInvitationResponseFromJSON(
  json: any
): CheckInvitationResponse {
  return CheckInvitationResponseFromJSONTyped(json, false);
}

export function CheckInvitationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CheckInvitationResponse {
  if (json == null) {
    return json;
  }
  return {
    isValid: json['isValid'],
  };
}

export function CheckInvitationResponseToJSON(
  value?: CheckInvitationResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    isValid: value['isValid'],
  };
}
