import { setup, assign, log, fromPromise } from 'xstate';
import { useKeycloakStore } from 'stores';
import {
  PersonDto,
  BusinessDto,
  BusinessDtoBusinessStructureEnum,
  OnboardingAddressDto,
  OnboardingDataDto,
  OnboardingMetaDataDto,
  OnboardingApi,
  AppApi,
} from 'api/wallet-app';
import { BASE_PATH, Configuration } from 'api/wallet-app';
import { v4 } from 'uuid';
import { PLAID_SCREEN_KEY } from 'pages/WalletApp/add-funding-source';

export type onboardingMachineContext = {
  people: Partial<PersonDto>[];
  meta: OnboardingMetaDataDto;
  business: Partial<BusinessDto>;
  isController?: boolean;
  isBeneficialOwner?: boolean;
  hasBeneficialOwners?: boolean;
  isEditing: boolean;
  person: Partial<PersonDto>;
};

export type onboardingMachineEvents =
  | { type: 'noController'; value: BusinessDtoBusinessStructureEnum }
  | { type: 'controllerRequired'; value: BusinessDtoBusinessStructureEnum }
  | {
      type: 'submitInfo';
      value: {
        payload: Partial<PersonDto>;
        target: string;
      };
    }
  | {
      type: 'submitAddress';
      value: { payload: OnboardingAddressDto };
    }
  | { type: 'submitSSN'; value: { payload: string } }
  | { type: 'skipSSN'; value: any }
  | { type: 'setBusinessStructure'; value: string }
  | { type: 'submitBusinessDetails'; value: BusinessDto }
  | { type: 'submitBusinessAddress'; value: OnboardingAddressDto }
  | { type: 'editBeneficialOwner'; value: Partial<PersonDto> }
  | { type: 'next'; value: any }
  | { type: 'back'; value: any }
  | { type: 'editPersonalInfo'; value: Partial<PersonDto> }
  | { type: 'editBusinessInfo'; value: Partial<BusinessDto> }
  | { type: 'removePerson'; value: string }
  | { type: 'addOwner'; value: any }
  | { type: 'submit'; value: any }
  | { type: 'yes'; value: any }
  | { type: 'no'; value: any };

export const getWalletApiConfig = () => {
  const keycloak = useKeycloakStore.getState().keycloak;
  return new Configuration({
    basePath: BASE_PATH,

    accessToken: `Bearer ${keycloak?.token}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak?.token}`,
    },
  });
};

// SEE: https://github.com/statelyai/xstate/discussions/4783
// SEE: https://github.com/statelyai/xstate/discussions/4812

export const onboardingMachine = setup({
  types: {
    context: {
      people: [] as Partial<PersonDto>[],
      business: {} as Partial<BusinessDto>,
      meta: {} as OnboardingMetaDataDto,
    } as onboardingMachineContext,
    events: {} as onboardingMachineEvents,
  },
  actions: {
    setInitialData: assign({
      people: ({ event }) => {
        //@ts-ignore
        return event.output?.appState.onboardingData?.people || [];
      },
      business: ({ event }) => {
        //@ts-ignore
        return event.output?.appState.onboardingData?.business || {};
      },
      meta: ({ event }) => {
        //@ts-ignore
        return event.output?.appState.onboardingData?.meta || {};
      },
    }),
    setBusinessStructure: assign({
      business: ({ context, event }) => {
        if (
          event.type === 'noController' ||
          event.type === 'controllerRequired'
        ) {
          return { ...context.business, businessStructure: event.value };
        } else {
          return { ...context.business };
        }
      },
    }),
    setHasBeneficialOwners: assign({
      hasBeneficialOwners: ({ event }) => event.type === 'yes',
    }),
    setIsBeneficialOwner: assign({
      isBeneficialOwner: ({ event }) => event.type === 'yes',
    }),
    removeBeneficialOwner: assign({
      people: ({ context, event }) => {
        return context.people.filter((i) => i.id !== event.value);
      },
    }),
    setIsController: assign({
      isController: ({ event }) => event.type === 'yes',
    }),
    turnEditOn: assign({
      isEditing: true,
    }),
    turnEditOff: assign({
      isEditing: false,
    }),
    setBeneficialOwnerToEdit: assign({
      person: ({ context, event }) => {
        if (event.type === 'editBeneficialOwner') {
          return event.value;
        } else {
          return context.person;
        }
      },
    }),
    updateSolePropPerson: assign({
      people: ({ context, event }) => {
        if ((event.type = 'submitInfo')) {
          return [event.value.payload];
        } else {
          return context.people;
        }
      },
    }),
    updateSolePropAddress: assign({
      people: ({ context, event }) => {
        context.people[0].address = event.value.payload;
        return context.people;
      },
    }),
    updateSolePropSSN: assign({
      people: ({ context, event }) => {
        context.people[0].ssn = event.value.payload;
        return context.people;
      },
    }),
    updatePerson: assign({
      people: ({ context, event }) => {
        const newPeopleArr = context.people.filter(
          (i) => i.id !== event.value.id
        );
        return newPeopleArr;
      },
      person: ({ context, event }) => {
        return { ...context.person, ...event.value.payload };
      },
    }),
    updatePersonAddress: assign({
      person: ({ context, event }) => {
        return { ...context.person, address: event.value.payload };
      },
    }),
    updatePersonSSN: assign({
      people: ({ context, event }) => {
        return [
          ...context.people,
          { ...context.person, ssn: event.value.payload },
        ];
      },
    }),
    setBusinessDetails: assign({
      business: ({ context, event }) => {
        return { ...context.business, ...event.value };
      },
    }),
    setBusinessAddress: assign({
      business: ({ context, event }) => {
        return { ...context.business, businessAddress: event.value };
      },
    }),
  },
  actors: {
    fetchOnboardingData: fromPromise(() => {
      const walletApiConfig = getWalletApiConfig();
      const api = new AppApi(walletApiConfig);
      return api.getAppState();
    }),
    updateOnboardingData: fromPromise(async (payload) => {
      const walletApiConfig = getWalletApiConfig();
      const api = new OnboardingApi(walletApiConfig);
      return await api.updateOnboardingData({
        onboardingDataDto: payload.input as OnboardingDataDto,
        idempotencyKey: v4(),
      });
    }),
    submitOnboarding: fromPromise(async () => {
      const walletApiConfig = getWalletApiConfig();
      const api = new OnboardingApi(walletApiConfig);
      return await api.submitOnboardingData();
    }),
  },
  guards: {
    isBeneficialOwner: ({ context }) => context.isBeneficialOwner === true,
    isNotBeneficialOwner: ({ context }) => context.isBeneficialOwner === false,
    isController: ({ context }) => context.isController === true,
    isNotController: ({ context }) => context.isController === false,
    hasBeneficialOwners: ({ context }) => context.hasBeneficialOwners == true,
    doesNotHaveBeneficialOwners: ({ context }) =>
      context.hasBeneficialOwners === false,
    alreadyHasSolePropUser: ({ context }) =>
      context.people.length > 0 &&
      context.business.businessStructure ===
        BusinessDtoBusinessStructureEnum.SoleProp,
    alreadyHasControllerOrBusinessData: ({ context }) =>
      (context.people.length > 0 || Object.keys(context.business).length > 0) &&
      context.business.businessStructure !==
        BusinessDtoBusinessStructureEnum.SoleProp,
  },
}).createMachine({
  id: 'onboarding',
  initial: 'TypeOfBusiness',
  context: {
    people: [],
    business: {} as BusinessDto,
    hasBeneficialOwners: undefined,
    isController: undefined,
    isBeneficialOwner: undefined,
    meta: {} as OnboardingMetaDataDto,
    isEditing: false,
    person: {} as PersonDto,
  },
  states: {
    TypeOfBusiness: {
      invoke: {
        src: 'fetchOnboardingData',
        input: ({ context, event }) => {
          return { context, event };
        },
        onError: {
          actions: log('error fetching onboarding data'),
        },
        onDone: {
          actions: ['setInitialData'],
        },
      },
      always: [
        {
          target: '#noController.Overview',
          guard: 'alreadyHasSolePropUser',
        },
        {
          target: '#controllerRequired.LLCOverview',
          guard: 'alreadyHasControllerOrBusinessData',
        },
      ],
      on: {
        noController: {
          target: 'NoController',
          actions: 'setBusinessStructure',
        },
        controllerRequired: {
          target: 'ControllerRequired',
          actions: 'setBusinessStructure',
        },
      },
    },
    Submit: {
      invoke: {
        src: 'submitOnboarding',
        onDone: {
          actions: () => {
            sessionStorage.setItem(PLAID_SCREEN_KEY, 'true');
          },
        },
        onError: {
          actions: log('error'),
        },
      },
    },
    NoController: {
      id: 'noController',
      initial: 'PersonalInfo',
      states: {
        PersonalInfo: {
          on: {
            submitInfo: {
              target: 'ResidentialAddress',
              actions: [
                {
                  type: 'updateSolePropPerson',
                  params: (value: Partial<PersonDto>, target: string) => {
                    return { person: value, target: target };
                  },
                },
              ],
            },
            back: '#onboarding.TypeOfBusiness',
          },
        },
        ResidentialAddress: {
          on: {
            submitAddress: {
              target: 'SocialSecurity',
              actions: {
                type: 'updateSolePropAddress',
                params: (value: Partial<PersonDto>, target: string) => {
                  return { address: value, target };
                },
              },
            },
            back: 'PersonalInfo',
          },
        },
        SocialSecurity: {
          on: {
            submitSSN: {
              actions: 'updateSolePropSSN',
              target: 'PersistData',
            },
            skipSSN: {
              target: 'PersistData',
            },
            back: 'ResidentialAddress',
          },
        },
        PersistData: {
          invoke: {
            src: 'updateOnboardingData',
            input: ({ context }) => {
              return {
                people: context.people,
                business: context.business,
                meta: context.meta,
              };
            },
            onDone: {
              target: 'FetchUpdatedData',
              actions: 'setInitialData',
            },
            onError: {
              actions: log('error'),
            },
          },
        },
        FetchUpdatedData: {
          invoke: {
            src: 'fetchOnboardingData',
            onDone: {
              actions: 'setInitialData',
              target: 'Overview',
            },
          },
        },
        Overview: {
          entry: ['turnEditOff'],
          on: {
            editPersonalInfo: {
              target: 'PersonalInfo',
              actions: 'turnEditOn',
            },
            editBusinessInfo: {
              target: 'BusinessDetails',
              actions: 'turnEditOn',
            },
            back: {
              target: '#onboarding.TypeOfBusiness',
              actions: 'turnEditOn',
            },
            submit: '#onboarding.Submit',
          },
        },
        BusinessDetails: {
          on: {
            back: 'Overview',
            submitBusinessDetails: {
              target: 'PersistData',
              actions: ['setBusinessDetails'],
            },
          },
        },
      },
    },
    ControllerRequired: {
      id: 'controllerRequired',
      initial: 'TwentyFivePercentOrMore',
      states: {
        TwentyFivePercentOrMore: {
          on: {
            yes: {
              target: 'AreYouABeneficialOwner',
              actions: 'setHasBeneficialOwners',
            },
            no: {
              target: 'AreYouAController',
              actions: 'setHasBeneficialOwners',
            },
            back: {
              target: '#onboarding.TypeOfBusiness',
            },
          },
        },
        AreYouABeneficialOwner: {
          on: {
            yes: {
              target: 'LLCOverview',
              actions: 'setIsBeneficialOwner',
            },
            no: {
              target: 'AreYouAController',
              actions: 'setIsBeneficialOwner',
            },
            back: {
              target: 'TwentyFivePercentOrMore',
            },
          },
        },
        AreYouAController: {
          on: {
            yes: [
              {
                target: 'LLCOverview',
                actions: 'setIsController',
                guard: 'doesNotHaveBeneficialOwners',
              },
              {
                target: 'LLCOverview',
                actions: 'setIsController',
                guard: 'hasBeneficialOwners',
              },
            ],
            no: [
              {
                target: 'DeadEnd',
                actions: 'setIsController',
                guard: 'doesNotHaveBeneficialOwners',
              },
              {
                target: 'DeadEnd',
                actions: 'setIsController',
                guard: 'isNotBeneficialOwner',
              },
              {
                target: 'LLCOverview',
                actions: 'setIsController',
                guard: 'hasBeneficialOwners',
              },
            ],
            back: {
              target: 'AreYouABeneficialOwner',
            },
          },
        },
        LLCBusinessDetails: {
          on: {
            submitBusinessDetails: {
              target: 'BusinessAddress',
              actions: {
                type: 'setBusinessDetails',
                params: (event: { value: Partial<BusinessDto> }) => {
                  return event.value;
                },
              },
            },
            back: 'LLCOverview',
          },
        },
        BusinessAddress: {
          on: {
            submitBusinessAddress: {
              target: 'PersistData',
              actions: [
                {
                  type: 'setBusinessAddress',
                  params: (event: { value: OnboardingAddressDto }) => {
                    return event.value;
                  },
                },
              ],
            },
            back: 'LLCBusinessDetails',
          },
        },
        ControllerInfo: {
          on: {
            submitInfo: {
              target: 'ControllerAddress',
              actions: {
                type: 'updatePerson',
                params: (event: { value: Partial<PersonDto> }) => {
                  return { person: event.value };
                },
              },
            },
            back: 'LLCOverview',
          },
        },
        ControllerAddress: {
          on: {
            submitAddress: {
              target: 'ControllerSocialSecurity',
              actions: {
                type: 'updatePersonAddress',
                params: (event: { value: OnboardingAddressDto }) => {
                  return { address: event.value };
                },
              },
            },
            back: { target: 'ControllerInfo' },
          },
        },
        ControllerSocialSecurity: {
          on: {
            submitSSN: {
              actions: ['updatePersonSSN', log('hitting submitSSN action')],
              target: 'PersistData',
            },
            skipSSN: {
              target: 'PersistData',
            },
            back: 'ControllerAddress',
          },
        },
        BeneficialOwnerInfo: {
          on: {
            submitInfo: {
              target: 'BeneficialOwnerAddress',
              actions: {
                type: 'updatePerson',
                params: (event: { value: Partial<PersonDto> }) => {
                  return { person: event.value };
                },
              },
            },
            back: 'LLCOverview',
          },
        },
        BeneficialOwnerAddress: {
          on: {
            submitAddress: {
              target: 'BeneficialOwnerSocialSecurity',
              actions: {
                type: 'updatePersonAddress',
                params: (event: { value: OnboardingAddressDto }) => {
                  return { address: event.value };
                },
              },
            },
            back: 'BeneficialOwnerInfo',
          },
        },
        BeneficialOwnerSocialSecurity: {
          on: {
            submitSSN: {
              target: 'PersistData',
              actions: [
                {
                  type: 'updatePersonSSN',
                  params: (event: { value: string; target: string }) => {
                    return { ssn: event.value };
                  },
                },
              ],
            },
            back: 'BeneficialOwnerAddress',
          },
        },
        PersistData: {
          invoke: {
            src: 'updateOnboardingData',
            input: ({ context }) => {
              return {
                people: context.people,
                business: context.business,
                meta: context.meta,
              };
            },
            onDone: {
              target: 'FetchUpdatedData',
              actions: 'setInitialData',
            },
            onError: {
              actions: log('error'),
            },
          },
        },
        FetchUpdatedData: {
          invoke: {
            src: 'fetchOnboardingData',
            onDone: {
              actions: 'setInitialData',
              target: 'LLCOverview',
            },
          },
        },
        LLCOverview: {
          entry: 'turnEditOff',
          on: {
            editBusinessInfo: {
              target: 'LLCBusinessDetails',
            },
            addOwner: {
              target: 'BeneficialOwnerInfo',
            },
            editPersonalInfo: {
              target: 'ControllerInfo',
              actions: ['turnEditOn'],
            },
            editBeneficialOwner: {
              target: 'BeneficialOwnerInfo',
              actions: ['turnEditOn', 'setBeneficialOwnerToEdit'],
            },
            removePerson: {
              target: 'PersistData',
              actions: ['removeBeneficialOwner'],
            },
            submit: '#onboarding.Submit',
            back: '#onboarding.TypeOfBusiness',
          },
        },
        hist: { type: 'history' },
        DeadEnd: {
          on: {
            back: 'hist',
          },
        },
      },
    },
  },
});
