import { createIcon } from '@chakra-ui/react';

export const Exit = createIcon({
  displayName: 'Exit',
  viewBox: '0 0 22 18',
  path: (
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.26884 1.51836C1.76113 1.02607 2.42881 0.749512 3.125 0.749512H12.125C12.8212 0.749512 13.4889 1.02607 13.9812 1.51836C14.4734 2.01064 14.75 2.67832 14.75 3.37451V5.24951C14.75 5.66373 14.4142 5.99951 14 5.99951C13.5858 5.99951 13.25 5.66373 13.25 5.24951V3.37451C13.25 3.07614 13.1315 2.78999 12.9205 2.57902C12.7095 2.36804 12.4234 2.24951 12.125 2.24951H3.125C2.82663 2.24951 2.54048 2.36804 2.3295 2.57902C2.11853 2.78999 2 3.07614 2 3.37451V14.6245C2 14.9229 2.11853 15.209 2.3295 15.42C2.54048 15.631 2.82663 15.7495 3.125 15.7495H12.125C12.4234 15.7495 12.7095 15.631 12.9205 15.42C13.1315 15.209 13.25 14.9229 13.25 14.6245V12.7495C13.25 12.3353 13.5858 11.9995 14 11.9995C14.4142 11.9995 14.75 12.3353 14.75 12.7495V14.6245C14.75 15.3207 14.4734 15.9884 13.9812 16.4807C13.4889 16.9729 12.8212 17.2495 12.125 17.2495H3.125C2.42881 17.2495 1.76113 16.9729 1.26884 16.4807C0.776562 15.9884 0.5 15.3207 0.5 14.6245V3.37451C0.5 2.67832 0.776562 2.01064 1.26884 1.51836ZM16.4697 4.71918C16.7626 4.42629 17.2374 4.42629 17.5303 4.71918L21.2803 8.46918C21.5732 8.76207 21.5732 9.23695 21.2803 9.52984L17.5303 13.2798C17.2374 13.5727 16.7626 13.5727 16.4697 13.2798C16.1768 12.9869 16.1768 12.5121 16.4697 12.2192L18.9393 9.74951H7.95312C7.53891 9.74951 7.20312 9.41373 7.20312 8.99951C7.20312 8.5853 7.53891 8.24951 7.95312 8.24951H18.9393L16.4697 5.77984C16.1768 5.48695 16.1768 5.01207 16.4697 4.71918Z"
      fill="#2D3748"
    />
  ),
});
