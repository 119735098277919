/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Lender Details Dto
 * @export
 * @interface LenderDetailsDto
 */
export interface LenderDetailsDto {
  /**
   *
   * @type {string}
   * @memberof LenderDetailsDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof LenderDetailsDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof LenderDetailsDto
   */
  byline?: string;
  /**
   *
   * @type {string}
   * @memberof LenderDetailsDto
   */
  icon?: string;
  /**
   *
   * @type {string}
   * @memberof LenderDetailsDto
   */
  banner?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof LenderDetailsDto
   */
  loanAttachmentStrategies: Array<LenderDetailsDtoLoanAttachmentStrategiesEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum LenderDetailsDtoLoanAttachmentStrategiesEnum {
  Compeer = 'COMPEER',
}

/**
 * Check if a given object implements the LenderDetailsDto interface.
 */
export function instanceOfLenderDetailsDto(
  value: object
): value is LenderDetailsDto {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (
    !('loanAttachmentStrategies' in value) ||
    value['loanAttachmentStrategies'] === undefined
  )
    return false;
  return true;
}

export function LenderDetailsDtoFromJSON(json: any): LenderDetailsDto {
  return LenderDetailsDtoFromJSONTyped(json, false);
}

export function LenderDetailsDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LenderDetailsDto {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    byline: json['byline'] == null ? undefined : json['byline'],
    icon: json['icon'] == null ? undefined : json['icon'],
    banner: json['banner'] == null ? undefined : json['banner'],
    loanAttachmentStrategies: json['loanAttachmentStrategies'],
  };
}

export function LenderDetailsDtoToJSON(value?: LenderDetailsDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    name: value['name'],
    byline: value['byline'],
    icon: value['icon'],
    banner: value['banner'],
    loanAttachmentStrategies: value['loanAttachmentStrategies'],
  };
}
