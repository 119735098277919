import { useEffect, useState } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Flex,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { find } from 'lodash';
import { TransferContext } from 'pages/WalletApp/Transfers/pages/load-unload/TransferFlow';
import { IndividualFundingSource } from '../../../components/IndividualFundingSource';
import currency from 'currency.js';
import { useFundingSourceList, useWalletBalance } from 'hooks';
import { FundingSourceDto, FundingSourceDtoTypeEnum } from 'api/wallet-app';

export const SelectFundingSource = ({
  context,
}: {
  context: TransferContext;
}) => {
  const [walletIsDisabled, setWalletIsDisabled] = useState(false);
  const [sources, setSources] = useState<FundingSourceDto[]>();

  const {
    send,
    type,
    transferAmount,
    fundingSource,
    setFundingSource,
    setWalletBalance,
  } = context;

  const { data: fundingSources, isLoading } = useFundingSourceList({
    includeLiabilityFundsAvailable: true,
    transactional: true,
  });
  const { data: walletBalance } = useWalletBalance();

  // only fetching/setting walletBalance here so we can use it on next screen. Prob should be done
  // higher up, if at all?

  setWalletBalance(walletBalance?.balance ?? '0');

  const handleSubmit = () => {
    send({ type: 'NEXT', fundingSource, balance: walletBalance?.balance });
  };

  useEffect(() => {
    if (!walletBalance?.balance) return;

    if (parseFloat(walletBalance?.balance ?? '0') === 0 && type == 'UNLOAD') {
      setWalletIsDisabled(true);
    }

    if (
      parseFloat(transferAmount) > parseFloat(walletBalance?.balance ?? '0') &&
      type == 'UNLOAD'
    ) {
      setWalletIsDisabled(true);
    }
  }, [walletBalance?.balance]);

  const handleSelectSource = (id: string) => {
    const source = find(
      fundingSources?.fundingSources,
      (fundSrc) => fundSrc.fundingSourceId == id
    );
    setFundingSource(source!);
  };

  useEffect(() => {
    if (!fundingSources?.fundingSources) return;

    if (fundingSources.fundingSources.length && type === 'UNLOAD') {
      const liabilityFundingSources = fundingSources.fundingSources.filter(
        (source) => source.type !== FundingSourceDtoTypeEnum.Liability
      );

      setSources(liabilityFundingSources);

      if (liabilityFundingSources.length === 1) {
        setFundingSource(fundingSources.fundingSources[0]);
      }
    } else if (fundingSources.fundingSources.length === 1) {
      setSources(fundingSources.fundingSources);
      setFundingSource(fundingSources.fundingSources[0]);
    } else {
      setSources(fundingSources.fundingSources);
    }
  }, [fundingSources?.fundingSources]);

  return (
    <Stack spacing={10}>
      <Flex justifyContent="space-between">
        <ArrowBackIcon
          fontSize="2xl"
          justifySelf="flex-start"
          onClick={() => send({ type: 'BACK' })}
          cursor="pointer"
        />
        <Text textAlign="center">Select Account</Text>
        <Flex w="24px" />
      </Flex>
      <Stack spacing={12}>
        <VStack>
          {type === 'UNLOAD' && (
            <Flex direction="column" mb="12px">
              <Flex
                direction="column"
                width="100%"
                border="solid 1px"
                borderColor="gray.300"
                borderRadius="lg"
                padding={4}
                opacity="0.7"
                fontFamily="body"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Text fontWeight="bold" color="black" as="span">
                    Wallet Balance
                  </Text>
                  <Text as="span" color="gray.500">
                    {currency(walletBalance?.balance ?? '').format()}
                  </Text>
                </Flex>
                <Text mt="8px" fontSize="12px" color="gray.500">
                  Your Wallet Balance is like cash. Once you pay, you can't
                  cancel it. So please verify to whom you are sending.
                </Text>
              </Flex>
              {walletIsDisabled && (
                <Text
                  fontSize="14px"
                  color="red.600"
                  alignSelf="flex-start"
                  mt="8px"
                >
                  Your wallet balance does not cover this transaction
                </Text>
              )}
            </Flex>
          )}
          {isLoading && (
            <Center minHeight="170px">
              <Spinner size="xl" thickness="4px" color="blue.400" />
            </Center>
          )}
          {sources &&
            sources.length &&
            sources.map((fundSrc, index) => {
              return (
                <IndividualFundingSource
                  fundSrc={fundSrc}
                  selectedSource={fundingSource}
                  handleSelectedSource={handleSelectSource}
                  index={index}
                  amount={transferAmount}
                  key={index}
                />
              );
            })}
        </VStack>

        <Button
          onClick={handleSubmit}
          disabled={!fundingSource || walletIsDisabled}
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};
