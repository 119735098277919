import { ComponentStyleConfig } from '@chakra-ui/react';

function constructButtonStyle(
  bg: string,
  color: string,
  hover: any,
  focus: any,
  disabled: any
) {
  return {
    bg,
    color,
    _active: hover,
    _hover: {
      ...hover,
      _disabled: disabled,
    },
    _focus: focus,
    _focusWithin: focus,
    _focusVisible: focus,
    _disabled: disabled,
  };
}

const outlineProps = {
  outlineWidth: '1px',
  outlineOffset: 0,
};

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'base',
    backgroundColor: 'blue.400',
    fontSize: 'md',
    textTransform: 'capitalize',
    width: '100%',
    minW: '102px',
    minWidth: '102px',
    color: 'white',
    _disabled: {
      opacity: 0.4,
    },
  },
  sizes: {
    sm: {
      fontSize: 'md',
      px: 4,
      py: 2,
    },
    md: {
      height: 10,
      px: '18px',
    },
    icon: {
      minW: '24px',
      minWidth: '24px',
    },
  },
  variants: {
    submit: {
      width: '100%',
      textTransform: 'capitalize',
    },
    primary: {
      bg: 'blue.400',
      _hover: {
        bg: 'blue.500',
        _disabled: {
          bg: 'blue.500',
        },
      },
      _active: {
        bg: 'blue.600',
      },
      _disabled: {
        bg: 'blue.400',
      },
    },
    secondary: {
      bg: 'gray.100',
      color: 'gray.700',
      _hover: {
        bg: 'gray.200',
        _disabled: {
          bg: 'gray.200',
        },
      },
      _active: {
        bg: 'gray.300',
      },
      _disabled: {
        bg: 'gray.100',
      },
    },
    success: {
      bg: 'green.500',
      color: '#fff',
      _hover: {
        bg: 'green.600',
        _disabled: {
          bg: 'green.600',
        },
      },
      _active: {
        bg: 'green.700',
      },
      _disabled: {
        bg: 'green.500',
      },
    },
    danger: {
      bg: 'red.500',
      color: '#fff',
      _hover: {
        bg: 'red.600',
        _disabled: {
          bg: 'red.600',
        },
      },
      _active: {
        bg: 'red.700',
      },
      _disabled: {
        bg: 'red.500',
      },
    },
    link: {
      bg: 'none',
      color: 'blue.400',
      _hover: {
        textDecoration: 'none',
      },
    },
    serious: {
      bg: 'gray.700',
      color: 'white',
      textTransform: 'uppercase',
    },
    iconButton: {
      bg: 'none',
      transition: 'none',
      color: 'black',
      border: 'none',
      _hover: {
        borderRadius: '16px',
        boxShadow: '0px 1px 6px rgba(45, 55, 72, 0.5)',
      },
      _focus: {
        outline: 'none',
        boxShadow: '0px',
        borderRadius: '16px',
        color: 'white',
        backgroundColor: 'gray.400',
        _hover: {
          boxShadow: '0px',
        },
      },
      _active: {
        borderRadius: '16px',
      },
    },
    ghost: {
      backgroundColor: 'transparent',
      color: 'blue.400',
    },
    outline: ({ colorScheme }) => {
      const _disabled = {
        bg: 'unset',
        color: `${colorScheme}.200`,
        border: '1px solid',
      };
      const _hover = {
        bg: `${colorScheme}.50`,
        border: '1px solid',
      };
      const _focus = {
        ...outlineProps,
        bg: `${colorScheme}.200`,
        outlineColor: `${colorScheme}.600`,
        boxShadow: 'none',
      };

      return constructButtonStyle(
        'unset',
        `${colorScheme}.500`,
        _hover,
        _focus,
        _disabled
      );
    },
  },
  defaultProps: {
    variant: 'primary',
    colorScheme: 'blue',
    size: 'md',
  },
};
