import { useMutation, useQueryClient } from '@tanstack/react-query';
import useFundingSourcesClient from './useFundingSourcesClient';
import { CreateFundingSourceRequest } from 'api/wallet-app';
import { v4 as uuid } from 'uuid';
import { FUNDING_SOURCE_LIST_QUERY_KEY } from './useFundingSourceList';
import { useWalletStore } from 'stores';

export default function useAddFundingSource() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const fundingSourceClient = useFundingSourcesClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['add-funding-source'],
    mutationFn: (payload: CreateFundingSourceRequest) => {
      return fundingSourceClient.addFundingSource({
        createFundingSourceRequest: payload,
        idempotencyKey: uuid(),
        xWalletId: walletId,
      });
    },
    onSuccess: () => {
      setTimeout(
        () =>
          queryClient.refetchQueries({
            queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY],
          }),
        1000
      );
    },
  });
}
