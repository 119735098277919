export const Heading = {
  baseStyle: {
    fontWeight: 'bold',
    letterSpacing: '-0.04rem',
  },
  defaultProps: {
    size: 'xl',
  },
  sizes: {
    xl: {
      fontSize: ['1.5rem', '2rem', '2rem'],
      lineHeight: ['32px', '40px'],
    },
    h1: {
      fontSize: '4.5rem',
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
  },
};
