import { useQuery } from '@tanstack/react-query';
import useWalletsClient from './useWalletsClient';

export const INVITE_SEARCH_PARAM_KEY = 'invite';
export const INVITE_SESSION_STORAGE_KEY = 'inviteToken';

export default function useCheckUserInvite(inviteCode: string | null) {
  const walletsClient = useWalletsClient(false);

  return useQuery({
    queryKey: ['check-user-invite'],
    queryFn: () => {
      return walletsClient.checkUserInvite({
        checkInvitationRequest: { inviteCode: inviteCode! },
      });
    },
    enabled: !!inviteCode,
  });
}
