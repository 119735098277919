import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Panel } from 'components/layout/onboarding';
import { desktopHeaderHeight, desktopHeaderMargin } from 'utils/helpers';
import { useLinkWallet } from 'hooks';
import { jwtDecode } from 'jwt-decode';
import { get } from 'lodash';

// Mocked LinkToken: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJhcHAiOnsibGlua1Rva2VuIjp7InJlZGlyZWN0VXJpIjoiaHR0cHM6Ly9nb29nbGUuY29tIiwiaW50ZWdyYXRpb25QYXJ0bmVyTmFtZSI6IkludGVncmF0aW9uIFBhcnRuZXIgTmFtZSJ9fX0.dPqfBJny0HL8WqtD-EcD-iKjgUMpuFoWZFjM4DOaskQ

export default function LinkIntegrationPartner({ token }: { token: string }) {
  const parsedToken: {
    'app.linkToken.redirectUri'?: string;
    'app.linkToken.integrationPartnerName'?: string;
  } = jwtDecode(token);
  const redirectUri = get(parsedToken, 'app.linkToken.redirectUri', '');
  const partnerName = get(
    parsedToken,
    'app.linkToken.integrationPartnerName',
    ''
  );

  const { mutate: linkWallet, isPending: linkWalletIsPending } =
    useLinkWallet(redirectUri);

  return (
    <Flex height="100vh">
      <Flex
        flexDirection="column"
        width={['auto', '100%']}
        height={[
          '100vh',
          `calc(100vh - ${desktopHeaderHeight} - ${desktopHeaderMargin} )`,
        ]}
        mt={[0, '4rem']}
      >
        <Panel>
          <Stack spacing={6} mt="24px">
            <Heading as="h3" size="xl">
              Link to Wallet
            </Heading>
            <Text>
              <span style={{ fontWeight: 'bold' }}>{partnerName}</span>
              &nbsp;wants to access your Wallet account number and business name
              to connect to your Wallet account and enable transactions.
            </Text>
            <Text border="solid 1px #CBD5E0" borderRadius="lg" p={4}>
              To enable the connection, click "Continue." If you do not want to
              allow the connection, click "Cancel." Without connection, you will
              not be able to transact business on your Wallet account with&nbsp;
              <span style={{ fontWeight: 'bold' }}>{partnerName}</span>.
            </Text>
            <HStack>
              <Button
                size="lg"
                variant="secondary"
                onClick={() => {
                  window.location.href = redirectUri;
                }}
              >
                Cancel
              </Button>
              <Button
                size="lg"
                onClick={() => {
                  linkWallet({ token });
                }}
                disabled={linkWalletIsPending}
              >
                Continue
              </Button>
            </HStack>
            <Link
              color="gray.500"
              target="_blank"
              href="https://www.bushelwallet.com/policies"
              rel="noopener noreferrer"
              alignSelf="center"
            >
              Bushel Exchange's Privacy and Terms of Use
            </Link>
          </Stack>
        </Panel>
      </Flex>
    </Flex>
  );
}
