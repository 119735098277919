import { Center, CenterProps, Spinner, SpinnerProps } from '@chakra-ui/react';

export default function CenteredSpinner({
  centerProps,
  spinnerProps,
}: {
  centerProps?: CenterProps;
  spinnerProps?: SpinnerProps;
}) {
  return (
    <Center h="100vh" {...centerProps}>
      <Spinner size="lg" color="blue.400" {...spinnerProps} />
    </Center>
  );
}
