/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CursorMetadata
 */
export interface CursorMetadata {
  /**
   *
   * @type {string}
   * @memberof CursorMetadata
   */
  prevCursor?: string;
  /**
   *
   * @type {string}
   * @memberof CursorMetadata
   */
  nextCursor?: string;
  /**
   *
   * @type {number}
   * @memberof CursorMetadata
   */
  count?: number;
}

/**
 * Check if a given object implements the CursorMetadata interface.
 */
export function instanceOfCursorMetadata(
  value: object
): value is CursorMetadata {
  return true;
}

export function CursorMetadataFromJSON(json: any): CursorMetadata {
  return CursorMetadataFromJSONTyped(json, false);
}

export function CursorMetadataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CursorMetadata {
  if (json == null) {
    return json;
  }
  return {
    prevCursor: json['prevCursor'] == null ? undefined : json['prevCursor'],
    nextCursor: json['nextCursor'] == null ? undefined : json['nextCursor'],
    count: json['count'] == null ? undefined : json['count'],
  };
}

export function CursorMetadataToJSON(value?: CursorMetadata | null): any {
  if (value == null) {
    return value;
  }
  return {
    prevCursor: value['prevCursor'],
    nextCursor: value['nextCursor'],
    count: value['count'],
  };
}
