import alloy, { Init } from '@alloyidentity/web-sdk';
import { DocVCallback } from 'types/docv';
import * as config from 'config.json';

const sdkThemeCustomStyle = {
  theme: {
    primaryColor: '#33A37D',
    backgroundColor: '#fff',
    textColor: '#000',
    borderRadius: '6px',
    iconColor: '#33A37D',
  },
  componentOverride: {
    HelpBanner: {
      default: {
        backgroundColor: 'gold',
        textColor: '#212121',
        border: '0.5px solid rgba(0, 0, 0, 0.1)',
      },
    },
    Header: {
      default: {
        border: '0.5px solid rgba(0, 0, 0, 0.1)',
      },
    },
    PhoneInput: {
      default: {
        backgroundColor: '#f9f9f9',
        border: '0.5px solid rgba(0, 0, 0, 0.1)',
      },
    },
  },
};

const getAlloyInitParams = (
  journeyToken: string,
  alloySdkToken: string,
  journeyApplicationToken?: string
): Init => ({
  key: alloySdkToken,
  journeyApplicationToken,
  journeyToken: journeyToken,
  customStyle: sdkThemeCustomStyle,
  customerSlug: config.customer_slug,
  apiUrl: config.sdk_api_url,
  appUrl: config.sdk_app_url,
});

export function initAlloy({
  journeyToken,
  alloySdkToken,
  journeyApplicationToken,
}: {
  journeyToken: string;
  alloySdkToken: string;
  journeyApplicationToken?: string;
}) {
  return alloy.init(
    getAlloyInitParams(journeyToken, alloySdkToken, journeyApplicationToken)
  );
}

export function closeAlloy() {
  alloy.close();
}

export function openAlloy(
  cb: (data: DocVCallback) => void,
  anchorElement: string
) {
  alloy.open(cb, anchorElement);
}
