/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RecordScreenViewRequest
 */
export interface RecordScreenViewRequest {
  /**
   * Slug for the screen name
   * @type {string}
   * @memberof RecordScreenViewRequest
   */
  screenName: RecordScreenViewRequestScreenNameEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RecordScreenViewRequestScreenNameEnum {
  CompeerWelcomeScreen = 'compeer-welcome-screen',
}

/**
 * Check if a given object implements the RecordScreenViewRequest interface.
 */
export function instanceOfRecordScreenViewRequest(
  value: object
): value is RecordScreenViewRequest {
  if (!('screenName' in value) || value['screenName'] === undefined)
    return false;
  return true;
}

export function RecordScreenViewRequestFromJSON(
  json: any
): RecordScreenViewRequest {
  return RecordScreenViewRequestFromJSONTyped(json, false);
}

export function RecordScreenViewRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecordScreenViewRequest {
  if (json == null) {
    return json;
  }
  return {
    screenName: json['screenName'],
  };
}

export function RecordScreenViewRequestToJSON(
  value?: RecordScreenViewRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    screenName: value['screenName'],
  };
}
