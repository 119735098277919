import {
  AvailableStatementData,
  GetStatementDetailsRequest,
  GetStatementsListResponse,
  GetTransactionDetailsRequest,
  GetTransactionHistoryResponse,
  LoadRequest,
  MakeLiabilityPaymentRequest,
  MakeLiabilityPaymentResponse,
  SendMoneyRequest,
  SendMoneyResponse,
  TransactionDetailsDtoMoneySentOrReceivedEnum,
  TransactionDetailsDtoStatusEnum,
  TransactionDetailsDtoTypeEnum,
  TransactionMetadataResponse,
  TransactionStatementResponse,
  TransferResponse,
  UnloadRequest,
} from 'api/wallet-app';
import { statementList, transactionList, WALLET_ID } from 'mocks/fixtures';
import {
  commandsBasePath,
  mockedRequestsRestApiBasePath,
  walletBasePath,
} from 'mocks/helpers';
import { http, HttpResponse, PathParams } from 'msw';
import { v4 as uuid } from 'uuid';

// TODO: getTransactionHistory, getTransactionDetails, getStatementsList, sendMoney, microDepositSubmitted
const transactionsHandlers = [
  /*
   * GET /api/v1/wallet/transactions
   * Generated API Endpoint Name: getTransactionHistory
   */
  http.get<PathParams, undefined, GetTransactionHistoryResponse>(
    walletBasePath('transactions'),
    () => {
      return HttpResponse.json({
        transactionHistory: transactionList,
        metadata: {
          count: transactionList.length,
          nextCursor: '',
          prevCursor: '',
        },
      });
    }
  ),

  /*
   * GET /api/v1/wallet/transactions/{transactionId}
   * Generated API Endpoint Name: getTransactionDetails
   */
  http.get<
    Pick<GetTransactionDetailsRequest, 'transactionId'>,
    undefined,
    TransactionMetadataResponse
  >(walletBasePath('transactions/:transactionId'), async ({ params }) => {
    const transaction = transactionList.find(
      (transaction) => transaction.id === params.transactionId
    );

    if (!transaction) {
      throw new HttpResponse(null, { status: 404 });
    } else {
      return HttpResponse.json({
        transactionDetails: {
          ...transaction,
          type: TransactionDetailsDtoTypeEnum.Load,
          moneySentOrReceived:
            transaction.moneySentOrReceived as unknown as TransactionDetailsDtoMoneySentOrReceivedEnum,
          status:
            transaction.status as unknown as TransactionDetailsDtoStatusEnum,
        },
      });
    }
  }),

  /*
   * GET /api/v1/transactions/statement/{periodYear}/{periodMonth}
   * Generated API Endpoint Name: getStatementDetails
   */
  http.get<GetStatementDetailsRequest, undefined, TransactionStatementResponse>(
    mockedRequestsRestApiBasePath({
      path: 'transactions/statement/:periodYear/:periodMonth',
    }),
    () => {
      return HttpResponse.json({
        data: {
          beginningBalance: '$210.70',
          creditsTotal: '$0.00',
          debitsTotal: '-$0.00',
          endingBalance: '$210.70',
          transactions: [],
          nonBalanceAffecting: [],
        },
        statementDateCode: '202410',
        businessName: 'Moo Moo Cow',
        businessAddress: {
          addressLine1: '123 Broadway',
          addressLine2: '',
          city: 'Fargo',
          postalCode: '58102',
          stateProvinceCode: 'ND',
          countryCode: 'US',
        },
      });
    }
  ),

  /*
   * GET /api/v1/transactions/statement/available
   * Generated API Endpoint Name: getStatementsList
   */
  http.get<PathParams, undefined, GetStatementsListResponse>(
    mockedRequestsRestApiBasePath({ path: 'transactions/statement/available' }),
    () => {
      return HttpResponse.json({ statementsList: statementList });
    }
  ),

  /*
   * POST /api/v1/commands/unload-account
   * Generated API Endpoint Name: unloadAccount
   */
  http.post<PathParams, UnloadRequest, TransferResponse>(
    commandsBasePath('unload-account'),
    async ({ request }) => {
      const { amount } = await request.json();
      let number = 1;

      transactionList.push({
        amount,
        destination: `New Unload Destination ${number}`,
        source: `New Unload Source ${number++}`,
        id: uuid(),
        type: TransactionDetailsDtoTypeEnum.Unload,
        status: TransactionDetailsDtoStatusEnum.Pending,
        moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Sent,
      });

      return HttpResponse.json({
        originatingWalletId: WALLET_ID,
      });
    }
  ),

  /*
   * POST /api/v1/commands/send-money
   * Generated API Endpoint Name: sendMoney
   */
  http.post<PathParams, SendMoneyRequest, SendMoneyResponse>(
    commandsBasePath('send-money'),
    async ({ request }) => {
      const { amount } = await request.json();
      let number = 1;

      transactionList.push({
        amount,
        destination: `New Instant Payment Destination ${number}`,
        source: `New Instant Payment Source ${number++}`,
        type: TransactionDetailsDtoTypeEnum.RealTimePayment,
        status: TransactionDetailsDtoStatusEnum.Pending,
        moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Sent,
      });

      return HttpResponse.json({ fundsFlowId: uuid() });
    }
  ),

  /*
   * POST /api/v1/commands/micro-deposit-submitted
   * Generated API Endpoint Name: microDepositSubmitted
   */
  http.post<PathParams>(commandsBasePath('micro-deposit-submitted'), () => {
    return HttpResponse.json({
      fundingSourceId: uuid(),
    });
  }),

  /*
   * POST /api/v1/commands/load-account
   * Generated API Endpoint Name: loadAccount
   */
  http.post<PathParams, LoadRequest, TransferResponse>(
    commandsBasePath('load-account'),
    async ({ request }) => {
      const { amount } = await request.json();
      let number = 1;

      transactionList.push({
        id: uuid(),
        amount,
        destination: `New Load Destination ${number}`,
        source: `New Load Source ${number++}`,
        type: TransactionDetailsDtoTypeEnum.Load,
        status: TransactionDetailsDtoStatusEnum.Pending,
        moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Sent,
      });
      return HttpResponse.json({
        originatingWalletId: WALLET_ID,
      });
    }
  ),

  /*
   * POST /api/v1/commands/liability-payment
   * Generated API Endpoint Name: payLiability
   */
  http.post<
    PathParams,
    MakeLiabilityPaymentRequest,
    MakeLiabilityPaymentResponse
  >(commandsBasePath('liability-payment'), async ({ request }) => {
    const { amount } = await request.json();
    const fundsFlowId = uuid();
    let number = 1;

    transactionList.push({
      id: fundsFlowId,
      amount,
      destination: `New Liability Payment Destination ${number}`,
      source: `New Liability Payment Source ${number++}`,
      type: TransactionDetailsDtoTypeEnum.AchPayment,
      status: TransactionDetailsDtoStatusEnum.Pending,
      moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Sent,
    });
    return HttpResponse.json({
      fundsFlowId,
    });
  }),
];

export default transactionsHandlers;
