import { Button, HStack, Image, Text } from '@chakra-ui/react';
import {
  TransactionDetailsDto,
  TransactionDetailsDtoMoneySentOrReceivedEnum,
  TransactionDetailsDtoStatusEnum,
  TransactionDetailsDtoTypeEnum,
} from 'api/wallet-app';
import WalletLogo from 'images/wallet-logo.svg';

export function transactionStatusBadgeColor(
  status: TransactionDetailsDtoStatusEnum | undefined
) {
  switch (status) {
    case TransactionDetailsDtoStatusEnum.Pending:
      return 'yellow';
    case TransactionDetailsDtoStatusEnum.Declined:
    case TransactionDetailsDtoStatusEnum.Canceled:
    case undefined:
      return 'gray';
    case TransactionDetailsDtoStatusEnum.Completed:
      return 'green';
    case TransactionDetailsDtoStatusEnum.Failed:
      return 'red';
  }
}

export function getTransactionType(transaction?: TransactionDetailsDto) {
  switch (transaction?.type) {
    case TransactionDetailsDtoTypeEnum.Apto:
    case TransactionDetailsDtoTypeEnum.AptoHold:
      return 'Card Transaction';
    case TransactionDetailsDtoTypeEnum.Load:
      return 'Deposit';
    case TransactionDetailsDtoTypeEnum.Unload:
      return 'Transfer to Bank';
    case TransactionDetailsDtoTypeEnum.VanTransaction:
      return 'Credit Received';
    case TransactionDetailsDtoTypeEnum.RealTimePayment:
    case TransactionDetailsDtoTypeEnum.AchPayment:
      switch (transaction.moneySentOrReceived) {
        case TransactionDetailsDtoMoneySentOrReceivedEnum.Sent:
          return 'Payment Sent';
        case TransactionDetailsDtoMoneySentOrReceivedEnum.Received:
          return 'Payment Received';
      }
      break;
    case TransactionDetailsDtoTypeEnum.LiabilityPayment:
      return 'Loan Payment';
    case TransactionDetailsDtoTypeEnum.PaymentRequest:
      switch (transaction.moneySentOrReceived) {
        // If money was trying to be sent by the user, they initially made the request for payment and should see request received
        case TransactionDetailsDtoMoneySentOrReceivedEnum.Sent:
          return 'Request Received';
        // If user was trying to receive money from someone, the request for money was originally sent by the user
        case TransactionDetailsDtoMoneySentOrReceivedEnum.Received:
          return 'Request Sent';
      }
      break;
  }
}

export function displayFundingSource(fundingSource?: string) {
  if (fundingSource === 'Wallet Balance') {
    return (
      <HStack>
        <Image src={WalletLogo} boxSize={6} data-testid="wallet-icon" />
        <Text>{fundingSource}</Text>
      </HStack>
    );
  } else {
    return <Text>{fundingSource}</Text>;
  }
}

export function transactionDetailsHeaderText({
  transaction,
  onClick,
}: {
  transaction?: TransactionDetailsDto;
  onClick: () => void;
}) {
  const isReceived =
    transaction?.moneySentOrReceived ===
    TransactionDetailsDtoMoneySentOrReceivedEnum.Received;
  const shouldDisplayAsButton = !!transaction?.walletId;
  switch (transaction?.type) {
    case TransactionDetailsDtoTypeEnum.PaymentRequest:
      if (isReceived) {
        return (
          <Text>
            <span>You requested payment from&nbsp;</span>
            {shouldDisplayAsButton ? (
              <Button
                fontWeight="bold"
                variant="link"
                minWidth="fit-content"
                w="fit-content"
                onClick={onClick}
              >
                {transaction.source}
              </Button>
            ) : (
              <span style={{ fontWeight: 'bold' }}>{transaction.source}</span>
            )}
          </Text>
        );
      } else {
        return (
          <Text>
            {shouldDisplayAsButton ? (
              <Button
                fontWeight="bold"
                variant="link"
                minWidth="fit-content"
                w="fit-content"
                onClick={onClick}
              >
                {transaction.destination}
              </Button>
            ) : (
              <span style={{ fontWeight: 'bold' }}>
                &nbsp;{transaction.destination}
              </span>
            )}
            <span>&nbsp;requested payment for</span>
          </Text>
        );
      }
    case TransactionDetailsDtoTypeEnum.AchPayment:
    case TransactionDetailsDtoTypeEnum.RealTimePayment:
      if (isReceived) {
        return (
          <Text>
            {shouldDisplayAsButton ? (
              <Button
                fontWeight="bold"
                variant="link"
                minWidth="fit-content"
                w="fit-content"
                onClick={onClick}
              >
                {transaction.source}
              </Button>
            ) : (
              <span style={{ fontWeight: 'bold' }}>
                &nbsp;{transaction.source}
              </span>
            )}
            <span>&nbsp;sent you</span>
          </Text>
        );
      } else {
        return (
          <Text>
            <span>You sent&nbsp;</span>
            {shouldDisplayAsButton ? (
              <Button
                fontWeight="bold"
                variant="link"
                minWidth="fit-content"
                w="fit-content"
                onClick={onClick}
              >
                {transaction.destination}
              </Button>
            ) : (
              <span style={{ fontWeight: 'bold' }}>
                {transaction.destination}
              </span>
            )}
          </Text>
        );
      }
    case TransactionDetailsDtoTypeEnum.Unload:
      return (
        <Text>
          <span>Transfer to&nbsp;</span>
          <span style={{ fontWeight: 'bold' }}>{transaction.destination}</span>
        </Text>
      );
    case TransactionDetailsDtoTypeEnum.Load:
    case TransactionDetailsDtoTypeEnum.VanTransaction:
      return (
        <Text>
          <span>Balance add from&nbsp;</span>
          <span style={{ fontWeight: 'bold' }}>{transaction.source}</span>
        </Text>
      );
    case TransactionDetailsDtoTypeEnum.LiabilityPayment:
      return (
        <Text>
          <span>You paid&nbsp;</span>
          <span style={{ fontWeight: 'bold' }}>{transaction.destination}</span>
        </Text>
      );
    case TransactionDetailsDtoTypeEnum.Apto:
    case TransactionDetailsDtoTypeEnum.AptoHold:
      return (
        <Text>
          <span>You sent&nbsp;</span>
          <span style={{ fontWeight: 'bold' }}>{transaction.destination}</span>
        </Text>
      );
  }
}

export function transactionAmountTextDecoration(
  transaction?: TransactionDetailsDto
) {
  if (
    transaction?.type === TransactionDetailsDtoTypeEnum.PaymentRequest ||
    transaction?.status === TransactionDetailsDtoStatusEnum.Canceled ||
    transaction?.status === TransactionDetailsDtoStatusEnum.Declined ||
    transaction?.status === TransactionDetailsDtoStatusEnum.Failed
  ) {
    return 'line-through';
  } else {
    return 'inherit';
  }
}

export function transactionAmountTextColor(
  transaction?: TransactionDetailsDto
) {
  if (
    transaction?.moneySentOrReceived ===
    TransactionDetailsDtoMoneySentOrReceivedEnum.Received
  ) {
    if (transaction.type === TransactionDetailsDtoTypeEnum.PaymentRequest) {
      return 'gray.700';
    } else {
      switch (transaction.status) {
        case TransactionDetailsDtoStatusEnum.Pending:
        case TransactionDetailsDtoStatusEnum.Completed:
          return 'green.500';
        case TransactionDetailsDtoStatusEnum.Canceled:
        case TransactionDetailsDtoStatusEnum.Declined:
        case TransactionDetailsDtoStatusEnum.Failed:
          return 'gray.700';
      }
    }
  } else {
    return 'gray.700';
  }
}

export function transactionAmountSymbol(transaction?: TransactionDetailsDto) {
  if (
    transaction?.type === TransactionDetailsDtoTypeEnum.PaymentRequest ||
    transaction?.status === TransactionDetailsDtoStatusEnum.Canceled ||
    transaction?.status === TransactionDetailsDtoStatusEnum.Declined ||
    transaction?.status === TransactionDetailsDtoStatusEnum.Failed
  ) {
    return '';
  } else {
    return transaction?.moneySentOrReceived ===
      TransactionDetailsDtoMoneySentOrReceivedEnum.Received
      ? '+'
      : '-';
  }
}

export function transactionDateLabel(status?: TransactionDetailsDtoStatusEnum) {
  switch (status) {
    case TransactionDetailsDtoStatusEnum.Canceled:
      return 'Canceled Date';
    case TransactionDetailsDtoStatusEnum.Completed:
      return 'Completed Date';
    case TransactionDetailsDtoStatusEnum.Declined:
      return 'Declined Date';
    case TransactionDetailsDtoStatusEnum.Failed:
      return 'Failed Date';
    default:
      // Pending Transactions shouldn't show the associated detail item for this date label
      return '';
  }
}

export function transactionMemoLabel(transaction?: TransactionDetailsDto) {
  if (transaction?.type === TransactionDetailsDtoTypeEnum.PaymentRequest) {
    if (transaction?.status === TransactionDetailsDtoStatusEnum.Canceled) {
      return 'Reason for Canceling';
    } else {
      return 'Reason for Declining';
    }
  }
  if (
    transaction?.type === TransactionDetailsDtoTypeEnum.Unload ||
    transaction?.type === TransactionDetailsDtoTypeEnum.Load
  ) {
    return 'Memo';
  } else {
    return 'Payment Memo';
  }
}
