import React from 'react';
import { Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import currency from 'currency.js';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';

export const Overview = () => {
  const [insufficientFunds, setInsufficientFunds] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const { send } = LoanPaymentContext.useActorRef();
  const { paymentSource, walletBalance, paymentAmount, loanDetails } =
    LoanPaymentContext.useSelector((state) => state.context);

  React.useEffect(() => {
    if (paymentSource?.name !== 'wallet' || !paymentAmount) return;
    if (
      paymentSource.name == 'wallet' &&
      parseFloat(walletBalance) < parseFloat(paymentAmount)
    ) {
      setInsufficientFunds(true);
    }
  }, [paymentAmount, paymentSource, walletBalance]);

  React.useEffect(() => {
    if (paymentAmount && !paymentSource) {
      setIsDisabled(true);
    } else if (paymentSource && parseFloat(paymentAmount) === 0) {
      setIsDisabled(true);
    } else if (insufficientFunds) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [isDisabled, paymentAmount, paymentSource]);

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        mt="2rem"
        fontWeight="bold"
      >
        <Text color="#69788a" fontSize="12px">
          PAY TO
        </Text>
        {loanDetails?.providerBanner ? (
          <Image
            mt="8px"
            src={atob(loanDetails.providerBanner)}
            alt=""
            maxHeight="80px"
          />
        ) : (
          <Text fontSize="16px" mt=".5em">
            {loanDetails?.providerName}
          </Text>
        )}
      </Flex>
      <Flex direction="column" mt="2rem">
        <Text color="#69788a" fontSize="14px" mb=".5rem">
          Amount
        </Text>
        <Flex
          width="100%"
          border="solid 1px #E2E8F0"
          borderRadius="8px"
          height="50px"
          cursor="pointer"
          justifyContent="space-between"
          alignItems="center"
          padding="1rem"
          onClick={() => send({ type: 'CHOOSE_AMOUNT' })}
        >
          {parseFloat(paymentAmount) > 0 ? (
            <Text fontFamily="heading" fontSize="24px">
              {currency(paymentAmount).format()}
            </Text>
          ) : (
            <Text color="blue.400">Choose Amount</Text>
          )}
          <Icon fontSize="34px" as={ChevronRightIcon} color="gray.500" />
        </Flex>
      </Flex>
      <Flex direction="column" mt="1rem">
        <Text color="#69788a" fontSize="14px" mb=".5rem">
          From
        </Text>
        <Flex
          width="100%"
          border="solid 1px #E2E8F0"
          borderRadius="8px"
          height="50px"
          cursor="pointer"
          justifyContent="space-between"
          alignItems="center"
          padding="1rem"
          onClick={() => send({ type: 'CHOOSE_FUNDING_SOURCE' })}
        >
          {paymentSource ? (
            paymentSource.name === 'wallet' ? (
              <Text maxWidth="80%" fontFamily="inter" fontWeight="bold">
                Wallet Balance
              </Text>
            ) : (
              <Text
                maxWidth="80%"
                fontFamily="inter"
                fontWeight="bold"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {paymentSource.name}
              </Text>
            )
          ) : (
            <Text color="blue.400">Choose Account</Text>
          )}
          <Icon fontSize="34px" as={ChevronRightIcon} color="gray.500" />
        </Flex>
      </Flex>
      <Flex direction="column" mt="3rem" alignItems="center">
        <Text
          width="100%"
          textAlign="center"
          color="#C53030"
          mt="8px"
          fontSize="12px"
          fontFamily="inter"
        >
          {insufficientFunds &&
            "Your current Wallet balance won't cover this payment"}
        </Text>
        <Button
          mt="1rem"
          isDisabled={isDisabled}
          onClick={() => send({ type: 'SUBMIT_PAYMENT' })}
        >
          Pay{' '}
          {parseFloat(paymentAmount) > 0 && currency(paymentAmount).format()}
        </Button>
        <Text
          color="#2D3748"
          fontSize="12px"
          mt="1rem"
          textAlign="center"
          width="220px"
          fontFamily="inter"
        >
          Payments made after 4pm CST will be processed on the next business
          day.
        </Text>
      </Flex>
    </Flex>
  );
};
