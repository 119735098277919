/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CursorMetadata } from './CursorMetadata';
import {
  CursorMetadataFromJSON,
  CursorMetadataFromJSONTyped,
  CursorMetadataToJSON,
} from './CursorMetadata';
import type { TransactionDetailsDto } from './TransactionDetailsDto';
import {
  TransactionDetailsDtoFromJSON,
  TransactionDetailsDtoFromJSONTyped,
  TransactionDetailsDtoToJSON,
} from './TransactionDetailsDto';

/**
 *
 * @export
 * @interface GetTransactionHistoryResponse
 */
export interface GetTransactionHistoryResponse {
  /**
   *
   * @type {Array<TransactionDetailsDto>}
   * @memberof GetTransactionHistoryResponse
   */
  transactionHistory: Array<TransactionDetailsDto>;
  /**
   *
   * @type {CursorMetadata}
   * @memberof GetTransactionHistoryResponse
   */
  metadata: CursorMetadata;
}

/**
 * Check if a given object implements the GetTransactionHistoryResponse interface.
 */
export function instanceOfGetTransactionHistoryResponse(
  value: object
): value is GetTransactionHistoryResponse {
  if (
    !('transactionHistory' in value) ||
    value['transactionHistory'] === undefined
  )
    return false;
  if (!('metadata' in value) || value['metadata'] === undefined) return false;
  return true;
}

export function GetTransactionHistoryResponseFromJSON(
  json: any
): GetTransactionHistoryResponse {
  return GetTransactionHistoryResponseFromJSONTyped(json, false);
}

export function GetTransactionHistoryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetTransactionHistoryResponse {
  if (json == null) {
    return json;
  }
  return {
    transactionHistory: (json['transactionHistory'] as Array<any>).map(
      TransactionDetailsDtoFromJSON
    ),
    metadata: CursorMetadataFromJSON(json['metadata']),
  };
}

export function GetTransactionHistoryResponseToJSON(
  value?: GetTransactionHistoryResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    transactionHistory: (value['transactionHistory'] as Array<any>).map(
      TransactionDetailsDtoToJSON
    ),
    metadata: CursorMetadataToJSON(value['metadata']),
  };
}
