import { useTranslation } from 'react-i18next';
import {
  LinkItem,
  SettingsCard,
} from 'pages/WalletApp/settings/components/index';

export default function History() {
  const { t } = useTranslation();

  return (
    <SettingsCard heading="History">
      <LinkItem
        linkTarget="/settings/statements"
        title={t('wallet.settings.statements')}
        newTab={false}
      />
    </SettingsCard>
  );
}
