/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LenderDetailsDto } from './LenderDetailsDto';
import {
  LenderDetailsDtoFromJSON,
  LenderDetailsDtoFromJSONTyped,
  LenderDetailsDtoToJSON,
} from './LenderDetailsDto';

/**
 * Lender Details Response
 * @export
 * @interface GetLendersDetailsResponse
 */
export interface GetLendersDetailsResponse {
  /**
   *
   * @type {LenderDetailsDto}
   * @memberof GetLendersDetailsResponse
   */
  details: LenderDetailsDto;
}

/**
 * Check if a given object implements the GetLendersDetailsResponse interface.
 */
export function instanceOfGetLendersDetailsResponse(
  value: object
): value is GetLendersDetailsResponse {
  if (!('details' in value) || value['details'] === undefined) return false;
  return true;
}

export function GetLendersDetailsResponseFromJSON(
  json: any
): GetLendersDetailsResponse {
  return GetLendersDetailsResponseFromJSONTyped(json, false);
}

export function GetLendersDetailsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetLendersDetailsResponse {
  if (json == null) {
    return json;
  }
  return {
    details: LenderDetailsDtoFromJSON(json['details']),
  };
}

export function GetLendersDetailsResponseToJSON(
  value?: GetLendersDetailsResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    details: LenderDetailsDtoToJSON(value['details']),
  };
}
