import { useQuery } from '@tanstack/react-query';
import useTransactionClient from './useTransactionsClient';
import { useWalletStore } from 'stores';

export const STATEMENT_LIST_QUERY_KEY = 'statement-list';

export default function useStatementList() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const transactionsClient = useTransactionClient();
  return useQuery({
    queryKey: [STATEMENT_LIST_QUERY_KEY],
    queryFn: () => {
      return transactionsClient.getStatementsList({ xWalletId: walletId });
    },
  });
}
