import React from 'react';
import { Header, Body, Footer } from 'components/layout/onboarding';
import { Button, Flex, Text } from '@chakra-ui/react';
import { RadioButtonGroup } from 'components/formComponents';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useSelector } from '@xstate/react';

export const AreYouAController = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const [selection, setSelection] = React.useState<string | undefined>(
    sessionStorage.getItem('wallet.isController') || undefined
  );
  const machineState = useSelector(onboardingActor, (state) => state?.context);

  React.useEffect(() => {
    if (typeof machineState?.isController === 'undefined') return;
    setSelection(machineState.isController ? 'yes' : 'no');
  }, [machineState]);

  const handleSubmit = () => {
    if (!selection || !onboardingActor) return;
    onboardingActor.send({
      type: selection === 'yes' ? 'yes' : 'no',
      value: '',
    });
    sessionStorage.setItem('wallet.isController', selection);
  };

  return (
    <Flex direction="column" minHeight="500px">
      <Header title="Ownership Details" />
      <Text>Are you considered a controller at this organization?</Text>
      <Body>
        <RadioButtonGroup
          name="controller"
          options={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]}
          onChange={(val) => setSelection(val)}
          defaultValue={selection}
        />
      </Body>
      <Footer>
        <Button onClick={handleSubmit}>Next</Button>
      </Footer>
    </Flex>
  );
};
