/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionDetailsDto } from './TransactionDetailsDto';
import {
  TransactionDetailsDtoFromJSON,
  TransactionDetailsDtoFromJSONTyped,
  TransactionDetailsDtoToJSON,
} from './TransactionDetailsDto';

/**
 * Compeer loan funding source details.
 * @export
 * @interface CompeerLoanFundingSourceDetailsDto
 */
export interface CompeerLoanFundingSourceDetailsDto {
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  nickname: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  accountNumberLastFour: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  providerName: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  providerIcon?: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  providerBanner?: string;
  /**
   *
   * @type {Date}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  maturityDate?: Date;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  currentBalance?: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  interestAccruedToDate?: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  availableCredit?: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  creditUsed?: string;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  originalAmount?: string;
  /**
   *
   * @type {number}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  loanTermDays?: number;
  /**
   *
   * @type {number}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  loanTermMonths?: number;
  /**
   *
   * @type {number}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  rate?: number;
  /**
   *
   * @type {Date}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  lastSeenAt?: Date;
  /**
   *
   * @type {Array<TransactionDetailsDto>}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  recentTransactions: Array<TransactionDetailsDto>;
  /**
   *
   * @type {string}
   * @memberof CompeerLoanFundingSourceDetailsDto
   */
  providerUrl?: string;
}

/**
 * Check if a given object implements the CompeerLoanFundingSourceDetailsDto interface.
 */
export function instanceOfCompeerLoanFundingSourceDetailsDto(
  value: object
): value is CompeerLoanFundingSourceDetailsDto {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('nickname' in value) || value['nickname'] === undefined) return false;
  if (
    !('accountNumberLastFour' in value) ||
    value['accountNumberLastFour'] === undefined
  )
    return false;
  if (!('providerName' in value) || value['providerName'] === undefined)
    return false;
  if (
    !('recentTransactions' in value) ||
    value['recentTransactions'] === undefined
  )
    return false;
  return true;
}

export function CompeerLoanFundingSourceDetailsDtoFromJSON(
  json: any
): CompeerLoanFundingSourceDetailsDto {
  return CompeerLoanFundingSourceDetailsDtoFromJSONTyped(json, false);
}

export function CompeerLoanFundingSourceDetailsDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompeerLoanFundingSourceDetailsDto {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'],
    nickname: json['nickname'],
    accountNumberLastFour: json['accountNumberLastFour'],
    providerName: json['providerName'],
    providerIcon:
      json['providerIcon'] == null ? undefined : json['providerIcon'],
    providerBanner:
      json['providerBanner'] == null ? undefined : json['providerBanner'],
    maturityDate:
      json['maturityDate'] == null ? undefined : new Date(json['maturityDate']),
    currentBalance:
      json['currentBalance'] == null ? undefined : json['currentBalance'],
    interestAccruedToDate:
      json['interestAccruedToDate'] == null
        ? undefined
        : json['interestAccruedToDate'],
    availableCredit:
      json['availableCredit'] == null ? undefined : json['availableCredit'],
    creditUsed: json['creditUsed'] == null ? undefined : json['creditUsed'],
    originalAmount:
      json['originalAmount'] == null ? undefined : json['originalAmount'],
    loanTermDays:
      json['loanTermDays'] == null ? undefined : json['loanTermDays'],
    loanTermMonths:
      json['loanTermMonths'] == null ? undefined : json['loanTermMonths'],
    rate: json['rate'] == null ? undefined : json['rate'],
    lastSeenAt:
      json['lastSeenAt'] == null ? undefined : new Date(json['lastSeenAt']),
    recentTransactions: (json['recentTransactions'] as Array<any>).map(
      TransactionDetailsDtoFromJSON
    ),
    providerUrl: json['providerUrl'] == null ? undefined : json['providerUrl'],
  };
}

export function CompeerLoanFundingSourceDetailsDtoToJSON(
  value?: CompeerLoanFundingSourceDetailsDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    nickname: value['nickname'],
    accountNumberLastFour: value['accountNumberLastFour'],
    providerName: value['providerName'],
    providerIcon: value['providerIcon'],
    providerBanner: value['providerBanner'],
    maturityDate:
      value['maturityDate'] == null
        ? undefined
        : value['maturityDate'].toISOString(),
    currentBalance: value['currentBalance'],
    interestAccruedToDate: value['interestAccruedToDate'],
    availableCredit: value['availableCredit'],
    creditUsed: value['creditUsed'],
    originalAmount: value['originalAmount'],
    loanTermDays: value['loanTermDays'],
    loanTermMonths: value['loanTermMonths'],
    rate: value['rate'],
    lastSeenAt:
      value['lastSeenAt'] == null
        ? undefined
        : value['lastSeenAt'].toISOString(),
    recentTransactions: (value['recentTransactions'] as Array<any>).map(
      TransactionDetailsDtoToJSON
    ),
    providerUrl: value['providerUrl'],
  };
}
