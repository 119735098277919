/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SuspendedStateDto
 */
export interface SuspendedStateDto {
  /**
   *
   * @type {string}
   * @memberof SuspendedStateDto
   */
  walletId: string;
}

/**
 * Check if a given object implements the SuspendedStateDto interface.
 */
export function instanceOfSuspendedStateDto(
  value: object
): value is SuspendedStateDto {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  return true;
}

export function SuspendedStateDtoFromJSON(json: any): SuspendedStateDto {
  return SuspendedStateDtoFromJSONTyped(json, false);
}

export function SuspendedStateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SuspendedStateDto {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
  };
}

export function SuspendedStateDtoToJSON(value?: SuspendedStateDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
  };
}
