/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The command to revoke user access.
 * @export
 * @interface RevokeUserAccessRequest
 */
export interface RevokeUserAccessRequest {
  /**
   *
   * @type {string}
   * @memberof RevokeUserAccessRequest
   */
  userId: string;
}

/**
 * Check if a given object implements the RevokeUserAccessRequest interface.
 */
export function instanceOfRevokeUserAccessRequest(
  value: object
): value is RevokeUserAccessRequest {
  if (!('userId' in value) || value['userId'] === undefined) return false;
  return true;
}

export function RevokeUserAccessRequestFromJSON(
  json: any
): RevokeUserAccessRequest {
  return RevokeUserAccessRequestFromJSONTyped(json, false);
}

export function RevokeUserAccessRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RevokeUserAccessRequest {
  if (json == null) {
    return json;
  }
  return {
    userId: json['userId'],
  };
}

export function RevokeUserAccessRequestToJSON(
  value?: RevokeUserAccessRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    userId: value['userId'],
  };
}
