import { Flex, Image, Box, Button, Text } from '@chakra-ui/react';
import Checkmark from 'images/plaid-done.svg';
import currency from 'currency.js';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export const Success = ({ onClose }: { onClose: () => void }) => {
  const { paymentAmount } = LoanPaymentContext.useSelector(
    (state) => state.context
  );
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate('/activity');
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      minHeight="300px"
    >
      <Flex alignItems="flex-start" width="100%" mt="1rem">
        <CloseIcon cursor="pointer" fontSize="12px" onClick={handleClose} />
      </Flex>
      <Image src={Checkmark} />
      <Flex flexDirection="column" alignItems="center">
        <Box>Your payment of</Box>
        <Box fontFamily="heading" fontSize="32px">
          {currency(paymentAmount).format()}
        </Box>
        <Text>has been successfully sent</Text>
      </Flex>
      <Flex flexDirection="column" width="100%" mt="2rem">
        <Button onClick={handleClose} width="100%">
          Done
        </Button>
      </Flex>
    </Flex>
  );
};
