import { Button, Heading, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import RequestSent from 'images/request-payment-success.png';
import currency from 'currency.js';
import { RequestContext } from './requestReducer';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  OPEN_REQUESTS_SEARCH_PARAM,
} from 'pages/WalletApp/Activity/activity-page-search-params';

export const Success = ({ context }: { context: RequestContext }) => {
  const navigate = useNavigate();
  return (
    <Stack spacing={12}>
      <Stack spacing={8}>
        <Image src={RequestSent} />
        <Stack alignItems="center">
          <Text>You have requested</Text>
          <Heading as="h2" size="2xl">
            {currency(context.amount.value).format()}
          </Heading>
          <HStack spacing={1}>
            <Text>from</Text>
            <Text display="inline" fontWeight="bold">
              {context.requestee?.name}
            </Text>
          </HStack>
        </Stack>
      </Stack>
      <Stack>
        <Button
          variant="ghost"
          onClick={() => {
            navigate(
              `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
            );
          }}
        >
          View Requests
        </Button>
        <Button
          onClick={() => {
            navigate('/transfers');
          }}
        >
          Done
        </Button>
      </Stack>
    </Stack>
  );
};
