import { useMediaQuery, useToast } from '@chakra-ui/react';
import {
  DeclinePaymentRequestRequest,
  DeclinePaymentRequestResponse,
  NotAcceptablePaymentRequestResponse,
  NotAcceptablePaymentRequestResponseStatusEnum,
  ResponseError,
} from 'api/wallet-app';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  OPEN_REQUESTS_SEARCH_PARAM,
} from 'pages/WalletApp/Activity/activity-page-search-params';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ALERT_LIST_QUERY_KEY } from '../alerts';
import {
  TRANSACTIONS_LIST_QUERY_KEY,
  INFINITE_TRANSACTION_LIST_QUERY_KEY,
} from '../transactions';
import { PAYMENT_REQUEST_LIST_QUERY_KEY } from './usePaymentRequestList';
import usePaymentRequestClient from './usePaymentRequestClient';
import { useWalletStore } from 'stores';

export default function useDeclineRequest() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const paymentRequestClient = usePaymentRequestClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation<
    DeclinePaymentRequestResponse,
    ResponseError,
    DeclinePaymentRequestRequest
  >({
    mutationKey: ['decline-request'],
    mutationFn: (payload) => {
      return paymentRequestClient.declinePaymentRequest({
        declinePaymentRequestRequest: payload,
        xWalletId: walletId,
      });
    },
    onError: async (error) => {
      if (error.response.status !== 406) {
        toast({
          status: 'error',
          title: 'Unable to Decline Request',
          description: 'An error occurred when trying to decline the request.',
        });
      } else {
        const body =
          (await error.response.json()) as NotAcceptablePaymentRequestResponse;
        switch (body.status) {
          case NotAcceptablePaymentRequestResponseStatusEnum.Declined:
            toast({
              status: 'error',
              title: 'Unable to Decline Request',
              description: 'Request has already been declined.',
            });
            break;
          case NotAcceptablePaymentRequestResponseStatusEnum.Paid:
            toast({
              status: 'error',
              title: 'Unable to Decline Request',
              description: 'Payment has already been made.',
            });
            break;
          case NotAcceptablePaymentRequestResponseStatusEnum.Canceled:
            toast({
              status: 'error',
              title: 'Unable to Decline Request',
              description: 'Request has been canceled.',
            });
            break;
        }
        queryClient.refetchQueries({
          queryKey: [PAYMENT_REQUEST_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [TRANSACTIONS_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [INFINITE_TRANSACTION_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({ queryKey: [ALERT_LIST_QUERY_KEY] });
        navigate(
          `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
        );
      }
    },
    onSuccess: () => {
      toast({ status: 'success', title: 'Request Successfully Declined' });
      queryClient.refetchQueries({
        queryKey: [PAYMENT_REQUEST_LIST_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRANSACTIONS_LIST_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [INFINITE_TRANSACTION_LIST_QUERY_KEY],
      });
      queryClient.invalidateQueries({ queryKey: [ALERT_LIST_QUERY_KEY] });
      navigate(
        `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
      );
    },
  });
}
