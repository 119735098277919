import { Button, Heading, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Checkmark from 'images/plaid-done.svg';
import currency from 'currency.js';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  OPEN_REQUESTS_SEARCH_PARAM,
  TRANSACTION_HISTORY_SEARCH_PARAM,
} from 'pages/WalletApp/Activity/activity-page-search-params';
import { TransferContext } from '../../transferMachine';

export const Success = ({ context }: { context: TransferContext }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  return (
    <Stack justify="space-between" minHeight="500px">
      <Image src={Checkmark} />
      <Stack alignItems="center">
        <Text>You successfully sent</Text>
        <Heading as="h2" size="2xl">
          {currency(context.amount as string).format()}
        </Heading>
        <HStack spacing={1}>
          <Text>to</Text>
          <Text display="inline" fontWeight="bold">
            {context.recipient?.name}
          </Text>
        </HStack>
      </Stack>
      <Stack>
        <Button
          variant="ghost"
          onClick={() => {
            navigate(
              `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${TRANSACTION_HISTORY_SEARCH_PARAM}`
            );
          }}
        >
          View Transactions
        </Button>
        <Button
          onClick={() => {
            if (searchParams.has('paymentId')) {
              navigate(
                `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
              );
            } else {
              navigate('/transfers');
            }
          }}
        >
          Done
        </Button>
      </Stack>
    </Stack>
  );
};
