import { BusinessDataResponse } from 'api/wallet-app';
import { SettingsCard } from '../settings-card';
import { Stack, Divider, HStack, Text } from '@chakra-ui/react';
import { SettingsDetailItem } from '../settings-detail-item';

export default function BusinessInfo({
  businessInfo,
}: {
  businessInfo: BusinessDataResponse | undefined;
}) {
  return (
    <SettingsCard heading="Business Info">
      <Stack divider={<Divider />} spacing={3}>
        <SettingsDetailItem
          title="Business Name"
          value={businessInfo?.businessName}
        />
        <SettingsDetailItem title="DBA" value={businessInfo?.doingBusinessAs} />
        <SettingsDetailItem title="Handle" value={businessInfo?.handle} />
        <HStack justify="space-between">
          <Text>Address</Text>
          <Stack spacing={0} fontSize="12px" color="gray.500" textAlign="end">
            <Text>{businessInfo?.businessAddress?.streetNumber1}</Text>
            {businessInfo?.businessAddress?.streetNumber2 && (
              <Text>{businessInfo.businessAddress.streetNumber2}</Text>
            )}
            <Text>{`${businessInfo?.businessAddress?.city}, ${businessInfo?.businessAddress?.stateProvinceRegion}, ${businessInfo?.businessAddress?.postalCode}`}</Text>
          </Stack>
        </HStack>
      </Stack>
    </SettingsCard>
  );
}
