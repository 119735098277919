import { useQuery } from '@tanstack/react-query';
import { PlaidLinkTokenRequest } from 'api/wallet-app';
import { useFundingSourcesClient } from 'hooks';
import { useWalletStore } from 'stores';

export const GENERATE_PLAID_TOKEN_QUERY_KEY = 'generate-plaid-token';

export default function useGeneratePlaidTokenQuery(
  payload: PlaidLinkTokenRequest = {}
) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const fundingSourcesClient = useFundingSourcesClient();
  return useQuery({
    queryKey: [GENERATE_PLAID_TOKEN_QUERY_KEY],
    queryFn: () => {
      return fundingSourcesClient.generatePlaidLinkToken({
        xWalletId: walletId,
        plaidLinkTokenRequest: payload,
      });
    },
  });
}
