/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AttachCompeerLoanRequest
 */
export interface AttachCompeerLoanRequest {
  /**
   *
   * @type {string}
   * @memberof AttachCompeerLoanRequest
   */
  lenderId: string;
  /**
   *
   * @type {number}
   * @memberof AttachCompeerLoanRequest
   */
  loanNumber: number;
}

/**
 * Check if a given object implements the AttachCompeerLoanRequest interface.
 */
export function instanceOfAttachCompeerLoanRequest(
  value: object
): value is AttachCompeerLoanRequest {
  if (!('lenderId' in value) || value['lenderId'] === undefined) return false;
  if (!('loanNumber' in value) || value['loanNumber'] === undefined)
    return false;
  return true;
}

export function AttachCompeerLoanRequestFromJSON(
  json: any
): AttachCompeerLoanRequest {
  return AttachCompeerLoanRequestFromJSONTyped(json, false);
}

export function AttachCompeerLoanRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AttachCompeerLoanRequest {
  if (json == null) {
    return json;
  }
  return {
    lenderId: json['lenderId'],
    loanNumber: json['loanNumber'],
  };
}

export function AttachCompeerLoanRequestToJSON(
  value?: AttachCompeerLoanRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    lenderId: value['lenderId'],
    loanNumber: value['loanNumber'],
  };
}
