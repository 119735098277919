/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationDeniedStateDto } from './ApplicationDeniedStateDto';
import {
  ApplicationDeniedStateDtoFromJSON,
  ApplicationDeniedStateDtoFromJSONTyped,
  ApplicationDeniedStateDtoToJSON,
} from './ApplicationDeniedStateDto';

/**
 * Application denied state
 * @export
 * @interface DeniedState
 */
export interface DeniedState {
  /**
   *
   * @type {ApplicationDeniedStateDto}
   * @memberof DeniedState
   */
  deniedState: ApplicationDeniedStateDto;
}

/**
 * Check if a given object implements the DeniedState interface.
 */
export function instanceOfDeniedState(value: object): value is DeniedState {
  if (!('deniedState' in value) || value['deniedState'] === undefined)
    return false;
  return true;
}

export function DeniedStateFromJSON(json: any): DeniedState {
  return DeniedStateFromJSONTyped(json, false);
}

export function DeniedStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeniedState {
  if (json == null) {
    return json;
  }
  return {
    deniedState: ApplicationDeniedStateDtoFromJSON(json['deniedState']),
  };
}

export function DeniedStateToJSON(value?: DeniedState | null): any {
  if (value == null) {
    return value;
  }
  return {
    deniedState: ApplicationDeniedStateDtoToJSON(value['deniedState']),
  };
}
