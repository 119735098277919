import {
  AvailableStatementData,
  TransactionDetailsDto,
  TransactionDetailsDtoMoneySentOrReceivedEnum,
  TransactionDetailsDtoStatusEnum,
  TransactionDetailsDtoTypeEnum,
} from 'api/wallet-app';

export const transactionList: TransactionDetailsDto[] = [
  {
    amount: '23.00',
    destination: 'destination-unload-1',
    id: '515fe2bd-d77a-4a16-82dc-d3f94bb9486a',
    source: 'source-unload-1',
    type: TransactionDetailsDtoTypeEnum.Unload,
    status: TransactionDetailsDtoStatusEnum.Pending,
    initiatedAt: new Date('11/12/2024').toISOString(),
    moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Received,
    updatedAt: new Date('11/12/2024').toISOString(),
  },
  {
    amount: '75.00',
    destination: 'destination-load-1',
    id: '64e2c74f-fa73-4bb9-b787-ad5470d9fc75',
    source: 'source-load-1',
    type: TransactionDetailsDtoTypeEnum.Load,
    status: TransactionDetailsDtoStatusEnum.Completed,
    initiatedAt: new Date('11/11/2024').toISOString(),
    moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Sent,
    updatedAt: new Date('11/11/2024').toISOString(),
  },
  {
    amount: '5.00',
    destination: 'destination-instant-payment-1',
    id: '3d01ebf1-67d3-4057-af50-223aa419c08f',
    source: 'source-instant-payment-1',
    type: TransactionDetailsDtoTypeEnum.RealTimePayment,
    status: TransactionDetailsDtoStatusEnum.Failed,
    initiatedAt: new Date('11/10/2024').toISOString(),
    moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Received,
    updatedAt: new Date('11/10/2024').toISOString(),
  },
  {
    amount: '11.98',
    destination: 'destination-funds-transfer-1',
    id: '21bbca55-2d3b-48a0-85f5-b0e68e243ea4',
    source: 'source-funds-transfer-1',
    type: TransactionDetailsDtoTypeEnum.AchPayment,
    status: TransactionDetailsDtoStatusEnum.Pending,
    initiatedAt: new Date('11/09/2024').toISOString(),
    moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Sent,
    updatedAt: new Date('11/09/2024').toISOString(),
  },
  {
    amount: '14.32',
    destination: 'destination-funded-payment-1',
    id: '39014d7e-6cf8-4fe2-b00a-653fb430e4d6',
    source: 'source-funded-payment-1',
    type: TransactionDetailsDtoTypeEnum.AchPayment,
    status: TransactionDetailsDtoStatusEnum.Pending,
    initiatedAt: new Date('11/08/2024').toISOString(),
    moneySentOrReceived: TransactionDetailsDtoMoneySentOrReceivedEnum.Sent,
    updatedAt: new Date('11/08/2024').toISOString(),
  },
];

export const statementList: AvailableStatementData[] = [
  {
    statementName: 'APRIL 2024',
    monthCode: '04',
    yearCode: '2024',
  },
  {
    statementName: 'MAY 2024',
    monthCode: '05',
    yearCode: '2024',
  },
  {
    statementName: 'JUNE 2024',
    monthCode: '06',
    yearCode: '2024',
  },
  {
    statementName: 'JULY 2024',
    monthCode: '07',
    yearCode: '2024',
  },
  {
    statementName: 'AUGUST 2024',
    monthCode: '08',
    yearCode: '2024',
  },
  {
    statementName: 'SEPTEMBER 2024',
    monthCode: '09',
    yearCode: '2024',
  },
  {
    statementName: 'OCTOBER 2024',
    monthCode: '10',
    yearCode: '2024',
  },
];
