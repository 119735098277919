/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AvailableStatementData } from './AvailableStatementData';
import {
  AvailableStatementDataFromJSON,
  AvailableStatementDataFromJSONTyped,
  AvailableStatementDataToJSON,
} from './AvailableStatementData';

/**
 *
 * @export
 * @interface GetStatementsListResponse
 */
export interface GetStatementsListResponse {
  /**
   *
   * @type {Array<AvailableStatementData>}
   * @memberof GetStatementsListResponse
   */
  statementsList: Array<AvailableStatementData>;
}

/**
 * Check if a given object implements the GetStatementsListResponse interface.
 */
export function instanceOfGetStatementsListResponse(
  value: object
): value is GetStatementsListResponse {
  if (!('statementsList' in value) || value['statementsList'] === undefined)
    return false;
  return true;
}

export function GetStatementsListResponseFromJSON(
  json: any
): GetStatementsListResponse {
  return GetStatementsListResponseFromJSONTyped(json, false);
}

export function GetStatementsListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetStatementsListResponse {
  if (json == null) {
    return json;
  }
  return {
    statementsList: (json['statementsList'] as Array<any>).map(
      AvailableStatementDataFromJSON
    ),
  };
}

export function GetStatementsListResponseToJSON(
  value?: GetStatementsListResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    statementsList: (value['statementsList'] as Array<any>).map(
      AvailableStatementDataToJSON
    ),
  };
}
