/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Sector
 */
export interface Sector {
  /**
   *
   * @type {string}
   * @memberof Sector
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof Sector
   */
  description: string;
}

/**
 * Check if a given object implements the Sector interface.
 */
export function instanceOfSector(value: object): value is Sector {
  if (!('code' in value) || value['code'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  return true;
}

export function SectorFromJSON(json: any): Sector {
  return SectorFromJSONTyped(json, false);
}

export function SectorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Sector {
  if (json == null) {
    return json;
  }
  return {
    code: json['code'],
    description: json['description'],
  };
}

export function SectorToJSON(value?: Sector | null): any {
  if (value == null) {
    return value;
  }
  return {
    code: value['code'],
    description: value['description'],
  };
}
