/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TransactionDetailsDto
 */
export interface TransactionDetailsDto {
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  destination?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  source?: string;
  /**
   * Transaction Status.
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  status?: TransactionDetailsDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  memo?: string;
  /**
   * Transaction Category Enum.
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  type: TransactionDetailsDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  moneySentOrReceived?: TransactionDetailsDtoMoneySentOrReceivedEnum;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  initiatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  amount?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  secondaryMemo?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  walletId?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetailsDto
   */
  requestedAt?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TransactionDetailsDtoStatusEnum {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionDetailsDtoTypeEnum {
  Load = 'LOAD',
  Unload = 'UNLOAD',
  AchPayment = 'ACH_PAYMENT',
  RealTimePayment = 'REAL_TIME_PAYMENT',
  Apto = 'APTO',
  AptoHold = 'APTO_HOLD',
  VanTransaction = 'VAN_TRANSACTION',
  LiabilityPayment = 'LIABILITY_PAYMENT',
  PaymentRequest = 'PAYMENT_REQUEST',
}
/**
 * @export
 * @enum {string}
 */
export enum TransactionDetailsDtoMoneySentOrReceivedEnum {
  Sent = 'MONEY_SENT',
  Received = 'MONEY_RECEIVED',
}

/**
 * Check if a given object implements the TransactionDetailsDto interface.
 */
export function instanceOfTransactionDetailsDto(
  value: object
): value is TransactionDetailsDto {
  if (!('type' in value) || value['type'] === undefined) return false;
  return true;
}

export function TransactionDetailsDtoFromJSON(
  json: any
): TransactionDetailsDto {
  return TransactionDetailsDtoFromJSONTyped(json, false);
}

export function TransactionDetailsDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TransactionDetailsDto {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
    destination: json['destination'] == null ? undefined : json['destination'],
    source: json['source'] == null ? undefined : json['source'],
    status: json['status'] == null ? undefined : json['status'],
    memo: json['memo'] == null ? undefined : json['memo'],
    type: json['type'],
    moneySentOrReceived:
      json['moneySentOrReceived'] == null
        ? undefined
        : json['moneySentOrReceived'],
    initiatedAt: json['initiatedAt'] == null ? undefined : json['initiatedAt'],
    updatedAt: json['updatedAt'] == null ? undefined : json['updatedAt'],
    amount: json['amount'] == null ? undefined : json['amount'],
    currency: json['currency'] == null ? undefined : json['currency'],
    secondaryMemo:
      json['secondaryMemo'] == null ? undefined : json['secondaryMemo'],
    walletId: json['walletId'] == null ? undefined : json['walletId'],
    requestedAt: json['requestedAt'] == null ? undefined : json['requestedAt'],
  };
}

export function TransactionDetailsDtoToJSON(
  value?: TransactionDetailsDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    destination: value['destination'],
    source: value['source'],
    status: value['status'],
    memo: value['memo'],
    type: value['type'],
    moneySentOrReceived: value['moneySentOrReceived'],
    initiatedAt: value['initiatedAt'],
    updatedAt: value['updatedAt'],
    amount: value['amount'],
    currency: value['currency'],
    secondaryMemo: value['secondaryMemo'],
    walletId: value['walletId'],
    requestedAt: value['requestedAt'],
  };
}
