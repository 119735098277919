import { Dispatch, SetStateAction } from 'react';
import { Button } from '@chakra-ui/react';
import { usePlaidLink } from 'react-plaid-link';
import { useLogPlaidEvent } from 'hooks';
import { convertMetadataToStringOrNull } from 'utils';
import { PossibleScreens } from '../add-funding-source';
import { UseMutateFunction } from '@tanstack/react-query';
import { CreateFundingSourceRequest } from 'api/wallet-app';

// NOTE: We have Plaid configured to only allow the user to select 1 account at their bank (radio button)
// If we were ever to allow them to select multiple accounts, we would need to update this component to
// allow that to work correctly
export default function PlaidLink({
  token,
  addFundingSourceIsPending,
  setScreenToDisplay,
  addFundingSource,
}: {
  token: string;
  addFundingSourceIsPending: boolean;
  setScreenToDisplay: Dispatch<SetStateAction<PossibleScreens>>;
  addFundingSource: UseMutateFunction<
    void,
    Error,
    CreateFundingSourceRequest,
    unknown
  >;
}) {
  const { mutate: logPlaidEvent } = useLogPlaidEvent();

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: (publicToken, metadata) => {
      addFundingSource(
        {
          plaidAccountId: metadata.accounts[0].id,
          plaidPublicToken: publicToken,
        },
        {
          onSuccess: () => {
            if (
              metadata.accounts[0].verification_status ==
              'pending_manual_verification'
            ) {
              setScreenToDisplay('micro');
            } else {
              setScreenToDisplay('success');
            }
          },
        }
      );
    },
    onEvent: (eventName, metadata) => {
      logPlaidEvent({
        eventName,
        plaidEvent: convertMetadataToStringOrNull(metadata),
      });
    },
  });

  return (
    <Button
      onClick={() => {
        open();
      }}
      isLoading={!ready || addFundingSourceIsPending}
    >
      Next
    </Button>
  );
}
