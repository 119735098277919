/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The command to decline an invite.
 * @export
 * @interface DeclineInviteRequest
 */
export interface DeclineInviteRequest {
  /**
   *
   * @type {string}
   * @memberof DeclineInviteRequest
   */
  inviteCode: string;
}

/**
 * Check if a given object implements the DeclineInviteRequest interface.
 */
export function instanceOfDeclineInviteRequest(
  value: object
): value is DeclineInviteRequest {
  if (!('inviteCode' in value) || value['inviteCode'] === undefined)
    return false;
  return true;
}

export function DeclineInviteRequestFromJSON(json: any): DeclineInviteRequest {
  return DeclineInviteRequestFromJSONTyped(json, false);
}

export function DeclineInviteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeclineInviteRequest {
  if (json == null) {
    return json;
  }
  return {
    inviteCode: json['inviteCode'],
  };
}

export function DeclineInviteRequestToJSON(
  value?: DeclineInviteRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    inviteCode: value['inviteCode'],
  };
}
