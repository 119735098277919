/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Merchant
 */
export interface Merchant {
  /**
   *
   * @type {string}
   * @memberof Merchant
   */
  mccIcon: string;
  /**
   *
   * @type {string}
   * @memberof Merchant
   */
  merchantName: string;
  /**
   *
   * @type {string}
   * @memberof Merchant
   */
  merchantLocality: string;
  /**
   *
   * @type {string}
   * @memberof Merchant
   */
  merchantCountry: string;
  /**
   *
   * @type {string}
   * @memberof Merchant
   */
  mccName?: string;
  /**
   *
   * @type {string}
   * @memberof Merchant
   */
  mcc?: string;
}

/**
 * Check if a given object implements the Merchant interface.
 */
export function instanceOfMerchant(value: object): value is Merchant {
  if (!('mccIcon' in value) || value['mccIcon'] === undefined) return false;
  if (!('merchantName' in value) || value['merchantName'] === undefined)
    return false;
  if (!('merchantLocality' in value) || value['merchantLocality'] === undefined)
    return false;
  if (!('merchantCountry' in value) || value['merchantCountry'] === undefined)
    return false;
  return true;
}

export function MerchantFromJSON(json: any): Merchant {
  return MerchantFromJSONTyped(json, false);
}

export function MerchantFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Merchant {
  if (json == null) {
    return json;
  }
  return {
    mccIcon: json['mccIcon'],
    merchantName: json['merchantName'],
    merchantLocality: json['merchantLocality'],
    merchantCountry: json['merchantCountry'],
    mccName: json['mccName'] == null ? undefined : json['mccName'],
    mcc: json['mcc'] == null ? undefined : json['mcc'],
  };
}

export function MerchantToJSON(value?: Merchant | null): any {
  if (value == null) {
    return value;
  }
  return {
    mccIcon: value['mccIcon'],
    merchantName: value['merchantName'],
    merchantLocality: value['merchantLocality'],
    merchantCountry: value['merchantCountry'],
    mccName: value['mccName'],
    mcc: value['mcc'],
  };
}
