/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WalletProfileDto } from './WalletProfileDto';
import {
  WalletProfileDtoFromJSON,
  WalletProfileDtoFromJSONTyped,
  WalletProfileDtoToJSON,
} from './WalletProfileDto';

/**
 *
 * @export
 * @interface ProfilesResponse
 */
export interface ProfilesResponse {
  /**
   *
   * @type {Array<WalletProfileDto>}
   * @memberof ProfilesResponse
   */
  profiles: Array<WalletProfileDto>;
}

/**
 * Check if a given object implements the ProfilesResponse interface.
 */
export function instanceOfProfilesResponse(
  value: object
): value is ProfilesResponse {
  if (!('profiles' in value) || value['profiles'] === undefined) return false;
  return true;
}

export function ProfilesResponseFromJSON(json: any): ProfilesResponse {
  return ProfilesResponseFromJSONTyped(json, false);
}

export function ProfilesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProfilesResponse {
  if (json == null) {
    return json;
  }
  return {
    profiles: (json['profiles'] as Array<any>).map(WalletProfileDtoFromJSON),
  };
}

export function ProfilesResponseToJSON(value?: ProfilesResponse | null): any {
  if (value == null) {
    return value;
  }
  return {
    profiles: (value['profiles'] as Array<any>).map(WalletProfileDtoToJSON),
  };
}
