import {
  LinkItem,
  SettingsCard,
} from 'pages/WalletApp/settings/components/index';

export default function ManageStaff() {
  return (
    <SettingsCard heading="Manage Staff">
      <LinkItem
        linkTarget="/settings/manage-staff"
        title="Add or Remove users"
        newTab={false}
      />
    </SettingsCard>
  );
}
