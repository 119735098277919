import React from 'react';
import { Header, Body, Footer } from 'components/layout/onboarding';
import { Text } from '@chakra-ui/react';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { OnboardingAddressDto } from 'api/wallet-app';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SubmitButton } from 'components/submitButton';
import { AddressForm } from 'pages/Onboarding/sharedComponents/AddressForm';
import { useSelector } from '@xstate/react';

export const ResidentialAddress = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);

  const handleSubmit: SubmitHandler<OnboardingAddressDto> = (data) => {
    if (!onboardingActor) return;
    onboardingActor.send({
      type: 'submitAddress',
      value: { payload: data },
    });
  };

  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors },
  } = useForm<OnboardingAddressDto>({
    mode: 'onBlur',
    defaultValues: context?.people?.length
      ? context.people[0].address
      : ({} as OnboardingAddressDto),
  });

  return (
    <>
      <Header title="Your Residential Address" />
      <Text mb="1rem" fontSize={14}>
        Make sure your address matches the address on your driver's license
      </Text>
      <Body>
        <form
          id="residential-address"
          data-testid="residential-address"
          onSubmit={hookFormSubmit(handleSubmit)}
          noValidate
        >
          <AddressForm
            register={register}
            errors={errors}
            labels={{ streetNumber1: "Driver's License Address Line 1" }}
          />
        </form>
      </Body>
      <Footer>
        <SubmitButton form="residential-address" />
      </Footer>
    </>
  );
};
