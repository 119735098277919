import React from 'react';
import { TransferFlow } from './TransferFlow';
import { transferMachine } from '../transferMachine';
import { useMachine } from '@xstate/react';
import { NumberFormatValues } from 'react-number-format';
import { FundingSourceDto } from 'api/wallet-app';

// TODO: this and 'AddFunds' are essentially the same thing with the only difference
// being the 'type' key/value. Could def DRY this up when we have a moment. May involve
// some thought about restarting the state machine if the user jumps between flows...

export const WithdrawFunds = () => {
  const [transferAmount, setTransferAmount] = React.useState('0.00');
  const handleMoneyInput = (amount: NumberFormatValues) => {
    setTransferAmount(amount.value);
  };
  const [fundingSource, setFundingSource] = React.useState<
    FundingSourceDto | undefined
  >();
  const [walletBalance, setWalletBalance] = React.useState<
    string | undefined
  >();
  const [state, send] = useMachine(transferMachine);

  return (
    <TransferFlow
      context={{
        transferAmount,
        fundingSource,
        setFundingSource,
        handleMoneyInput,
        send: send as any,
        state: state as any,
        type: 'UNLOAD',
        walletBalance,
        setWalletBalance,
      }}
    />
  );
};
