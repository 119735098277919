import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  Heading,
} from '@chakra-ui/react';
import { UserListItem } from 'api/wallet-app';
import { useRevokeUser } from 'hooks/api';

export default function RemoveStaffModal({
  isOpen,
  onClose,
  user,
}: {
  isOpen: boolean;
  onClose: () => void;
  user: UserListItem;
}) {
  const { mutate: revokeUser, isPending: revokeUserIsPending } =
    useRevokeUser();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={!revokeUserIsPending}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading
            as="h5"
            size="md"
          >{`Remove ${user.firstName} ${user.lastName}?`}</Heading>
        </ModalHeader>
        <ModalBody py={3}>
          <Stack>
            <Text>{`Are you sure you want to remove ${user.firstName} ${user.lastName}?`}</Text>
            <Text>
              They will no longer be able to manage or access this Bushel Wallet
              account
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter p={6}>
          <HStack justify="flex-end">
            <Button
              variant="secondary"
              isDisabled={revokeUserIsPending}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              isLoading={revokeUserIsPending}
              onClick={() => {
                revokeUser(user, { onSuccess: onClose });
              }}
            >
              Remove
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
