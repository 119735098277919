/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingAppStateDto } from './OnboardingAppStateDto';
import {
  OnboardingAppStateDtoFromJSON,
  OnboardingAppStateDtoFromJSONTyped,
  OnboardingAppStateDtoToJSON,
} from './OnboardingAppStateDto';

/**
 * Onboarding application state
 * @export
 * @interface OnboardingAppState
 */
export interface OnboardingAppState {
  /**
   *
   * @type {OnboardingAppStateDto}
   * @memberof OnboardingAppState
   */
  appState: OnboardingAppStateDto;
}

/**
 * Check if a given object implements the OnboardingAppState interface.
 */
export function instanceOfOnboardingAppState(
  value: object
): value is OnboardingAppState {
  if (!('appState' in value) || value['appState'] === undefined) return false;
  return true;
}

export function OnboardingAppStateFromJSON(json: any): OnboardingAppState {
  return OnboardingAppStateFromJSONTyped(json, false);
}

export function OnboardingAppStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OnboardingAppState {
  if (json == null) {
    return json;
  }
  return {
    appState: OnboardingAppStateDtoFromJSON(json['appState']),
  };
}

export function OnboardingAppStateToJSON(
  value?: OnboardingAppState | null
): any {
  if (value == null) {
    return value;
  }
  return {
    appState: OnboardingAppStateDtoToJSON(value['appState']),
  };
}
