import { HStack, Text } from '@chakra-ui/react';

export default function SettingsDetailItem({
  title,
  value,
}: {
  title: string;
  value: string | undefined;
}) {
  return (
    <HStack justify="space-between">
      <Text>{title}</Text>
      <Text fontSize="12px" color="gray.500">
        {value}
      </Text>
    </HStack>
  );
}
