/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AlertsResponse,
  DefaultErrorResponse,
  ViolationsError,
} from '../models/index';
import {
  AlertsResponseFromJSON,
  AlertsResponseToJSON,
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
} from '../models/index';

export interface AcknowledgeAlertRequest {
  xWalletId: string;
  alertId: string;
}

export interface GetAlertsRequest {
  xWalletId: string;
}

/**
 *
 */
export class AlertsApi extends runtime.BaseAPI {
  /**
   * Acknowledge alert.
   */
  async acknowledgeAlertRaw(
    requestParameters: AcknowledgeAlertRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling acknowledgeAlert().'
      );
    }

    if (requestParameters['alertId'] == null) {
      throw new runtime.RequiredError(
        'alertId',
        'Required parameter "alertId" was null or undefined when calling acknowledgeAlert().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/alerts/{alertId}/acknowledge`.replace(
          `{${'alertId'}}`,
          encodeURIComponent(String(requestParameters['alertId']))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Acknowledge alert.
   */
  async acknowledgeAlert(
    requestParameters: AcknowledgeAlertRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.acknowledgeAlertRaw(requestParameters, initOverrides);
  }

  /**
   * Get alerts.
   */
  async getAlertsRaw(
    requestParameters: GetAlertsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AlertsResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getAlerts().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/alerts`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AlertsResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get alerts.
   */
  async getAlerts(
    requestParameters: GetAlertsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AlertsResponse> {
    const response = await this.getAlertsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
