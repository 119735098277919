/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Plaid funding source details.
 * @export
 * @interface PlaidFundingSourceDetailsDto
 */
export interface PlaidFundingSourceDetailsDto {
  /**
   *
   * @type {string}
   * @memberof PlaidFundingSourceDetailsDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PlaidFundingSourceDetailsDto
   */
  financialInstitutionName: string;
  /**
   *
   * @type {string}
   * @memberof PlaidFundingSourceDetailsDto
   */
  accountNumberLastFour: string;
  /**
   *
   * @type {boolean}
   * @memberof PlaidFundingSourceDetailsDto
   */
  isVerified: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PlaidFundingSourceDetailsDto
   */
  needsUserValidation: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PlaidFundingSourceDetailsDto
   */
  verificationFailed: boolean;
}

/**
 * Check if a given object implements the PlaidFundingSourceDetailsDto interface.
 */
export function instanceOfPlaidFundingSourceDetailsDto(
  value: object
): value is PlaidFundingSourceDetailsDto {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (
    !('financialInstitutionName' in value) ||
    value['financialInstitutionName'] === undefined
  )
    return false;
  if (
    !('accountNumberLastFour' in value) ||
    value['accountNumberLastFour'] === undefined
  )
    return false;
  if (!('isVerified' in value) || value['isVerified'] === undefined)
    return false;
  if (
    !('needsUserValidation' in value) ||
    value['needsUserValidation'] === undefined
  )
    return false;
  if (
    !('verificationFailed' in value) ||
    value['verificationFailed'] === undefined
  )
    return false;
  return true;
}

export function PlaidFundingSourceDetailsDtoFromJSON(
  json: any
): PlaidFundingSourceDetailsDto {
  return PlaidFundingSourceDetailsDtoFromJSONTyped(json, false);
}

export function PlaidFundingSourceDetailsDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PlaidFundingSourceDetailsDto {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'],
    financialInstitutionName: json['financialInstitutionName'],
    accountNumberLastFour: json['accountNumberLastFour'],
    isVerified: json['isVerified'],
    needsUserValidation: json['needsUserValidation'],
    verificationFailed: json['verificationFailed'],
  };
}

export function PlaidFundingSourceDetailsDtoToJSON(
  value?: PlaidFundingSourceDetailsDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    financialInstitutionName: value['financialInstitutionName'],
    accountNumberLastFour: value['accountNumberLastFour'],
    isVerified: value['isVerified'],
    needsUserValidation: value['needsUserValidation'],
    verificationFailed: value['verificationFailed'],
  };
}
