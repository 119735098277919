/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NotAcceptablePaymentRequestResponse
 */
export interface NotAcceptablePaymentRequestResponse {
  /**
   *
   * @type {string}
   * @memberof NotAcceptablePaymentRequestResponse
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof NotAcceptablePaymentRequestResponse
   */
  status: NotAcceptablePaymentRequestResponseStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum NotAcceptablePaymentRequestResponseStatusEnum {
  Pending = 'Pending',
  Canceled = 'Canceled',
  Declined = 'Declined',
  Paid = 'Paid',
}

/**
 * Check if a given object implements the NotAcceptablePaymentRequestResponse interface.
 */
export function instanceOfNotAcceptablePaymentRequestResponse(
  value: object
): value is NotAcceptablePaymentRequestResponse {
  if (!('message' in value) || value['message'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function NotAcceptablePaymentRequestResponseFromJSON(
  json: any
): NotAcceptablePaymentRequestResponse {
  return NotAcceptablePaymentRequestResponseFromJSONTyped(json, false);
}

export function NotAcceptablePaymentRequestResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NotAcceptablePaymentRequestResponse {
  if (json == null) {
    return json;
  }
  return {
    message: json['message'],
    status: json['status'],
  };
}

export function NotAcceptablePaymentRequestResponseToJSON(
  value?: NotAcceptablePaymentRequestResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    message: value['message'],
    status: value['status'],
  };
}
