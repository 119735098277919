import { useKeycloak } from '@react-keycloak/web';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { KEYCLOAK_USER_INFO_QUERY_KEY } from './useKeycloakUserInfo';
import { disableMfa } from 'api/keycloak';

export default function useDisableMfa() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['disable-mfa'],
    mutationFn: async (method: string) => {
      return disableMfa({
        baseUrl: keycloak?.authServerUrl,
        realm: keycloak.realm,
        token: keycloak.token,
        method,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [KEYCLOAK_USER_INFO_QUERY_KEY] });
    },
  });
}
