import { useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { transferMachine } from '../../transferMachine';
import { EnterValue } from './EnterValue';
import { SelectFundingSource } from './SelectFundingSource';
import { Confirm } from './Confirm';
import { Success } from './Success';
import { useWalletProfile } from 'hooks';
import { CenteredSpinner } from 'components/CenteredSpinner';

export function Send() {
  const [sendAmount, setSendAmount] = useState('0.00');
  const [state, send] = useMachine(transferMachine);
  const [searchParams] = useSearchParams();
  const { data, isLoading, isSuccess } = useWalletProfile(
    searchParams.get('walletId') ?? undefined
  );

  const amount = useMemo(() => searchParams.get('amount'), [searchParams]);

  useEffect(() => {
    if (amount && isSuccess) {
      send({
        type: 'NEXT',
        amount: amount,
        recipient: data,
      });
    }
  }, [amount, isSuccess]);

  if (isLoading || !data) {
    return <CenteredSpinner centerProps={{ height: '200px' }} />;
  }

  return state.value == 'enterAmount' ? (
    <EnterValue
      recipient={data}
      amount={sendAmount}
      handleMoneyInput={(amount) => {
        setSendAmount(amount.value);
      }}
      send={send}
    />
  ) : state.value == 'selectSource' ? (
    <SelectFundingSource send={send} context={state.context} />
  ) : state.value == 'confirm' ? (
    <Confirm send={send} context={state.context} />
  ) : state.value == 'success' ? (
    <Success context={state.context} />
  ) : (
    <Box />
  );
}
