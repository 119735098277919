import { useKeycloak } from '@react-keycloak/web';
import { BASE_PATH, Configuration } from 'api/wallet-app';
import { AppContext } from 'contexts/AppContext';
import { useContext } from 'react';

export default function useWalletApiConfig(withAuth: boolean = true) {
  const { keycloak } = useKeycloak();
  const appContext = useContext(AppContext);

  if (!keycloak.token || !keycloak.authenticated) {
    throw new Error('You are not authenticated');
  }

  let correctlyFormattedWalletUrl;

  if (appContext?.restApiBasePath?.at(-1) === '/') {
    correctlyFormattedWalletUrl = appContext?.restApiBasePath?.replace(
      /\/$/,
      ''
    );
  } else {
    correctlyFormattedWalletUrl = appContext?.restApiBasePath;
  }

  return withAuth
    ? new Configuration({
        basePath: correctlyFormattedWalletUrl ?? BASE_PATH,
        accessToken: `Bearer ${keycloak.token}`,
      })
    : new Configuration({ basePath: correctlyFormattedWalletUrl ?? BASE_PATH });
}
