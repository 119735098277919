import emojiRegex from 'emoji-regex';
import { Resolver } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export type ConfirmForm = { memo: string };

export const confirmFormResolver: Resolver<ConfirmForm> = yupResolver(
  object({
    memo: string()
      .required('Memo is required')
      .max(255, `Memo must be less than 256 characters long`)
      .test({
        name: 'isValidInput',
        message: 'Memo must be at least 3 characters long',
        test: (value) => {
          const emoji = emojiRegex();
          return value.length > 2 || emoji.test(value.trim());
        },
      }),
  })
);
