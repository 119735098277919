/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Industry
 */
export interface Industry {
  /**
   *
   * @type {string}
   * @memberof Industry
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof Industry
   */
  description: string;
}

/**
 * Check if a given object implements the Industry interface.
 */
export function instanceOfIndustry(value: object): value is Industry {
  if (!('code' in value) || value['code'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  return true;
}

export function IndustryFromJSON(json: any): Industry {
  return IndustryFromJSONTyped(json, false);
}

export function IndustryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Industry {
  if (json == null) {
    return json;
  }
  return {
    code: json['code'],
    description: json['description'],
  };
}

export function IndustryToJSON(value?: Industry | null): any {
  if (value == null) {
    return value;
  }
  return {
    code: value['code'],
    description: value['description'],
  };
}
