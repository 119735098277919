/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DeactivatedAppStateDto
 */
export interface DeactivatedAppStateDto {
  /**
   *
   * @type {string}
   * @memberof DeactivatedAppStateDto
   */
  walletId: string;
}

/**
 * Check if a given object implements the DeactivatedAppStateDto interface.
 */
export function instanceOfDeactivatedAppStateDto(
  value: object
): value is DeactivatedAppStateDto {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  return true;
}

export function DeactivatedAppStateDtoFromJSON(
  json: any
): DeactivatedAppStateDto {
  return DeactivatedAppStateDtoFromJSONTyped(json, false);
}

export function DeactivatedAppStateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeactivatedAppStateDto {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
  };
}

export function DeactivatedAppStateDtoToJSON(
  value?: DeactivatedAppStateDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
  };
}
