/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AlertDto
 */
export interface AlertDto {
  /**
   *
   * @type {string}
   * @memberof AlertDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AlertDto
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof AlertDto
   */
  alertType: AlertDtoAlertTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AlertDto
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof AlertDto
   */
  resourceId?: string;
  /**
   *
   * @type {boolean}
   * @memberof AlertDto
   */
  dismissible: boolean;
  /**
   *
   * @type {string}
   * @memberof AlertDto
   */
  dba: string;
  /**
   *
   * @type {string}
   * @memberof AlertDto
   */
  amount: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AlertDtoAlertTypeEnum {
  CreatedPayer = 'PAYMENT_REQUEST_CREATED_PAYER',
  DeclinedPayee = 'PAYMENT_REQUEST_DECLINED_PAYEE',
  CanceledPayer = 'PAYMENT_REQUEST_CANCELED_PAYER',
}

/**
 * Check if a given object implements the AlertDto interface.
 */
export function instanceOfAlertDto(value: object): value is AlertDto {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  if (!('alertType' in value) || value['alertType'] === undefined) return false;
  if (!('message' in value) || value['message'] === undefined) return false;
  if (!('dismissible' in value) || value['dismissible'] === undefined)
    return false;
  if (!('dba' in value) || value['dba'] === undefined) return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  return true;
}

export function AlertDtoFromJSON(json: any): AlertDto {
  return AlertDtoFromJSONTyped(json, false);
}

export function AlertDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AlertDto {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    walletId: json['walletId'],
    alertType: json['alertType'],
    message: json['message'],
    resourceId: json['resourceId'] == null ? undefined : json['resourceId'],
    dismissible: json['dismissible'],
    dba: json['dba'],
    amount: json['amount'],
  };
}

export function AlertDtoToJSON(value?: AlertDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    walletId: value['walletId'],
    alertType: value['alertType'],
    message: value['message'],
    resourceId: value['resourceId'],
    dismissible: value['dismissible'],
    dba: value['dba'],
    amount: value['amount'],
  };
}
