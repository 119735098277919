import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import usePaymentsClient from './usePaymentRequestClient';

export const PAYMENT_REQUEST_LIST_QUERY_KEY = 'payment-request-list';

export default function usePaymentRequestList() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const paymentsClient = usePaymentsClient();
  return useQuery({
    queryKey: [PAYMENT_REQUEST_LIST_QUERY_KEY],
    queryFn: () => {
      return paymentsClient.getPendingPaymentRequests({
        xWalletId: walletId,
      });
    },
  });
}
