import { Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

export default function SettingsLayout() {
  return (
    <Stack pb={['6rem', 8]}>
      <Outlet />
    </Stack>
  );
}
