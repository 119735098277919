import { createRoot } from 'react-dom/client';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import ourTheme from './theme';
import './i18n';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppEnv, checkAppEnvValues, enableMockServiceWorker } from 'utils';
import { AppContextProvider } from 'contexts/AppContext';

declare global {
  interface Window {
    bushel: Record<string, ((arg: any) => any) | string | number | boolean>;
    logout: any;
  }
}

defineCustomElements(window);

export const run = (appEnv: AppEnv) => {
  checkAppEnvValues(appEnv);

  const {
    keycloakClientId,
    keycloakRealm,
    keycloakUrl,
    restApiBasePath,
    isMockMode,
  } = appEnv;

  enableMockServiceWorker(isMockMode).then(() => {
    createRoot(document.getElementById('root')!).render(
      <>
        <ChakraProvider theme={ourTheme}>
          <CSSReset />
          <AppContextProvider
            restApiBasePath={restApiBasePath}
            keycloakConfig={{
              clientId: keycloakClientId,
              realm: keycloakRealm,
              url: keycloakUrl,
            }}
          />
        </ChakraProvider>
      </>
    );
  });
};
