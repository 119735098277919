import { useMutation } from '@tanstack/react-query';
import useFundingSourcesClient from './useFundingSourcesClient';
import { PlaidLinkTokenRequest } from 'api/wallet-app';
import { useWalletStore } from 'stores';

export default function useGeneratePlaidToken() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const fundingSourcesClient = useFundingSourcesClient();
  return useMutation({
    mutationKey: ['generate-plaid-token'],
    mutationFn: (payload: PlaidLinkTokenRequest) => {
      return fundingSourcesClient.generatePlaidLinkToken({
        plaidLinkTokenRequest: payload,
        xWalletId: walletId,
      });
    },
  });
}
