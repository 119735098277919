import { ResponseError, UserListResponse } from 'api/wallet-app';
import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useWalletsClient from './useWalletsClient';

export const USER_LIST_QUERY_KEY = 'user-list';

export default function useUserList() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const walletsClient = useWalletsClient();
  return useQuery<unknown, ResponseError, UserListResponse>({
    queryKey: [USER_LIST_QUERY_KEY],
    queryFn: () => walletsClient.getUsers({ xWalletId: walletId }),
  });
}
