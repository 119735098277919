import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { PanelWrapper } from 'components/layout/onboarding';
import { useSdkUploadState } from 'hooks';
import { HatsIcon } from 'images/custom-icons/hats-icon';
import { useEffect, useState } from 'react';
import { IoLogOutOutline } from 'react-icons/io5';
import { initAlloy, openAlloy } from 'utils/alloy';
import { background } from 'utils/helpers';

export default function SdkUpload() {
  const [alloyIsInitialized, setAlloyIsInitialized] = useState(false);
  const [alloySdkIsOpen, setAlloySdkIsOpen] = useState(false);
  const { data: sdkUploadData, isLoading: sdkUploadIsLoading } =
    useSdkUploadState();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (sdkUploadData && sdkUploadData.appState.sdkParams?.sdkKey) {
      initAlloy({
        alloySdkToken: sdkUploadData.appState.sdkParams.sdkKey,
        journeyApplicationToken:
          sdkUploadData.appState.sdkParams?.journeyApplicationId,
        journeyToken: sdkUploadData.appState.sdkParams?.journeyToken,
      }).then(() => {
        setAlloyIsInitialized(true);
      });
    }
  }, [sdkUploadData]);

  if (sdkUploadIsLoading || !alloyIsInitialized) {
    return <CenteredSpinner spinnerProps={{ size: 'xl', thickness: '4px' }} />;
  }
  return !alloySdkIsOpen ? (
    <PanelWrapper>
      <Stack spacing={7}>
        <IconButton
          aria-label="logout-button"
          icon={<IoLogOutOutline fontSize="1.25rem" />}
          onClick={() => {
            keycloak.logout();
          }}
          size="icon"
          w="fit-content"
          h="24px"
          variant="unstyled"
          display="flex"
          alignItems="center"
          justifyContent="center"
          alignSelf="flex-end"
        />
        <Stack spacing={12}>
          <Stack spacing={6}>
            <Icon as={HatsIcon} w="100%" h="160px" />
            <Heading as="h3" fontSize="24px">
              Additional Verification Required
            </Heading>
            <Text>
              The information you provided requires additional verification with
              our 3rd party partner Alloy before your account can be created.
            </Text>
            <Stack spacing={4}>
              <Text color="textSecondary" textAlign="center">
                Select the button below to complete the verification process
              </Text>
              <Button
                onClick={() => {
                  setAlloySdkIsOpen(true);
                  openAlloy(() => {
                    setAlloySdkIsOpen(false);
                    console.log('Closing Alloy SDK');
                  }, 'alloy-sdk-container');
                }}
              >
                Start Verification
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </PanelWrapper>
  ) : (
    <Box
      overflow="scroll"
      height="100vh"
      width="100vw"
      id="alloy-sdk-container"
    >
      <Flex
        className="content"
        flexDirection="column"
        width={['auto', '100%']}
        height="100%"
        minHeight="100%"
        backgroundImage={['null', 'null', 'null', background]}
        backgroundRepeat="no-repeat"
        mt={[0, '3rem']}
      />
    </Box>
  );
}
