import { useEffect } from 'react';
import { LiabilityContextMenu } from 'pages/WalletApp/Accounts/components/LiabilityContextMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from './ManageLoan';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';
import { FundingSourceDto } from 'api/wallet-app';
import { CenteredSpinner } from 'components/CenteredSpinner';

export function Loan() {
  const { state } = useLocation() as {
    state: FundingSourceDto | undefined;
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate('/transfers/manage-loan');
    }
  }, [state]);

  return (
    <LoanPaymentContext.Provider>
      <Header to={'-1'} title={state?.financialInstitutionName} />
      {state ? (
        <LiabilityContextMenu
          fundingSource={state}
          onClose={() => {
            navigate(-1);
          }}
          isOpen={true}
        />
      ) : (
        <CenteredSpinner centerProps={{ h: '100%' }} />
      )}
    </LoanPaymentContext.Provider>
  );
}
