import { useEffect, useCallback } from 'react';
import { Center, Spinner } from '@chakra-ui/react';
import { usePlaidLink } from 'react-plaid-link';
import { PlaidLinkOptions, PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import {
  useAddFundingSource,
  useAppState,
  useLogPlaidEvent,
  usePlaidToken,
} from 'hooks';
import { convertMetadataToStringOrNull } from 'utils';

export const OauthRedirect = () => {
  const { mutate: addFundingSource } = useAddFundingSource();
  const { mutate: logPlaidEvent } = useLogPlaidEvent();
  const { data: plaidTokenData } = usePlaidToken();
  const { data, isSuccess } = useAppState();

  const onSuccess = useCallback(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      addFundingSource(
        {
          plaidAccountId: metadata.accounts[0].id,
          plaidPublicToken: public_token,
        },
        {
          onSuccess: () => {
            const { origin } = window.location;
            let replacement = origin;
            // if they're being redirected to onboarding, send a query param
            // so the plaid page doesn't start over from the beginning. Else, route them back
            // to the accounts screen
            if (isSuccess) {
              replacement =
                data?.type == 'ONBOARDING'
                  ? origin + '/?redirect=true'
                  : origin + '/accounts';
            }
            window.location.replace(replacement);
          },
        }
      );
    },
    []
  );

  const onExit = () => {
    console.log('exit');
  };

  const config: PlaidLinkOptions = {
    token: plaidTokenData?.linkToken ?? '',
    receivedRedirectUri: window.location.href,
    onSuccess,
    onExit,
    onEvent: (e, m) => {
      logPlaidEvent({
        eventName: e,
        plaidEvent: convertMetadataToStringOrNull(m),
      });
    },
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return (
    <Center
      height="100vh"
      width="100%"
      zIndex="9"
      backgroundColor="#fff"
      position="fixed"
    >
      <Spinner color="blue.400" thickness="4px" size="xl" />
    </Center>
  );
};
