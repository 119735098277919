import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { MoneyInput } from 'pages/WalletApp/Transfers/components';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';

export const CustomAmount = () => {
  const { send } = LoanPaymentContext.useActorRef();
  const { paymentAmount } = LoanPaymentContext.useSelector(
    (state) => state.context
  );

  const handleChange = (amount: any) => {
    send({ type: 'SET_PAYMENT_AMOUNT', paymentAmount: amount.value });
  };

  React.useEffect(() => {
    send({ type: 'SET_PAYMENT_AMOUNT', paymentAmount: '0.00' });
  }, []);

  return (
    <Flex mt="1.5rem" direction="column" alignItems="center">
      <MoneyInput
        onChange={handleChange}
        showBorder={false}
        amount={paymentAmount}
      />
      <Text fontSize="12" color="gray.500">
        USD
      </Text>
      <Button
        disabled={parseFloat(paymentAmount) == 0.0}
        mt="2rem"
        onClick={() => send({ type: 'NEXT' })}
      >
        Continue
      </Button>
    </Flex>
  );
};
