/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The create load request
 * @export
 * @interface LoadRequest
 */
export interface LoadRequest {
  /**
   *
   * @type {string}
   * @memberof LoadRequest
   */
  fromFundingSourceId: string;
  /**
   *
   * @type {string}
   * @memberof LoadRequest
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof LoadRequest
   */
  currencyType?: LoadRequestCurrencyTypeEnum;
  /**
   *
   * @type {string}
   * @memberof LoadRequest
   */
  memo?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum LoadRequestCurrencyTypeEnum {
  Usd = 'USD',
}

/**
 * Check if a given object implements the LoadRequest interface.
 */
export function instanceOfLoadRequest(value: object): value is LoadRequest {
  if (
    !('fromFundingSourceId' in value) ||
    value['fromFundingSourceId'] === undefined
  )
    return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  return true;
}

export function LoadRequestFromJSON(json: any): LoadRequest {
  return LoadRequestFromJSONTyped(json, false);
}

export function LoadRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LoadRequest {
  if (json == null) {
    return json;
  }
  return {
    fromFundingSourceId: json['fromFundingSourceId'],
    amount: json['amount'],
    currencyType:
      json['currencyType'] == null ? undefined : json['currencyType'],
    memo: json['memo'] == null ? undefined : json['memo'],
  };
}

export function LoadRequestToJSON(value?: LoadRequest | null): any {
  if (value == null) {
    return value;
  }
  return {
    fromFundingSourceId: value['fromFundingSourceId'],
    amount: value['amount'],
    currencyType: value['currencyType'],
    memo: value['memo'],
  };
}
