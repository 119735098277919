/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InfoLinks } from './InfoLinks';
import {
  InfoLinksFromJSON,
  InfoLinksFromJSONTyped,
  InfoLinksToJSON,
} from './InfoLinks';
import type { BusinessDataResponse } from './BusinessDataResponse';
import {
  BusinessDataResponseFromJSON,
  BusinessDataResponseFromJSONTyped,
  BusinessDataResponseToJSON,
} from './BusinessDataResponse';

/**
 * Data related to the settings menu.
 * @export
 * @interface SettingsInfoResponse
 */
export interface SettingsInfoResponse {
  /**
   *
   * @type {BusinessDataResponse}
   * @memberof SettingsInfoResponse
   */
  business: BusinessDataResponse;
  /**
   *
   * @type {InfoLinks}
   * @memberof SettingsInfoResponse
   */
  links: InfoLinks;
}

/**
 * Check if a given object implements the SettingsInfoResponse interface.
 */
export function instanceOfSettingsInfoResponse(
  value: object
): value is SettingsInfoResponse {
  if (!('business' in value) || value['business'] === undefined) return false;
  if (!('links' in value) || value['links'] === undefined) return false;
  return true;
}

export function SettingsInfoResponseFromJSON(json: any): SettingsInfoResponse {
  return SettingsInfoResponseFromJSONTyped(json, false);
}

export function SettingsInfoResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SettingsInfoResponse {
  if (json == null) {
    return json;
  }
  return {
    business: BusinessDataResponseFromJSON(json['business']),
    links: InfoLinksFromJSON(json['links']),
  };
}

export function SettingsInfoResponseToJSON(
  value?: SettingsInfoResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    business: BusinessDataResponseToJSON(value['business']),
    links: InfoLinksToJSON(value['links']),
  };
}
