/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The rename funding sources command
 * @export
 * @interface RenameFundingSourceRequest
 */
export interface RenameFundingSourceRequest {
  /**
   *
   * @type {string}
   * @memberof RenameFundingSourceRequest
   */
  fundingSourceId: string;
  /**
   *
   * @type {string}
   * @memberof RenameFundingSourceRequest
   */
  friendlyName: string;
}

/**
 * Check if a given object implements the RenameFundingSourceRequest interface.
 */
export function instanceOfRenameFundingSourceRequest(
  value: object
): value is RenameFundingSourceRequest {
  if (!('fundingSourceId' in value) || value['fundingSourceId'] === undefined)
    return false;
  if (!('friendlyName' in value) || value['friendlyName'] === undefined)
    return false;
  return true;
}

export function RenameFundingSourceRequestFromJSON(
  json: any
): RenameFundingSourceRequest {
  return RenameFundingSourceRequestFromJSONTyped(json, false);
}

export function RenameFundingSourceRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RenameFundingSourceRequest {
  if (json == null) {
    return json;
  }
  return {
    fundingSourceId: json['fundingSourceId'],
    friendlyName: json['friendlyName'],
  };
}

export function RenameFundingSourceRequestToJSON(
  value?: RenameFundingSourceRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSourceId: value['fundingSourceId'],
    friendlyName: value['friendlyName'],
  };
}
