import { useWalletStore } from 'stores';
import useWalletsClient from './useWalletsClient';
import { useQuery } from '@tanstack/react-query';

export const USER_INFO_QUERY_KEY = 'user-info';

export default function useUserInfo() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const walletsClient = useWalletsClient();
  return useQuery({
    queryKey: [USER_INFO_QUERY_KEY],
    queryFn: () => {
      return walletsClient.getUser({
        xWalletId: walletId,
      });
    },
  });
}
