import { useMediaQuery, useToast } from '@chakra-ui/react';
import { ResponseError } from 'api/wallet-app';
import { InviteUserToWallet } from 'pages/WalletApp/settings/manage-staff/invite-user';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useWalletStore } from 'stores';
import { USER_LIST_QUERY_KEY } from './useUserList';
import useWalletsClient from './useWalletsClient';
import { v4 as uuid } from 'uuid';

export default function useInviteUser() {
  const { walletId } = useWalletStore();
  const queryClient = useQueryClient();
  if (!walletId) {
    throw new Error('Wallet ID is not defined');
  }
  const walletsClient = useWalletsClient();
  const toast = useToast();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  return useMutation<void, ResponseError, InviteUserToWallet>({
    mutationKey: ['invite-user'],
    mutationFn: (payload) => {
      return walletsClient.inviteUser({
        inviteUserCmd: payload,
        idempotencyKey: uuid(),
        xWalletId: walletId,
      });
    },
    onSuccess: (_data, variables) => {
      toast({
        status: 'success',
        isClosable: true,
        title: 'Invite Sent!',
        description: `We sent an invite to ${variables.firstName} at ${variables.email}`,
        position: isMobile ? 'top' : 'bottom',
      });
      navigate(-1);
      queryClient.invalidateQueries({ queryKey: [USER_LIST_QUERY_KEY] });
    },
    onError: () => {
      toast({
        status: 'error',
        isClosable: true,
        title: 'Invite Failed',
        description: 'Something went wrong sending your invite.',
        position: isMobile ? 'top' : 'bottom',
      });
    },
  });
}
