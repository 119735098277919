import { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  PaymentRequestDetails,
  PaymentRequestDetailsStatusEnum,
} from 'api/wallet-app';
import { PanelWrapper } from 'components/layout/onboarding';
import currency from 'currency.js';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dateAsmMdDYyyy, paymentRequestStatusBadgeColor } from 'utils';
import { CancelOrDeclineRequestModal } from '../components';
import { ProfileOverlay } from 'components/ProfileOverlay';
import { CenteredSpinner } from 'components/CenteredSpinner';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  OPEN_REQUESTS_SEARCH_PARAM,
} from '../activity-page-search-params';
import usePaymentRequestDetails from 'hooks/api/payment-request/usePaymentRequestDetails';
import usePaymentRequestReminder from 'hooks/api/payment-request/usePaymentRequestReminder';
import { BellIcon } from 'images/custom-icons/bell-icon';

export default function RequestDetails() {
  const { paymentId } = useParams();
  const { data, isLoading, isSuccess } = usePaymentRequestDetails(paymentId);
  const navigate = useNavigate();
  const {
    isOpen: cancelOrDeclineModalIsOpen,
    onOpen: cancelOrDeclineModalOnOpen,
    onClose: cancelOrDeclineModalOnClose,
  } = useDisclosure();
  const {
    mutate: paymentRequestReminder,
    isPending: paymentRequestReminderIsPending,
  } = usePaymentRequestReminder();
  const [searchParams] = useSearchParams();
  const [walletId, setWalletId] = useState('');
  const {
    isOpen: profileModalIsOpen,
    onOpen: profileModalOnOpen,
    onClose: profileModalOnClose,
  } = useDisclosure();
  const [isNotMobile] = useMediaQuery('(max-width: 480px)');

  const requestShouldNavigateToTransactionDetails = useMemo(() => {
    return (
      data?.status === PaymentRequestDetailsStatusEnum.Canceled ||
      data?.status === PaymentRequestDetailsStatusEnum.Declined ||
      data?.status === PaymentRequestDetailsStatusEnum.Paid
    );
  }, [data?.status]);

  useEffect(() => {
    if (requestShouldNavigateToTransactionDetails) {
      navigate(
        `/activity/transaction/${data?.transactionId ?? data?.paymentId}`
      );
    }
  }, [requestShouldNavigateToTransactionDetails]);

  return (
    <>
      <Flex
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        background="white"
        zIndex={9}
        padding="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
      >
        <PanelWrapper>
          {isLoading || requestShouldNavigateToTransactionDetails ? (
            <CenteredSpinner centerProps={{ height: '50vh' }} />
          ) : (
            <Stack spacing={7}>
              <CloseButton
                onClick={() =>
                  navigate(
                    `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
                  )
                }
              />
              {isSuccess ? (
                <>
                  <Stack spacing={4}>
                    <Stack>
                      <HStack spacing={1}>
                        {data?.isReceiving ? (
                          <Text>
                            <Button
                              fontWeight="bold"
                              variant="link"
                              minWidth="fit-content"
                              w="fit-content"
                              onClick={() => {
                                setWalletId(data?.payeeWalletId);
                                profileModalOnOpen();
                              }}
                            >
                              {data?.payeeName}
                            </Button>
                            <span>&nbsp;requested payment for</span>
                          </Text>
                        ) : (
                          <Text>
                            <span>You have requested payment from&nbsp;</span>
                            <Button
                              fontWeight="bold"
                              variant="link"
                              minWidth="fit-content"
                              w="fit-content"
                              onClick={() => {
                                setWalletId(data?.payerWalletId ?? '');
                                profileModalOnOpen();
                              }}
                            >
                              {data?.payerName}
                            </Button>
                          </Text>
                        )}
                      </HStack>
                      <Heading
                        as="h2"
                        size="2xl"
                        textDecor={
                          data?.status ===
                            PaymentRequestDetailsStatusEnum.Pending ||
                          data?.status === PaymentRequestDetailsStatusEnum.Paid
                            ? 'inherit'
                            : 'line-through'
                        }
                      >
                        {currency(data?.amount ?? '0').format()}
                      </Heading>
                    </Stack>
                    <Stack spacing={6}>
                      <Badge
                        fontSize="sm"
                        w="fit-content"
                        colorScheme={paymentRequestStatusBadgeColor(
                          data?.status
                        )}
                      >
                        {data?.status}
                      </Badge>
                      <DetailsSection requestDetails={data} />
                      {data?.status ===
                        PaymentRequestDetailsStatusEnum.Pending && (
                        <Stack direction={isNotMobile ? 'column' : 'row'}>
                          <Button
                            variant="secondary"
                            isDisabled={paymentRequestReminderIsPending}
                            onClick={cancelOrDeclineModalOnOpen}
                          >
                            {data?.isReceiving ? 'Decline' : 'Cancel Request'}
                          </Button>
                          {data?.isReceiving ? (
                            <Button
                              onClick={() => {
                                searchParams.append(
                                  'walletId',
                                  data?.payeeWalletId
                                );
                                searchParams.append(
                                  'amount',
                                  data?.amount.toString()
                                );
                                searchParams.append(
                                  'paymentId',
                                  data?.paymentId
                                );
                                navigate(
                                  `/transfers/send-and-request/send?${searchParams}`
                                );
                              }}
                            >
                              Make Payment
                            </Button>
                          ) : (
                            <Button
                              isLoading={paymentRequestReminderIsPending}
                              leftIcon={<BellIcon h="22px" w="20px" />}
                              onClick={() => {
                                paymentRequestReminder({
                                  paymentRequestId: data.paymentId,
                                });
                              }}
                            >
                              Send Reminder
                            </Button>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                  <Text fontSize="xs" color="#A0AEC0" textAlign="center">
                    Wallet Request ID: {data?.paymentId}
                  </Text>
                  {data && (
                    <CancelOrDeclineRequestModal
                      isOpen={cancelOrDeclineModalIsOpen}
                      onClose={cancelOrDeclineModalOnClose}
                      request={data}
                    />
                  )}
                </>
              ) : (
                <>
                  <Text>You do not have permission to view this request.</Text>
                  <Button
                    onClick={() =>
                      navigate(
                        `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
                      )
                    }
                  >
                    View Activity
                  </Button>
                </>
              )}
            </Stack>
          )}
        </PanelWrapper>
      </Flex>
      <ProfileOverlay
        isOpen={profileModalIsOpen}
        onClose={profileModalOnClose}
        walletId={walletId}
      />
    </>
  );
}

function DetailItem({
  label,
  value,
}: {
  label: string;
  value?: string | ReactElement;
}) {
  return (
    <Stack spacing={1}>
      <Text color="textSecondary" fontSize="sm">
        {label}
      </Text>
      {typeof value === 'string' || typeof value === undefined ? (
        <Text>{value}</Text>
      ) : (
        value
      )}
    </Stack>
  );
}

function DetailsSection({
  requestDetails,
}: {
  requestDetails?: PaymentRequestDetails;
}) {
  return (
    <Stack spacing={3}>
      <Text
        fontSize="xs"
        fontWeight="bold"
        letterSpacing="0.06rem"
        textTransform="uppercase"
      >
        Details
      </Text>
      <Stack spacing={3} divider={<Divider />}>
        <DetailItem label="Request Memo" value={requestDetails?.memo} />
        <DetailItem
          label="Type"
          value={
            requestDetails?.isReceiving ? 'Request Received' : 'Request Sent'
          }
        />
        <DetailItem
          label="Request Date"
          value={dateAsmMdDYyyy(new Date(requestDetails?.initiatedAt ?? ''))}
        />
      </Stack>
    </Stack>
  );
}
