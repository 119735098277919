import { useReducer } from 'react';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EnterValue } from './EnterValue';
import { Confirm } from './Confirm';
import { RequestContext, requestReducer, RequestState } from './requestReducer';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Success } from './Success';
import { useWalletProfile } from 'hooks';
import { CenteredSpinner } from 'components/CenteredSpinner';

const initialState: RequestContext = {
  amount: {
    formattedValue: '0.00',
    floatValue: 0,
    value: '0.00',
  },
  requestee: undefined,
  currentState: RequestState.ENTER_AMOUNT,
};

export function Request() {
  const navigate = useNavigate();
  const [context, send] = useReducer(requestReducer, initialState);
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useWalletProfile(
    searchParams.get('walletId') ?? undefined
  );

  if (isLoading || !data) {
    return <CenteredSpinner centerProps={{ height: '200px' }} />;
  }
  return (
    <Stack spacing={4}>
      {context.currentState !== 'success' && (
        <HStack justifyContent="space-between">
          <ArrowBackIcon
            boxSize={6}
            cursor="pointer"
            onClick={() => {
              if (context.currentState == RequestState.ENTER_AMOUNT) {
                navigate(-1);
              } else {
                send({ type: 'BACK' });
              }
            }}
          />
          <Text>Request from {data.name}</Text>
          <div />
        </HStack>
      )}
      {context.currentState == RequestState.ENTER_AMOUNT ? (
        <EnterValue
          requestee={data}
          send={send}
          defaultAmount={context.amount}
        />
      ) : context.currentState == 'confirm' ? (
        <Confirm context={context} send={send} />
      ) : context.currentState == 'success' ? (
        <Success context={context} />
      ) : (
        <Box />
      )}
    </Stack>
  );
}
