import { Badge } from '@chakra-ui/react';
import { BadgeValue } from './Card';
import React from 'react';

export const TitleBadge = ({ value }: { value: BadgeValue }) => {
  const [color, setColor] = React.useState<
    'green' | 'orange' | 'blue' | 'red'
  >();

  React.useEffect(() => {
    if (!value) return;
    const color = getColor();
    setColor(color);
  }, [value]);

  const getColor = () => {
    switch (value) {
      case 'verified':
      case 'ready to submit':
        return 'green';
      case 'needs info':
        return 'orange';
      case 'not started':
        return 'blue';
      case 'further documentation':
        return 'red';
    }
  };

  return (
    color && (
      <Badge colorScheme={color} textTransform="uppercase" fontSize={13}>
        {value}
      </Badge>
    )
  );
};
