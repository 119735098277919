/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Merchant } from './Merchant';
import {
  MerchantFromJSON,
  MerchantFromJSONTyped,
  MerchantToJSON,
} from './Merchant';
import type { Dispute } from './Dispute';
import {
  DisputeFromJSON,
  DisputeFromJSONTyped,
  DisputeToJSON,
} from './Dispute';
import type { DebitCardAmount } from './DebitCardAmount';
import {
  DebitCardAmountFromJSON,
  DebitCardAmountFromJSONTyped,
  DebitCardAmountToJSON,
} from './DebitCardAmount';

/**
 * Debit Card Transaction Details
 * @export
 * @interface AptoTransactionMetadata
 */
export interface AptoTransactionMetadata {
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  state: string;
  /**
   *
   * @type {DebitCardAmount}
   * @memberof AptoTransactionMetadata
   */
  localAmount: DebitCardAmount;
  /**
   *
   * @type {DebitCardAmount}
   * @memberof AptoTransactionMetadata
   */
  holdAmount: DebitCardAmount;
  /**
   *
   * @type {DebitCardAmount}
   * @memberof AptoTransactionMetadata
   */
  cashbackAmount: DebitCardAmount;
  /**
   *
   * @type {DebitCardAmount}
   * @memberof AptoTransactionMetadata
   */
  feeAmount: DebitCardAmount;
  /**
   *
   * @type {DebitCardAmount}
   * @memberof AptoTransactionMetadata
   */
  billingAmount: DebitCardAmount;
  /**
   *
   * @type {DebitCardAmount}
   * @memberof AptoTransactionMetadata
   */
  nativeBalanceAmount: DebitCardAmount;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  settlementDate: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  cardId?: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  corporationId?: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  context: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  declineReason?: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  declineCode?: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  fundingSourceName?: string;
  /**
   *
   * @type {string}
   * @memberof AptoTransactionMetadata
   */
  type: string;
  /**
   *
   * @type {Merchant}
   * @memberof AptoTransactionMetadata
   */
  merchant: Merchant;
  /**
   *
   * @type {Dispute}
   * @memberof AptoTransactionMetadata
   */
  dispute?: Dispute;
}

/**
 * Check if a given object implements the AptoTransactionMetadata interface.
 */
export function instanceOfAptoTransactionMetadata(
  value: object
): value is AptoTransactionMetadata {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  if (!('state' in value) || value['state'] === undefined) return false;
  if (!('localAmount' in value) || value['localAmount'] === undefined)
    return false;
  if (!('holdAmount' in value) || value['holdAmount'] === undefined)
    return false;
  if (!('cashbackAmount' in value) || value['cashbackAmount'] === undefined)
    return false;
  if (!('feeAmount' in value) || value['feeAmount'] === undefined) return false;
  if (!('billingAmount' in value) || value['billingAmount'] === undefined)
    return false;
  if (
    !('nativeBalanceAmount' in value) ||
    value['nativeBalanceAmount'] === undefined
  )
    return false;
  if (!('settlementDate' in value) || value['settlementDate'] === undefined)
    return false;
  if (!('context' in value) || value['context'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('merchant' in value) || value['merchant'] === undefined) return false;
  return true;
}

export function AptoTransactionMetadataFromJSON(
  json: any
): AptoTransactionMetadata {
  return AptoTransactionMetadataFromJSONTyped(json, false);
}

export function AptoTransactionMetadataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AptoTransactionMetadata {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: json['createdAt'],
    description: json['description'],
    state: json['state'],
    localAmount: DebitCardAmountFromJSON(json['localAmount']),
    holdAmount: DebitCardAmountFromJSON(json['holdAmount']),
    cashbackAmount: DebitCardAmountFromJSON(json['cashbackAmount']),
    feeAmount: DebitCardAmountFromJSON(json['feeAmount']),
    billingAmount: DebitCardAmountFromJSON(json['billingAmount']),
    nativeBalanceAmount: DebitCardAmountFromJSON(json['nativeBalanceAmount']),
    settlementDate: json['settlementDate'],
    cardId: json['cardId'] == null ? undefined : json['cardId'],
    corporationId:
      json['corporationId'] == null ? undefined : json['corporationId'],
    context: json['context'],
    declineReason:
      json['declineReason'] == null ? undefined : json['declineReason'],
    declineCode: json['declineCode'] == null ? undefined : json['declineCode'],
    fundingSourceName:
      json['fundingSourceName'] == null ? undefined : json['fundingSourceName'],
    type: json['type'],
    merchant: MerchantFromJSON(json['merchant']),
    dispute:
      json['dispute'] == null ? undefined : DisputeFromJSON(json['dispute']),
  };
}

export function AptoTransactionMetadataToJSON(
  value?: AptoTransactionMetadata | null
): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    createdAt: value['createdAt'],
    description: value['description'],
    state: value['state'],
    localAmount: DebitCardAmountToJSON(value['localAmount']),
    holdAmount: DebitCardAmountToJSON(value['holdAmount']),
    cashbackAmount: DebitCardAmountToJSON(value['cashbackAmount']),
    feeAmount: DebitCardAmountToJSON(value['feeAmount']),
    billingAmount: DebitCardAmountToJSON(value['billingAmount']),
    nativeBalanceAmount: DebitCardAmountToJSON(value['nativeBalanceAmount']),
    settlementDate: value['settlementDate'],
    cardId: value['cardId'],
    corporationId: value['corporationId'],
    context: value['context'],
    declineReason: value['declineReason'],
    declineCode: value['declineCode'],
    fundingSourceName: value['fundingSourceName'],
    type: value['type'],
    merchant: MerchantToJSON(value['merchant']),
    dispute: DisputeToJSON(value['dispute']),
  };
}
