import React, { useState } from 'react';
import { Box, Button, Flex, Spinner, Textarea, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { TransferContext } from '../TransferFlow';
import currency from 'currency.js';
import {
  FundingSourceDto,
  LoadRequestCurrencyTypeEnum,
  UnloadRequestCurrencyTypeEnum,
} from 'api/wallet-app';
import { useLoad, useUnload } from 'hooks';

const LabelStyle = {
  fontSize: '14px',
  color: '#718096',
  marginBottom: '8px',
};

const InputStyle = {
  padding: '16px',
  border: 'solid 1px',
  borderColor: '#E2E8F0',
  borderRadius: '8px',
  marginBottom: '32px',
};

export const Confirm = ({ context }: { context: TransferContext }) => {
  const { send, type, walletBalance, transferAmount, fundingSource } = context;
  const [memo, setMemo] = useState('');
  const { mutate: unload, isPending: unloadIsPending } = useUnload();
  const { mutate: load, isPending: loadIsPending } = useLoad();

  const handleSubmit = () => {
    if (type === 'UNLOAD') {
      unload(
        {
          memo,
          toFundingSourceId: fundingSource?.fundingSourceId ?? '',
          amount: transferAmount,
          currencyType: UnloadRequestCurrencyTypeEnum.Usd,
        },
        {
          onSuccess: () => {
            send({ type: 'NEXT' });
          },
        }
      );
    } else {
      load(
        {
          memo,
          fromFundingSourceId: fundingSource?.fundingSourceId ?? '',
          amount: transferAmount,
          currencyType: LoadRequestCurrencyTypeEnum.Usd,
        },
        {
          onSuccess: () => {
            send({ type: 'NEXT' });
          },
        }
      );
    }
  };

  const LoadingSpinner = () => {
    if (!unloadIsPending && !loadIsPending) {
      return null;
    }

    return (
      <Flex
        height="100%"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgba(255, 255, 255, 0.5)"
        zIndex="9"
      >
        <Spinner size="xl" thickness="4px" color="blue.400" />
      </Flex>
    );
  };

  return (
    <Box position="relative">
      <LoadingSpinner />
      <Flex justifyContent="space-between" mb="2rem">
        <ArrowBackIcon
          fontSize="1.5rem"
          cursor="pointer"
          onClick={() => send({ type: 'BACK' })}
        />
        {type == 'LOAD' ? 'Deposit in Wallet' : 'Transfer to Bank'}
        <Flex w="24px" />
      </Flex>
      <Flex flexDirection="column" fontFamily="inter">
        <Flex flexDirection="column">
          <Text style={LabelStyle}>Amount</Text>
          <Flex
            style={InputStyle}
            padding="12px 16px!important"
            fontFamily="heading"
            fontSize="24px"
          >
            {currency(transferAmount).format()}
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text style={LabelStyle}>From</Text>
          <Flex style={InputStyle} justifyContent="space-between">
            {type === 'UNLOAD' ? (
              <DisplayWalletSource balance={walletBalance ?? '0'} />
            ) : (
              <DisplayFundingSource fundingSource={fundingSource} />
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text style={LabelStyle}>To</Text>
          <Flex
            color="gray.500"
            style={InputStyle}
            justifyContent="space-between"
          >
            {type == 'LOAD' ? (
              <DisplayWalletSource balance={walletBalance ?? '0'} />
            ) : (
              <DisplayFundingSource fundingSource={fundingSource} />
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text style={LabelStyle}>Memo</Text>
          <Textarea
            maxLength={256}
            placeholder="256 character limit"
            padding="1rem"
            fontSize="16px"
            value={memo}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
          />
        </Flex>
        <Text mt="3rem" fontSize="sm" color="textSecondary">
          By clicking the button below, I authorize the above transaction.
        </Text>
        <Button
          fontFamily="inter"
          mt="1rem"
          isDisabled={unloadIsPending || loadIsPending}
          onClick={handleSubmit}
        >
          {type == 'LOAD' ? (
            <Text>Deposit: {currency(transferAmount).format()}</Text>
          ) : (
            <Text>Send: {currency(transferAmount).format()}</Text>
          )}
        </Button>
      </Flex>
    </Box>
  );
};

const DisplayFundingSource = ({
  fundingSource,
}: {
  fundingSource: FundingSourceDto | undefined;
}) => {
  if (!fundingSource) return <></>;

  return (
    <Flex justifyContent="space-between" width="100%">
      <Text
        fontWeight="bold"
        color="black"
        maxWidth="70%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {fundingSource.name
          ? fundingSource.name
          : fundingSource.financialInstitutionName}
      </Text>
      <Flex color="gray.500"> •••• {fundingSource?.accountNumber}</Flex>
    </Flex>
  );
};

const DisplayWalletSource = ({ balance }: { balance: string }) => {
  return (
    <>
      <Flex fontWeight="bold" color="black">
        Wallet Balance
      </Flex>
      <Flex color="gray.500">{currency(balance).format()}</Flex>
    </>
  );
};
