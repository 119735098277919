/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserInviteListItem
 */
export interface UserInviteListItem {
  /**
   *
   * @type {string}
   * @memberof UserInviteListItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserInviteListItem
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserInviteListItem
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserInviteListItem
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserInviteListItem
   */
  inviteStatus: UserInviteListItemInviteStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserInviteListItemInviteStatusEnum {
  Invited = 'INVITED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Revoked = 'REVOKED',
  Expired = 'EXPIRED',
}

/**
 * Check if a given object implements the UserInviteListItem interface.
 */
export function instanceOfUserInviteListItem(
  value: object
): value is UserInviteListItem {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('firstName' in value) || value['firstName'] === undefined) return false;
  if (!('lastName' in value) || value['lastName'] === undefined) return false;
  if (!('email' in value) || value['email'] === undefined) return false;
  if (!('inviteStatus' in value) || value['inviteStatus'] === undefined)
    return false;
  return true;
}

export function UserInviteListItemFromJSON(json: any): UserInviteListItem {
  return UserInviteListItemFromJSONTyped(json, false);
}

export function UserInviteListItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserInviteListItem {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    inviteStatus: json['inviteStatus'],
  };
}

export function UserInviteListItemToJSON(
  value?: UserInviteListItem | null
): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    firstName: value['firstName'],
    lastName: value['lastName'],
    email: value['email'],
    inviteStatus: value['inviteStatus'],
  };
}
