import { CloseIcon } from '@chakra-ui/icons';
import LockImage from 'images/accountLocked.svg';
import {
  Flex,
  Button,
  Heading,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Switch,
  Text,
  HStack,
} from '@chakra-ui/react';
import { IoLockClosedOutline } from 'react-icons/io5';
import { useKeycloak } from '@react-keycloak/web';
import {
  LinkItem,
  SettingsCard,
} from 'pages/WalletApp/settings/components/index';
import { useDisableMfa, useEnableMfa, useKeycloakUserInfo } from 'hooks/api';

export default function AccountSecurity() {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: warningOnOpen,
    isOpen: warningIsOpen,
    onClose: warningOnClose,
  } = useDisclosure();
  const { data: keycloakUserInfo } = useKeycloakUserInfo();
  const { mutate: disableMfa } = useDisableMfa();
  const { mutate: enableMfa } = useEnableMfa();
  const { keycloak } = useKeycloak();

  function displayLogoutWarning() {
    onClose();
    warningOnOpen();
  }

  function toggleMFA() {
    if (keycloakUserInfo?.multi_factor_enabled) {
      disableMfa(keycloakUserInfo.multi_factor_method ?? '');
    } else {
      displayLogoutWarning();
    }
  }

  return (
    <>
      <SettingsCard heading="Account Security">
        <HStack
          justifyContent="space-between"
          borderBottom="1px solid #CBD5E0"
          py={3}
        >
          <HStack>
            <Icon as={IoLockClosedOutline} boxSize={6} />
            <Text>Two Step Verification</Text>
          </HStack>
          <Switch
            onChange={onOpen}
            isChecked={!!keycloakUserInfo?.multi_factor_enabled}
          />
        </HStack>
        <LinkItem
          title="Update Password"
          linkTarget={`${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/auth?client_id=${keycloak.clientId}&redirect_uri=${window.location.href}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`}
          newTab={false}
        />
      </SettingsCard>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p="32px">
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Icon
              tabIndex={0}
              as={CloseIcon}
              alignSelf="flex-start"
              onClick={onClose}
              cursor="pointer"
              width="12px"
              _focus={{
                outline: 'none',
              }}
            />
          </Flex>
          <Flex flexDirection="column" mt="1rem">
            <Image src={LockImage} width="100%" mb="1rem" />
            <Heading my="1rem">Two Step Verification</Heading>
            <Text fontWeight="bold">
              Two Step Verification provides an additional layer of security to
              your account
            </Text>
            <Button
              mt="1rem"
              background="gray.200"
              color="gray.700"
              fontWeight="bold"
              onClick={toggleMFA}
              _hover={{
                color: '#fff',
              }}
            >
              Edit Verification
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
      <Modal isOpen={warningIsOpen} onClose={warningOnClose}>
        <ModalOverlay />
        <ModalContent p="32px">
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <Icon
              tabIndex={0}
              as={CloseIcon}
              alignSelf="flex-start"
              onClick={warningOnClose}
              cursor="pointer"
              width="12px"
              _focus={{
                outline: 'none',
              }}
            />
          </Flex>
          <Flex flexDirection="column" mt="1rem">
            <Heading>Continue to Login</Heading>
            <Text mt="1.5rem">
              Please log in again to enable two-step verification and ensure
              secure access.
            </Text>
            <Flex
              mt="1.5rem"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                mr="1rem"
                onClick={() => {
                  enableMfa();
                }}
              >
                Continue
              </Button>
              <Button
                background="gray.200"
                color="black"
                onClick={warningOnClose}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
}
