import { FormProvider, useForm } from 'react-hook-form';
import { IoMailOutline } from 'react-icons/io5';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { InviteBusinessRequest } from 'api/wallet-app';
import { useInviteBusiness } from 'hooks/api';
import { emailRegex } from 'utils/helpers';
import { PhoneNumberInput } from 'components/formComponents/phone-number-input';

export default function InviteABusinessModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  const { mutate: inviteBusiness, isPending: inviteBusinessIsPending } =
    useInviteBusiness();
  const methods = useForm<InviteBusinessRequest>({
    defaultValues: { email: '', phoneNumber: '' },
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    formState: { isDirty },
  } = methods;

  function onSubmit(data: InviteBusinessRequest) {
    inviteBusiness(data, {
      onSettled: () => {
        onCloseModal();
      },
    });
  }

  function onCloseModal() {
    onClose();
    reset();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
      closeOnOverlayClick={!inviteBusinessIsPending}
      size={isMobile ? 'xs' : 'md'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={[4, 12]} pt={[4, 12]} pb={2}>
          <Heading as="h4" fontSize="24px">
            Sending an invite
          </Heading>
        </ModalHeader>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalCloseButton isDisabled={inviteBusinessIsPending} />
            <ModalBody px={[4, 12]} py={0}>
              <Stack spacing={8}>
                <Text>
                  Enter the email and/or phone number for the business you want
                  to invite
                </Text>
                <Stack spacing={6}>
                  <FormControl isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'Email is required',
                        },
                        pattern: {
                          value: emailRegex,
                          message: 'Please enter a valid email',
                        },
                        maxLength: {
                          value: 256,
                          message: 'Email must be 256 characters or less',
                        },
                      })}
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                  <PhoneNumberInput isRequired={false} />
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter px={[4, 12]} pb={[4, 12]} pt={8}>
              <Button
                leftIcon={<IoMailOutline />}
                w="fit-content"
                type="submit"
                isLoading={inviteBusinessIsPending}
                isDisabled={!isDirty}
              >
                Send Invite
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
