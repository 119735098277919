/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DeclinePaymentRequestRequest
 */
export interface DeclinePaymentRequestRequest {
  /**
   *
   * @type {string}
   * @memberof DeclinePaymentRequestRequest
   */
  payerWalletId: string;
  /**
   *
   * @type {string}
   * @memberof DeclinePaymentRequestRequest
   */
  paymentId: string;
  /**
   *
   * @type {string}
   * @memberof DeclinePaymentRequestRequest
   */
  declinedReason: string;
}

/**
 * Check if a given object implements the DeclinePaymentRequestRequest interface.
 */
export function instanceOfDeclinePaymentRequestRequest(
  value: object
): value is DeclinePaymentRequestRequest {
  if (!('payerWalletId' in value) || value['payerWalletId'] === undefined)
    return false;
  if (!('paymentId' in value) || value['paymentId'] === undefined) return false;
  if (!('declinedReason' in value) || value['declinedReason'] === undefined)
    return false;
  return true;
}

export function DeclinePaymentRequestRequestFromJSON(
  json: any
): DeclinePaymentRequestRequest {
  return DeclinePaymentRequestRequestFromJSONTyped(json, false);
}

export function DeclinePaymentRequestRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeclinePaymentRequestRequest {
  if (json == null) {
    return json;
  }
  return {
    payerWalletId: json['payerWalletId'],
    paymentId: json['paymentId'],
    declinedReason: json['declinedReason'],
  };
}

export function DeclinePaymentRequestRequestToJSON(
  value?: DeclinePaymentRequestRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    payerWalletId: value['payerWalletId'],
    paymentId: value['paymentId'],
    declinedReason: value['declinedReason'],
  };
}
