import { useMutation } from '@tanstack/react-query';
import { useTransactionClient } from '.';
import { useWalletStore } from 'stores';
import { v4 as uuid } from 'uuid';
import { UnloadRequest } from 'api/wallet-app';
import { useMediaQuery, useToast } from '@chakra-ui/react';

export default function useUnload() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const transactionsClient = useTransactionClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  return useMutation({
    mutationKey: ['unload'],
    mutationFn: (payload: UnloadRequest) => {
      return transactionsClient.unloadAccount({
        xWalletId: walletId,
        idempotencyKey: uuid(),
        unloadRequest: payload,
      });
    },
    onError: () => {
      toast({ status: 'error', title: 'Failed to Withdraw' });
    },
  });
}
