/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SendMoneyResponse
 */
export interface SendMoneyResponse {
  /**
   *
   * @type {string}
   * @memberof SendMoneyResponse
   */
  fundsFlowId: string;
}

/**
 * Check if a given object implements the SendMoneyResponse interface.
 */
export function instanceOfSendMoneyResponse(
  value: object
): value is SendMoneyResponse {
  if (!('fundsFlowId' in value) || value['fundsFlowId'] === undefined)
    return false;
  return true;
}

export function SendMoneyResponseFromJSON(json: any): SendMoneyResponse {
  return SendMoneyResponseFromJSONTyped(json, false);
}

export function SendMoneyResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SendMoneyResponse {
  if (json == null) {
    return json;
  }
  return {
    fundsFlowId: json['fundsFlowId'],
  };
}

export function SendMoneyResponseToJSON(value?: SendMoneyResponse | null): any {
  if (value == null) {
    return value;
  }
  return {
    fundsFlowId: value['fundsFlowId'],
  };
}
