/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AlertDto } from './AlertDto';
import {
  AlertDtoFromJSON,
  AlertDtoFromJSONTyped,
  AlertDtoToJSON,
} from './AlertDto';

/**
 *
 * @export
 * @interface AlertsResponse
 */
export interface AlertsResponse {
  /**
   *
   * @type {Array<AlertDto>}
   * @memberof AlertsResponse
   */
  alerts: Array<AlertDto>;
}

/**
 * Check if a given object implements the AlertsResponse interface.
 */
export function instanceOfAlertsResponse(
  value: object
): value is AlertsResponse {
  if (!('alerts' in value) || value['alerts'] === undefined) return false;
  return true;
}

export function AlertsResponseFromJSON(json: any): AlertsResponse {
  return AlertsResponseFromJSONTyped(json, false);
}

export function AlertsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AlertsResponse {
  if (json == null) {
    return json;
  }
  return {
    alerts: (json['alerts'] as Array<any>).map(AlertDtoFromJSON),
  };
}

export function AlertsResponseToJSON(value?: AlertsResponse | null): any {
  if (value == null) {
    return value;
  }
  return {
    alerts: (value['alerts'] as Array<any>).map(AlertDtoToJSON),
  };
}
