import {
  Center,
  Flex,
  Spinner,
  Stack,
  Text,
  Grid,
  GridItem,
  GridItemProps,
  Badge,
  useMediaQuery,
  Heading,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { useInfiniteScroll } from 'hooks';
import useInfiniteTransactionList from 'hooks/api/transactions/useInfiniteTransactionList';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import NoActivityImage from 'images/NoActivity.svg';
import { useNavigate } from 'react-router-dom';
import { twoDigitMMDDYY } from 'utils/date-utils';
import currency from 'currency.js';
import { IoChevronForwardOutline } from 'react-icons/io5';
import {
  getTransactionType,
  transactionStatusBadgeColor,
  transactionAmountTextDecoration,
  transactionAmountTextColor,
  transactionAmountSymbol,
} from 'utils';
import { HorizontalSkeletons } from 'components/HorizontalSkeletons';
import {
  TransactionDetailsDtoMoneySentOrReceivedEnum,
  TransactionDetailsDtoTypeEnum,
} from 'api/wallet-app';

const headerStyles: GridItemProps = {
  color: 'gray.700',
  fontWeight: 'bold',
  fontSize: '14px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
};

export default function TransactionHistory() {
  const { t } = useTranslation();
  const targetRef = useRef<HTMLDivElement>(null);
  const [isMobileDesigns] = useMediaQuery('(max-width: 700px)');

  const {
    data,
    isLoading,
    isError,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetched,
  } = useInfiniteTransactionList();

  useInfiniteScroll({
    targetRef,
    hasNextPage,
    fetchNextPage,
    options: {
      root: null, // Viewport is the root
      rootMargin: '10px',
      threshold: 0.01,
    },
  });

  const navigate = useNavigate();

  return (
    <Stack spacing={[4, 8]} pb={isMobileDesigns ? '125px' : 0}>
      {isLoading && (
        <HorizontalSkeletons
          numOfSkeletons={7}
          skeletonProps={{ height: '70px' }}
        />
      )}
      {isError && <Text>{t('wallet.activity.error')}</Text>}
      {isSuccess && (
        <Stack spacing={4}>
          {!isMobileDesigns && (
            <Grid
              templateColumns="repeat(24, 1fr)"
              px={3}
              gap={isMobileDesigns ? 2 : 4}
            >
              <GridItem {...headerStyles} colSpan={5}>
                Name
              </GridItem>
              <GridItem {...headerStyles} colSpan={4}>
                Type
              </GridItem>
              <GridItem {...headerStyles} colSpan={4}>
                Status
              </GridItem>
              <GridItem {...headerStyles} colSpan={4}>
                Date
              </GridItem>
              <GridItem {...headerStyles} textAlign="end" colSpan={6}>
                Amount
              </GridItem>
              <GridItem colSpan={1} />
            </Grid>
          )}
          <Grid
            templateRows={`repeat(${data?.pages[0].transactionHistory.length}, 1fr)`}
            templateColumns="repeat(1, 1fr)"
            bgColor="white"
            borderRadius="lg"
            boxShadow="0px 0px 2px 0px #2D374826, 0px 2px 3px 0px #2D374833, 0px 0px 0.5px 0px #FFFFFF00 inset, 0px 0.5px 0px 0px #FFFFFF00 inset"
          >
            {data?.pages[0]?.transactionHistory.length === 0 && (
              <Stack spacing={12} align="center" p={6}>
                <Image src={NoActivityImage} w="400px" h="120px" />
                <Stack spacing={6} align="center">
                  <Heading as="h3" size="xl">
                    {t('wallet.activity.noResultsFound')}
                  </Heading>
                  <Text textAlign="center">
                    {t('wallet.activity.transactionHistoryAppearHere')}
                  </Text>
                </Stack>
              </Stack>
            )}
            {data?.pages.map((page) => {
              return page.transactionHistory.map((transaction) => {
                return (
                  <Grid
                    key={transaction.id}
                    templateColumns="repeat(24, 1fr)"
                    px={3}
                    py={6}
                    _hover={{ bgColor: 'gray.50', cursor: 'pointer' }}
                    onClick={() => {
                      if (
                        transaction.type ===
                        TransactionDetailsDtoTypeEnum.PaymentRequest
                      ) {
                        navigate(`request/${transaction.id as string}`);
                      } else {
                        navigate(`transaction/${transaction.id as string}`);
                      }
                    }}
                    borderBottom="1px solid #CBD5E0"
                    gap={isMobileDesigns ? 2 : 4}
                    data-testid="table-row"
                  >
                    <GridItem
                      fontWeight="bold"
                      rowStart={isMobileDesigns ? 2 : undefined}
                      colSpan={isMobileDesigns ? 23 : 5}
                      isTruncated
                    >
                      <Tooltip
                        label={
                          transaction.moneySentOrReceived ===
                          TransactionDetailsDtoMoneySentOrReceivedEnum.Sent
                            ? transaction.destination
                            : transaction.source
                        }
                      >
                        {transaction.moneySentOrReceived ===
                        TransactionDetailsDtoMoneySentOrReceivedEnum.Sent
                          ? transaction.destination
                          : transaction.source}
                      </Tooltip>
                    </GridItem>
                    <GridItem
                      rowStart={isMobileDesigns ? 1 : undefined}
                      isTruncated
                      colSpan={isMobileDesigns ? 12 : 4}
                    >
                      {getTransactionType(transaction)}
                    </GridItem>
                    <GridItem
                      rowStart={isMobileDesigns ? 3 : undefined}
                      colSpan={isMobileDesigns ? 10 : 4}
                    >
                      <Badge
                        colorScheme={transactionStatusBadgeColor(
                          transaction.status
                        )}
                        fontSize="sm"
                      >
                        {transaction.status}
                      </Badge>
                    </GridItem>
                    <GridItem
                      textAlign={isMobileDesigns ? 'end' : 'inherit'}
                      colSpan={isMobileDesigns ? 11 : 4}
                      fontFamily="Roboto Mono"
                    >
                      {twoDigitMMDDYY(new Date(transaction?.updatedAt ?? ''))}
                    </GridItem>
                    <GridItem
                      rowStart={isMobileDesigns ? 3 : undefined}
                      fontWeight="bold"
                      textAlign="end"
                      colSpan={isMobileDesigns ? 13 : 6}
                      fontFamily="Roboto Mono"
                      textDecor={transactionAmountTextDecoration(transaction)}
                      color={transactionAmountTextColor(transaction)}
                    >
                      {`${transactionAmountSymbol(transaction)}${currency(
                        transaction?.amount ?? ''
                      ).format()}`}
                    </GridItem>
                    <GridItem
                      colSpan={isMobileDesigns ? 1 : 1}
                      rowSpan={isMobileDesigns ? 3 : 1}
                      alignContent="center"
                      justifySelf="end"
                    >
                      <IoChevronForwardOutline
                        size={isMobileDesigns ? 16 : 24}
                        color="#69788A"
                      />
                    </GridItem>
                  </Grid>
                );
              });
            })}
          </Grid>
        </Stack>
      )}
      <Center>
        {isFetchingNextPage && <Spinner color="blue.400" size="lg" />}
        {!hasNextPage &&
          data?.pages[0]?.transactionHistory.length !== 0 &&
          isFetched &&
          !isError && (
            <Text color="gray.400">No more transactions to fetch</Text>
          )}
      </Center>
      {hasNextPage && <Flex mb="200px" ref={targetRef} height="20px" />}
    </Stack>
  );
}
