import { createIcon } from '@chakra-ui/react';

export const WalletProductIcon = createIcon({
  displayName: 'WalletProductIcon',
  viewBox: '0 0 159 16',
  path: (
    <>
      <path
        d="M42.9039 9.11928C43.3219 9.61851 43.5313 10.2328 43.532 10.9623C43.532 11.9949 43.0916 12.7888 42.2109 13.344C41.3303 13.8991 40.0464 14.177 38.3594 14.1777H31.6719V2.39941H37.9901C39.5653 2.39941 40.7751 2.67435 41.6197 3.22423C42.0199 3.46945 42.3457 3.80272 42.567 4.19317C42.7883 4.58363 42.8978 5.01864 42.8855 5.45793C42.8981 5.99393 42.7433 6.52214 42.439 6.98102C42.1348 7.4399 41.6937 7.81055 41.1671 8.04978C41.8547 8.23169 42.4623 8.60579 42.9039 9.11928ZM34.6431 4.44862V7.22595H37.6165C38.3565 7.22595 38.917 7.10789 39.2982 6.87176C39.6793 6.63563 39.8703 6.28768 39.871 5.82792C39.871 5.36749 39.68 5.02251 39.2982 4.79295C38.9163 4.5634 38.3558 4.44862 37.6165 4.44862H34.6431ZM39.9197 11.7723C40.3203 11.5368 40.5207 11.1721 40.5207 10.6781C40.5207 9.70138 39.7266 9.21333 38.1385 9.21399H34.6431V12.1255H38.1341C38.9246 12.1242 39.5198 12.0065 39.9197 11.7723Z"
        fill="#2D3748"
      />
      <path
        d="M55.2438 5.12109V14.1773H52.5097V13.0999C52.1337 13.489 51.6667 13.7965 51.1432 13.9997C50.6 14.2102 50.015 14.3163 49.4248 14.3114C48.1189 14.3114 47.0844 13.9691 46.3214 13.2844C45.5584 12.5997 45.1765 11.5841 45.1758 10.2377V5.12109H48.0582V9.85094C48.0582 11.3098 48.7292 12.0393 50.0712 12.0393C50.7613 12.0393 51.3157 11.8344 51.7344 11.4246C52.1531 11.0148 52.3624 10.4061 52.3624 9.59837V5.12109H55.2438Z"
        fill="#2D3748"
      />
      <path
        d="M59.2161 14.0345C58.4278 13.849 57.7998 13.6162 57.332 13.336L58.2925 11.4506C58.7918 11.7312 59.3335 11.9436 59.9005 12.081C60.5007 12.2409 61.1225 12.3232 61.7478 12.3257C62.967 12.3257 63.5767 12.0508 63.5767 11.5009C63.5767 11.2424 63.4099 11.0579 63.0775 10.9454C62.7451 10.8329 62.234 10.7382 61.5442 10.6593C60.8638 10.5728 60.1908 10.4433 59.5302 10.2716C59.0054 10.1297 58.5308 9.86468 58.1539 9.50298C57.7662 9.13201 57.5724 8.60581 57.5724 7.92439C57.5632 7.37605 57.7536 6.84036 58.1138 6.40105C58.4748 5.95839 59.0043 5.6134 59.7023 5.36609C60.4004 5.11877 61.2226 4.99512 62.169 4.99512C62.8741 4.99576 63.5771 5.06656 64.2653 5.20625C64.8728 5.31512 65.4575 5.51092 65.9978 5.78639L65.0363 7.65504C64.163 7.20651 63.1758 6.9745 62.1733 6.98217C61.5568 6.98217 61.0948 7.06044 60.7873 7.21698C60.4798 7.37353 60.326 7.57578 60.326 7.82375C60.326 8.10395 60.4917 8.30029 60.8252 8.41573C61.3427 8.56689 61.8745 8.6739 62.4137 8.73539C63.0866 8.83042 63.7527 8.96221 64.4083 9.13004C64.9253 9.27009 65.3914 9.53458 65.7575 9.89565C66.1386 10.266 66.3295 10.7823 66.3303 11.4446C66.3373 11.9867 66.1418 12.5149 65.7759 12.9423C65.4063 13.3804 64.8674 13.7198 64.1592 13.9605C63.451 14.2013 62.6104 14.322 61.6373 14.3226C60.8202 14.32 60.0066 14.2232 59.2161 14.0345Z"
        fill="#2D3748"
      />
      <path
        d="M77.1286 5.99704C77.8866 6.67123 78.2655 7.67002 78.2655 8.99341V14.1781H75.3831V9.39693C75.3831 8.67999 75.2106 8.14425 74.8655 7.78973C74.5204 7.4352 74.022 7.2586 73.3701 7.25992C72.6425 7.25992 72.065 7.46447 71.6376 7.87359C71.2103 8.28271 70.9937 8.89145 70.9879 9.69982V14.1781H68.1055V1.68848H70.9879V6.06413C71.3804 5.70876 71.8524 5.43481 72.3718 5.26102C72.9397 5.07147 73.5406 4.97721 74.1454 4.9828C75.377 4.98543 76.3713 5.32351 77.1286 5.99704Z"
        fill="#2D3748"
      />
      <path
        d="M90.2906 10.3908H82.7715C82.8914 10.9304 83.2356 11.4069 83.732 11.7208C84.2373 12.0496 84.8657 12.2121 85.6172 12.2082C86.0862 12.2161 86.5529 12.1449 86.9935 11.998C87.4112 11.847 87.7912 11.6208 88.1109 11.333L89.6442 12.8485C88.7057 13.8239 87.3385 14.312 85.5425 14.3126C84.4221 14.3126 83.431 14.1133 82.5691 13.7147C81.7434 13.3476 81.0508 12.7724 80.5734 12.0572C80.0995 11.3294 79.8566 10.496 79.8718 9.64986C79.8589 8.8078 80.0985 7.97851 80.5648 7.25139C81.0178 6.54644 81.6763 5.96963 82.4673 5.58499C83.2926 5.1965 84.207 4.99139 85.1359 4.98642C86.0648 4.98145 86.9818 5.17675 87.8121 5.55638C88.5908 5.93005 89.235 6.49996 89.668 7.19811C90.1185 7.91045 90.3433 8.7379 90.3426 9.68044C90.3448 9.71531 90.3275 9.95209 90.2906 10.3908ZM83.5479 7.47732C83.0958 7.82816 82.8055 8.32136 82.7347 8.85859H87.6302C87.5551 8.32562 87.2657 7.83697 86.818 7.48719C86.3633 7.14164 85.7857 6.95921 85.1938 6.97415C84.5966 6.95569 84.0121 7.13437 83.5479 7.47732Z"
        fill="#2D3748"
      />
      <path
        d="M92.2656 1.68848H95.1481V14.1781H92.2656V1.68848Z"
        fill="#2D3748"
      />
      <path
        d="M118.202 2.39941L113.842 14.1826H112.401L108.611 4.1329L104.811 14.1777H103.387L99.0273 2.39941H100.435L104.161 12.528L108.023 2.39941H109.298L113.104 12.5833L116.894 2.39941H118.202Z"
        fill="#2D3748"
      />
      <path
        d="M126.001 6.12328C126.678 6.70143 127.016 7.5565 127.016 8.68848V14.1761H125.76V12.7948C125.454 13.2634 125.002 13.6382 124.461 13.8712C123.888 14.1297 123.208 14.2589 122.419 14.2589C121.336 14.2589 120.474 14.0231 119.833 13.5515C119.523 13.3309 119.274 13.0468 119.108 12.7223C118.941 12.3978 118.86 12.042 118.872 11.6839C118.872 10.9321 119.171 10.3259 119.768 9.86552C120.365 9.4051 121.316 9.17489 122.622 9.17489H125.707V8.63619C125.707 7.87255 125.473 7.29176 125.005 6.89383C124.538 6.49589 123.854 6.29659 122.955 6.29593C122.35 6.29409 121.75 6.38825 121.181 6.57416C120.645 6.74265 120.15 7.00248 119.721 7.33978L119.131 6.45182C119.653 6.05361 120.255 5.752 120.904 5.56386C121.607 5.35749 122.343 5.25523 123.082 5.26097C124.351 5.26097 125.323 5.54841 126.001 6.12328ZM124.52 12.8579C125.06 12.5378 125.475 12.0692 125.703 11.5201V10.0707H122.655C120.994 10.0707 120.164 10.5969 120.164 11.6493C120.155 11.8856 120.209 12.1205 120.322 12.3332C120.435 12.546 120.604 12.7302 120.814 12.8698C121.247 13.1677 121.85 13.3164 122.624 13.3157C123.36 13.3177 123.992 13.1651 124.52 12.8579Z"
        fill="#2D3748"
      />
      <path
        d="M130.211 1.68848H131.522V14.1781H130.211V1.68848Z"
        fill="#2D3748"
      />
      <path
        d="M135.18 1.68848H136.491V14.1781H135.18V1.68848Z"
        fill="#2D3748"
      />
      <path
        d="M148.612 10.1203H140.483C140.555 11.0412 140.943 11.7844 141.647 12.3501C142.351 12.9158 143.238 13.1993 144.308 13.2006C144.878 13.2065 145.444 13.1058 145.97 12.9046C146.474 12.7102 146.922 12.414 147.282 12.0383L148.021 12.8128C147.586 13.2863 147.031 13.6561 146.404 13.8902C145.727 14.1422 145.002 14.268 144.271 14.2602C143.347 14.2769 142.434 14.0768 141.62 13.6791C140.867 13.3071 140.242 12.7504 139.818 12.0719C139.379 11.3639 139.155 10.5624 139.169 9.74938C139.155 8.94108 139.368 8.14302 139.788 7.43082C140.181 6.76491 140.769 6.21132 141.487 5.83151C142.226 5.44592 143.065 5.24829 143.917 5.25927C144.765 5.24765 145.602 5.44538 146.337 5.83151C147.047 6.20935 147.629 6.75876 148.017 7.41898C148.435 8.13633 148.644 8.93868 148.627 9.75036L148.612 10.1203ZM141.565 7.09932C140.93 7.64327 140.569 8.35298 140.482 9.22844H147.373C147.286 8.35298 146.926 7.64327 146.29 7.09932C145.655 6.55536 144.864 6.28306 143.917 6.2824C142.983 6.2824 142.199 6.5547 141.565 7.09932Z"
        fill="#2D3748"
      />
      <path
        d="M156.796 13.6387C156.53 13.8501 156.218 14.0078 155.881 14.1014C155.514 14.2085 155.131 14.2625 154.745 14.2612C153.833 14.2612 153.131 14.0366 152.639 13.5874C152.147 13.1381 151.9 12.5044 151.9 11.6862V6.33277H150.168V5.32346H151.9V3.38574H153.215V5.32346H156.171V6.33277H153.215V11.6181C153.215 12.1443 153.359 12.5455 153.648 12.8218C153.937 13.098 154.353 13.2355 154.896 13.2342C155.163 13.2345 155.428 13.1948 155.681 13.1168C155.919 13.0435 156.139 12.9293 156.33 12.7803L156.796 13.6387Z"
        fill="#2D3748"
      />
      <path
        d="M155.773 1.70898H157.092V1.93689H156.591V3.01231H156.275V1.94084H155.773V1.70898ZM158.697 3.00836V2.18749L158.215 2.86431H158.071L157.586 2.20328V3.00836H157.287V1.70898H157.545L158.15 2.54169L158.745 1.70898H158.999V3.00836H158.697Z"
        fill="#2D3748"
      />
      <path
        d="M20.5346 6.02627H22.436C22.564 6.02627 22.6907 6.0033 22.8089 5.95868C22.9272 5.91405 23.0346 5.84865 23.1251 5.76619C23.2156 5.68374 23.2874 5.58585 23.3364 5.47812C23.3853 5.37039 23.4105 5.25492 23.4105 5.13831V0.887957C23.4105 0.652456 23.3079 0.426601 23.1251 0.260076C22.9423 0.0935522 22.6945 0 22.436 0H0.974537C0.716073 0 0.468196 0.0935522 0.285435 0.260076C0.102674 0.426601 0 0.652456 0 0.887957V15.1111C0 15.3466 0.102674 15.5724 0.285435 15.7389C0.468196 15.9055 0.716073 15.999 0.974537 15.999H22.4349C22.6934 15.999 22.9413 15.9055 23.124 15.7389C23.3068 15.5724 23.4095 15.3466 23.4095 15.1111V10.8607C23.4095 10.6252 23.3068 10.3993 23.124 10.2328C22.9413 10.0663 22.6934 9.97274 22.4349 9.97274H20.4858C20.1947 9.97292 19.9065 9.9196 19.6384 9.81596C19.3704 9.71232 19.128 9.5605 18.9259 9.36957C18.7237 9.17864 18.5659 8.95253 18.4618 8.70475C18.3577 8.45697 18.3096 8.19261 18.3202 7.92748C18.3603 6.85897 19.3608 6.02627 20.5346 6.02627ZM11.0783 12.8142C8.66255 12.8142 8.66363 10.7078 8.66363 10.7078V9.52383C11.0783 9.52383 11.0783 11.7931 11.0783 11.7931V12.8142ZM11.0783 8.84307C8.65822 8.84307 8.66363 6.43868 8.66363 6.43868V3.16902C11.0783 3.16902 11.0783 5.43824 11.0783 5.43824V8.84307ZM12.0529 5.43726C12.0529 5.43726 12.0529 3.16803 14.4675 3.16803V4.35198C14.4675 4.35198 14.4675 6.45841 12.0529 6.45841V5.43726ZM14.5109 10.6871C14.5109 10.6871 14.5109 12.7935 12.0951 12.7935V9.39064C12.0951 9.39064 12.0951 7.12142 14.5109 7.12142V10.6871Z"
        fill="#F2BC23"
      />
      <path
        d="M22.413 8.88724H20.4823C20.2239 8.88724 19.976 8.79369 19.7932 8.62717C19.6105 8.46064 19.5078 8.23479 19.5078 7.99929C19.5078 7.76378 19.6105 7.53793 19.7932 7.3714C19.976 7.20488 20.2239 7.11133 20.4823 7.11133H22.413C22.6715 7.11133 22.9194 7.20488 23.1021 7.3714C23.2849 7.53793 23.3876 7.76378 23.3876 7.99929C23.3876 8.23479 23.2849 8.46064 23.1021 8.62717C22.9194 8.79369 22.6715 8.88724 22.413 8.88724Z"
        fill="#2D3748"
      />
    </>
  ),
});
