export default async function getUserInfo({
  baseUrl,
  realm,
  token,
}: {
  baseUrl?: string;
  realm?: string;
  token?: string;
}) {
  const response = await fetch(
    `${baseUrl}/realms/${realm}/protocol/openid-connect/userinfo`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    }
  );
  return response.json() as Promise<UserInfo>;
}

export type UserInfo = {
  sub: string;
  email_verified: boolean;
  bushel_id: string;
  preferred_username: string;
  email: string;
  multi_factor_enabled?: boolean;
  multi_factor_method?: string;
};
