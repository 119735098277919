/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The address representation
 * @export
 * @interface AddressDtoResponse
 */
export interface AddressDtoResponse {
  /**
   *
   * @type {string}
   * @memberof AddressDtoResponse
   */
  streetNumber1: string;
  /**
   *
   * @type {string}
   * @memberof AddressDtoResponse
   */
  streetNumber2?: string;
  /**
   *
   * @type {string}
   * @memberof AddressDtoResponse
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof AddressDtoResponse
   */
  stateProvinceRegion: string;
  /**
   *
   * @type {string}
   * @memberof AddressDtoResponse
   */
  postalCode: string;
}

/**
 * Check if a given object implements the AddressDtoResponse interface.
 */
export function instanceOfAddressDtoResponse(
  value: object
): value is AddressDtoResponse {
  if (!('streetNumber1' in value) || value['streetNumber1'] === undefined)
    return false;
  if (!('city' in value) || value['city'] === undefined) return false;
  if (
    !('stateProvinceRegion' in value) ||
    value['stateProvinceRegion'] === undefined
  )
    return false;
  if (!('postalCode' in value) || value['postalCode'] === undefined)
    return false;
  return true;
}

export function AddressDtoResponseFromJSON(json: any): AddressDtoResponse {
  return AddressDtoResponseFromJSONTyped(json, false);
}

export function AddressDtoResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddressDtoResponse {
  if (json == null) {
    return json;
  }
  return {
    streetNumber1: json['streetNumber1'],
    streetNumber2:
      json['streetNumber2'] == null ? undefined : json['streetNumber2'],
    city: json['city'],
    stateProvinceRegion: json['stateProvinceRegion'],
    postalCode: json['postalCode'],
  };
}

export function AddressDtoResponseToJSON(
  value?: AddressDtoResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    streetNumber1: value['streetNumber1'],
    streetNumber2: value['streetNumber2'],
    city: value['city'],
    stateProvinceRegion: value['stateProvinceRegion'],
    postalCode: value['postalCode'],
  };
}
