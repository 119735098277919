import { useMediaQuery, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useFundingSourcesClient from './useFundingSourcesClient';
import { FUNDING_SOURCE_LIST_QUERY_KEY } from './useFundingSourceList';
import { v4 as uuid } from 'uuid';

export default function useRemoveFundingSource() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet id is not defined');
  }
  const fundingSourceClient = useFundingSourcesClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    position: isMobile ? 'top' : 'bottom',
    isClosable: true,
  });
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['remove-funding-source'],
    mutationFn: (fundingSourceId: string) =>
      fundingSourceClient.removeFundingSource({
        removeFundingSourceRequest: { fundingSourceId },
        idempotencyKey: uuid(),
        xWalletId: walletId,
      }),
    onError: () => {
      toast({
        status: 'error',
        title: 'Failed to Unlink Account',
        description: 'There was an error when trying to unlink the account.',
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY],
      });
      toast({
        status: 'success',
        title: 'Successfully Unlinked Account',
        description: 'The account has been unlinked.',
      });
    },
  });
}
