/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import { VerifyMicroDepositsFromJSONTyped } from './VerifyMicroDeposits';
/**
 * Action items.
 * @export
 * @interface ActionItem
 */
export interface ActionItem {
  /**
   *
   * @type {string}
   * @memberof ActionItem
   */
  type: string;
}

/**
 * Check if a given object implements the ActionItem interface.
 */
export function instanceOfActionItem(value: object): value is ActionItem {
  if (!('type' in value) || value['type'] === undefined) return false;
  return true;
}

export function ActionItemFromJSON(json: any): ActionItem {
  return ActionItemFromJSONTyped(json, false);
}

export function ActionItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ActionItem {
  if (json == null) {
    return json;
  }
  if (!ignoreDiscriminator) {
    if (json['type'] === 'verify-micro-deposits') {
      return VerifyMicroDepositsFromJSONTyped(json, true);
    }
  }
  return {
    type: json['type'],
  };
}

export function ActionItemToJSON(value?: ActionItem | null): any {
  if (value == null) {
    return value;
  }
  return {
    type: value['type'],
  };
}
