import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Heading,
  Icon,
  Image,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  Text,
  Spinner,
  VStack,
  Center,
  Link,
} from '@chakra-ui/react';
import {
  CompeerLoanFundingSourceDetailsDto,
  TransactionDetailsDto,
  TransactionDetailsDtoMoneySentOrReceivedEnum,
} from 'api/wallet-app';
import currency from 'currency.js';
import { useNavigate } from 'react-router-dom';
import { getMMNumericDayYY } from 'utils/date-utils';

export const LiabilityContextMenuContent = ({
  fundingSourceDetails,
  paymentsOnOpen,
  loanDetailsOnOpen,
  lastUpdated,
}: {
  fundingSourceDetails?: CompeerLoanFundingSourceDetailsDto;
  paymentsOnOpen: () => void;
  loanDetailsOnOpen: () => void;
  lastUpdated: string;
}) => {
  const navigate = useNavigate();

  return (
    <Flex direction="column">
      {!fundingSourceDetails ? (
        <Center height="200px" width="100%">
          <Spinner color="blue.400" />
        </Center>
      ) : (
        <>
          <Center
            {...(fundingSourceDetails.providerUrl
              ? {
                  as: Link,
                  href: fundingSourceDetails.providerUrl,
                  isExternal: true,
                }
              : {})}
          >
            {fundingSourceDetails.providerBanner ? (
              <Image
                src={atob(fundingSourceDetails.providerBanner)}
                mt="2rem"
                maxW="240px"
                maxH="80px"
              />
            ) : (
              <Text>{fundingSourceDetails.providerName}</Text>
            )}
          </Center>
          <Flex
            mt="1.5rem"
            flexDirection="column"
            padding="1rem"
            boxShadow="0px 0px 2px 0px rgba(45, 55, 72, 0.15), 0px 2px 3px 0px rgba(45, 55, 72, 0.20)"
            borderRadius="8px"
          >
            <Heading size="sm">Payment Info</Heading>
            <VStack alignItems="flex-start" mt="1rem">
              <Flex justifyContent="space-between" width="100%">
                <Text color="#69788A">Loan Maturity Date</Text>
                <Text>
                  {getMMNumericDayYY(
                    new Date(fundingSourceDetails.maturityDate ?? '')
                  )}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" width="100%">
                <Text color="#69788A">Current Principal Balance</Text>
                <Text>
                  {currency(
                    fundingSourceDetails.currentBalance?.toString() ?? ''
                  ).format()}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" width="100%">
                <Text color="#69788A">Interest Accrued to Date</Text>
                <Text>
                  {currency(
                    fundingSourceDetails.interestAccruedToDate?.toString() ?? ''
                  ).format()}
                </Text>
              </Flex>
            </VStack>
            <Button
              mt="1.5rem"
              colorScheme="blue"
              _focus={{ outline: 'none' }}
              _active={{ outline: 'none' }}
              onClick={paymentsOnOpen}
            >
              Pay My Loan
            </Button>
          </Flex>

          <Flex
            mt="1.5rem"
            flexDirection="column"
            padding="1rem"
            boxShadow="0px 0px 2px 0px rgba(45, 55, 72, 0.15), 0px 2px 3px 0px rgba(45, 55, 72, 0.20)"
            borderRadius="8px"
          >
            <Flex direction="column">
              <Heading>
                {currency(
                  fundingSourceDetails.availableCredit?.toString() ?? ''
                ).format()}
              </Heading>
              <Text mt="4px" fontSize="14px" color="#69788A">
                Available Credit
              </Text>
            </Flex>

            <Flex justifyContent="space-between" mt="1rem">
              <Flex direction="column">
                <Text fontSize="14px" color="#69788A">
                  Current Principal Balance
                </Text>
                <Text fontSize="18px" fontWeight="bold" fontFamily="heading">
                  {currency(
                    fundingSourceDetails.creditUsed?.toString() ?? ''
                  ).format()}
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fontSize="14px" color="#69788A">
                  Original Loan Amount
                </Text>
                <Text fontSize="18px" fontWeight="bold" fontFamily="heading">
                  {currency(
                    fundingSourceDetails.originalAmount?.toString() ?? ''
                  ).format()}
                </Text>
              </Flex>
            </Flex>
            <Button
              mt="1.5rem"
              variant="secondary"
              onClick={loanDetailsOnOpen}
              _focus={{ outline: 'none' }}
              _active={{ outline: 'none' }}
            >
              Loan Details
            </Button>
          </Flex>

          <Flex
            mt="1.2rem"
            width="100%"
            color="#69788A"
            fontSize="14px"
            justifyContent="center"
          >
            Loan data updated {lastUpdated}
          </Flex>
          <Flex direction="column">
            <Flex
              mt="1.5rem"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading size="md">Recent Wallet Transactions</Heading>
              <Popover>
                <PopoverTrigger>
                  <Icon as={InfoOutlineIcon} cursor="pointer" />
                </PopoverTrigger>
                <PopoverContent
                  borderRadius="4px"
                  width="225px"
                  padding=".5rem"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.16)"
                  _focus={{
                    outline: 'none',
                  }}
                >
                  <PopoverArrow />
                  <PopoverBody
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    fontSize="14px"
                  >
                    Only transactions made with your Wallet account will appear
                    here
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
            <Flex mt=".5rem" flexDirection="column">
              <VStack alignItems="flex-start" divider={<Divider />} mt="1rem">
                {fundingSourceDetails.recentTransactions.length ? (
                  fundingSourceDetails.recentTransactions.map((transaction) => {
                    return (
                      <SingleTransaction
                        key={transaction.id}
                        transaction={transaction}
                      />
                    );
                  })
                ) : (
                  <div>No transactions</div>
                )}
              </VStack>
              <Button
                variant="secondary"
                mt="1rem"
                mb="3rem"
                onClick={() => navigate('/activity')}
              >
                View Wallet History
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

const SingleTransaction = ({
  transaction,
}: {
  transaction: TransactionDetailsDto;
}) => {
  const getTransactionType = (
    type?: TransactionDetailsDtoMoneySentOrReceivedEnum
  ) => {
    switch (type) {
      case TransactionDetailsDtoMoneySentOrReceivedEnum.Received:
        return 'Money Sent';
      default:
        return 'Loan Payment';
    }
  };

  return (
    <Flex direction="column" width="100%" fontSize="14px">
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          fontWeight="bold"
          width="250px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {transaction.source}
        </Text>
        <Text color="gray.400">
          {getMMNumericDayYY(new Date(transaction.initiatedAt ?? ''))}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mt="5px" alignItems="center">
        <Text color="gray.400">
          {getTransactionType(transaction.moneySentOrReceived)}
        </Text>
        <Text fontWeight="bold" fontSize="16px">
          {transaction.moneySentOrReceived ===
          TransactionDetailsDtoMoneySentOrReceivedEnum.Received
            ? ''
            : '-'}
          {currency(transaction.amount?.toString() ?? '').format()}
        </Text>
      </Flex>
    </Flex>
  );
};
