import { Spacer, Stack, useMediaQuery } from '@chakra-ui/react';
import { Alerts, Transactions } from './components';
import { WalletBalanceHeader } from '../Accounts/components';

export default function Home() {
  const [isTablet] = useMediaQuery('(min-width: 768px)');

  return (
    <Stack spacing={{ base: 4, md: 6 }} pb={!isTablet ? '150px' : 4}>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={6}>
        <WalletBalanceHeader />
        {isTablet && <Spacer flex={3} />}
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align="flex-start"
        spacing={6}
      >
        <Alerts />
        <Transactions />
      </Stack>
    </Stack>
  );
}
