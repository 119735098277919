import React from 'react';
import { To, useNavigate } from 'react-router-dom';
import { Button, Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  FundingSourceDto,
  GetActiveFundingSourcesTypeEnum,
} from 'api/wallet-app';
import { useFundingSourceList } from 'hooks';

export const ManageLoan = () => {
  const navigate = useNavigate();
  const [selectedLiability, setSelectedLiability] = React.useState<
    FundingSourceDto | undefined
  >();

  const { data: liabilityFundingSources, isLoading } = useFundingSourceList({
    transactional: true,
    type: GetActiveFundingSourcesTypeEnum.Liability,
  });

  const handleSelectedLiability = (liability: FundingSourceDto) => {
    setSelectedLiability(liability);
  };

  const handleContinue = () => {
    if (!selectedLiability) return;
    navigate(selectedLiability.fundingSourceId, {
      state: { liability: { ...selectedLiability } },
    });
  };

  return isLoading ? (
    <Flex direction="column" minHeight="60vh" justifyContent="space-between">
      <Header />
      <Flex
        mt="3rem"
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner color="blue.400" size="lg" />
      </Flex>
      <Flex />
    </Flex>
  ) : (
    <Flex direction="column" minHeight="60vh" justifyContent="space-between">
      <div>
        <Header />
        <VStack mt="1rem" spacing="1rem">
          {liabilityFundingSources?.fundingSources &&
            liabilityFundingSources?.fundingSources.map(
              (fundingSource, index) => (
                <SingleLiability
                  key={index}
                  source={fundingSource}
                  handleSelectedLiability={handleSelectedLiability}
                  enabled={fundingSource.isVerified}
                  selectedLiability={selectedLiability}
                />
              )
            )}
        </VStack>
      </div>
      <Button
        mt="1rem"
        width="100%"
        colorScheme="blue"
        onClick={handleContinue}
        disabled={!selectedLiability}
      >
        Continue
      </Button>
    </Flex>
  );
};

export const Header = ({
  to = '/transfers',
  title = 'Select Account',
}: {
  to?: To;
  title?: string;
}) => {
  const navigate = useNavigate();
  return (
    <Flex justifyContent="space-between">
      <ArrowBackIcon
        mb="10px"
        fontWeight="bold"
        ml="8px"
        fontSize="1.5rem"
        onClick={() => navigate(to)}
        cursor="pointer"
      />
      {title}
      <Flex width="40px" />
    </Flex>
  );
};

const SingleLiability = ({
  source,
  enabled,
  selectedLiability,
  handleSelectedLiability,
}: {
  source: FundingSourceDto;
  enabled: boolean;
  selectedLiability: FundingSourceDto | undefined;
  handleSelectedLiability: (liability: FundingSourceDto) => void;
}) => {
  const handleClick = () => {
    if (!enabled) return;
    handleSelectedLiability(source);
  };

  return (
    <Flex
      border="solid 1px #E2E8F0"
      padding="1rem"
      borderRadius="8px"
      width="100%"
      onClick={handleClick}
      _hover={
        enabled
          ? { cursor: 'pointer', backgroundColor: 'gray.50' }
          : { cursor: 'not-allowed' }
      }
      opacity={enabled ? 1 : 0.5}
      {...(selectedLiability?.fundingSourceId === source.fundingSourceId && {
        borderColor: 'blue.400',
        background: 'gray.50',
      })}
    >
      <Flex direction="column" width="60%">
        <Text fontWeight="bold">{source.name}</Text>
        <Text>{source.financialInstitutionName}</Text>
      </Flex>
      <Flex direction="column" width="40%">
        {!source.isVerified && <Text>Pending</Text>}
      </Flex>
    </Flex>
  );
};
