import { PropsWithChildren, useMemo } from 'react';
import { Tooltip, TooltipProps, useMediaQuery } from '@chakra-ui/react';
import { UserListItemTypeEnum } from 'api/wallet-app';
import { useUserInfo } from 'hooks/api';

const TOOLTIP_LABEL =
  'You do not have permission to manage linked accounts. Only the account admin can manage linked accounts.';

export function AuthorizedUserTooltip(props: PropsWithChildren<TooltipProps>) {
  const { children, ...rest } = props;
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  const { data: userInfo, isLoading: userInfoIsLoading } = useUserInfo();

  const isAuthorizedUser = useMemo(
    () => userInfo?.type === UserListItemTypeEnum.AuthorizedUser,
    [userInfo?.type]
  );

  return (
    <Tooltip
      hasArrow
      // Keeps tooltip from displaying when user clicks on mobile device
      shouldWrapChildren={!isMobile ? true : false}
      // This keeps the tooltip from displaying when the user is a Wallet Owner
      label={isAuthorizedUser && !userInfoIsLoading ? TOOLTIP_LABEL : ''}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
