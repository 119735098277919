import { Box, Button, Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import currency from 'currency.js';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';
import {
  CompeerLoanFundingSourceDetailsDto,
  FundingSourceDto,
} from 'api/wallet-app';

export const ChooseFundingSource = () => {
  const { send } = LoanPaymentContext.useActorRef();
  const {
    paymentSource,
    walletBalance,
    allFundingSources,
    fundingSourceId,
    paymentAmount,
  } = LoanPaymentContext.useSelector((state) => state.context);

  const setPaymentSource = (
    paymentSource: CompeerLoanFundingSourceDetailsDto | FundingSourceDto
  ) => {
    send({ type: 'NEXT', paymentSource: paymentSource });
  };

  const walletHasInsufficientFunds =
    parseFloat(walletBalance) < parseFloat(paymentAmount);

  return (
    <Flex direction="column">
      <VStack spacing="16px" mt="2.5rem">
        <Box width="100%">
          <Flex
            justifyContent="space-between"
            borderRadius="8px"
            width="100%"
            border="solid 1px"
            borderColor={
              paymentSource?.name === 'wallet' ? 'blue.500' : 'gray.300'
            }
            background={paymentSource?.name === 'wallet' ? '#E0F3FF' : '#fff'}
            color="black"
            padding="1rem"
            cursor={walletHasInsufficientFunds ? 'not-allowed' : 'pointer'}
            _hover={
              walletHasInsufficientFunds
                ? { borderColor: 'gray.300' }
                : { borderColor: 'blue.400' }
            }
            opacity={walletHasInsufficientFunds ? 0.5 : 1}
            onClick={() => {
              if (walletHasInsufficientFunds) {
                return;
              }
              // @ts-ignore
              return setPaymentSource({
                name: 'wallet',
                fundingSourceId: '',
                financialInstitutionName: 'wallet',
                accountNumber: '000',
                activeTransactions: false,
                isVerified: true,
                needsUserValidation: false,
                verificationFailed: false,
              });
            }}
          >
            <Text fontWeight="bold" data-account="wallet">
              Wallet Balance
            </Text>
            <Text>{currency(walletBalance).format()}</Text>
          </Flex>
          {walletHasInsufficientFunds && (
            <Text
              color="red.500"
              fontSize="14px"
              alignSelf="flex-start"
              mt="5px"
            >
              Your wallet balance does not cover this transaction
            </Text>
          )}
        </Box>
        {!allFundingSources && (
          <Flex minHeight="170px" alignItems="center" justifyContent="center">
            <Spinner size="xl" thickness="4px" color="blue.400" />
          </Flex>
        )}
        {allFundingSources &&
          allFundingSources.length &&
          allFundingSources
            .filter(
              (fundingSource) =>
                fundingSource.fundingSourceId !== fundingSourceId
            )
            .map((fundingSource, index: number) => {
              const isCurrentSource =
                paymentSource?.fundingSourceId ===
                fundingSource.fundingSourceId;
              const plannedAmount = paymentAmount;
              const isLiability = fundingSource.type === 'LIABILITY';
              const liabilityIsOverLimit =
                isLiability &&
                fundingSource.availableFunds &&
                parseFloat(plannedAmount) >
                  parseFloat(fundingSource.availableFunds);

              return (
                <Flex width="100%" direction="column">
                  <Flex
                    opacity={liabilityIsOverLimit ? 0.6 : 1}
                    key={fundingSource.fundingSourceId}
                    justifyContent="space-between"
                    border="solid 1px"
                    borderRadius="8px"
                    borderColor={isCurrentSource ? 'blue.400' : 'gray.300'}
                    backgroundColor={isCurrentSource ? '#E0F3FF' : '#fff'}
                    padding="1rem"
                    width="100%"
                    data-account={fundingSource.accountNumber}
                    cursor={liabilityIsOverLimit ? 'not-allowed' : 'pointer'}
                    onClick={() =>
                      !liabilityIsOverLimit && setPaymentSource(fundingSource)
                    }
                    _hover={
                      liabilityIsOverLimit ? {} : { borderColor: 'blue.400' }
                    }
                  >
                    <Flex flexDirection="column" maxWidth="70%">
                      <Text fontWeight="bold">
                        {fundingSource.financialInstitutionName}
                      </Text>
                      <Text
                        fontSize="14px"
                        color="gray.500"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {fundingSource.name}
                      </Text>
                    </Flex>
                    <Flex flexDirection="column">
                      <Text color="gray.500">
                        **** {fundingSource.accountNumber}
                      </Text>
                      <Text textAlign="end" color="blue.400" fontSize="14px">
                        {index == 0 && 'Default'}
                      </Text>
                    </Flex>
                  </Flex>
                  {liabilityIsOverLimit && (
                    <Text
                      color="red.500"
                      fontSize="14px"
                      alignSelf="flex-start"
                      mt="5px"
                    >
                      Your line of credit does not cover this transaction
                    </Text>
                  )}
                </Flex>
              );
            })}
      </VStack>
      <Button
        disabled={!paymentSource}
        mt="3rem"
        onClick={() => send({ type: 'NEXT' })}
      >
        Continue
      </Button>
    </Flex>
  );
};
