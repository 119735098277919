import {
  AttachCompeerLoanRequest,
  CreateFundingSourceRequest,
  FundingSourceDtoTypeEnum,
  GetActiveFundingSourceResponse,
  GetFundingSourceDetailsRequest,
  GetFundingSourceResponse,
  GetLendersDetailsRequest,
  GetLendersDetailsResponse,
  GetLendersListResponse,
  GetLinkTokenResponse,
  LenderDetailsDtoLoanAttachmentStrategiesEnum,
  PlaidLinkTokenRequest,
  RemoveFailedFundingSourceRequest,
  RemoveFundingSourceRequest,
  RemoveFundingSourcesResponse,
  RenameFundingSourceRequest,
  RenameFundingSourceResponse,
  ReorderFundingSourcesRequest,
  ReorderFundingSourcesResponse,
} from 'api/wallet-app';
import { http, HttpResponse, PathParams } from 'msw';
import {
  commandsBasePath,
  mockedRequestsRestApiBasePath,
  walletBasePath,
} from 'mocks/helpers';
import {
  compeerFundingSourceDetails,
  fundingSourceList,
  lenderList,
  plaidFundingSourceDetails,
  WALLET_ID,
} from 'mocks/fixtures';

const fundingSourcesHandlers = [
  /*
   * GET /api/v1/wallet/funding-sources
   * Generated API Endpoint Name: getActiveFundingSources
   */
  http.get<PathParams, undefined, GetActiveFundingSourceResponse>(
    walletBasePath('funding-sources'),
    ({ request }) => {
      const url = new URL(request.url);
      const transactional = url.searchParams.get('transactional') === 'true';
      const type = url.searchParams.get('type');
      return HttpResponse.json({
        fundingSources: fundingSourceList.filter((fundingSource) => {
          let shouldReturn = true;
          if (transactional) {
            shouldReturn = fundingSource.isVerified;
          }
          if (type && type !== fundingSource.type) {
            shouldReturn = false;
          }
          return shouldReturn;
        }),
      });
    }
  ),

  /*
   * GET /api/v1/wallet/funding-sources/{fundingSourceId}
   * Generated API Endpoint Name: getFundingSourceDetails
   */
  http.get<
    Pick<GetFundingSourceDetailsRequest, 'fundingSourceId'>,
    undefined,
    GetFundingSourceResponse
  >(walletBasePath('funding-sources/:fundingSourceId'), ({ params }) => {
    const fundingSource = fundingSourceList.find(
      (fundingSource) =>
        fundingSource.fundingSourceId === params.fundingSourceId
    );

    if (!fundingSource) {
      throw new HttpResponse(null, { status: 404 });
    } else {
      if (fundingSource.type === FundingSourceDtoTypeEnum.Plaid) {
        return HttpResponse.json({
          fundingSourceId: fundingSource.fundingSourceId,
          hasActiveTransactions: true,
          details: plaidFundingSourceDetails,
          walletId: WALLET_ID,
        });
      } else {
        return HttpResponse.json({
          fundingSourceId: fundingSource.fundingSourceId,
          hasActiveTransactions: true,
          details: compeerFundingSourceDetails,
          walletId: WALLET_ID,
        });
      }
    }
  }),

  /*
   * GET /api/v1/lenders
   * Generated API Endpoint Name: getLendersList
   */
  http.get<PathParams, undefined, GetLendersListResponse>(
    mockedRequestsRestApiBasePath({ path: 'lenders' }),
    () => {
      return HttpResponse.json({ lenders: lenderList });
    }
  ),

  /*
   * GET /api/v1/lenders/{id}
   * Generated API Endpoint Name: getLendersDetails
   */
  http.get<GetLendersDetailsRequest, undefined, GetLendersDetailsResponse>(
    mockedRequestsRestApiBasePath({ path: 'lenders/:id' }),
    ({ params }) => {
      const lender = lenderList.find((lender) => lender.id === params.id);

      if (!lender) {
        throw new HttpResponse(null, { status: 404 });
      } else {
        return HttpResponse.json({
          details: {
            id: lender.id,
            name: lender.name,
            byline: lender.byline,
            icon: lender.icon,
            loanAttachmentStrategies: lender.loanAttachmentStrategies.map(
              (las) =>
                las as unknown as LenderDetailsDtoLoanAttachmentStrategiesEnum
            ),
          },
        });
      }
    }
  ),

  /*
   * POST /api/v1/commands/reorder-funding-sources
   * Generated API Endpoint Name: reorderFundingSources
   */
  http.post<
    PathParams,
    ReorderFundingSourcesRequest,
    ReorderFundingSourcesResponse
  >(commandsBasePath('reorder-funding-sources'), async ({ request }) => {
    const { fundingSourceIds } = await request.json();

    const reorderedList = fundingSourceList
      .sort((a, b) => {
        return (
          fundingSourceIds.indexOf(a.fundingSourceId) -
          fundingSourceIds.indexOf(b.fundingSourceId)
        );
      })
      .map((fundingSource) => {
        return fundingSource.fundingSourceId;
      });

    return HttpResponse.json({ fundingSourceIds: reorderedList });
  }),

  /*
   * POST /api/v1/commands/rename-funding-source
   * Generated API Endpoint Name: renameFundingSource
   */
  http.post<
    PathParams,
    RenameFundingSourceRequest,
    RenameFundingSourceResponse
  >(commandsBasePath('rename-funding-source'), async ({ request }) => {
    const { friendlyName, fundingSourceId } = await request.json();
    const index = fundingSourceList.findIndex(
      (fundingSource) => fundingSource.fundingSourceId === fundingSourceId
    );

    if (index === -1) {
      throw new HttpResponse(null, { status: 404 });
    } else {
      fundingSourceList[index] = {
        ...fundingSourceList[index],
        name: friendlyName,
      };
      return HttpResponse.json({ friendlyName, fundingSourceId });
    }
  }),

  /*
   * POST /api/v1/commands/remove-funding-source
   * Generated API Endpoint Name: removeFundingSource
   */
  http.post<
    PathParams,
    RemoveFundingSourceRequest,
    RemoveFundingSourcesResponse
  >(commandsBasePath('remove-funding-source'), async ({ request }) => {
    const { fundingSourceId } = await request.json();

    const index = fundingSourceList.findIndex(
      (fundingSource) => fundingSource.fundingSourceId === fundingSourceId
    );

    if (index === -1) {
      throw new HttpResponse(null, { status: 404 });
    } else {
      fundingSourceList.splice(index, 1);
      return HttpResponse.json({ fundingSourceId });
    }
  }),

  /*
   * POST /api/v1/commands/remove-failed-funding-source
   * Generated API Endpoint Name: removeFailedFundingSource
   */
  http.post<PathParams, RemoveFailedFundingSourceRequest, undefined>(
    commandsBasePath('remove-failed-funding-source'),
    async ({ request }) => {
      const { fundingSourceId } = await request.json();

      const index = fundingSourceList.findIndex(
        (fundingSource) => fundingSource.fundingSourceId === fundingSourceId
      );

      if (index === -1) {
        throw new HttpResponse(null, { status: 404 });
      } else {
        fundingSourceList.splice(index, 1);
        return new HttpResponse(null, { status: 204 });
      }
    }
  ),

  /*
   * POST /api/v1/commands/generate-plaid-link-token
   * Generated API Endpoint Name: generatePlaidLinkToken
   */
  http.post<PathParams, PlaidLinkTokenRequest, GetLinkTokenResponse>(
    commandsBasePath('generate-plaid-link-token'),
    async ({ request }) => {
      const { fundingSourceId } = await request.json();

      if (
        !fundingSourceList.some(
          (fundingSource) => fundingSource.fundingSourceId === fundingSourceId
        )
      ) {
        throw new HttpResponse(null, { status: 404 });
      } else {
        return HttpResponse.json({
          linkToken: 'link-sandbox-aeb1ba29-9229-4259-a1eb-8ab7946582d6',
        });
      }
    }
  ),

  /*
   * POST /api/v1/commands/attach-compeer-loan
   * Generated API Endpoint Name: attachCompeerLoan
   */
  http.post<PathParams, AttachCompeerLoanRequest, undefined>(
    commandsBasePath('attach-compeer-loan'),
    () => {
      return new HttpResponse(null, { status: 204 });
    }
  ),

  /*
   * POST /api/v1/commands/add-funding-source
   * Generated API Endpoint Name: addFundingSource
   */
  http.post<PathParams, CreateFundingSourceRequest, undefined>(
    commandsBasePath('add-funding-source'),
    () => {
      return new HttpResponse(null, { status: 204 });
    }
  ),
];

export default fundingSourcesHandlers;
