import { useParams, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PanelWrapper } from 'components/layout/onboarding';
import currency from 'currency.js';
import { useTransactionDetails } from 'hooks/api';
import {
  dateAsmMdDYyyy,
  displayFundingSource,
  getTransactionType,
  transactionDetailsHeaderText,
  transactionStatusBadgeColor,
  transactionAmountTextDecoration,
  transactionAmountTextColor,
  transactionAmountSymbol,
  transactionDateLabel,
  transactionMemoLabel,
} from 'utils';
import { ReactElement, useMemo } from 'react';
import { CenteredSpinner } from 'components/CenteredSpinner';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  TRANSACTION_HISTORY_SEARCH_PARAM,
} from '../activity-page-search-params';
import { ProfileOverlay } from 'components/ProfileOverlay';
import {
  TransactionDetailsDtoMoneySentOrReceivedEnum,
  TransactionDetailsDtoStatusEnum,
  TransactionDetailsDtoTypeEnum,
  TransactionMetadataResponse,
} from 'api/wallet-app';

export default function TransactionDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isSuccess } = useTransactionDetails(id as string);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const transactionSymbol = useMemo(() => {
    return transactionAmountSymbol(data?.transactionDetails);
  }, [data?.transactionDetails]);

  return (
    <Flex
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      background="white"
      zIndex={9}
      padding="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
    >
      <PanelWrapper>
        {isLoading ? (
          <CenteredSpinner centerProps={{ h: '50vh' }} />
        ) : (
          <Stack spacing={7}>
            <CloseButton
              onClick={() =>
                navigate(
                  `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${TRANSACTION_HISTORY_SEARCH_PARAM}`
                )
              }
            />
            {isSuccess ? (
              <>
                <Stack spacing={4}>
                  <Stack>
                    <HStack spacing={1}>
                      {transactionDetailsHeaderText({
                        transaction: data?.transactionDetails,
                        onClick: () => {
                          onOpen();
                        },
                      })}
                    </HStack>
                    <Heading
                      as="h2"
                      size="2xl"
                      color={transactionAmountTextColor(
                        data?.transactionDetails
                      )}
                      textDecor={transactionAmountTextDecoration(
                        data?.transactionDetails
                      )}
                    >
                      {`${transactionSymbol}${currency(
                        data?.transactionDetails?.amount ?? 0
                      ).format()}`}
                    </Heading>
                  </Stack>
                  <Stack spacing={6}>
                    <Badge
                      fontSize="sm"
                      w="fit-content"
                      colorScheme={transactionStatusBadgeColor(
                        data?.transactionDetails?.status
                      )}
                    >
                      {data?.transactionDetails?.status}
                    </Badge>
                    <DetailsSection transactionDetails={data} />
                  </Stack>
                </Stack>
                <Text fontSize="xs" color="#A0AEC0" textAlign="center">
                  Wallet Transaction ID: {data?.transactionDetails?.id}
                </Text>
                <ProfileOverlay
                  isOpen={isOpen}
                  onClose={onClose}
                  walletId={data?.transactionDetails?.walletId}
                />
              </>
            ) : (
              <>
                <Text>
                  You do not have permission to view this transaction.
                </Text>
                <Button onClick={() => navigate('/activity')}>
                  View Activity
                </Button>
              </>
            )}
          </Stack>
        )}
      </PanelWrapper>
    </Flex>
  );
}

function DetailItem({
  label,
  value,
}: {
  label: string;
  value?: ReactElement | string;
}) {
  return (
    <Stack spacing={1}>
      <Text color="textSecondary" fontSize="sm">
        {label}
      </Text>
      {typeof value === 'string' ? <Text>{value}</Text> : value}
    </Stack>
  );
}

function DetailsSection({
  transactionDetails,
}: {
  transactionDetails?: TransactionMetadataResponse;
}) {
  return (
    <Stack spacing={3}>
      <Text
        fontSize="xs"
        fontWeight="bold"
        letterSpacing="0.06rem"
        textTransform="uppercase"
      >
        Details
      </Text>
      <Stack spacing={3} divider={<Divider />}>
        {transactionDetails?.transactionDetails?.type !==
          TransactionDetailsDtoTypeEnum.PaymentRequest && (
          <DetailItem
            label={
              transactionDetails?.transactionDetails?.moneySentOrReceived ===
              TransactionDetailsDtoMoneySentOrReceivedEnum.Received
                ? 'To'
                : 'From'
            }
            value={displayFundingSource(
              transactionDetails?.transactionDetails?.moneySentOrReceived ===
                TransactionDetailsDtoMoneySentOrReceivedEnum.Received
                ? transactionDetails?.transactionDetails.destination
                : transactionDetails?.transactionDetails?.source
            )}
          />
        )}
        {transactionDetails?.link?.url && transactionDetails?.link?.title && (
          <DetailItem
            label="Item Paid"
            value={
              <Link
                href={transactionDetails.link.url}
                textTransform="none"
                textDecoration="none"
                ml="0"
                width="100%"
                isExternal={true}
                color="blue.500"
              >
                {transactionDetails.link.title}
              </Link>
            }
          />
        )}
        {transactionDetails?.transactionDetails?.secondaryMemo && (
          <DetailItem
            label="Request Memo"
            value={transactionDetails?.transactionDetails?.secondaryMemo}
          />
        )}
        {transactionDetails?.transactionDetails?.memo && (
          <DetailItem
            label={transactionMemoLabel(transactionDetails.transactionDetails)}
            value={transactionDetails?.transactionDetails?.memo}
          />
        )}
        <DetailItem
          label="Type"
          value={getTransactionType(transactionDetails?.transactionDetails)}
        />
        {transactionDetails?.transactionDetails?.requestedAt && (
          <DetailItem
            label="Request Date"
            value={dateAsmMdDYyyy(
              new Date(
                transactionDetails?.transactionDetails?.requestedAt ?? ''
              )
            )}
          />
        )}
        {transactionDetails?.transactionDetails?.type !==
          TransactionDetailsDtoTypeEnum.PaymentRequest && (
          <DetailItem
            label={
              transactionDetails?.transactionDetails?.type ===
              TransactionDetailsDtoTypeEnum.VanTransaction
                ? 'Pending Date'
                : 'Initiated Date'
            }
            value={dateAsmMdDYyyy(
              new Date(
                transactionDetails?.transactionDetails?.initiatedAt ?? ''
              )
            )}
          />
        )}
        {transactionDetails?.transactionDetails?.status !==
          TransactionDetailsDtoStatusEnum.Pending && (
          <DetailItem
            label={transactionDateLabel(
              transactionDetails?.transactionDetails?.status
            )}
            value={dateAsmMdDYyyy(
              new Date(transactionDetails?.transactionDetails?.updatedAt ?? '')
            )}
          />
        )}
      </Stack>
    </Stack>
  );
}
