import {
  Image,
  Stack,
  Heading,
  Text,
  HStack,
  Circle,
  Link,
  IconButton,
} from '@chakra-ui/react';
import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink, To } from 'react-router-dom';
import TransferIcon from 'images/transfer-and-pay/transfer-icon.svg';
import DepositIcon from 'images/transfer-and-pay/deposit-icon.svg';
import SendReceive from 'images/transfer-and-pay/send-receive.svg';
import Prepaid from 'images/transfer-and-pay/prepaid.svg';
import { useTranslation } from 'react-i18next';
import { useFundingSourceList } from 'hooks';
import { GetActiveFundingSourcesTypeEnum } from 'api/wallet-app';
import { CenteredSpinner } from 'components/CenteredSpinner';

export function Menu() {
  const { t } = useTranslation();
  const { data: liabilityFundingSources, isLoading } = useFundingSourceList({
    type: GetActiveFundingSourcesTypeEnum.Liability,
    transactional: true,
  });

  return (
    <Stack spacing={6}>
      <Stack pl={2} spacing={5}>
        <IconButton
          aria-label="Back button"
          icon={<CloseIcon boxSize={3} />}
          as={ReactRouterLink}
          to="/"
          variant="unstyled"
          height="fit-content"
          minWidth="fit-content"
          minW="fit-content"
          w="fit-content"
        />
        <Heading as="h3" size="xl">
          {t('wallet.transfers.transferAndPay')}
        </Heading>
      </Stack>
      {isLoading ? (
        <CenteredSpinner
          centerProps={{ h: '300px' }}
          spinnerProps={{ size: 'xl' }}
        />
      ) : (
        <Stack>
          <TransferLink
            to="deposit"
            title={t('wallet.transfers.depositInWallet')}
            icon={DepositIcon}
            text="Add money to your Wallet balance"
          />
          <TransferLink
            to="withdraw"
            title={t('wallet.transfers.transferToBank')}
            icon={TransferIcon}
            text="Move money to your linked bank accounts"
          />
          <TransferLink
            to="send-and-request"
            title="Send & Request"
            icon={SendReceive}
            text="Move money between other users"
          />
          {liabilityFundingSources?.fundingSources &&
            liabilityFundingSources.fundingSources.length > 0 && (
              <TransferLink
                to="manage-loan"
                title="Make Loan Payment"
                icon={Prepaid}
                text="Make a payment on your linked loan account"
              />
            )}
        </Stack>
      )}
    </Stack>
  );
}

function TransferLink({
  to,
  title,
  icon,
  text,
}: {
  to: To;
  title: string;
  icon: string;
  text: string;
}) {
  return (
    <Link
      as={ReactRouterLink}
      to={to}
      py={4}
      px={2}
      borderRadius="xl"
      _hover={{
        backgroundColor: 'blue.50',
        transition: 'all 0.4s ease',
      }}
      role="group"
    >
      <HStack justify="space-between" align="center">
        <HStack spacing={4}>
          <Circle
            backgroundColor="blue.400"
            size="50px"
            alignContent="center"
            _groupHover={{
              backgroundColor: 'blue.500',
            }}
            transition="all 0.4s ease"
          >
            <Image src={icon} boxSize={6} color="white" />
          </Circle>
          <Stack spacing={0}>
            <Heading as="h4" fontSize="xl" fontWeight="bold">
              {title}
            </Heading>
            <Text fontFamily="inter" fontSize="sm" color="textSecondary">
              {text}
            </Text>
          </Stack>
        </HStack>
        <ChevronRightIcon
          color="gray.500"
          boxSize={35}
          _groupHover={{ color: 'blue.500' }}
        />
      </HStack>
    </Link>
  );
}
