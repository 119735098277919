import { useMediaQuery, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useAlertsClient from './useAlertsClient';
import { ALERT_LIST_QUERY_KEY } from './useAlertList';

export default function useAcknowledgeAlert() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const alertsClient = useAlertsClient();
  const queryClient = useQueryClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  return useMutation({
    mutationKey: ['acknowledge-alert'],
    mutationFn: (alertId: string) => {
      return alertsClient.acknowledgeAlert({ xWalletId: walletId, alertId });
    },
    onError: () => {
      toast({ status: 'error', title: 'Failed to Dismiss Alert' });
    },
    onSuccess: () => {
      toast({ status: 'success', title: 'Dismissed Alert' });
      queryClient.refetchQueries({ queryKey: [ALERT_LIST_QUERY_KEY] });
    },
  });
}
