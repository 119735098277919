import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Heading,
  Image,
  Icon,
  Text,
  Stack,
  HStack,
} from '@chakra-ui/react';
import ContactImage from 'images/contact-us.svg';
import { IoCallOutline, IoMailOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

export default function ContactUsModalBody({
  onClose,
}: {
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Stack p={8} spacing={4}>
      <Icon
        as={CloseIcon}
        alignSelf="flex-start"
        cursor="pointer"
        width="12px"
        onClick={onClose}
        _focus={{
          outline: 'none',
        }}
      />
      <Stack spacing={4}>
        <Image src={ContactImage} />
        <Heading>{t('wallet.settings.contactUs')}</Heading>
        <Stack>
          <Button
            variant="secondary"
            textTransform="none"
            onClick={() => {
              window.location.href = 'tel:18664392888';
            }}
          >
            <HStack>
              <Icon as={IoCallOutline} fontSize="1.3rem" />
              <Text alignSelf="center">1-866-439-2888</Text>
            </HStack>
          </Button>
          <Button
            variant="secondary"
            textTransform="none"
            onClick={() => {
              window.location.href = 'mailto:support@bushelwallet.com';
            }}
          >
            <HStack>
              <Icon as={IoMailOutline} fontSize="1.3rem" />
              <Text alignSelf="center">support@bushelwallet.com</Text>
            </HStack>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
