import {
  Heading,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { PanelWrapper } from 'components/layout/onboarding';
import { SupportInfoButtons } from 'components/support-info-buttons';
import { useReviewState } from 'hooks';
import { UsersAndMagnifyingGlassIcon } from 'images/custom-icons/users-and-magnifying-glass';
import { WalletProductIcon } from 'images/custom-icons/wallet-product-logo';
import { usePollThenRefetchAppState } from 'hooks';
import { LinkIntegrationPartner } from 'pages/WalletApp/link-integration-partner';
import { IoLogOutOutline } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';

export default function Review() {
  const { isLoading: reviewStateIsLoading } = useReviewState();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  return token ? (
    <LinkIntegrationPartner token={token} />
  ) : (
    <PanelWrapper>
      {reviewStateIsLoading ? <CenteredSpinner /> : <ReviewScreen />}
    </PanelWrapper>
  );
}

function ReviewScreen() {
  const { keycloak } = useKeycloak();
  usePollThenRefetchAppState({
    refetchAppStateInterval: 5000,
    refetchQueryTimeDelay: 0,
    stateTypes: ['UNDER_REVIEW'],
  });

  return (
    <Stack spacing={16}>
      <HStack justify="space-between" spacing={0}>
        <Spacer maxW="24px" />
        <Spacer flex={1} />
        <Icon as={WalletProductIcon} h="16px" w="159px" />
        <Spacer flex={1} />
        <IconButton
          aria-label="logout-button"
          icon={<IoLogOutOutline fontSize="1.25rem" />}
          onClick={() => {
            keycloak.logout();
          }}
          size="icon"
          w="fit-content"
          h="24px"
          variant="unstyled"
          display="flex"
          alignItems="center"
          justifyContent="center"
        />
      </HStack>
      <Stack spacing={12}>
        <Stack spacing={6}>
          <Icon as={UsersAndMagnifyingGlassIcon} w="100%" h="160px" />
          <Stack align="center" textAlign="center">
            <Heading as="h3" fontSize="24px">
              Account Under Review
            </Heading>
            <Text>
              Your account is under further review. This process helps us
              protect your account.
            </Text>
            <Text>If you have any questions please contact support.</Text>
          </Stack>
        </Stack>
        <SupportInfoButtons />
      </Stack>
    </Stack>
  );
}
