import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMediaQuery, useToast } from '@chakra-ui/react';
import {
  NotAcceptablePaymentRequestResponse,
  NotAcceptablePaymentRequestResponseStatusEnum,
  ResponseError,
  SendMoneyRequest,
  SendMoneyResponse,
} from 'api/wallet-app';
import { useWalletStore } from 'stores';
import {
  TRANSACTIONS_LIST_QUERY_KEY,
  INFINITE_TRANSACTION_LIST_QUERY_KEY,
} from '../transactions';
import { ALERT_LIST_QUERY_KEY } from '../alerts';
import { useNavigate } from 'react-router-dom';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  OPEN_REQUESTS_SEARCH_PARAM,
} from 'pages/WalletApp/Activity/activity-page-search-params';
import useTransactionsClient from './useTransactionsClient';
import { v4 as uuid } from 'uuid';
import { PAYMENT_REQUEST_LIST_QUERY_KEY } from '../payment-request';

export default function useSendMoney() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const transactionsClient = useTransactionsClient();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  return useMutation<SendMoneyResponse, ResponseError, SendMoneyRequest>({
    mutationKey: ['send-money'],
    mutationFn: (payload) => {
      return transactionsClient.sendMoney({
        idempotencyKey: uuid(),
        sendMoneyRequest: payload,
        xWalletId: walletId,
      });
    },
    onError: async (error) => {
      if (error.response.status !== 406) {
        toast({
          status: 'error',
          title: 'Failed to Send Money',
          description: 'Please try again.',
        });
      } else {
        const body =
          (await error.response.json()) as NotAcceptablePaymentRequestResponse;
        switch (body.status) {
          case NotAcceptablePaymentRequestResponseStatusEnum.Declined:
            toast({
              status: 'error',
              title: 'Failed to Send Money',
              description: 'Associated request has been declined.',
            });
            break;
          case NotAcceptablePaymentRequestResponseStatusEnum.Paid:
            toast({
              status: 'error',
              title: 'Failed to Send Money',
              description: 'Associated request has been paid.',
            });
            break;
          case NotAcceptablePaymentRequestResponseStatusEnum.Canceled:
            toast({
              status: 'error',
              title: 'Failed to Send Money',
              description: 'Associated request has been canceled.',
            });
            break;
        }
        queryClient.refetchQueries({
          queryKey: [PAYMENT_REQUEST_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [TRANSACTIONS_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [INFINITE_TRANSACTION_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({ queryKey: [ALERT_LIST_QUERY_KEY] });
        navigate(
          `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PAYMENT_REQUEST_LIST_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [INFINITE_TRANSACTION_LIST_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRANSACTIONS_LIST_QUERY_KEY],
      });
      queryClient.invalidateQueries({ queryKey: [ALERT_LIST_QUERY_KEY] });
    },
  });
}
