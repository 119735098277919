import { IoArrowBack } from 'react-icons/io5';
import { To, useNavigate } from 'react-router-dom';
import { Text, HStack, Icon, Link } from '@chakra-ui/react';

export default function BackButton({ to }: { to?: To }) {
  const navigate = useNavigate();
  return (
    <Link
      _hover={{ textDecoration: 'none' }}
      onClick={() => {
        if (to) {
          navigate(to);
        } else {
          navigate(-1);
        }
      }}
    >
      <HStack spacing={3} color="blue.400">
        <Icon as={IoArrowBack} boxSize={5} />
        <Text fontWeight="semibold">Back</Text>
      </HStack>
    </Link>
  );
}
