import React from 'react';
import { Header, Footer } from 'components/layout/onboarding';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { PersonDtoRolesEnum } from 'api/wallet-app';
import {
  SocialSecurityForm,
  SSN_TEMP_VAL,
} from 'pages/Onboarding/sharedComponents/SocialSecurity';
import { InfoButton } from 'pages/Onboarding/sharedComponents/SocialSecurity/infoButton';
import { useSelector } from '@xstate/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SubmitButton } from 'components/submitButton';
import { cleanupSSN } from 'utils/helpers';
import { Waiting } from 'pages/Onboarding/sharedComponents/Waiting';

type SocialSecurityFormValues = {
  ssn: string;
};

export const ControllerSocialSecurity = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<SocialSecurityFormValues>({
    mode: 'onBlur',
    defaultValues: {
      ssn:
        context?.people?.find((person) =>
          person.roles?.includes(PersonDtoRolesEnum.Controller)
        )?.ssn || '',
    },
  });

  const handleSubmit: SubmitHandler<SocialSecurityFormValues> = (data) => {
    const { ssn } = data;

    setIsSubmitting(true);

    if (!ssn) {
      return;
    }

    if (ssn === SSN_TEMP_VAL) {
      onboardingActor.send({ type: 'skipSSN', value: {} });
      return;
    } else {
      onboardingActor.send({
        type: 'submitSSN',
        value: { payload: cleanupSSN(data.ssn) },
      });
    }
  };

  const infoButton = <InfoButton />;

  return isSubmitting ? (
    <Waiting />
  ) : (
    <>
      <form
        id="controller-ssn"
        data-testid="controller-ssn"
        onSubmit={hookFormSubmit(handleSubmit)}
        noValidate
      >
        <Header title="Your Social Security Number" customButton={infoButton} />
        <SocialSecurityForm
          register={register}
          errors={errors}
          isEditing={context?.isEditing || false}
          getValues={getValues}
          setValue={setValue}
        />
      </form>
      <Footer>
        <SubmitButton form="controller-ssn" />
      </Footer>
    </>
  );
};
