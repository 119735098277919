/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SuspendedStateDto } from './SuspendedStateDto';
import {
  SuspendedStateDtoFromJSON,
  SuspendedStateDtoFromJSONTyped,
  SuspendedStateDtoToJSON,
} from './SuspendedStateDto';

/**
 * Application suspended state
 * @export
 * @interface SuspendedState
 */
export interface SuspendedState {
  /**
   *
   * @type {SuspendedStateDto}
   * @memberof SuspendedState
   */
  deactivatedState: SuspendedStateDto;
}

/**
 * Check if a given object implements the SuspendedState interface.
 */
export function instanceOfSuspendedState(
  value: object
): value is SuspendedState {
  if (!('deactivatedState' in value) || value['deactivatedState'] === undefined)
    return false;
  return true;
}

export function SuspendedStateFromJSON(json: any): SuspendedState {
  return SuspendedStateFromJSONTyped(json, false);
}

export function SuspendedStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SuspendedState {
  if (json == null) {
    return json;
  }
  return {
    deactivatedState: SuspendedStateDtoFromJSON(json['deactivatedState']),
  };
}

export function SuspendedStateToJSON(value?: SuspendedState | null): any {
  if (value == null) {
    return value;
  }
  return {
    deactivatedState: SuspendedStateDtoToJSON(value['deactivatedState']),
  };
}
