import { useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { useMachine } from '@xstate/react';
import { TransferFlow } from 'pages/WalletApp/Transfers/pages/load-unload/TransferFlow';
import { transferMachine } from 'pages/WalletApp/Transfers/pages/transferMachine';
import { FundingSourceDto } from 'api/wallet-app';

// TODO: this and 'WithdrawFunds' are essentially the same thing with the only difference
// being the 'type' key/value. Could def DRY this up when we have a moment. May involve
// some thought about restarting the state machine if the user jumps between flows...
export const AddFunds = () => {
  const [transferAmount, setTransferAmount] = useState('0.00');
  const handleMoneyInput = (amount: NumberFormatValues) => {
    setTransferAmount(amount.value);
  };
  const [fundingSource, setFundingSource] = useState<
    FundingSourceDto | undefined
  >();
  const [walletBalance, setWalletBalance] = useState<string | undefined>();
  const [state, send] = useMachine(transferMachine);

  return (
    <TransferFlow
      context={{
        transferAmount,
        fundingSource,
        setFundingSource,
        handleMoneyInput,
        send: send as any,
        state: state as any,
        type: 'LOAD',
        walletBalance,
        setWalletBalance,
      }}
    />
  );
};
