import { Body, Header, Footer } from 'components/layout/onboarding';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { OnboardingAddressDto } from 'api/wallet-app';
import { SubmitButton } from 'components/submitButton';
import { AddressForm } from 'pages/Onboarding/sharedComponents/AddressForm';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from '@xstate/react';

export const BeneficialOwnerAddress = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);

  const handleSubmit: SubmitHandler<OnboardingAddressDto> = (data) => {
    onboardingActor?.send({
      type: 'submitAddress',
      value: { payload: data },
    });
  };

  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors },
  } = useForm<OnboardingAddressDto>({
    mode: 'onBlur',
    defaultValues: context?.isEditing ? context?.person.address : undefined,
  });

  return (
    <div>
      <Header title="Beneficial Owner Address" />
      <Body>
        <form
          id="beneficial-owner-address"
          data-testid="beneficial-owner-address"
          onSubmit={hookFormSubmit(handleSubmit)}
          noValidate
        >
          <AddressForm
            register={register}
            errors={errors}
            labels={{ streetNumber1: "Driver's License Address Line 1" }}
          />
        </form>
      </Body>
      <Footer>
        <SubmitButton form="beneficial-owner-address" />
      </Footer>
    </div>
  );
};
