import { useQuery } from '@tanstack/react-query';
import useOnboardingClient from './useOnboardingClient';

export const INDUSTRY_CLASSIFICATION_CODE_LIST_QUERY_KEY =
  'industry-classification-code-list';

export default function useIndustryClassificationCodeList() {
  const onboardingClient = useOnboardingClient();
  return useQuery({
    queryKey: [INDUSTRY_CLASSIFICATION_CODE_LIST_QUERY_KEY],
    queryFn: () => {
      return onboardingClient.getIndustryClassificationCodes();
    },
  });
}
