export function twoDigitMMDDYY(date: Date) {
  return date.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
}

export function getMMNumericDayYY(date: Date) {
  return date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: '2-digit',
    year: '2-digit',
  });
}

export function dateAsmMdDYyyy(date: Date) {
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}
