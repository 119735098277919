import { PanelWrapper } from 'components/layout/onboarding/panelWrapper';
import { Flex, Heading } from '@chakra-ui/react';
export const UnderReview = () => {
  return (
    <PanelWrapper>
      <Heading>Under Review</Heading>
      <Flex mt="2rem">
        The information you submitted is currently under review. I'm sure we'll
        want to put some copy here to avoid freaking anyone out. Please try back
        later. 🙃
      </Flex>
    </PanelWrapper>
  );
};
