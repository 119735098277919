import { PlaidLinkOnEventMetadata } from 'react-plaid-link';

export function convertMetadataToStringOrNull(
  metadata: PlaidLinkOnEventMetadata
) {
  const plaidEvent: { [key: string]: string } = {};

  // Iterate over each key in m
  Object.keys(metadata).forEach((key) => {
    const value = metadata[key as keyof PlaidLinkOnEventMetadata];
    // Ensure the value is a string, convert null to "null" (or an empty string, based on your needs)
    plaidEvent[key] = value !== null ? String(value) : '';
  });

  return plaidEvent;
}
