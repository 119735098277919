import { http, HttpResponse, PathParams } from 'msw';
import {
  GetCountryCodesResponse,
  GetIndustryClassificationCodesResponse,
  OnboardingDataDto,
  Sector,
  SubmitOnboardingResponse,
  ValidateAddressRequest,
  AddressValidationResponse,
} from 'api/wallet-app';
import {
  mockedRequestsRestApiBasePath,
  onboardingClientBasePath,
} from 'mocks/helpers';
import {
  countryList,
  industryClassificationCodeList,
  onboardingAppState,
  sectorClassificationCodeList,
  WALLET_ID,
} from 'mocks/fixtures';

const onboardingHandlers = [
  /*
   * GET /api/v1/sector-classification-codes
   * Generated API Endpoint Name: getSectorClassificationCodes
   */
  http.get<PathParams, undefined, Sector[]>(
    mockedRequestsRestApiBasePath({ path: 'sector-classification-codes' }),
    () => {
      return HttpResponse.json(sectorClassificationCodeList);
    }
  ),

  /*
   * GET /api/v1/onboarding/countries
   * Generated API Endpoint Name: getCountryCodes
   */
  http.get<PathParams, undefined, GetCountryCodesResponse>(
    onboardingClientBasePath('countries'),
    () => {
      return HttpResponse.json({ codes: countryList });
    }
  ),

  /*
   * GET /api/v1/industry-classification-codes
   * Generated API Endpoint Name: getIndustryClassificationCodes
   */
  http.get<PathParams, undefined, GetIndustryClassificationCodesResponse>(
    mockedRequestsRestApiBasePath({ path: 'industry-classification-codes' }),
    () => {
      return HttpResponse.json({
        industryClassifications: industryClassificationCodeList,
      });
    }
  ),

  /*
   * POST /api/v1/onboarding/wallet
   * Generated API Endpoint Name: getIndustryClassificationCodes
   */
  http.post<PathParams, undefined, SubmitOnboardingResponse>(
    onboardingClientBasePath(),
    () => {
      return HttpResponse.json({ walletId: WALLET_ID });
    }
  ),

  /*
   * POST /api/v1/onboarding/validate-address
   * Generated API Endpoint Name: validateAddress
   */
  http.post<PathParams, ValidateAddressRequest, AddressValidationResponse>(
    onboardingClientBasePath('validate-address'),
    async ({ request }) => {
      const requestBody = await request.json();

      return HttpResponse.json({
        enteredAddress: requestBody,
        isValid: true,
        suggestedAddress: {
          city: 'Suggested City',
          postalCode: '38476',
          stateProvinceRegion: 'Suggested State',
          streetNumber1: 'Suggested Address Line 1',
          streetNumber2: 'Suggested Address Line 2',
        },
      });
    }
  ),

  /*
   * PUT /api/v1/onboarding/wallet
   * Generated API Endpoint Name: updateOnboardingData
   */
  http.put<PathParams, OnboardingDataDto, OnboardingDataDto>(
    onboardingClientBasePath(),
    async ({ request }) => {
      const requestBody = await request.json();

      if (onboardingAppState.type === 'ONBOARDING') {
        onboardingAppState.appState.onboardingData = {
          ...requestBody,
        };
      }

      return HttpResponse.json(requestBody);
    }
  ),
];

export default onboardingHandlers;
