import { WalletProfileDto } from 'api/wallet-app';

export const recentProfileList: WalletProfileDto[] = [
  {
    handle: 'handle1',
    id: '798a338e-910d-4edc-aa1e-dbbd8792c46e',
    location: 'Location 1',
    name: 'Profile Name 1',
    doingBusinessAs: 'Doing Business As 1',
  },
  {
    handle: 'handle2',
    id: '908a8e45-4050-4967-b576-9f3159e8324d',
    location: 'Location 2',
    name: 'Profile Name 2',
    doingBusinessAs: 'Doing Business As 2',
  },
  {
    handle: 'handle3',
    id: '5a5f6615-7947-4687-a984-206a37748c2c',
    location: 'Location 3',
    name: 'Profile Name 3',
    doingBusinessAs: 'Doing Business As 3',
  },
];

export const searchProfileList: WalletProfileDto[] = [
  ...recentProfileList,
  {
    handle: 'handle4',
    id: '775c80c2-abfc-4633-8394-41dd937df6cd',
    location: 'Location 4',
    name: 'Profile Name 4',
    doingBusinessAs: 'Doing Business As 4',
  },
  {
    handle: 'handle5',
    id: '775c80c2-abfc-5633-8395-51dd937df6cd',
    location: 'Location 5',
    name: 'Profile Name 5',
    doingBusinessAs: 'Doing Business As 5',
  },
];
