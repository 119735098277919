import { Stack, Button } from '@chakra-ui/react';

export default function SupportInfoButtons() {
  return (
    <Stack>
      <Button
        variant="secondary"
        textTransform="none"
        onClick={() => {
          window.location.href = 'tel:18664392888';
        }}
      >
        1-866-439-2888
      </Button>
      <Button
        variant="secondary"
        textTransform="none"
        onClick={() => {
          window.location.href = 'mailto:support@bushelwallet.com';
        }}
      >
        support@bushelwallet.com
      </Button>
    </Stack>
  );
}
