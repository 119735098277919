import { format } from 'date-fns';
import { Heading, HStack, Stack, Text } from '@chakra-ui/react';
import currency from 'currency.js';
import { getTransactionType } from 'utils';
import { Link } from 'react-router-dom';
import {
  TransactionDetailsDto,
  TransactionDetailsDtoMoneySentOrReceivedEnum,
  TransactionDetailsDtoStatusEnum,
  TransactionDetailsDtoTypeEnum,
} from 'api/wallet-app';

export default function TransactionSummaryLink({
  transaction,
}: {
  transaction: TransactionDetailsDto;
}) {
  const transactionIsPending =
    transaction.status === TransactionDetailsDtoStatusEnum.Pending;
  const transactionTypeIsSent =
    transaction.moneySentOrReceived ===
    TransactionDetailsDtoMoneySentOrReceivedEnum.Sent;

  const titleFormatting = transactionIsPending && {
    color: 'gray.500',
    fontStyle: 'italic',
  };

  return (
    <Stack
      cursor="pointer"
      as={Link}
      to={`/activity/${
        transaction.type === TransactionDetailsDtoTypeEnum.PaymentRequest
          ? 'request'
          : 'transaction'
      }/${transaction.id}`}
    >
      <HStack>
        <Text
          width="100%"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          fontWeight="bold"
          {...titleFormatting}
        >
          {transactionTypeIsSent ? transaction.destination : transaction.source}
        </Text>
        {!transactionIsPending && transaction.updatedAt && (
          <Text fontSize="md" color="textSecondary">
            {format(new Date(transaction.updatedAt), 'MM/dd/yyyy')}
          </Text>
        )}
      </HStack>
      <HStack justify="space-between" width="100%" alignItems="center">
        <Text
          fontSize="sm"
          color={transactionIsPending ? 'blue.400' : 'gray.400'}
        >
          {getTransactionType(transaction)}
        </Text>
        <Heading
          fontSize="lg"
          lineHeight={6}
          color={
            transaction.status == TransactionDetailsDtoStatusEnum.Failed ||
            transaction.status === TransactionDetailsDtoStatusEnum.Canceled ||
            (transactionIsPending && transactionTypeIsSent)
              ? 'gray.500'
              : transactionTypeIsSent
                ? 'gray.700'
                : 'green.500'
          }
          fontStyle={transactionIsPending ? 'italic' : 'inherit'}
          textDecor={
            transaction.type === TransactionDetailsDtoTypeEnum.PaymentRequest
              ? 'line-through'
              : 'inherit'
          }
        >
          {transactionTypeIsSent ? '-' : '+'}
          {currency(transaction?.amount ?? '0').format()}
        </Heading>
      </HStack>
    </Stack>
  );
}
