import React from 'react';
import { Body, Header, Footer } from 'components/layout/onboarding';
import { TextInput } from 'components/formComponents';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SubmitButton } from 'components/submitButton';
import { BusinessDto, OnboardingAddressDto } from 'api/wallet-app';
import {
  Center,
  Checkbox,
  Text,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useSelector } from '@xstate/react';
import { AddressForm } from 'pages/Onboarding/sharedComponents/AddressForm';
import { useHookFormMask } from 'use-mask-input';
import { isEqual } from 'lodash';
import { useIndustryClassificationCodeList } from 'hooks/api';

export const BusinessDetails = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);
  const { data: industryCodes } = useIndustryClassificationCodeList();
  const [einChoice, setEinChoice] = React.useState<string | undefined>(
    undefined
  );

  const [sameAddress, setSameAddress] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const address = React.useRef<OnboardingAddressDto>();
  const einValue = React.useRef<string>();

  const handleSubmit: SubmitHandler<BusinessDto> = (data) => {
    if (!onboardingActor) return;
    setIsSubmitting(true);
    const address = getAddressValues();
    const payload = {
      businessAddress: {
        ...address,
      },
      ein: data.ein,
      businessName: `${context?.people[0].firstName} ${context?.people[0].lastName}`,
      industryClassificationCode: data.industryClassificationCode,
    };
    onboardingActor.send({ type: 'submitBusinessDetails', value: payload });
  };

  React.useEffect(() => {
    if (sameAddress) {
      address.current = context?.people[0].address;
      address.current &&
        Object.entries(address.current).forEach(([key, value]: any) => {
          setAddressValue(key, value);
        });
    } else {
      address.current = undefined;
      reset();
    }
  }, [sameAddress]);

  React.useEffect(() => {
    if (einChoice === 'off') {
      einValue.current = undefined;
      setValue('ein', einValue.current);
    } else {
      einValue.current = context?.business?.ein;
      setValue('ein', einValue.current);
    }
  }, [einChoice]);

  const {
    register: addressFormRegister,
    formState: { errors: addressErrors },
    getValues: getAddressValues,
    setValue: setAddressValue,
    reset,
  } = useForm<OnboardingAddressDto>({
    mode: 'onBlur',
  });

  const {
    register,
    handleSubmit: formSubmit,
    formState: { errors },
    setValue,
  } = useForm<BusinessDto>({
    mode: 'onBlur',
    defaultValues: {
      ein: context?.business?.ein,
      industryClassificationCode: context?.business?.industryClassificationCode,
    },
  });

  const registerWithMask = useHookFormMask(register);

  React.useEffect(() => {
    const business = context?.business;
    if (
      isEqual(context?.business?.businessAddress, context?.people[0].address)
    ) {
      setSameAddress(true);
    }

    if (business?.ein) {
      setEinChoice('on');
    }
  }, []);

  return isSubmitting ? (
    <Center minHeight="400px" width="100%">
      <Spinner color="blue.400" thickness="4px" size="xl" />
    </Center>
  ) : (
    <>
      <Header title="Business Details" />
      <Text fontWeight="bold" fontFamily="heading">
        Registered Business Address
      </Text>
      <Text fontSize={14} my={3}>
        Please enter your business address as listed with your business's
        official documents.
      </Text>
      <Checkbox
        onChange={(e) => setSameAddress(e.target.checked)}
        isChecked={sameAddress}
        mb={4}
        borderColor="gray.700"
      >
        Same as residential address
      </Checkbox>
      <Body>
        <form
          id="business-details"
          data-testid="business-details"
          onSubmit={formSubmit(handleSubmit)}
          noValidate
        >
          <AddressForm
            labels={{
              streetNumber1: 'Business Address Line 1',
              streetNumber2: 'Business Address Line 2',
            }}
            register={addressFormRegister}
            errors={addressErrors}
          />
          <Stack spacing={4}>
            <>
              <Text fontWeight="bold" fontFamily="heading">
                Business Details
              </Text>
              <Text fontSize={14} mt={-2}>
                The following fields may require supporting business documents
                to be uploaded after Wallet creation.
              </Text>
            </>
            <>
              <Text>
                <Text as="span" color="red">
                  *{' '}
                </Text>
                Industry Classification
              </Text>
              {industryCodes && (
                <Select
                  placeholder="Select option"
                  {...register('industryClassificationCode', {
                    required: true,
                  })}
                  defaultValue={context?.business?.industryClassificationCode}
                >
                  {industryCodes.industryClassifications.map((item) => (
                    <option key={item.code} value={item.code}>
                      {item.description}
                    </option>
                  ))}
                </Select>
              )}
            </>
            <>
              <div>
                DBA
                <Text ml={2} as="span" color="gray.500" fontSize={13}>
                  Optional
                </Text>
              </div>
              <Text fontSize="14" color="gray.500" mt={-3}>
                Enter your business's operating name, if different from the
                legal name.
              </Text>
              <TextInput label="" {...register('doingBusinessAs')} />
            </>
            <>
              <Text fontWeight="bold" fontFamily="heading">
                Employer Identification Number (EIN)
              </Text>
              <Text>I use an EIN for tax reporting purposes</Text>
              <RadioGroup onChange={setEinChoice} value={einChoice ?? 'off'}>
                <Stack direction="row" spacing={40}>
                  <Radio value="on">Yes</Radio>
                  <Radio value="off">No</Radio>
                </Stack>
              </RadioGroup>
              {einChoice === 'on' && (
                <TextInput
                  label=""
                  type="text"
                  inputMode="numeric"
                  placeholder="12-3456789"
                  {...registerWithMask('ein', '99-9999999', {
                    autoUnmask: true,
                    required: {
                      value: true,
                      message: 'If you use an EIN, you must provide it here',
                    },
                    minLength: {
                      value: 9,
                      message: 'EIN must be 9 number',
                    },
                  })}
                  defaultValue={context?.business?.ein}
                  errors={errors}
                />
              )}
            </>
          </Stack>
        </form>
      </Body>
      <Footer>
        <SubmitButton form="business-details" />
      </Footer>
    </>
  );
};
