import { useCallback, useEffect, useRef } from 'react';

export default function useInfiniteScroll({
  targetRef,
  options,
  fetchNextPage,
  hasNextPage,
}: {
  targetRef: React.MutableRefObject<HTMLElement | null>;
  options: IntersectionObserverInit;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
}) {
  const observer = useRef<IntersectionObserver | null>(null);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          fetchNextPage();
        }
      });
    },
    [fetchNextPage]
  );

  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(handleIntersection, options);
    }

    if (targetRef.current && hasNextPage) {
      observer.current.observe(targetRef.current);
    }

    return () => {
      if (observer.current && targetRef.current) {
        observer.current.unobserve(targetRef.current);
      }
    };
  }, [targetRef, handleIntersection, options, hasNextPage]);

  return { observer: observer.current };
}
