import { useMediaQuery, useToast } from '@chakra-ui/react';
import {
  NotAcceptablePaymentRequestResponse,
  NotAcceptablePaymentRequestResponseStatusEnum,
  ResponseError,
  SendReminderPaymentRequestRequest,
} from 'api/wallet-app';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  OPEN_REQUESTS_SEARCH_PARAM,
} from 'pages/WalletApp/Activity/activity-page-search-params';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useWalletStore } from 'stores';
import { ALERT_LIST_QUERY_KEY } from '../alerts';
import {
  TRANSACTIONS_LIST_QUERY_KEY,
  INFINITE_TRANSACTION_LIST_QUERY_KEY,
} from '../transactions';
import { PAYMENT_REQUEST_LIST_QUERY_KEY } from './usePaymentRequestList';
import usePaymentsClient from './usePaymentRequestClient';

export default function usePaymentRequestReminder() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet ID is not defined');
  }
  const paymentsClient = usePaymentsClient();
  const queryClient = useQueryClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  const navigate = useNavigate();
  return useMutation<
    void,
    ResponseError,
    Omit<SendReminderPaymentRequestRequest, 'xWalletId'>
  >({
    mutationKey: ['payment-request-reminder'],
    mutationFn: (payload) => {
      return paymentsClient.sendReminderPaymentRequest({
        xWalletId: walletId,
        ...payload,
      });
    },
    onError: async (error) => {
      if (error.response.status === 429) {
        toast({
          status: 'error',
          title: 'Reminder Not Sent',
          description:
            'Reminders for this request can only be sent once every 12 hours.',
        });
      } else if (error.response.status !== 406) {
        toast({
          status: 'error',
          title: 'Unable to Send Reminder',
          description: 'An error occurred when trying to send a reminder.',
        });
      } else {
        const body =
          (await error.response.json()) as NotAcceptablePaymentRequestResponse;
        switch (body.status) {
          case NotAcceptablePaymentRequestResponseStatusEnum.Declined:
            toast({
              status: 'error',
              title: 'Unable to Send Reminder',
              description: 'Request has been declined.',
            });
            break;
          case NotAcceptablePaymentRequestResponseStatusEnum.Paid:
            toast({
              status: 'error',
              title: 'Unable to Send Reminder',
              description: 'Payment has been made.',
            });
            break;
          case NotAcceptablePaymentRequestResponseStatusEnum.Canceled:
            toast({
              status: 'error',
              title: 'Unable to Send Reminder',
              description: 'Request has been canceled.',
            });
            break;
        }
        queryClient.refetchQueries({
          queryKey: [PAYMENT_REQUEST_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [TRANSACTIONS_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [INFINITE_TRANSACTION_LIST_QUERY_KEY],
        });
        queryClient.invalidateQueries({ queryKey: [ALERT_LIST_QUERY_KEY] });
        navigate(
          `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
        );
      }
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Successfully Reminded',
        description: 'Email sent to notification address on file.',
      });
      navigate(
        `/activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
      );
    },
  });
}
