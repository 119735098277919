import React from 'react';
import { useParams } from 'react-router-dom';
import walletLogo from 'images/wallet-print-logo.png';
import currency from 'currency.js';
import {
  Flex,
  Heading,
  Image,
  Portal,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getDaysInMonth } from 'date-fns';
import { useStatementDetails } from 'hooks/api';

type StatementParams = {
  month: string;
  year: string;
};

export default function SingleMonth() {
  const params = useParams<keyof StatementParams>() as StatementParams;
  const { year, month } = params;
  const title = new Date(parseInt(year), parseInt(month) - 1).toLocaleString(
    'default',
    {
      month: 'long',
      year: 'numeric',
    }
  );

  document.title = `${title} - Bushel Wallet`;

  const customStyles = {
    'thead td': {
      paddingTop: '2px',
      paddingBottom: '2px',
      width: '100%',
    },
    '.right': {
      textAlign: 'right',
    },
    '.account-details td': {
      paddingBottom: '4px',
    },
    tr: {
      '&.spacer': {
        height: '18px',
      },
      '&.transaction:nth-child(odd)': {
        backgroundColor: '#f7fafc',
      },
    },
    td: {
      paddingTop: '6px',
      paddingBottom: '6px',
      '&.gray': {
        color: '#718096',
      },
    },
    th: {
      fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
    },
    tfoot: {
      td: {
        paddingTop: '2px',
        paddingBottom: '2px',
      },
    },
    '@media print': {
      table: {
        backgroundColor: '#fff',
        overflow: 'visible',
      },
      'thead td': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      td: {
        padding: '10px 10px 0 0',
      },
      th: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    '@media screen and (max-width: 768px)': {
      th: {
        padding: '2px',
      },
      td: {
        padding: '4px',
        fontSize: '13px',
        '&.description': {
          maxWidth: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    '@media screen and (min-width: 769px) and (max-width: 970px)': {
      th: {
        padding: '5px',
      },
      td: {
        padding: '4px',
        fontSize: '13px',
      },
    },
  };

  const { t } = useTranslation();
  const { data, isSuccess } = useStatementDetails({
    periodMonth: params.month,
    periodYear: params.year,
  });

  const daysInMonth = getDaysInMonth(
    new Date(parseInt(params.year), parseInt(params.month) - 1)
  );

  React.useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.opacity = '0';
    }
  }, []);

  return (
    <Portal>
      <Flex
        backgroundColor="#fff"
        zIndex="999"
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        overflow="auto"
        sx={{
          '@media print': {
            overflow: 'visible',
          },
        }}
        display="flex"
      >
        {isSuccess ? (
          <Flex direction="column" minWidth="100%">
            <Table variant="unstyled" sx={customStyles}>
              <Thead fontSize="12px">
                <Tr>
                  <Td />
                  <Td />
                  <Td />
                  <Td className="right" colSpan={2} paddingLeft="0">
                    <Image
                      src={walletLogo}
                      maxWidth="100%"
                      width="220px"
                      marginLeft="auto"
                    />
                  </Td>
                </Tr>
                <Tr className="spacer" />
                <Tr py="4px">
                  <Td>{data?.businessName}</Td>
                  <Td />
                  <Td />
                  <Td />
                  <Td className="right gray">503 7th St N</Td>
                </Tr>
                <Tr py="4px">
                  <Td>
                    {data?.businessAddress?.addressLine1}{' '}
                    {data?.businessAddress?.addressLine2}
                  </Td>
                  <Td />
                  <Td />
                  <Td />
                  <Td className="right gray">Fargo, ND 58102</Td>
                </Tr>
                <Tr py="4px">
                  <Td>
                    {data?.businessAddress?.city},{' '}
                    {data?.businessAddress?.stateProvinceCode}{' '}
                    {data?.businessAddress?.postalCode}
                  </Td>
                  <Td />
                  <Td />
                  <Td />
                  <Td className="right gray">bushelwallet.com</Td>
                </Tr>
                <Tr className="spacer" />
                <Tr className="spacer" />
              </Thead>
              <Tbody>
                <Tr className="account-details">
                  <Td fontWeight="bold" paddingBottom="10px!important">
                    <Heading fontSize="16px">
                      {t('wallet.settings.accountOverview')}
                    </Heading>
                  </Td>
                  <Td />
                  <Td />
                  <Td />
                  <Td
                    className="right"
                    fontSize="12px"
                    width="100%"
                    whiteSpace="nowrap"
                  >
                    {params.month}/01/{params.year} to {params.month}/
                    {daysInMonth}/{params.year}
                  </Td>
                </Tr>
                <Tr className="account-details">
                  <Td fontSize="14px">Beginning Balance</Td>
                  <Td />
                  <Td />
                  <Td />
                  <Td className="right">
                    {currency(data.data.beginningBalance).format()}
                  </Td>
                </Tr>
                <Tr className="account-details">
                  <Td fontSize="14px">Deposits</Td>
                  <Td />
                  <Td />
                  <Td />
                  <Td className="right">
                    {currency(data.data.creditsTotal).format()}
                  </Td>
                </Tr>

                <Tr className="account-details">
                  <Td fontSize="14px">Withdrawls</Td>
                  <Td />
                  <Td />
                  <Td />
                  <Td className="right">
                    {currency(data.data.debitsTotal).format()}
                  </Td>
                </Tr>

                <Tr className="account-details">
                  <Td fontSize="14px" fontWeight="bold" paddingY="3px">
                    Ending Balance
                  </Td>
                  <Td />
                  <Td />
                  <Td />
                  <Td className="right" fontWeight="bold">
                    {currency(data?.data?.endingBalance).format()}
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <Table variant="unstyled" sx={customStyles} mt="50px">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold" colSpan={4}>
                    <Heading fontSize="16px" mb="10px">
                      Balance Affecting Transactions
                    </Heading>
                  </Td>
                </Tr>

                <Tr>
                  <Th className="date" width="50px">
                    Post Date
                  </Th>
                  <Th className="desc">Transaction Description</Th>
                  <Th width="20%">Withdrawls</Th>
                  <Th width="20%">Deposits</Th>
                  <Th className="right">Balance</Th>
                </Tr>
                {data?.data.transactions.map((transaction, idx) => {
                  return (
                    <Tr key={idx} className="transaction">
                      <Td>{transaction.date}</Td>
                      <Td className="description">{transaction.description}</Td>
                      <Td>
                        {transaction.type === 'WITHDRAWAL' &&
                          transaction.withdrawal}
                      </Td>
                      <Td>
                        {transaction.type === 'DEPOSIT' && transaction.deposit}
                      </Td>
                      <Td className="right">{transaction.balance}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <Table variant="unstyled" sx={customStyles} mt="50px">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold" colSpan={5}>
                    <Heading fontSize="16px">
                      Non-Balance Affecting Transactions
                    </Heading>
                  </Td>
                </Tr>
                <Tr width="80%">
                  <Th width="50px">Completion Date</Th>
                  <Th>Transaction Description</Th>
                  <Th></Th>
                  <Th></Th>
                  <Th className="right">Amount</Th>
                </Tr>

                {data.data.nonBalanceAffecting.map(
                  (transaction, idx: number) => (
                    <Tr key={idx} className="transaction">
                      <Td>{transaction.date}</Td>
                      <Td className="description">{transaction.description}</Td>
                      <Td></Td>
                      <Td></Td>
                      <Td className="right">{transaction.amount}</Td>
                    </Tr>
                  )
                )}
              </Tbody>
              <Tfoot color="gray.400" fontSize="12px">
                <Tr className="spacer" />
                <Tr className="spacer" />
                <Tr>
                  <Td paddingY="2px">1-866-439-2888</Td>
                </Tr>
                <Tr>
                  <Td paddingY="2px">support@bushelwallet.com</Td>
                </Tr>
              </Tfoot>
            </Table>
          </Flex>
        ) : (
          <Flex
            width="100%"
            height="100vh"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner color="blue.400" size="xl" thickness="4px" />
          </Flex>
        )}
      </Flex>
    </Portal>
  );
}
