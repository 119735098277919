import { useMutation } from '@tanstack/react-query';
import { MakeLiabilityPaymentRequest } from 'api/wallet-app';
import useTransactionsClient from './useTransactionsClient';
import { v4 as uuid } from 'uuid';
import { useWalletStore } from 'stores';

export default function useLiabilityPayment() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const transactionsClient = useTransactionsClient();
  return useMutation({
    mutationKey: ['liability-payment'],
    mutationFn: (payload: MakeLiabilityPaymentRequest) => {
      return transactionsClient.payLiability({
        idempotencyKey: uuid(),
        makeLiabilityPaymentRequest: payload,
        xWalletId: walletId,
      });
    },
  });
}
