import { useMutation } from '@tanstack/react-query';
import usePlaidClient from './usePlaidClient';
import { useWalletStore } from 'stores';
import { LogPlaidEventRequest } from 'api/wallet-app';

export default function useLogPlaidEvent() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined.');
  }
  const plaidClient = usePlaidClient();
  return useMutation({
    mutationKey: ['log-plaid-event'],
    mutationFn: (payload: Omit<LogPlaidEventRequest, 'walletId'>) => {
      return plaidClient.logPlaidEvent({
        logPlaidEventRequest: { ...payload },
        xWalletId: walletId,
      });
    },
    onSuccess: () => {
      console.info('Logged Plaid Event');
    },
  });
}
