import { Skeleton, SkeletonProps, Stack, StackProps } from '@chakra-ui/react';

export default function HorizontalSkeletons({
  skeletonProps = { height: '50px' },
  stackProps,
  numOfSkeletons,
}: {
  skeletonProps?: SkeletonProps;
  stackProps?: StackProps;
  numOfSkeletons: number;
}) {
  return (
    <Stack {...stackProps}>
      {new Array(numOfSkeletons).fill(0).map((_number, index) => {
        return <Skeleton {...skeletonProps} key={index} />;
      })}
    </Stack>
  );
}
