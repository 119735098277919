import { Routes, Route } from 'react-router-dom';
import { OauthRedirect } from 'pages/Oauth/Redirect';
import {
  Settings,
  Statements,
  SingleMonth,
  ManageStaff,
  InviteUser,
  InviteCenter,
  Home,
} from 'pages/WalletApp';
import { Transfers } from 'pages/WalletApp/Transfers';
import { Accounts } from 'pages/WalletApp/Accounts';
import {
  Activity,
  RequestDetails,
  TransactionDetails,
} from 'pages/WalletApp/Activity';
import { SettingsLayout } from 'components/layout/app/settings';

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/oauth-redirect" element={<OauthRedirect />} />
      <Route path="/" element={<Home />} />
      <Route path="/transfers/*" element={<Transfers />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route path="/activity" element={<Activity />} />
      <Route
        path="/activity/transaction/:id"
        element={<TransactionDetails />}
      />
      <Route path="/activity/request/:paymentId" element={<RequestDetails />} />
      <Route path="/settings" element={<SettingsLayout />}>
        <Route index element={<Settings />} />
        <Route path="/settings/invite-center" element={<InviteCenter />} />
        <Route path="/settings/manage-staff" element={<ManageStaff />} />
        <Route
          path="/settings/manage-staff/invite-user"
          element={<InviteUser />}
        />
        <Route path="/settings/statements" element={<Statements />} />
        <Route
          path="/settings/statements/:year/:month"
          element={<SingleMonth />}
        />
      </Route>
    </Routes>
  );
};
