import { useEffect, useState } from 'react';

const step = 0.075;

export default function useAnimateProgressBar() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let currentProgress = 0;
    const progressBarInterval = setInterval(() => {
      currentProgress += step;
      const progress =
        Math.round((Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000) /
        1000;
      setProgress(progress);
    }, 200);

    return () => clearInterval(progressBarInterval);
  }, []);

  return progress;
}
