import { extendTheme } from '@chakra-ui/react';
import * as Components from './theme/components';
import { Colors } from './theme/colors';
import { Typography } from './theme/typography';
import { Global } from './theme/global-styles';

/*
  Breakpoints: 
    sm: 30em / 480px
    md: 48em / 768px
    lg: 62em / 992px 
    xl: 80em / 1280px
    2xl: 96em / 1536px

  Google device breakpoints:
    mobile: {
      S: { maxWidth: 320 },
      M: { minWidth: 321, maxWidth: 375 },
      L: { minWidth: 376, maxWidth: 425 },
      XL: { minWidth: 426, maxWidth: 767 },
      generic: { maxWidth: 767 }
    },
    tablet: {
      generic: { minWidth: 768, maxWidth: 991 }
    },
    desktop: {
      S: { minWidth: 992, maxWidth: 1024 },
      M: { minWidth: 1024, maxWidth: 1360 },
      L: { minWidth: 1360, maxWidth: 2000 },
      XL: { minWidth: 2000 },
      generic: { minWidth: 992 }
    }

  Chakra responsive object syntax: 
    base: 0 - 30em / (0 - 480px)
    sm: 30em  / (481px - 767px), 
    md: 48em / (768px - 991px), 
    lg: 62em / (992px - 1280px), 
    xl: 80em / (1281px - inf)

    eg: fontSize={{ base: "24px", md: "40px", lg: "56px" }
*/

const extended = extendTheme({
  colors: { ...Colors },
  fonts: Typography,
  styles: {
    global: Global,
  },
  components: { ...Components },
});

// console.log(extended);

export default extended;
