import { useKeycloak } from '@react-keycloak/web';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { KEYCLOAK_USER_INFO_QUERY_KEY } from './useKeycloakUserInfo';
import { enableMfa } from 'api/keycloak';

export default function useEnableMfa() {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['enable-mfa'],
    mutationFn: async () => {
      return enableMfa({
        baseUrl: keycloak.authServerUrl,
        realm: keycloak.realm,
        token: keycloak.token,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [KEYCLOAK_USER_INFO_QUERY_KEY] });
      keycloak.logout({ redirectUri: window.location.origin });
    },
  });
}
