import { Body, Footer, Header } from 'components/layout/onboarding';
import { Image, Text, Button } from '@chakra-ui/react';

import mainImage from 'images/buoy.svg';

export const DeadEnd = () => {
  return (
    <div>
      <Header title="You cannot open a Wallet account at this time" />
      <Body>
        <Image src={mainImage} alt="Buoy" mb={6} />
        <Text fontSize={14} mb={4}>
          To open a Bushel Wallet™ account you must have beneficial ownership
          of the company or substantial control of the company.
        </Text>
      </Body>
      <Footer>
        <Button>Learn More</Button>
      </Footer>
    </div>
  );
};
