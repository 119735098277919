import React, { ReactElement, useEffect } from 'react';
import { Flex, useDisclosure, useMediaQuery, Box } from '@chakra-ui/react';
import { DesktopMenu } from './menus/desktopMenu';
import { TabletMenu } from './menus/tabletMenu';
import { MobileMenu } from './menus/mobileMenu';
import { Header } from './header';

const HEADER_HEIGHT = '64px';

export const Wrapper = ({ children }: { children: ReactElement }) => {
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const [isDesktop] = useMediaQuery('(min-width: 991px)');
  const [isWidthToCloseSideNav] = useMediaQuery('(min-width: 1200px)');
  const isTablet = !isMobile && !isDesktop;
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure({
    defaultIsOpen: isWidthToCloseSideNav ? true : false,
  });
  const showOverlay = isTablet && isOpen;

  useEffect(() => {
    if (!isWidthToCloseSideNav) {
      onClose();
    }
    if (isWidthToCloseSideNav) {
      onOpen();
    }
  }, [isWidthToCloseSideNav, onClose, onOpen]);

  return (
    <Flex
      width="100%"
      height="100vh"
      flexDirection="column"
      className="page-wrapper"
    >
      <Header
        toggleMenu={() => {
          onToggle();
        }}
        hideNavToggler={isMobile}
      />
      <Flex
        className="main-body"
        minHeight={['100%', `calc(100% - ${HEADER_HEIGHT})`]}
        overflow="hidden"
      >
        {isDesktop && <DesktopMenu isOpen={isOpen} toggleMenu={onToggle} />}
        {isTablet && <TabletMenu isOpen={isOpen} toggleMenu={onToggle} />}
        <Flex
          p={{
            base: '79px 24px 0 24px',
            sm: '24px 24px 0 24px',
            lg: '48px 48px 0 48px',
          }}
          flexDirection="column"
          flex="1"
          backgroundColor={'gray.50'}
          overflow="scroll"
        >
          {showOverlay && <Overlay />}
          <Flex flexDirection="column" height="100%">
            {children}
          </Flex>
        </Flex>
      </Flex>
      {isMobile && <MobileMenu />}
    </Flex>
  );
};

const Overlay = () => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="black"
      opacity="0.3"
      zIndex={1}
    />
  );
};
