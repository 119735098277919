import { Body, Header, Footer } from 'components/layout/onboarding';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { PersonDtoRolesEnum, PersonDto } from 'api/wallet-app';
import { PersonalInfoForm } from 'pages/Onboarding/sharedComponents/PersonalInfo';
import { useForm, SubmitHandler } from 'react-hook-form';
import { PersonInputs } from 'pages/Onboarding/NoController/PersonalInfo';
import { SubmitButton } from 'components/submitButton';
import { useSelector } from '@xstate/react';

export const BeneficialOwnerInfo = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state!.context);

  const {
    handleSubmit: hookFormSubmit,
    formState: { errors },
    register,
    resetField,
    setValue,
  } = useForm<Partial<PersonInputs>>({
    mode: 'onBlur',
    defaultValues: context.isEditing ? context?.person : undefined,
  });

  const handleSubmit: SubmitHandler<Partial<PersonInputs>> = (data) => {
    const birthdate = `${data.year}-${data.month}-${data.day}`;

    const payload: Partial<PersonDto> = {
      ...data,
      dateOfBirth: birthdate,
      roles: [PersonDtoRolesEnum.BeneficialOwner],
    };

    onboardingActor?.send({
      type: 'submitInfo',
      value: { payload, target: 'beneficialOwner' },
    });
  };
  return (
    <>
      <Header title="Beneficial Owner Info" />
      <Body>
        <form
          id="beneficial-owner-info"
          data-testid="beneficial-owner-info"
          onSubmit={hookFormSubmit(handleSubmit)}
          noValidate
        >
          <PersonalInfoForm
            register={register}
            errors={errors}
            resetField={resetField}
            setValue={setValue}
          />
        </form>
      </Body>
      <Footer>
        <SubmitButton form="beneficial-owner-info" />
      </Footer>
    </>
  );
};
