import { useState } from 'react';
import {
  Badge,
  Button,
  useMediaQuery,
  Stack,
  HStack,
  Text,
  Divider,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import { BusinessInvitation } from 'api/wallet-app';
import { Table, ColumnProps } from 'components/Table';
import { useResendBusinessInvite } from 'hooks/api';
import { twoDigitMMDDYY } from 'utils/date-utils';

const columns: ColumnProps[] = [
  { heading: 'Email', id: 'email', width: 10 / 40 },
  { heading: 'Phone Number', id: 'phoneNumber', width: 8 / 40 },
  { heading: 'Date Invited', id: 'dateInvited', width: 6 / 40 },
  {
    heading: 'Account Status',
    id: 'accountStatus',
    width: 8 / 40,
    emptyStateValue: '',
  },
  { heading: '', id: 'resendInvite', width: 8 / 40, emptyStateValue: '' },
];

export default function SentInviteTable({
  invites,
}: {
  invites: BusinessInvitation[];
}) {
  const [inviteId, setInviteId] = useState('');
  const [breakToMobileDesign] = useMediaQuery('(max-width: 900px)');
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const {
    mutate: resendBusinessInvite,
    isPending: resendBusinessInviteIsPending,
  } = useResendBusinessInvite();

  if (breakToMobileDesign) {
    return (
      <Stack spacing={8} divider={<Divider borderColor="gray.400" />}>
        {invites.map((invite) => {
          return (
            <Stack key={invite.id} spacing={6}>
              <Stack divider={<Divider borderColor="gray.400" />}>
                <HStack justify="space-between">
                  <Text>Email</Text>
                  <Text
                    fontWeight="bold"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    title={invite.email}
                  >
                    {invite.email}
                  </Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>Phone Number</Text>
                  <Text fontWeight="bold">{invite.phoneNumber ?? '-'}</Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>Date Invited</Text>
                  <Text fontWeight="bold">
                    {twoDigitMMDDYY(new Date(invite.dateInvited))}
                  </Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>Account Status</Text>
                  <Badge>Invite Sent</Badge>
                </HStack>
              </Stack>
              <Flex justify="center">
                <Tooltip
                  label="Invites may only be sent to the same email address every 12 hours. The recipient should check all email inboxes."
                  shouldWrapChildren={!isMobile}
                  isDisabled={invite.canBeResent}
                  hasArrow
                  placement="top"
                >
                  <Button
                    variant="outline"
                    w="fit-content"
                    isDisabled={!invite.canBeResent}
                    isLoading={
                      resendBusinessInviteIsPending && inviteId === invite.id
                    }
                    onClick={() => {
                      setInviteId(invite.id);
                      resendBusinessInvite(invite.id);
                    }}
                  >
                    Resend Invite
                  </Button>
                </Tooltip>
              </Flex>
            </Stack>
          );
        })}
      </Stack>
    );
  } else {
    return (
      <Table
        columns={columns}
        tableData={invites.map((invite) => {
          return {
            email: invite.email,
            phoneNumber: invite.phoneNumber,
            dateInvited: twoDigitMMDDYY(new Date(invite.dateInvited)),
            accountStatus: <Badge>Invite Sent</Badge>,
            resendInvite: (
              <Flex>
                <Tooltip
                  label="Invites may only be sent to the same email address every 12 hours. The recipient should check all email inboxes."
                  shouldWrapChildren={!isMobile}
                  isDisabled={invite.canBeResent}
                  hasArrow
                  placement="top"
                >
                  <Button
                    variant="outline"
                    w="fit-content"
                    isDisabled={!invite.canBeResent}
                    isLoading={
                      resendBusinessInviteIsPending && inviteId === invite.id
                    }
                    onClick={() => {
                      setInviteId(invite.id);
                      resendBusinessInvite(invite.id);
                    }}
                  >
                    Resend Invite
                  </Button>
                </Tooltip>
              </Flex>
            ),
          };
        })}
      />
    );
  }
}
