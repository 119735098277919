import { create } from 'zustand';
import { FundingSource } from './types/App/fundingSources';
import Keycloak from 'keycloak-js';

export type WalletStore = {
  walletId: string | undefined;
  balance: string | undefined;
  fundingSources: FundingSource[];
  setFundingSources: (fundingSources: FundingSource[]) => void;
  setWalletId: (walletId: string) => void;
};

export interface KeycloakStore {
  keycloak: Keycloak | undefined;
  setKeycloak: (keycloak: Keycloak) => void;
}

export const useWalletStore = create<WalletStore>((set) => ({
  walletId: undefined,
  balance: undefined,
  fundingSources: [],
  setFundingSources: (fundingSources: FundingSource[]) =>
    set(() => ({ fundingSources: fundingSources })),
  setWalletId: (walletId: string) => set(() => ({ walletId: walletId })),
}));

export const useKeycloakStore = create<KeycloakStore>((set) => ({
  keycloak: undefined,
  setKeycloak: (keycloak: Keycloak) => set(() => ({ keycloak: keycloak })),
}));
