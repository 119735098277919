/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface StatementAddress
 */
export interface StatementAddress {
  /**
   *
   * @type {string}
   * @memberof StatementAddress
   */
  addressLine1: string;
  /**
   *
   * @type {string}
   * @memberof StatementAddress
   */
  addressLine2: string;
  /**
   *
   * @type {string}
   * @memberof StatementAddress
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof StatementAddress
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof StatementAddress
   */
  stateProvinceCode: string;
  /**
   *
   * @type {string}
   * @memberof StatementAddress
   */
  countryCode: string;
}

/**
 * Check if a given object implements the StatementAddress interface.
 */
export function instanceOfStatementAddress(
  value: object
): value is StatementAddress {
  if (!('addressLine1' in value) || value['addressLine1'] === undefined)
    return false;
  if (!('addressLine2' in value) || value['addressLine2'] === undefined)
    return false;
  if (!('city' in value) || value['city'] === undefined) return false;
  if (!('postalCode' in value) || value['postalCode'] === undefined)
    return false;
  if (
    !('stateProvinceCode' in value) ||
    value['stateProvinceCode'] === undefined
  )
    return false;
  if (!('countryCode' in value) || value['countryCode'] === undefined)
    return false;
  return true;
}

export function StatementAddressFromJSON(json: any): StatementAddress {
  return StatementAddressFromJSONTyped(json, false);
}

export function StatementAddressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StatementAddress {
  if (json == null) {
    return json;
  }
  return {
    addressLine1: json['addressLine1'],
    addressLine2: json['addressLine2'],
    city: json['city'],
    postalCode: json['postalCode'],
    stateProvinceCode: json['stateProvinceCode'],
    countryCode: json['countryCode'],
  };
}

export function StatementAddressToJSON(value?: StatementAddress | null): any {
  if (value == null) {
    return value;
  }
  return {
    addressLine1: value['addressLine1'],
    addressLine2: value['addressLine2'],
    city: value['city'],
    postalCode: value['postalCode'],
    stateProvinceCode: value['stateProvinceCode'],
    countryCode: value['countryCode'],
  };
}
