import { Body, Header, Footer } from 'components/layout/onboarding';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { OnboardingAddressDto, PersonDtoRolesEnum } from 'api/wallet-app';
import { AddressForm } from 'pages/Onboarding/sharedComponents/AddressForm';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SubmitButton } from 'components/submitButton';
import { useSelector } from '@xstate/react';

export const ControllerAddress = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);

  const handleSubmit: SubmitHandler<OnboardingAddressDto> = (data) => {
    onboardingActor?.send({
      type: 'submitAddress',
      value: { payload: data },
    });
  };

  const existingAddress = context?.people?.find((person) =>
    person.roles?.includes(PersonDtoRolesEnum.Controller)
  )?.address;

  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors },
  } = useForm<OnboardingAddressDto>({
    defaultValues: existingAddress,
  });

  return (
    <div>
      <Header title="Your Personal Address" />
      <Body>
        <form
          id="controller-address"
          data-testid="controller-address"
          onSubmit={hookFormSubmit(handleSubmit)}
          noValidate
        >
          <AddressForm
            register={register}
            errors={errors}
            labels={{ streetNumber1: "Driver's License Address Line 1" }}
          />
        </form>
      </Body>
      <Footer>
        <SubmitButton form="controller-address" />
      </Footer>
    </div>
  );
};
