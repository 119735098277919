import React, { lazy, Suspense } from 'react';

type ErrorType = {
  addError: (error?: any) => void;
  removeError: () => void;
};

const Suspended = lazy(() => import('pages/ErrorPages/Suspended'));
const NetworkError = lazy(() => import('pages/ErrorPages/NetworkError'));

// generic error boundary should use the same modal as errorService
export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: any;
}) => {
  switch (error) {
    case '404':
      return <div>404</div>;
    case '403':
      return (
        <Suspense fallback={<div></div>}>
          <Suspended />
        </Suspense>
      );
    default:
      return (
        <Suspense fallback={<div></div>}>
          <NetworkError resetError={resetErrorBoundary} />
        </Suspense>
      );
  }
};

export const ErrorContext = React.createContext<ErrorType>({
  addError: () => null,
  removeError: () => null,
});

export const ErrorProvider = ({ children }: { children: JSX.Element }) => {
  const [error, setError] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (error) throw error;
  }, [error]);

  const addError = (error: string) => {
    setError(error);
  };

  const removeError = () => setError(undefined);

  const values = {
    addError: React.useCallback((error: string) => addError(error), []),
    removeError: React.useCallback(() => removeError(), []),
  };

  return (
    <ErrorContext.Provider value={values}>{children}</ErrorContext.Provider>
  );
};

export const useError = () => {
  const { addError, removeError } = React.useContext(ErrorContext);
  return { addError, removeError };
};
