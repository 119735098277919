import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { SelectPathModal } from './components/SelectPathModal';
import { WalletBalanceHeader } from './components';
import { LinkAccounts } from './components/LinkAccounts';
import { find, findIndex } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAddCircleOutline } from 'react-icons/io5';
import {
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import { FundingSource } from './components/fundingSource';
import { PendingFundingSource } from './components/pendingFundingSource';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { LendingPartnersModal } from './components/LendingPartnersModal';
import { LoanPaymentMachine } from './components/LoanPayment/loanPaymentMachine';
import { createActorContext } from '@xstate/react';
import { useSearchParams } from 'react-router-dom';
import {
  FUNDING_SOURCE_LIST_QUERY_KEY,
  useAddFundingSource,
  useGeneratePlaidToken,
  useFundingSourceList,
  useRemoveFundingSource,
  useRenameFundingSource,
  useReorderFundingSources,
  useLogPlaidEvent,
  useUserInfo,
} from 'hooks/api';
import { AuthorizedUserTooltip } from 'components/AuthorizedUserTooltip';
import { useQueryClient } from '@tanstack/react-query';
import { FundingSourceDto, UserListItemTypeEnum } from 'api/wallet-app';
import { convertMetadataToStringOrNull } from 'utils';

export const LoanPaymentContext = createActorContext(LoanPaymentMachine);

export const Accounts = () => {
  const [linkToken, setLinkToken] = React.useState<string | null>(null);
  const [plaidIsLoading, setPlaidIsLoading] = React.useState(false);
  const [accountToUpdate, setAccountToUpdate] =
    React.useState<FundingSourceDto>();

  const queryClient = useQueryClient();

  const { data: fundingSources, isLoading: fundingSourcesIsLoading } =
    useFundingSourceList();
  const { data: userInfo, isLoading: userInfoIsLoading } = useUserInfo();
  const { mutate: reorderFundingSources } = useReorderFundingSources();

  const isAuthorizedUser = useMemo(
    () => userInfo?.type === UserListItemTypeEnum.AuthorizedUser,
    [userInfo?.type]
  );

  const pendingSources = useMemo(() => {
    return (
      fundingSources?.fundingSources?.filter((fundingSource) => {
        return (
          fundingSource.isVerified === false ||
          fundingSource.needsUserValidation === true
        );
      }) ?? []
    );
  }, [fundingSources?.fundingSources]);

  const translatableSources = useMemo(() => {
    return (
      fundingSources?.fundingSources?.filter((fundingSource) => {
        return (
          fundingSource.isVerified === true &&
          fundingSource.needsUserValidation === false
        );
      }) ?? []
    );
  }, [fundingSources?.fundingSources]);

  const reorder = (
    list: FundingSourceDto[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams.has('addLineOfCredit')) {
      searchParams.delete('addLineOfCredit');
      setSearchParams({});
      handleAddLoanAccount();
    }
  }, [searchParams]);

  const toast = useToast();
  const { t } = useTranslation();

  const {
    isOpen: isRemoveModalOpen,
    onOpen: onRemoveModalOpen,
    onClose: onRemoveModalClose,
  } = useDisclosure();

  const {
    isOpen: isLinkPathModalOpen,
    onClose: onLinkPathModalClose,
    onOpen: onLinkPathModalOpen,
  } = useDisclosure();

  const {
    isOpen: isLendingPartnersModalOpen,
    onClose: onLendingPartnersModalClose,
    onOpen: onLendingPartnersModalOpen,
  } = useDisclosure();

  const {
    mutateAsync: removeFundingSourceAsync,
    mutate: removeFundingSource,
    isPending: removeFundingSourceIsPending,
  } = useRemoveFundingSource();
  const { mutate: renameFundingSource } = useRenameFundingSource();
  const { mutate: generatePlaidToken } = useGeneratePlaidToken();
  const { mutate: addFundingSource } = useAddFundingSource();
  const { mutate: logPlaidEvent } = useLogPlaidEvent();

  React.useEffect(() => {
    document.getElementById('accounts')?.focus();
  }, []);

  const onSuccess = (_publicToken: string, metadata: any) => {
    addFundingSource({
      plaidAccountId: metadata.account_id,
      plaidPublicToken: metadata.public_token,
    });
  };

  const reorderItems = (origin: number, destination: number) => {
    if (!translatableSources) return;

    const items = reorder(translatableSources, origin, destination);
    const fundingSourceIds = items.map((source) => source.fundingSourceId);
    reorderFundingSources({ fundingSourceIds: fundingSourceIds });
  };

  const handleRenameSource = (fundingSourceId: string, name: string) => {
    renameFundingSource({
      friendlyName: name,
      fundingSourceId: fundingSourceId,
    });
  };

  const confirmRemoveAccount = (fundingSourceId: string) => {
    const account = find(
      translatableSources,
      (o) => o.fundingSourceId == fundingSourceId
    );
    setAccountToUpdate(account);
    onRemoveModalOpen();
  };

  const removeAccount = async () => {
    if (!accountToUpdate) return;

    await removeFundingSourceAsync(accountToUpdate.fundingSourceId).finally(
      () => {
        onRemoveModalClose();
      }
    );
  };

  const setAsDefault = (fundingSourceId: string) => {
    const idx = findIndex(
      translatableSources,
      (o) => fundingSourceId == o.fundingSourceId
    );
    reorderItems(idx, 0);
  };

  const displayFailedFundingSourceToast = (fundingSource: FundingSourceDto) => {
    showErrorToast(fundingSource);
  };

  const handleRemoveFailedFundingSource = (fundingSource: FundingSourceDto) => {
    removeFundingSource(fundingSource.fundingSourceId, {
      onSettled: () => {
        toast.closeAll();
      },
    });
  };

  const showErrorToast = (fundingSource: FundingSourceDto) => {
    return toast({
      position: 'bottom',
      variant: 'solid',
      duration: 99999999,
      containerStyle: {
        maxWidth: '90%',
        width: '100%',
        margin: '20px',
      },
      render: () => {
        return (
          <Flex
            padding=".8rem"
            color="white"
            borderRadius="8px"
            background="red.500"
            width="100%"
            fontSize="12px"
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            onClick={() => handleRemoveFailedFundingSource(fundingSource)}
          >
            <Flex>
              <Flex
                width="16px"
                height="16px"
                marginRight="10px"
                alignItems="center"
                justifyContent="center"
                borderRadius="100px"
                background="#fff"
                mt="2px"
              >
                <Text color="red.500" fontSize="12px">
                  !
                </Text>
              </Flex>

              <Flex flexDirection="column" flex="9" width="100%">
                <Flex>
                  <Text fontWeight="bold">
                    Your account could not be linked.
                  </Text>
                </Flex>
                <Flex>
                  <Text>
                    We were unable to verify your account. Please try adding
                    your account again.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <ChevronRightIcon w={7} h={7} />
          </Flex>
        );
      },
    });
  };

  const onEvent = (
    e: PlaidLinkStableEvent | string,
    m: PlaidLinkOnEventMetadata
  ) => {
    logPlaidEvent({
      eventName: e,
      plaidEvent: convertMetadataToStringOrNull(m),
    });

    if (e === 'HANDOFF' || e === 'EXIT') {
      setPlaidIsLoading(false);
      queryClient.refetchQueries({
        queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY],
      });
    }
  };

  const onExit = (e: any, x: any) => {
    console.log({ e }, { x });
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onEvent,
    onExit,
  });

  const handleAddBankAccount = () => {
    linkToken ? open() : getLinkToken();
  };

  const handleAddLoanAccount = () => {
    onLendingPartnersModalOpen();
  };

  const handleLendingPartnersModalClose = () => {
    onLendingPartnersModalClose();
  };

  const getLinkToken = () => {
    generatePlaidToken(
      {},
      {
        onSuccess: (data) => {
          setLinkToken(data.linkToken!);
        },
      }
    );
  };

  React.useEffect(() => {
    if (linkToken && ready) open();
  }, [linkToken, ready]);

  const chooseLinkPath = () => {
    onLinkPathModalOpen();
  };

  return (
    <LoanPaymentContext.Provider>
      {fundingSourcesIsLoading ||
      userInfoIsLoading ||
      !translatableSources ||
      !pendingSources ? (
        <Center h="50vh">
          <Spinner color="blue.400" size="xl" />
        </Center>
      ) : (
        <Stack spacing={6}>
          <WalletBalanceHeader />
          <LinkAccounts chooseLinkPath={chooseLinkPath} />
          <Flex flexDirection="column">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              flex="1"
              pb="8px"
            >
              <Text fontWeight="bold" fontSize={['1.2rem', '1.5rem']}>
                Linked Accounts
              </Text>
              <AuthorizedUserTooltip placement="left">
                <IconButton
                  icon={<IoAddCircleOutline size="24px" />}
                  aria-label="Add Account"
                  variant="link"
                  size="icon"
                  w="fit-content"
                  onClick={chooseLinkPath}
                  _focus={{ outline: 'none' }}
                  isDisabled={isAuthorizedUser}
                  isLoading={plaidIsLoading}
                />
              </AuthorizedUserTooltip>
            </Flex>
            <Box mb="1.5rem" paddingBottom="4rem">
              <Flex
                direction="column"
                borderRadius="8px"
                overflow="hidden"
                boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
              >
                {pendingSources &&
                  pendingSources.map((source) => {
                    return (
                      <>
                        <PendingFundingSource
                          fundingSource={source}
                          key={source.fundingSourceId}
                          onSuccess={onSuccess}
                          removeFailedFundingSource={
                            displayFailedFundingSourceToast
                          }
                        />
                        <Divider color="gray.200" />
                      </>
                    );
                  })}
                {translatableSources &&
                  translatableSources.map((source, idx) => {
                    return (
                      <>
                        <FundingSource
                          fundingSource={source}
                          isPreferred={idx == 0}
                          setAsDefault={setAsDefault}
                          handleRenameSource={handleRenameSource}
                          handleRemoveSource={confirmRemoveAccount}
                          key={source.fundingSourceId}
                          isOnlyFundingSource={translatableSources.length === 1}
                        />
                        {idx !== translatableSources.length - 1 && (
                          <Divider color="gray.200" />
                        )}
                      </>
                    );
                  })}
              </Flex>
            </Box>
          </Flex>
          <SelectPathModal
            isOpen={isLinkPathModalOpen}
            onClose={onLinkPathModalClose}
            addBankAccount={handleAddBankAccount}
            addLoanAccount={handleAddLoanAccount}
          />
          {isLendingPartnersModalOpen && (
            <LendingPartnersModal
              isOpen={isLendingPartnersModalOpen}
              onClose={handleLendingPartnersModalClose}
            />
          )}
          {translatableSources && (
            <>
              <Modal isOpen={isRemoveModalOpen} onClose={onRemoveModalClose}>
                <ModalOverlay />
                <ModalContent p="2rem">
                  <Heading fontSize="1.2rem">
                    {t('wallet.accounts.removeAccount')}
                  </Heading>
                  <Text mt="1rem">
                    {t('wallet.accounts.areYouSureYouWantRemove')}
                    <strong>
                      {` ${accountToUpdate?.financialInstitutionName} (xx-${accountToUpdate?.accountNumber})`}
                    </strong>
                    {t('wallet.accounts.willNotDeletePendingTransactions')}
                  </Text>
                  <Flex justifyContent="flex-end" width="100%" mt="2rem">
                    <Button
                      variant="secondary"
                      mr="1rem"
                      onClick={onRemoveModalClose}
                      isDisabled={removeFundingSourceIsPending}
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={removeAccount}
                      isLoading={removeFundingSourceIsPending}
                    >
                      {t('button.remove')}
                    </Button>
                  </Flex>
                </ModalContent>
              </Modal>
            </>
          )}
        </Stack>
      )}
    </LoanPaymentContext.Provider>
  );
};
