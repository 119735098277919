import React from 'react';
import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { find } from 'lodash';
import currency from 'currency.js';
import { IndividualFundingSource } from 'pages/WalletApp/Transfers/components/IndividualFundingSource';
import { useFundingSourceList, useWalletBalance } from 'hooks';
import { FundingSourceDto } from 'api/wallet-app';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { TransferContext } from '../../transferMachine';

export const SelectFundingSource = ({
  context,
  send,
}: {
  send: any;
  context: TransferContext;
}) => {
  const { data: fundingSources, isLoading: fundingSourcesIsLoading } =
    useFundingSourceList({
      includeLiabilityFundsAvailable: true,
      transactional: true,
    });
  const { data: walletBalance } = useWalletBalance();
  const [selectedFundingSource, setSelectedFundingSource] =
    React.useState<FundingSourceDto>();
  const [walletIsDisabled, setWalletIsDisabled] = React.useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleSubmit = () => {
    send('NEXT', {
      fundingSource: selectedFundingSource,
      balance: walletBalance?.balance,
    });
  };

  const walletHasInsufficientFunds =
    context.amount &&
    walletBalance?.balance &&
    parseFloat(context.amount) > parseFloat(walletBalance?.balance ?? '0')
      ? true
      : false;

  React.useEffect(() => {
    if (parseFloat(walletBalance?.balance ?? '0') === 0) {
      setWalletIsDisabled(true);
    }
  }, [walletBalance?.balance]);

  const handleSelectSource = (id: string) => {
    const source = find(
      fundingSources?.fundingSources,
      (i) => i.fundingSourceId == id
    );
    setSelectedFundingSource(source);
  };

  return (
    <Stack spacing={10}>
      <HStack justifyContent="space-between">
        <ArrowBackIcon
          boxSize={6}
          cursor="pointer"
          onClick={() => {
            if (searchParams.get('amount')) {
              navigate(-1);
            } else {
              send('BACK');
            }
          }}
        />
        <Text>Send to {context?.recipient?.name}</Text>
        <div />
      </HStack>
      <Stack spacing={12}>
        <Stack>
          <HStack
            justifyContent="space-between"
            borderRadius="lg"
            border="solid 1px"
            padding={4}
            borderColor={
              selectedFundingSource?.name === 'wallet' ? 'blue.500' : 'gray.300'
            }
            background={
              selectedFundingSource?.name === 'wallet' ? 'blue.50' : 'white'
            }
            color={walletIsDisabled ? 'gray.400' : 'black'}
            cursor={walletIsDisabled ? 'not-allowed' : 'pointer'}
            _hover={
              walletIsDisabled
                ? { borderColor: 'none' }
                : { borderColor: 'blue.400' }
            }
            onClick={() => {
              if (walletIsDisabled) return;
              // @ts-ignore
              return setSelectedFundingSource({
                name: 'wallet',
                fundingSourceId: '',
                financialInstitutionName: 'wallet',
                accountNumber: '000',
                activeTransactions: false,
                isVerified: true,
                needsUserValidation: false,
                verificationFailed: false,
              });
            }}
          >
            <Text fontWeight="bold">Wallet Balance</Text>
            <Text>{currency(walletBalance?.balance ?? '').format()}</Text>
          </HStack>
          {walletHasInsufficientFunds && (
            <Text
              color="red.600"
              fontSize="14px"
              alignSelf="flex-start"
              pb="0.8rem"
            >
              Your wallet balance does not cover this transaction
            </Text>
          )}
          {fundingSourcesIsLoading && (
            <CenteredSpinner
              centerProps={{ height: '170px' }}
              spinnerProps={{ size: 'xl', thickness: '4px' }}
            />
          )}
          {!fundingSourcesIsLoading &&
            fundingSources?.fundingSources?.map((fundingSource, index) => {
              return (
                <IndividualFundingSource
                  key={index}
                  fundSrc={fundingSource}
                  handleSelectedSource={handleSelectSource}
                  selectedSource={selectedFundingSource}
                  amount={context.amount}
                  index={index}
                />
              );
            })}
        </Stack>
        <Button onClick={handleSubmit} disabled={!selectedFundingSource}>
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};
