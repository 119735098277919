import {
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { PaymentRequestDetails } from 'api/wallet-app';
import { useCancelRequest, useDeclineRequest } from 'hooks/api';
// import { useCancelRequest, useDeclineRequest } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';

type CancelOrDenyForm = {
  reason: string;
};

export default function CancelOrDeclineRequestModal({
  isOpen,
  onClose,
  request,
}: {
  isOpen: boolean;
  onClose: () => void;
  request: PaymentRequestDetails;
}) {
  const { mutate: cancelRequest, isPending: cancelRequestIsPending } =
    useCancelRequest();
  const { mutate: declineRequest, isPending: declineRequestIsPending } =
    useDeclineRequest();

  const methods = useForm<CancelOrDenyForm>({
    defaultValues: {
      reason: '',
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
    formState: { errors },
  } = methods;

  function handleClose() {
    reset();
    onClose();
  }

  function onSubmit(formData: CancelOrDenyForm) {
    {
      if (request.isReceiving) {
        declineRequest(
          {
            payerWalletId: request.payerWalletId,
            paymentId: request.paymentId,
            declinedReason: formData.reason,
          },
          {
            onSuccess: handleClose,
          }
        );
      } else {
        cancelRequest(
          {
            paymentId: request.paymentId,
            cancelReason: formData.reason,
          },
          {
            onSuccess: handleClose,
          }
        );
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      closeOnOverlayClick={!cancelRequestIsPending && !declineRequestIsPending}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack justify="space-between">
            <Heading as="h5" size="md">
              {request.isReceiving ? 'Decline Payment' : 'Cancel Request'}
            </Heading>
            <CloseButton
              onClick={handleClose}
              isDisabled={cancelRequestIsPending || declineRequestIsPending}
            />
          </HStack>
        </ModalHeader>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack>
                <FormControl isInvalid={!!errors?.reason}>
                  <FormLabel>
                    Reason for {request.isReceiving ? 'declining' : 'canceling'}
                  </FormLabel>
                  <Textarea
                    {...register('reason', {
                      required: {
                        value: true,
                        message: 'Reason is required',
                      },
                      maxLength: {
                        value: 255,
                        message: 'Reason must be less than 256 characters',
                      },
                    })}
                  />
                  <FormErrorMessage color="red.400">
                    {errors?.reason?.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={3}>
                <Button
                  isDisabled={cancelRequestIsPending || declineRequestIsPending}
                  variant="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  isDisabled={!isDirty}
                  isLoading={cancelRequestIsPending || declineRequestIsPending}
                  type="submit"
                >
                  {request.isReceiving ? 'Decline' : 'Confirm'}
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
