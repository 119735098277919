/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingDataDto } from './OnboardingDataDto';
import {
  OnboardingDataDtoFromJSON,
  OnboardingDataDtoFromJSONTyped,
  OnboardingDataDtoToJSON,
} from './OnboardingDataDto';

/**
 *
 * @export
 * @interface OnboardingAppStateDto
 */
export interface OnboardingAppStateDto {
  /**
   *
   * @type {string}
   * @memberof OnboardingAppStateDto
   */
  walletId?: string;
  /**
   *
   * @type {OnboardingDataDto}
   * @memberof OnboardingAppStateDto
   */
  onboardingData?: OnboardingDataDto;
}

/**
 * Check if a given object implements the OnboardingAppStateDto interface.
 */
export function instanceOfOnboardingAppStateDto(
  value: object
): value is OnboardingAppStateDto {
  return true;
}

export function OnboardingAppStateDtoFromJSON(
  json: any
): OnboardingAppStateDto {
  return OnboardingAppStateDtoFromJSONTyped(json, false);
}

export function OnboardingAppStateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OnboardingAppStateDto {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'] == null ? undefined : json['walletId'],
    onboardingData:
      json['onboardingData'] == null
        ? undefined
        : OnboardingDataDtoFromJSON(json['onboardingData']),
  };
}

export function OnboardingAppStateDtoToJSON(
  value?: OnboardingAppStateDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
    onboardingData: OnboardingDataDtoToJSON(value['onboardingData']),
  };
}
