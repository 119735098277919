/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Dispute
 */
export interface Dispute {
  /**
   *
   * @type {string}
   * @memberof Dispute
   */
  disputeId: string;
  /**
   *
   * @type {string}
   * @memberof Dispute
   */
  disputeAt?: string;
  /**
   *
   * @type {string}
   * @memberof Dispute
   */
  disputeWonAt?: string;
  /**
   *
   * @type {string}
   * @memberof Dispute
   */
  disputeLostAt?: string;
}

/**
 * Check if a given object implements the Dispute interface.
 */
export function instanceOfDispute(value: object): value is Dispute {
  if (!('disputeId' in value) || value['disputeId'] === undefined) return false;
  return true;
}

export function DisputeFromJSON(json: any): Dispute {
  return DisputeFromJSONTyped(json, false);
}

export function DisputeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Dispute {
  if (json == null) {
    return json;
  }
  return {
    disputeId: json['disputeId'],
    disputeAt: json['disputeAt'] == null ? undefined : json['disputeAt'],
    disputeWonAt:
      json['disputeWonAt'] == null ? undefined : json['disputeWonAt'],
    disputeLostAt:
      json['disputeLostAt'] == null ? undefined : json['disputeLostAt'],
  };
}

export function DisputeToJSON(value?: Dispute | null): any {
  if (value == null) {
    return value;
  }
  return {
    disputeId: value['disputeId'],
    disputeAt: value['disputeAt'],
    disputeWonAt: value['disputeWonAt'],
    disputeLostAt: value['disputeLostAt'],
  };
}
