import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useWalletsClient from './useWalletsClient';

export const GET_BUSINESS_INVITES_QUERY_KEY = 'get-business-invites';

export default function useBusinessInviteList() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const walletsClient = useWalletsClient();
  return useQuery({
    queryKey: [GET_BUSINESS_INVITES_QUERY_KEY],
    queryFn: () => {
      return walletsClient.getBusinessInvites({ xWalletId: walletId });
    },
    retry: true,
  });
}
