import {
  Badge,
  Grid,
  GridItem,
  GridItemProps,
  Heading,
  Stack,
  Text,
  useMediaQuery,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import currency from 'currency.js';
import { usePaymentRequestList } from 'hooks/api';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { paymentRequestStatusBadgeColor, twoDigitMMDDYY } from 'utils';
import NoActivityImage from 'images/NoActivity.svg';
import { useNavigate } from 'react-router-dom';
import { HorizontalSkeletons } from 'components/HorizontalSkeletons';

const headerStyles: GridItemProps = {
  color: 'gray.700',
  fontWeight: 'bold',
  fontSize: '14px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
};

export default function OpenRequests() {
  const [isMobileDesigns] = useMediaQuery('(max-width: 700px)');
  const navigate = useNavigate();

  const { data, isLoading, isError, isSuccess } = usePaymentRequestList();

  return (
    <Stack spacing={[4, 8]} pb={isMobileDesigns ? '125px' : 0}>
      {isLoading && (
        <HorizontalSkeletons
          numOfSkeletons={5}
          skeletonProps={{ height: '60px' }}
        />
      )}
      {isError && <Text>Error</Text>}
      {isSuccess && (
        <Stack spacing={4}>
          {!isMobileDesigns && (
            <Grid
              templateColumns="repeat(24, 1fr)"
              px={3}
              gap={isMobileDesigns ? 2 : 4}
            >
              <GridItem {...headerStyles} colSpan={5}>
                Name
              </GridItem>
              <GridItem {...headerStyles} colSpan={4}>
                Type
              </GridItem>
              <GridItem {...headerStyles} colSpan={4}>
                Status
              </GridItem>
              <GridItem {...headerStyles} colSpan={4}>
                Date
              </GridItem>
              <GridItem {...headerStyles} textAlign="end" colSpan={6}>
                Amount
              </GridItem>
              <GridItem colSpan={1} />
            </Grid>
          )}
          <Grid
            templateRows={`repeat(${data?.paymentRequests.length}, 1fr)`}
            templateColumns="repeat(1, 1fr)"
            bgColor="white"
            borderRadius="lg"
            boxShadow="0px 0px 2px 0px #2D374826, 0px 2px 3px 0px #2D374833, 0px 0px 0.5px 0px #FFFFFF00 inset, 0px 0.5px 0px 0px #FFFFFF00 inset"
          >
            {data?.paymentRequests.length === 0 && (
              <Stack spacing={12} align="center" p={6}>
                <Image src={NoActivityImage} w="400px" h="120px" />
                <Stack spacing={6} align="center">
                  <Heading as="h3" size="xl">
                    No results Found
                  </Heading>
                  <Text textAlign="center">
                    There are no open requests at this time.
                  </Text>
                </Stack>
              </Stack>
            )}
            {data?.paymentRequests.map((pendingRequest) => {
              return (
                <Grid
                  key={pendingRequest.paymentId}
                  templateColumns="repeat(24, 1fr)"
                  px={3}
                  py={6}
                  _hover={{ bgColor: 'gray.50', cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`request/${pendingRequest.paymentId}`);
                  }}
                  borderBottom="1px solid #CBD5E0"
                  gap={isMobileDesigns ? 2 : 4}
                  data-testid="table-row"
                  isTruncated
                >
                  <GridItem
                    fontWeight="bold"
                    rowStart={isMobileDesigns ? 2 : undefined}
                    isTruncated
                    colSpan={isMobileDesigns ? 23 : 5}
                  >
                    <Tooltip
                      label={
                        pendingRequest.isReceiving
                          ? pendingRequest.payeeName
                          : pendingRequest.payerName
                      }
                    >
                      {pendingRequest.isReceiving
                        ? pendingRequest.payeeName
                        : pendingRequest.payerName}
                    </Tooltip>
                  </GridItem>
                  <GridItem
                    rowStart={isMobileDesigns ? 1 : undefined}
                    isTruncated
                    colSpan={isMobileDesigns ? 12 : 4}
                  >
                    {pendingRequest.isReceiving
                      ? 'Request Received'
                      : 'Request Sent'}
                  </GridItem>
                  <GridItem
                    rowStart={isMobileDesigns ? 3 : undefined}
                    colSpan={isMobileDesigns ? 12 : 4}
                  >
                    <Badge
                      fontSize="sm"
                      colorScheme={paymentRequestStatusBadgeColor(
                        pendingRequest.status
                      )}
                    >
                      {pendingRequest.status}
                    </Badge>
                  </GridItem>
                  <GridItem
                    textAlign={isMobileDesigns ? 'end' : 'inherit'}
                    colSpan={isMobileDesigns ? 11 : 4}
                    fontFamily="Roboto Mono"
                  >
                    {twoDigitMMDDYY(new Date(pendingRequest.initiatedAt))}
                  </GridItem>
                  <GridItem
                    rowStart={isMobileDesigns ? 3 : undefined}
                    fontWeight="bold"
                    textAlign="end"
                    colSpan={isMobileDesigns ? 11 : 6}
                    fontFamily="Roboto Mono"
                  >
                    {currency(pendingRequest.amount).format()}
                  </GridItem>
                  <GridItem
                    colSpan={isMobileDesigns ? 1 : 1}
                    rowSpan={isMobileDesigns ? 3 : 1}
                    alignContent="center"
                    justifySelf="end"
                  >
                    <IoChevronForwardOutline
                      size={isMobileDesigns ? 16 : 24}
                      color="#69788A"
                    />
                  </GridItem>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      )}
    </Stack>
  );
}
