import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';
import { useLiabilityPayment } from 'hooks/api';

export const SubmitPayment = () => {
  const uuid = React.useRef<string>('');
  const { mutate: liabilityPayment } = useLiabilityPayment();

  const { send } = LoanPaymentContext.useActorRef();
  const { paymentSource, fundingSourceId, paymentAmount } =
    LoanPaymentContext.useSelector((state) => state.context);

  React.useEffect(() => {
    if (!uuid.current) return;

    liabilityPayment(
      {
        memo: '',
        fromFundingSourceId: paymentSource?.fundingSourceId
          ? paymentSource.fundingSourceId
          : '',
        toFundingSourceId: fundingSourceId,
        amount: paymentAmount,
      },
      {
        onSuccess: () => {
          send({ type: 'SUCCESS' });
        },
        onError: (e) => {
          console.error(e);
          send({ type: 'FAIL' });
        },
      }
    );
  }, [uuid.current]);

  return (
    <Flex
      height="300px"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner color="blue.400" size="xl" />
    </Flex>
  );
};
