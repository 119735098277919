import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Checkmark from 'images/plaid-done.svg';
import { TransferContext } from '../TransferFlow';
import currency from 'currency.js';

export const Success = ({ context }: { context: TransferContext }) => {
  const navigate = useNavigate();
  const returnToTransfers = () => {
    navigate('/transfers');
  };
  const goToTransactions = () => {
    navigate('/activity');
  };
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      minHeight="500px"
    >
      <Image src={Checkmark} />
      <Flex flexDirection="column" alignItems="center">
        <Box>You successfully transferred</Box>
        <Box fontFamily="heading" fontSize="32px">
          {currency(context.transferAmount).format()}
        </Box>
        <Box>
          to{' '}
          <Text display="inline" fontWeight="bold">
            {context.type === 'LOAD'
              ? 'Wallet Balance'
              : context.fundingSource?.name}
          </Text>
        </Box>
      </Flex>
      <Flex flexDirection="column" width="100%">
        <Button
          mb="8px"
          onClick={goToTransactions}
          width="100%"
          variant="ghost"
        >
          View Transactions
        </Button>
        <Button onClick={returnToTransfers} width="100%">
          Done
        </Button>
      </Flex>
    </Flex>
  );
};
