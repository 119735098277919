/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AppStateResponse,
  DefaultErrorResponse,
  ErrorResponse,
  ForbiddenResponse,
  InternalServerResponse,
  NotFoundResponse,
  RecordScreenViewRequest,
  ViolationsError,
} from '../models/index';
import {
  AppStateResponseFromJSON,
  AppStateResponseToJSON,
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ForbiddenResponseFromJSON,
  ForbiddenResponseToJSON,
  InternalServerResponseFromJSON,
  InternalServerResponseToJSON,
  NotFoundResponseFromJSON,
  NotFoundResponseToJSON,
  RecordScreenViewRequestFromJSON,
  RecordScreenViewRequestToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
} from '../models/index';

export interface RecordAppViewRequest {
  xWalletId: string;
  recordScreenViewRequest: RecordScreenViewRequest;
}

/**
 *
 */
export class AppApi extends runtime.BaseAPI {
  /**
   * Call this endpoint to get the application\'s state.
   */
  async getAppStateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppStateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/app/state`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppStateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Call this endpoint to get the application\'s state.
   */
  async getAppState(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppStateResponse> {
    const response = await this.getAppStateRaw(initOverrides);
    return await response.value();
  }

  /**
   * Records a timestamp when a user views an item on the Client.
   */
  async recordAppViewRaw(
    requestParameters: RecordAppViewRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling recordAppView().'
      );
    }

    if (requestParameters['recordScreenViewRequest'] == null) {
      throw new runtime.RequiredError(
        'recordScreenViewRequest',
        'Required parameter "recordScreenViewRequest" was null or undefined when calling recordAppView().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/screen`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RecordScreenViewRequestToJSON(
          requestParameters['recordScreenViewRequest']
        ),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Records a timestamp when a user views an item on the Client.
   */
  async recordAppView(
    requestParameters: RecordAppViewRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.recordAppViewRaw(requestParameters, initOverrides);
  }
}
