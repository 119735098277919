import { useMutation } from '@tanstack/react-query';
import useAppClient from './useAppClient';
import { useWalletStore } from 'stores';
import { RecordScreenViewRequestScreenNameEnum } from 'api/wallet-app';

export default function useRecordAppView() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const appClient = useAppClient();
  return useMutation({
    mutationKey: ['record-app-view'],
    mutationFn: (screenName: RecordScreenViewRequestScreenNameEnum) => {
      return appClient.recordAppView({
        recordScreenViewRequest: {
          screenName,
        },
        xWalletId: walletId,
      });
    },
  });
}
