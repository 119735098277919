import { Stack, Heading, Divider, Button, Text } from '@chakra-ui/react';
import { HorizontalSkeletons } from 'components/HorizontalSkeletons';
import { useTransactionsList } from 'hooks/api';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  TRANSACTION_HISTORY_SEARCH_PARAM,
} from 'pages/WalletApp/Activity/activity-page-search-params';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TransactionSummaryLink } from '../TransactionSummaryLink';

export default function Transactions() {
  const { t } = useTranslation();
  const { data: transactionsData, isLoading } = useTransactionsList({
    limit: 5,
    excludePaymentRequest: true,
  });
  const navigate = useNavigate();
  return (
    <Stack
      flex={3}
      background="white"
      boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
      borderRadius="lg"
      p={4}
      w="100%"
      spacing={4}
    >
      <Heading as="h6" size="md">
        {t('wallet.home.recentTransactions')}
      </Heading>
      <>
        {isLoading ? (
          <HorizontalSkeletons
            numOfSkeletons={5}
            skeletonProps={{ height: '60px' }}
          />
        ) : (
          <Stack spacing={6}>
            <Stack divider={<Divider />}>
              {transactionsData?.transactionHistory.length === 0 && (
                <Text>{t('wallet.home.noTransactions')}</Text>
              )}
              {transactionsData?.transactionHistory.map((transaction) => {
                return (
                  <TransactionSummaryLink
                    transaction={transaction}
                    key={transaction.id}
                  />
                );
              })}
            </Stack>
            <Button
              variant="secondary"
              maxW="fit-content"
              alignSelf="flex-end"
              size="sm"
              onClick={() => {
                navigate(
                  `activity?${ACTIVITY_SEARCH_PARAM_KEY}=${TRANSACTION_HISTORY_SEARCH_PARAM}`
                );
              }}
            >
              View More
            </Button>
          </Stack>
        )}
      </>
    </Stack>
  );
}
