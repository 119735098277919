import {
  PaymentRequestDetails,
  PaymentRequestDetailsStatusEnum,
} from 'api/wallet-app';
import { WALLET_ID } from './wallets-fixtures';

export const paymentRequestList: PaymentRequestDetails[] = [
  {
    amount: '10.00',
    initiatedAt: new Date('11/04/24').toISOString(),
    isReceiving: true,
    payeeWalletId: '6ff57c99-53b3-45ab-a2cc-432e8ad186bd',
    payerWalletId: WALLET_ID,
    paymentId: '550b158a-e5c9-4828-955e-803c9122c31c',
    status: PaymentRequestDetailsStatusEnum.Pending,
    updatedAt: new Date('11/04/24').toISOString(),
    payeeHandle: 'payeeHandle1',
    payeeName: 'Payee Name 1',
    payerHandle: 'payerHandle1',
    payerName: 'Payer Name 1',
    memo: 'Request Memo 1',
  },
  {
    amount: '1.00',
    initiatedAt: new Date('11/03/24').toISOString(),
    isReceiving: false,
    payeeWalletId: WALLET_ID,
    payerWalletId: 'df7f88bf-be74-4559-aa34-92363408750b',
    paymentId: 'c15df94b-7da5-4ebc-b217-0f1c708c5721',
    status: PaymentRequestDetailsStatusEnum.Pending,
    updatedAt: new Date('11/03/24').toISOString(),
    payeeHandle: 'payeeHandle2',
    payeeName: 'Payee Name 2',
    payerHandle: 'payerHandle2',
    payerName: 'Payer Name 2',
    memo: 'Request Memo 2',
  },
  {
    amount: '9999',
    initiatedAt: new Date('11/01/24').toISOString(),
    isReceiving: false,
    payeeWalletId: WALLET_ID,
    payerWalletId: '391af14d-f60d-4eb7-8ca8-caccc97d92ee',
    paymentId: 'f4659d7f-97bc-4996-a91b-411496c0a590',
    status: PaymentRequestDetailsStatusEnum.Pending,
    updatedAt: new Date('11/02/24').toISOString(),
    payeeHandle: 'payeeHandle3',
    payeeName: 'Payee Name 3',
    payerHandle: 'payerHandle3',
    payerName: 'Payer Name 3',
    memo: 'Request Memo 3',
  },
  {
    amount: '33',
    initiatedAt: new Date('11/02/24').toISOString(),
    isReceiving: false,
    payeeWalletId: WALLET_ID,
    payerWalletId: 'b970016d-689c-4b22-8de8-2c49a1baffd2',
    paymentId: '8af9e83f-9d84-4419-8dea-04e735033b6f',
    status: PaymentRequestDetailsStatusEnum.Pending,
    updatedAt: new Date('11/01/24').toISOString(),
    payeeHandle: 'payeeHandle4',
    payeeName: 'Payee Name 4',
    payerHandle: 'payerHandle4',
    payerName: 'Payer Name 4',
    memo: 'Request Memo 4',
  },
];
