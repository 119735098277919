/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The create new funding source request
 * @export
 * @interface CreateFundingSourceRequest
 */
export interface CreateFundingSourceRequest {
  /**
   *
   * @type {string}
   * @memberof CreateFundingSourceRequest
   */
  plaidPublicToken: string;
  /**
   *
   * @type {string}
   * @memberof CreateFundingSourceRequest
   */
  plaidAccountId: string;
}

/**
 * Check if a given object implements the CreateFundingSourceRequest interface.
 */
export function instanceOfCreateFundingSourceRequest(
  value: object
): value is CreateFundingSourceRequest {
  if (!('plaidPublicToken' in value) || value['plaidPublicToken'] === undefined)
    return false;
  if (!('plaidAccountId' in value) || value['plaidAccountId'] === undefined)
    return false;
  return true;
}

export function CreateFundingSourceRequestFromJSON(
  json: any
): CreateFundingSourceRequest {
  return CreateFundingSourceRequestFromJSONTyped(json, false);
}

export function CreateFundingSourceRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateFundingSourceRequest {
  if (json == null) {
    return json;
  }
  return {
    plaidPublicToken: json['plaidPublicToken'],
    plaidAccountId: json['plaidAccountId'],
  };
}

export function CreateFundingSourceRequestToJSON(
  value?: CreateFundingSourceRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    plaidPublicToken: value['plaidPublicToken'],
    plaidAccountId: value['plaidAccountId'],
  };
}
