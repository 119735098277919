/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DeactivatedAppStateDto } from './DeactivatedAppStateDto';
import {
  DeactivatedAppStateDtoFromJSON,
  DeactivatedAppStateDtoFromJSONTyped,
  DeactivatedAppStateDtoToJSON,
} from './DeactivatedAppStateDto';

/**
 * Application deactivated state
 * @export
 * @interface DeactivatedState
 */
export interface DeactivatedState {
  /**
   *
   * @type {DeactivatedAppStateDto}
   * @memberof DeactivatedState
   */
  deactivatedState: DeactivatedAppStateDto;
}

/**
 * Check if a given object implements the DeactivatedState interface.
 */
export function instanceOfDeactivatedState(
  value: object
): value is DeactivatedState {
  if (!('deactivatedState' in value) || value['deactivatedState'] === undefined)
    return false;
  return true;
}

export function DeactivatedStateFromJSON(json: any): DeactivatedState {
  return DeactivatedStateFromJSONTyped(json, false);
}

export function DeactivatedStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeactivatedState {
  if (json == null) {
    return json;
  }
  return {
    deactivatedState: DeactivatedAppStateDtoFromJSON(json['deactivatedState']),
  };
}

export function DeactivatedStateToJSON(value?: DeactivatedState | null): any {
  if (value == null) {
    return value;
  }
  return {
    deactivatedState: DeactivatedAppStateDtoToJSON(value['deactivatedState']),
  };
}
