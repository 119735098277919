import { useMediaQuery, useToast } from '@chakra-ui/react';
import { ResponseError, UserListItem } from 'api/wallet-app';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import { USER_LIST_QUERY_KEY } from './useUserList';
import useWalletsClient from './useWalletsClient';
import { v4 as uuid } from 'uuid';

export default function useRevokeUser() {
  const { walletId } = useWalletStore();
  const queryClient = useQueryClient();
  if (!walletId) {
    throw new Error('Wallet ID is not defined');
  }
  const walletsClient = useWalletsClient();
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  return useMutation<void, ResponseError, UserListItem>({
    mutationKey: ['revoke-user'],
    mutationFn: (payload) => {
      return walletsClient.revokeUserAccess({
        revokeUserAccessRequest: { userId: payload.id },
        idempotencyKey: uuid(),
        xWalletId: walletId,
      });
    },
    onSuccess: (_data, variables) => {
      toast({
        status: 'success',
        isClosable: true,
        title: `${variables.firstName} ${variables.lastName} has been removed`,
        position: isMobile ? 'top' : 'bottom',
      });
      queryClient.refetchQueries({ queryKey: [USER_LIST_QUERY_KEY] });
    },
    onError: () => {
      toast({
        status: 'error',
        isClosable: true,
        title: 'Failed to remove user',
        position: isMobile ? 'top' : 'bottom',
      });
    },
  });
}
