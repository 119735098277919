/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The check business invitation response
 * @export
 * @interface CheckBusinessInvitationResponse
 */
export interface CheckBusinessInvitationResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckBusinessInvitationResponse
   */
  isValid: boolean;
}

/**
 * Check if a given object implements the CheckBusinessInvitationResponse interface.
 */
export function instanceOfCheckBusinessInvitationResponse(
  value: object
): value is CheckBusinessInvitationResponse {
  if (!('isValid' in value) || value['isValid'] === undefined) return false;
  return true;
}

export function CheckBusinessInvitationResponseFromJSON(
  json: any
): CheckBusinessInvitationResponse {
  return CheckBusinessInvitationResponseFromJSONTyped(json, false);
}

export function CheckBusinessInvitationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CheckBusinessInvitationResponse {
  if (json == null) {
    return json;
  }
  return {
    isValid: json['isValid'],
  };
}

export function CheckBusinessInvitationResponseToJSON(
  value?: CheckBusinessInvitationResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    isValid: value['isValid'],
  };
}
