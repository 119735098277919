/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DeactivatedState } from './DeactivatedState';
import {
  instanceOfDeactivatedState,
  DeactivatedStateFromJSON,
  DeactivatedStateFromJSONTyped,
  DeactivatedStateToJSON,
} from './DeactivatedState';
import type { DeniedState } from './DeniedState';
import {
  instanceOfDeniedState,
  DeniedStateFromJSON,
  DeniedStateFromJSONTyped,
  DeniedStateToJSON,
} from './DeniedState';
import type { NormalAppState } from './NormalAppState';
import {
  instanceOfNormalAppState,
  NormalAppStateFromJSON,
  NormalAppStateFromJSONTyped,
  NormalAppStateToJSON,
} from './NormalAppState';
import type { OnboardingAppState } from './OnboardingAppState';
import {
  instanceOfOnboardingAppState,
  OnboardingAppStateFromJSON,
  OnboardingAppStateFromJSONTyped,
  OnboardingAppStateToJSON,
} from './OnboardingAppState';
import type { SdkUploadAppState } from './SdkUploadAppState';
import {
  instanceOfSdkUploadAppState,
  SdkUploadAppStateFromJSON,
  SdkUploadAppStateFromJSONTyped,
  SdkUploadAppStateToJSON,
} from './SdkUploadAppState';
import type { SuspendedState } from './SuspendedState';
import {
  instanceOfSuspendedState,
  SuspendedStateFromJSON,
  SuspendedStateFromJSONTyped,
  SuspendedStateToJSON,
} from './SuspendedState';
import type { UnderReviewState } from './UnderReviewState';
import {
  instanceOfUnderReviewState,
  UnderReviewStateFromJSON,
  UnderReviewStateFromJSONTyped,
  UnderReviewStateToJSON,
} from './UnderReviewState';

/**
 * @type AppStateResponse
 * The state of the application
 * @export
 */
export type AppStateResponse =
  | ({ type: 'DEACTIVATED' } & DeactivatedState)
  | ({ type: 'DENIED' } & DeniedState)
  | ({ type: 'NORMAL' } & NormalAppState)
  | ({ type: 'ONBOARDING' } & OnboardingAppState)
  | ({ type: 'SDK_UPLOAD' } & SdkUploadAppState)
  | ({ type: 'SUSPENDED' } & SuspendedState)
  | ({ type: 'UNDER_REVIEW' } & UnderReviewState);

export function AppStateResponseFromJSON(json: any): AppStateResponse {
  return AppStateResponseFromJSONTyped(json, false);
}

export function AppStateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppStateResponse {
  if (json == null) {
    return json;
  }
  switch (json['type']) {
    case 'DEACTIVATED':
      return Object.assign({}, DeactivatedStateFromJSONTyped(json, true), {
        type: 'DEACTIVATED',
      } as const);
    case 'DENIED':
      return Object.assign({}, DeniedStateFromJSONTyped(json, true), {
        type: 'DENIED',
      } as const);
    case 'NORMAL':
      return Object.assign({}, NormalAppStateFromJSONTyped(json, true), {
        type: 'NORMAL',
      } as const);
    case 'ONBOARDING':
      return Object.assign({}, OnboardingAppStateFromJSONTyped(json, true), {
        type: 'ONBOARDING',
      } as const);
    case 'SDK_UPLOAD':
      return Object.assign({}, SdkUploadAppStateFromJSONTyped(json, true), {
        type: 'SDK_UPLOAD',
      } as const);
    case 'SUSPENDED':
      return Object.assign({}, SuspendedStateFromJSONTyped(json, true), {
        type: 'SUSPENDED',
      } as const);
    case 'UNDER_REVIEW':
      return Object.assign({}, UnderReviewStateFromJSONTyped(json, true), {
        type: 'UNDER_REVIEW',
      } as const);
    default:
      throw new Error(
        `No variant of AppStateResponse exists with 'type=${json['type']}'`
      );
  }
}

export function AppStateResponseToJSON(value?: AppStateResponse | null): any {
  if (value == null) {
    return value;
  }
  switch (value['type']) {
    case 'DEACTIVATED':
      return DeactivatedStateToJSON(value);
    case 'DENIED':
      return DeniedStateToJSON(value);
    case 'NORMAL':
      return NormalAppStateToJSON(value);
    case 'ONBOARDING':
      return OnboardingAppStateToJSON(value);
    case 'SDK_UPLOAD':
      return SdkUploadAppStateToJSON(value);
    case 'SUSPENDED':
      return SuspendedStateToJSON(value);
    case 'UNDER_REVIEW':
      return UnderReviewStateToJSON(value);
    default:
      throw new Error(
        `No variant of AppStateResponse exists with 'type=${value['type']}'`
      );
  }
}
