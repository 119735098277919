/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Business's physical address.
 * @export
 * @interface OnboardingAddressDto
 */
export interface OnboardingAddressDto {
  /**
   *
   * @type {string}
   * @memberof OnboardingAddressDto
   */
  streetNumber1?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingAddressDto
   */
  streetNumber2?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingAddressDto
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingAddressDto
   */
  stateProvinceRegion?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingAddressDto
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardingAddressDto
   */
  country?: string;
}

/**
 * Check if a given object implements the OnboardingAddressDto interface.
 */
export function instanceOfOnboardingAddressDto(
  value: object
): value is OnboardingAddressDto {
  return true;
}

export function OnboardingAddressDtoFromJSON(json: any): OnboardingAddressDto {
  return OnboardingAddressDtoFromJSONTyped(json, false);
}

export function OnboardingAddressDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OnboardingAddressDto {
  if (json == null) {
    return json;
  }
  return {
    streetNumber1:
      json['streetNumber1'] == null ? undefined : json['streetNumber1'],
    streetNumber2:
      json['streetNumber2'] == null ? undefined : json['streetNumber2'],
    city: json['city'] == null ? undefined : json['city'],
    stateProvinceRegion:
      json['stateProvinceRegion'] == null
        ? undefined
        : json['stateProvinceRegion'],
    postalCode: json['postalCode'] == null ? undefined : json['postalCode'],
    country: json['country'] == null ? undefined : json['country'],
  };
}

export function OnboardingAddressDtoToJSON(
  value?: OnboardingAddressDto | null
): any {
  if (value == null) {
    return value;
  }
  return {
    streetNumber1: value['streetNumber1'],
    streetNumber2: value['streetNumber2'],
    city: value['city'],
    stateProvinceRegion: value['stateProvinceRegion'],
    postalCode: value['postalCode'],
    country: value['country'],
  };
}
