/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PlaidLinkTokenRequest
 */
export interface PlaidLinkTokenRequest {
  /**
   *
   * @type {string}
   * @memberof PlaidLinkTokenRequest
   */
  fundingSourceId?: string;
}

/**
 * Check if a given object implements the PlaidLinkTokenRequest interface.
 */
export function instanceOfPlaidLinkTokenRequest(
  value: object
): value is PlaidLinkTokenRequest {
  return true;
}

export function PlaidLinkTokenRequestFromJSON(
  json: any
): PlaidLinkTokenRequest {
  return PlaidLinkTokenRequestFromJSONTyped(json, false);
}

export function PlaidLinkTokenRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PlaidLinkTokenRequest {
  if (json == null) {
    return json;
  }
  return {
    fundingSourceId:
      json['fundingSourceId'] == null ? undefined : json['fundingSourceId'],
  };
}

export function PlaidLinkTokenRequestToJSON(
  value?: PlaidLinkTokenRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSourceId: value['fundingSourceId'],
  };
}
