import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

export const ConfirmModal = ({
  onClose,
  isOpen,
  onSubmit,
}: {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ready to submit?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Some undetermined copy here</ModalBody>

        <ModalFooter alignItems="flex-end">
          <Button variant="secondary" onClick={onClose} width="auto" mr={2}>
            Cancel
          </Button>
          <Button width="auto" onClick={onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
