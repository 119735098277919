/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentRequestDetails } from './PaymentRequestDetails';
import {
  PaymentRequestDetailsFromJSON,
  PaymentRequestDetailsFromJSONTyped,
  PaymentRequestDetailsToJSON,
} from './PaymentRequestDetails';

/**
 *
 * @export
 * @interface PendingPaymentRequests
 */
export interface PendingPaymentRequests {
  /**
   *
   * @type {Array<PaymentRequestDetails>}
   * @memberof PendingPaymentRequests
   */
  paymentRequests: Array<PaymentRequestDetails>;
}

/**
 * Check if a given object implements the PendingPaymentRequests interface.
 */
export function instanceOfPendingPaymentRequests(
  value: object
): value is PendingPaymentRequests {
  if (!('paymentRequests' in value) || value['paymentRequests'] === undefined)
    return false;
  return true;
}

export function PendingPaymentRequestsFromJSON(
  json: any
): PendingPaymentRequests {
  return PendingPaymentRequestsFromJSONTyped(json, false);
}

export function PendingPaymentRequestsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PendingPaymentRequests {
  if (json == null) {
    return json;
  }
  return {
    paymentRequests: (json['paymentRequests'] as Array<any>).map(
      PaymentRequestDetailsFromJSON
    ),
  };
}

export function PendingPaymentRequestsToJSON(
  value?: PendingPaymentRequests | null
): any {
  if (value == null) {
    return value;
  }
  return {
    paymentRequests: (value['paymentRequests'] as Array<any>).map(
      PaymentRequestDetailsToJSON
    ),
  };
}
