/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CancelPaymentRequestRequest
 */
export interface CancelPaymentRequestRequest {
  /**
   *
   * @type {string}
   * @memberof CancelPaymentRequestRequest
   */
  paymentId: string;
  /**
   *
   * @type {string}
   * @memberof CancelPaymentRequestRequest
   */
  cancelReason: string;
}

/**
 * Check if a given object implements the CancelPaymentRequestRequest interface.
 */
export function instanceOfCancelPaymentRequestRequest(
  value: object
): value is CancelPaymentRequestRequest {
  if (!('paymentId' in value) || value['paymentId'] === undefined) return false;
  if (!('cancelReason' in value) || value['cancelReason'] === undefined)
    return false;
  return true;
}

export function CancelPaymentRequestRequestFromJSON(
  json: any
): CancelPaymentRequestRequest {
  return CancelPaymentRequestRequestFromJSONTyped(json, false);
}

export function CancelPaymentRequestRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancelPaymentRequestRequest {
  if (json == null) {
    return json;
  }
  return {
    paymentId: json['paymentId'],
    cancelReason: json['cancelReason'],
  };
}

export function CancelPaymentRequestRequestToJSON(
  value?: CancelPaymentRequestRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    paymentId: value['paymentId'],
    cancelReason: value['cancelReason'],
  };
}
