/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The command to invite a business.
 * @export
 * @interface InviteBusinessRequest
 */
export interface InviteBusinessRequest {
  /**
   *
   * @type {string}
   * @memberof InviteBusinessRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof InviteBusinessRequest
   */
  phoneNumber?: string;
}

/**
 * Check if a given object implements the InviteBusinessRequest interface.
 */
export function instanceOfInviteBusinessRequest(
  value: object
): value is InviteBusinessRequest {
  if (!('email' in value) || value['email'] === undefined) return false;
  return true;
}

export function InviteBusinessRequestFromJSON(
  json: any
): InviteBusinessRequest {
  return InviteBusinessRequestFromJSONTyped(json, false);
}

export function InviteBusinessRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteBusinessRequest {
  if (json == null) {
    return json;
  }
  return {
    email: json['email'],
    phoneNumber: json['phoneNumber'] == null ? undefined : json['phoneNumber'],
  };
}

export function InviteBusinessRequestToJSON(
  value?: InviteBusinessRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    email: value['email'],
    phoneNumber: value['phoneNumber'],
  };
}
