import { useMediaQuery, useToast } from '@chakra-ui/react';
import { CreatePaymentRequest } from 'api/wallet-app';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import { ALERT_LIST_QUERY_KEY } from '../alerts';
import { PAYMENT_REQUEST_LIST_QUERY_KEY } from './usePaymentRequestList';
import usePaymentsClient from './usePaymentRequestClient';

export default function useRequestPayment() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const paymentsClient = usePaymentsClient();
  const queryClient = useQueryClient();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: isMobile ? 'top' : 'bottom',
  });
  return useMutation({
    mutationKey: ['request-money'],
    mutationFn: (payload: Omit<CreatePaymentRequest, 'myWalletId'>) => {
      return paymentsClient.requestPayment({
        createPaymentRequest: payload,
        xWalletId: walletId,
      });
    },
    onError: () => {
      toast({
        title: 'Request Failed',
        description: 'Recipient not notified',
        status: 'error',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PAYMENT_REQUEST_LIST_QUERY_KEY],
      });
      queryClient.invalidateQueries({ queryKey: [ALERT_LIST_QUERY_KEY] });
    },
  });
}
