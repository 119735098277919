import { mockedRequestsRestApiBasePath } from 'mocks/helpers';
import { http, HttpResponse } from 'msw';

const plaidHandlers = [
  /*
   * POST /api/v1/log-plaid-event
   * Generated API Endpoint Name: logPlaidEvent
   */
  http.post(mockedRequestsRestApiBasePath({ path: 'log-plaid-event' }), () => {
    return new HttpResponse(null, { status: 204 });
  }),
];

export default plaidHandlers;
