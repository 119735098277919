/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CompletedBusinessInvitation
 */
export interface CompletedBusinessInvitation {
  /**
   *
   * @type {string}
   * @memberof CompletedBusinessInvitation
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CompletedBusinessInvitation
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof CompletedBusinessInvitation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompletedBusinessInvitation
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CompletedBusinessInvitation
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CompletedBusinessInvitation
   */
  dateInvited: string;
}

/**
 * Check if a given object implements the CompletedBusinessInvitation interface.
 */
export function instanceOfCompletedBusinessInvitation(
  value: object
): value is CompletedBusinessInvitation {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('email' in value) || value['email'] === undefined) return false;
  if (!('dateInvited' in value) || value['dateInvited'] === undefined)
    return false;
  return true;
}

export function CompletedBusinessInvitationFromJSON(
  json: any
): CompletedBusinessInvitation {
  return CompletedBusinessInvitationFromJSONTyped(json, false);
}

export function CompletedBusinessInvitationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompletedBusinessInvitation {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    walletId: json['walletId'],
    name: json['name'],
    email: json['email'],
    phoneNumber: json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    dateInvited: json['dateInvited'],
  };
}

export function CompletedBusinessInvitationToJSON(
  value?: CompletedBusinessInvitation | null
): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    walletId: value['walletId'],
    name: value['name'],
    email: value['email'],
    phoneNumber: value['phoneNumber'],
    dateInvited: value['dateInvited'],
  };
}
