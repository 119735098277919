import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useWalletProfilesClient from './useWalletProfilesClient';

export const SEARCH_WALLET_PROFILES_QUERY_KEY = 'search-wallet-profiles';

export default function useSearchWalletProfiles(searchValue?: string) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const walletProfilesClient = useWalletProfilesClient();
  return useQuery({
    queryKey: [SEARCH_WALLET_PROFILES_QUERY_KEY, searchValue],
    queryFn: () => {
      return walletProfilesClient.searchWalletProfiles({
        xWalletId: walletId,
        searchValue: searchValue!,
      });
    },
    enabled: !!searchValue && searchValue.length > 2,
  });
}
