/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LenderDto } from './LenderDto';
import {
  LenderDtoFromJSON,
  LenderDtoFromJSONTyped,
  LenderDtoToJSON,
} from './LenderDto';

/**
 *
 * @export
 * @interface GetLendersListResponse
 */
export interface GetLendersListResponse {
  /**
   *
   * @type {Array<LenderDto>}
   * @memberof GetLendersListResponse
   */
  lenders: Array<LenderDto>;
}

/**
 * Check if a given object implements the GetLendersListResponse interface.
 */
export function instanceOfGetLendersListResponse(
  value: object
): value is GetLendersListResponse {
  if (!('lenders' in value) || value['lenders'] === undefined) return false;
  return true;
}

export function GetLendersListResponseFromJSON(
  json: any
): GetLendersListResponse {
  return GetLendersListResponseFromJSONTyped(json, false);
}

export function GetLendersListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetLendersListResponse {
  if (json == null) {
    return json;
  }
  return {
    lenders: (json['lenders'] as Array<any>).map(LenderDtoFromJSON),
  };
}

export function GetLendersListResponseToJSON(
  value?: GetLendersListResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    lenders: (value['lenders'] as Array<any>).map(LenderDtoToJSON),
  };
}
