import React from 'react';
import { Header, Body, Footer } from 'components/layout/onboarding';
import {
  RadioButtonGroup,
  RadioButtonGroupOptions,
} from 'components/formComponents';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from 'components/submitButton';
import { Center, Flex, Spinner, Text } from '@chakra-ui/react';
import { useSelector } from '@xstate/react';
import { BusinessDtoBusinessStructureEnum } from 'api/wallet-app';
import { useOnboardingAppState } from 'hooks/api';
import { WalletQueryParams } from 'utils/helpers';
// import alloy from '@alloyidentity/web-sdk';
// import * as config from 'config.json';
// import { TempOnboardingState } from 'pages/Onboarding/onboardingMachine';

export const TypeOfBusiness = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);
  const businessStructure = context?.business?.businessStructure;

  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<
    BusinessDtoBusinessStructureEnum | undefined
  >(businessStructure);
  const [origin, setOrigin] = React.useState('');
  const walletQueryParams = new WalletQueryParams();

  React.useEffect(() => {
    if (walletQueryParams.hasValidPartner()) {
      setOrigin(walletQueryParams.getPartnerId());
    }

    if (walletQueryParams.hasToken()) {
      const partnerId = walletQueryParams.getLinkTokenIntegrationPartnerId();
      setOrigin(partnerId || '');
    }
  }, [origin]);

  const { data, isLoading } = useOnboardingAppState();
  // React.useEffect(() => {
  //   if (data && onboardingActor) {
  //     if (!data.appState?.onboardingData) {
  //       return;
  //     } else {
  //       onboardingActor.send({
  //         type: 'setOnboardingData',
  //         //@ts-ignore
  //         value: data?.appState?.onboardingData,
  //       });
  //     }
  //   }
  // }, [data, onboardingActor]);

  onboardingActor &&
    onboardingActor.subscribe((state) => {
      if (state.context.business?.businessStructure) {
        setSelected(state.context.business.businessStructure);
      }
    });

  // React.useEffect(() => {
  //   alloy
  //     .init({
  //       key: config.sdk_keys[0].key,
  //       journeyToken: config.journeys[0].journey_token,
  //     })
  //     .then(() => alloy.open(callback, "content-container"));
  // }, []);

  const handleSubmit = (): void => {
    if (!onboardingActor) return;

    let response: 'noController' | 'controllerRequired';

    if (!selected) {
      return;
    }

    if (selected === BusinessDtoBusinessStructureEnum.SoleProp) {
      response = 'noController';
    } else {
      response = 'controllerRequired';
    }

    onboardingActor.send({
      type: response,
      value: selected,
    });
  };

  const handleClick = (val: string): void => {
    setSelected(val as BusinessDtoBusinessStructureEnum);
  };

  const options: RadioButtonGroupOptions[] = [
    {
      label: t('onboarding.businessTypes.soleProp'),
      value: BusinessDtoBusinessStructureEnum.SoleProp,
      subtitle: 'You own and run the business by yourself.',
    },
    {
      label: t('onboarding.businessTypes.llc'),
      value: BusinessDtoBusinessStructureEnum.Llc,
      subtitle:
        'Your business limits personal liability and offers flexible management, often with co-owners.',
    },
    {
      label: 'Corporation',
      value: BusinessDtoBusinessStructureEnum.Corporation,
      subtitle: '',
    },
    {
      label: 'Partnership',
      value: BusinessDtoBusinessStructureEnum.Partnership,
      subtitle: '',
    },
  ];

  return isLoading ? (
    <Center height="100%" width="100%">
      <Spinner size="xl" thickness="4px" color="blue.400" />
    </Center>
  ) : (
    <>
      <Flex alignItems="center">
        <Header backButton={false} logoutButton={true} title="Business Type" />{' '}
      </Flex>
      <Text>Please select your business type from the options below:</Text>
      <Body>
        <RadioButtonGroup
          onChange={handleClick}
          name="businessType"
          options={options}
          defaultValue={selected}
        />
      </Body>
      <Footer>
        <SubmitButton handleSubmit={handleSubmit} />
      </Footer>
    </>
  );
};
