/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The command to reorder funding sources.
 * @export
 * @interface ReorderFundingSourcesRequest
 */
export interface ReorderFundingSourcesRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ReorderFundingSourcesRequest
   */
  fundingSourceIds: Array<string>;
}

/**
 * Check if a given object implements the ReorderFundingSourcesRequest interface.
 */
export function instanceOfReorderFundingSourcesRequest(
  value: object
): value is ReorderFundingSourcesRequest {
  if (!('fundingSourceIds' in value) || value['fundingSourceIds'] === undefined)
    return false;
  return true;
}

export function ReorderFundingSourcesRequestFromJSON(
  json: any
): ReorderFundingSourcesRequest {
  return ReorderFundingSourcesRequestFromJSONTyped(json, false);
}

export function ReorderFundingSourcesRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReorderFundingSourcesRequest {
  if (json == null) {
    return json;
  }
  return {
    fundingSourceIds: json['fundingSourceIds'],
  };
}

export function ReorderFundingSourcesRequestToJSON(
  value?: ReorderFundingSourcesRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSourceIds: value['fundingSourceIds'],
  };
}
