import { useQuery } from '@tanstack/react-query';
import useFundingSourcesClient from './useFundingSourcesClient';
import { useWalletStore } from 'stores';

export const PLAID_TOKEN_QUERY_KEY = 'plaid-token';

export default function usePlaidToken() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const fundingSourcesClient = useFundingSourcesClient();
  return useQuery({
    queryKey: [PLAID_TOKEN_QUERY_KEY],
    queryFn: () => {
      return fundingSourcesClient.generatePlaidLinkToken({
        xWalletId: walletId,
      });
    },
  });
}
