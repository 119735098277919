export type AppEnv = {
  keycloakUrl: string;
  keycloakRealm: string;
  keycloakClientId: string;
  restApiBasePath: string;
  isMockMode: boolean;
  [key: string]: string | boolean;
};

export function checkAppEnvValues(appEnv: AppEnv) {
  const schema: Record<keyof AppEnv, string> = {
    keycloakUrl: 'string',
    keycloakRealm: 'string',
    keycloakClientId: 'string',
    restApiBasePath: 'string',
    isMockMode: 'boolean',
  };

  if (
    !appEnv ||
    Object.keys(schema).filter((key) => appEnv[key] === undefined).length > 0
  ) {
    throw new Error(
      `Error: You must include application arguments for the 'run' function in the index.html file.
      
      Required arguments:
      
        - keycloakUrl: string - Base URL of the Keycloak instance you want to integrate with
        - keycloakRealm: string - Realm being used in the Keycloak instance
        - keycloakClientId: string - Client ID being used in the Keycloak instance
        - restApiBasePath: string - Base path for the REST API (Do not end URL with '/')
        - isMockMode: boolean - Whether or not to intercept API requests with Mock Service Worker
      
      Example:
      
        run({
          keycloakUrl: "https://wallet-keycloak-dev.sandbox.bushelwallet.com/auth/",
          keycloakRealm: "exchange-admin-portal",
          keycloakClientId: "wallet-ap-front-end-dev",
          restApiBasePath: "https://adminportal-feature4.sandbox.bushelwallet.com",
          isMockMode: false,
        });`
    );
  }
}

export async function enableMockServiceWorker(isMockMode: boolean) {
  if (!isMockMode) {
    return;
  }

  const { worker } = await import('../../mocks/browser');
  worker.start();
}
