import {
  useRadio,
  useRadioGroup,
  Box,
  Stack,
  HStack,
  Text,
  Image,
} from '@chakra-ui/react';
import Checkmark from 'images/toggle_checkmark.svg';

export type RadioButtonGroupProps = {
  defaultValue?: string;
  name?: string;
  onChange: (val: string) => void;
  options: RadioButtonGroupOptions[];
  grow?: boolean;
};

export type RadioButtonGroupOptions = {
  label: string | number;
  value: string | undefined;
  subtitle?: string;
};

export type RadioButtonProps = RadioButtonGroupOptions & {
  isChecked: boolean;
  grow?: boolean;
};

export const RadioButton = (props: RadioButtonProps) => {
  const { getInputProps, getRadioProps } = useRadio(props);
  const { isChecked, label, subtitle } = props;
  // input.onChange;
  // const handleKeyDown = (e: React.KeyboardEvent) => {
  //   if (e.key === 'Enter' && input.onChange) {
  //     input?.onChange(props.value);
  //   }
  // };
  const conditionalStyles = isChecked
    ? {
        borderColor: 'green.500',
        borderWidth: '2px',
        color: 'green.500',
        bg: 'green.50',
      }
    : {};

  return (
    <Box as="label" tabIndex={0}>
      <input {...getInputProps()} tabIndex={-1} />
      <HStack
        {...getRadioProps()}
        p={6}
        alignItems="center"
        spacing={isChecked ? 4 : 0}
        _hover={{ bgColor: 'green.50' }}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="base"
        cursor="pointer"
        {...conditionalStyles}
        data-testid={props.value}
      >
        {isChecked && <Image src={Checkmark} boxSize={6} />}
        <Stack spacing={0}>
          <Text fontWeight="bold">{label}</Text>
          {isChecked && subtitle && <Text fontSize="sm">{subtitle}</Text>}
        </Stack>
      </HStack>
    </Box>
  );
};

export const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const { defaultValue, name, onChange } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue,
    name,
    onChange,
  });

  return (
    <Stack {...getRootProps()}>
      {props.options.map((option) => {
        return (
          <RadioButton
            label={option.label}
            value={option.value}
            subtitle={option.subtitle}
            key={option.value}
            isChecked={props.defaultValue == option.value}
            {...getRadioProps({ value: option.value })}
          />
        );
      })}
    </Stack>
  );
};
