import { PaymentRequestDetailsStatusEnum } from 'api/wallet-app';

export function paymentRequestStatusBadgeColor(
  status: PaymentRequestDetailsStatusEnum | undefined
) {
  switch (status) {
    case PaymentRequestDetailsStatusEnum.Pending:
      return 'yellow';
    case PaymentRequestDetailsStatusEnum.Canceled:
    case PaymentRequestDetailsStatusEnum.Declined:
      return 'gray';
    case PaymentRequestDetailsStatusEnum.Paid:
      return 'green';
  }
}
