import React from 'react';
import pig from 'images/Pig.svg';
import {
  Flex,
  Heading,
  Text,
  Button,
  Image,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const MicrodepositNotification = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    handleClose();
    navigate('/accounts');
  };

  return (
    <>
      <ModalBody>
        <Flex alignItems="center" justifyContent="center" mt="2rem">
          <Image src={pig} alt="Verify Microdeposit Code" boxSize="195px" />
        </Flex>
        <Heading fontSize="32px" my="1.5rem" textAlign="center">
          Verify Microdeposit Code
        </Heading>
        <Text mb="1rem" textAlign="center">
          A $0.01 deposit with a 3-letter verification code has been added to
          your linked bank account.
        </Text>
      </ModalBody>
      <ModalFooter>
        <Flex flex="1" justifyContent="center">
          <Button width="100%" onClick={handleClick}>
            Verify Account
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
