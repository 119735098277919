import { Heading, Text, Skeleton, Stack } from '@chakra-ui/react';
import currency from 'currency.js';
import { useWalletBalance } from 'hooks/api';

export default function WalletBalanceHeader() {
  const { data, isLoading } = useWalletBalance();

  return (
    <Stack
      flex={{ base: 1, md: 2 }}
      align="flex-start"
      background="white"
      boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
      borderRadius="lg"
      h="fit-content"
      p={2}
      as={Skeleton}
      isLoaded={!isLoading}
      data-testid="balance-container"
    >
      <Text fontSize="14px" color="#69788A" fontFamily="inter">
        Wallet Available Balance
      </Text>
      <Heading as="h3" my="8px">
        {currency(data?.balance ?? '0').format()}
      </Heading>
      <Text fontSize="12px" color="#69788A" fontFamily="inter">
        Evolve Bank & Trust, Member FDIC
      </Text>
    </Stack>
  );
}
