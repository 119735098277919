import React from 'react';
import { PanelWrapper, Body, Footer } from 'components/layout/onboarding';
import { Button, Heading, Icon, Text } from '@chakra-ui/react';
import { IoReloadSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

type ErrorProps = {
  resetError: () => void;
};

const NetworkError = ({ resetError }: ErrorProps) => {
  const { t } = useTranslation();

  const handleError = () => {
    resetError();
    window.location.reload();
  };

  return (
    <PanelWrapper>
      <Heading mb="1rem">{t('errors.technicalDifficulties')}</Heading>
      <Body>
        <Text height="200px">
          {t('errors.havingSomeIssuesReachingPage')}&nbsp;
          <Text as="span" color="blue.400" fontWeight="bold">
            <a href="mailto:support@bushelwallet.com">
              {t('errors.supportEmail')}
            </a>
          </Text>
          .
        </Text>
      </Body>
      <Footer>
        <Button onClick={handleError}>
          <Icon as={IoReloadSharp} fontSize="18px" mr="0.5rem" />
          {t('button.retry')}
        </Button>
      </Footer>
    </PanelWrapper>
  );
};

export default NetworkError;
