import React from 'react';
import { Flex } from '@chakra-ui/react';

export const Footer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Flex
      className="content__footer"
      height="50px"
      fontSize="14px"
      alignItems="center"
      justifyContent="center"
      mt="auto"
      py="2rem"
    >
      {children}
    </Flex>
  );
};
