import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoAddCircleOutline } from 'react-icons/io5';
import {
  Button,
  Divider,
  Heading,
  HStack,
  Stack,
  Image,
  Text,
  Center,
  Spinner,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { UserListItem, UserListItemTypeEnum } from 'api/wallet-app';
import { CardContainer } from 'components/layout/app';
import { useUserList } from 'hooks/api';
import NoActivityImage from 'images/NoActivity.svg';
import { BackButton } from '../components/back-button';
import RemoveStaffModal from './remove-staff-modal';

export default function ManageStaff() {
  const [userToRemove, setUserToRemove] = useState<UserListItem>();
  const { data, isRefetching, isLoading } = useUserList();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  return (
    <>
      <Stack spacing={6} pb={isMobile ? 16 : 6}>
        <BackButton />
        <CardContainer spacing={6}>
          {isLoading || isRefetching ? (
            <Center height="20vh">
              <Spinner color="blue.400" />
            </Center>
          ) : (
            <>
              <HStack justify="space-between">
                <Heading as="h3" size="md">
                  Manage Staff
                </Heading>
                <Button
                  variant="outline"
                  size="sm"
                  maxW="fit-content"
                  iconSpacing="10px"
                  leftIcon={<IoAddCircleOutline size={24} />}
                  onClick={() => {
                    navigate('invite-user');
                  }}
                >
                  Add User
                </Button>
              </HStack>
              <Stack spacing={10}>
                <Stack spacing={5}>
                  <Text
                    fontFamily="robotoFlex"
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="xs"
                    color="textSecondary"
                    letterSpacing="1.2px"
                  >
                    Wallet Users
                  </Text>
                  <Stack spacing={3}>
                    {data?.users.map((user, index) => (
                      <Stack key={user.id} spacing={3}>
                        <HStack justify="space-between" key={user.id}>
                          <Stack spacing={1} overflow="hidden" wrap="nowrap">
                            <Text>{`${user.firstName} ${user.lastName}`}</Text>
                            <Text fontSize="sm" color="textSecondary">
                              {user.email}
                            </Text>
                          </Stack>
                          {user.type === UserListItemTypeEnum.WalletOwner ? (
                            <Text color="textSecondary">Wallet Admin</Text>
                          ) : (
                            <Button
                              variant="outline"
                              size="sm"
                              maxW="fit-content"
                              colorScheme="red"
                              onClick={() => {
                                setUserToRemove(user);
                                onOpen();
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </HStack>
                        {(data.users.length === 1 ||
                          index !== data.users.length - 1) && (
                          <Divider borderColor="border" borderWidth="1px" />
                        )}
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
                <Stack spacing={5}>
                  <Text
                    fontFamily="robotoFlex"
                    textTransform="uppercase"
                    fontWeight="bold"
                    fontSize="xs"
                    color="textSecondary"
                    letterSpacing="1.2px"
                  >
                    Invites Sent
                  </Text>
                  <Stack spacing={3}>
                    {data?.invites.map((invite, index) => (
                      <Stack spacing={3} key={invite.id}>
                        <HStack key={invite.id} justify="space-between">
                          <Stack spacing={1} overflow="hidden" wrap="nowrap">
                            <Text>{`${invite.firstName} ${invite.lastName}`}</Text>
                            <Text fontSize="sm" color="textSecondary">
                              {invite.email}
                            </Text>
                          </Stack>
                          <Text>Invite Sent</Text>
                        </HStack>
                        {index !== data.invites.length - 1 && (
                          <Divider borderColor="border" borderWidth="1px" />
                        )}
                      </Stack>
                    ))}
                  </Stack>
                  {data?.invites.length === 0 && (
                    <Stack align="center" spacing={6}>
                      <Image src={NoActivityImage} h="80px" />
                      <Text color="textSecondary">No sent Invites</Text>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </CardContainer>
      </Stack>
      {userToRemove && (
        <RemoveStaffModal
          isOpen={isOpen}
          onClose={onClose}
          user={userToRemove}
        />
      )}
    </>
  );
}
