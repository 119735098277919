import React from 'react';
import { Body, Header, Footer } from 'components/layout/onboarding';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { PersonDtoRolesEnum } from 'api/wallet-app';
import { useSelector } from '@xstate/react';
import { SubmitButton } from 'components/submitButton';
import { useForm, SubmitHandler } from 'react-hook-form';
import { PersonalInfoForm } from 'pages/Onboarding/sharedComponents/PersonalInfo';
import { PersonalInfoType } from 'pages/Onboarding/NoController/PersonalInfo';

export const ControllerInfo = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);
  const existingDateOfBirth = React.useRef<boolean>(false);

  const getDefaultValues = () => {
    if (!context) return;
    const controller: PersonalInfoType = context.people?.find((person) =>
      person.roles?.includes(PersonDtoRolesEnum.Controller)
    ) as PersonalInfoType;
    //@ts-ignore
    if (controller?.dateOfBirth && isNaN(controller.dateOfBirth)) {
      existingDateOfBirth.current = true;
    }

    return controller;
  };

  const {
    handleSubmit: hookFormSubmit,
    formState: { errors },
    register,
    resetField,
    setValue,
  } = useForm<PersonalInfoType>({
    defaultValues: getDefaultValues(),
  });

  const handleSubmit: SubmitHandler<PersonalInfoType> = (data) => {
    if (!onboardingActor) return;

    const birthdate = `${data.year}-${data.month}-${data.day}`;

    const payload = existingDateOfBirth.current
      ? {
          ...data,
          roles: [PersonDtoRolesEnum.Controller],
        }
      : {
          ...data,
          dateOfBirth: birthdate,
          roles: [PersonDtoRolesEnum.Controller],
        };

    onboardingActor.send({
      type: 'submitInfo',
      value: { payload, target: 'person' },
    });
  };

  return (
    <div>
      <Header title="Your Personal Info" />
      <Body>
        <form
          id="controller-info"
          data-testid="controller-info"
          onSubmit={hookFormSubmit(handleSubmit)}
          noValidate
        >
          <PersonalInfoForm
            register={register}
            errors={errors}
            hasDateOfBirth={existingDateOfBirth.current}
            resetField={resetField}
            setValue={setValue}
          />
        </form>
      </Body>
      <Footer>
        <SubmitButton form="controller-info" />
      </Footer>
    </div>
  );
};
