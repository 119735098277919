/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CountryCode
 */
export interface CountryCode {
  /**
   *
   * @type {string}
   * @memberof CountryCode
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CountryCode
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CountryCode
   */
  isActive: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CountryCode
   */
  active?: boolean;
}

/**
 * Check if a given object implements the CountryCode interface.
 */
export function instanceOfCountryCode(value: object): value is CountryCode {
  if (!('code' in value) || value['code'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('isActive' in value) || value['isActive'] === undefined) return false;
  return true;
}

export function CountryCodeFromJSON(json: any): CountryCode {
  return CountryCodeFromJSONTyped(json, false);
}

export function CountryCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CountryCode {
  if (json == null) {
    return json;
  }
  return {
    code: json['code'],
    name: json['name'],
    isActive: json['isActive'],
    active: json['active'] == null ? undefined : json['active'],
  };
}

export function CountryCodeToJSON(value?: CountryCode | null): any {
  if (value == null) {
    return value;
  }
  return {
    code: value['code'],
    name: value['name'],
    isActive: value['isActive'],
    active: value['active'],
  };
}
