import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useWalletsClient from './useWalletsClient';

export const WALLET_BALANCE_QUERY_KEY = 'wallet-balance';

export default function useWalletBalance() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const walletsClient = useWalletsClient();
  return useQuery({
    queryKey: [WALLET_BALANCE_QUERY_KEY],
    queryFn: () => {
      return walletsClient.getWalletBalance({
        xWalletId: walletId,
      });
    },
  });
}
