import {
  Flex,
  Heading,
  Icon,
  Button,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import ContactUsModalBody from 'components/contact-us-modal-body';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';
import { CloseIcon } from '@chakra-ui/icons';

export const Fail = ({ handleClose }: { handleClose: () => void }) => {
  const { send } = LoanPaymentContext.useActorRef();

  const handleRetry = () => {
    send({ type: 'RESET', paymentAmount: '0.00', paymentSource: undefined });
  };

  const { isOpen, onOpen, onClose: onContactUsClose } = useDisclosure();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      minHeight="300px"
    >
      <Flex alignItems="flex-start" width="100%" mt="1rem">
        <CloseIcon cursor="pointer" fontSize="12px" onClick={handleClose} />
      </Flex>

      <Flex flexDirection="column" mt="1rem" alignItems="center">
        <Icon as={WarningTwoIcon} color="red" fontSize="42px" />
        <Heading my="1rem"> Payment Failed </Heading>
        <Text>There was a problem sending this payment</Text>
      </Flex>
      <VStack width="100%" mt="2rem" spacing="13px">
        <Button variant="secondary" onClick={onOpen}>
          Help/Support
        </Button>
        <Button variant="secondary" onClick={handleRetry}>
          Retry
        </Button>
        <Button onClick={handleClose} width="100%">
          Done
        </Button>
      </VStack>
      <Modal isOpen={isOpen} onClose={onContactUsClose}>
        <ModalOverlay backgroundColor="transparent" />
        <ModalContent>
          <ContactUsModalBody onClose={onContactUsClose} />
        </ModalContent>
      </Modal>
    </Flex>
  );
};
