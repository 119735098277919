/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingAddressDto } from './OnboardingAddressDto';
import {
  OnboardingAddressDtoFromJSON,
  OnboardingAddressDtoFromJSONTyped,
  OnboardingAddressDtoToJSON,
} from './OnboardingAddressDto';

/**
 *
 * @export
 * @interface BusinessDto
 */
export interface BusinessDto {
  /**
   * Business Structure.
   * @type {string}
   * @memberof BusinessDto
   */
  businessStructure?: BusinessDtoBusinessStructureEnum;
  /**
   *
   * @type {OnboardingAddressDto}
   * @memberof BusinessDto
   */
  businessAddress?: OnboardingAddressDto;
  /**
   * Business’s 10 digit phone number. No hyphens or other separators.
   * @type {string}
   * @memberof BusinessDto
   */
  businessPhone?: string;
  /**
   * Business' ein.
   * @type {string}
   * @memberof BusinessDto
   */
  ein?: string;
  /**
   * Registered business name.
   * @type {string}
   * @memberof BusinessDto
   */
  businessName?: string;
  /**
   * Doing business as.
   * @type {string}
   * @memberof BusinessDto
   */
  doingBusinessAs?: string;
  /**
   * The industry classification code that corresponds to the business owner's business.
   * @type {string}
   * @memberof BusinessDto
   */
  industryClassificationCode?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum BusinessDtoBusinessStructureEnum {
  SoleProp = 'SOLE_PROP',
  Llc = 'LLC',
  Partnership = 'PARTNERSHIP',
  Corporation = 'CORPORATION',
}

/**
 * Check if a given object implements the BusinessDto interface.
 */
export function instanceOfBusinessDto(value: object): value is BusinessDto {
  return true;
}

export function BusinessDtoFromJSON(json: any): BusinessDto {
  return BusinessDtoFromJSONTyped(json, false);
}

export function BusinessDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessDto {
  if (json == null) {
    return json;
  }
  return {
    businessStructure:
      json['businessStructure'] == null ? undefined : json['businessStructure'],
    businessAddress:
      json['businessAddress'] == null
        ? undefined
        : OnboardingAddressDtoFromJSON(json['businessAddress']),
    businessPhone:
      json['businessPhone'] == null ? undefined : json['businessPhone'],
    ein: json['ein'] == null ? undefined : json['ein'],
    businessName:
      json['businessName'] == null ? undefined : json['businessName'],
    doingBusinessAs:
      json['doingBusinessAs'] == null ? undefined : json['doingBusinessAs'],
    industryClassificationCode:
      json['industryClassificationCode'] == null
        ? undefined
        : json['industryClassificationCode'],
  };
}

export function BusinessDtoToJSON(value?: BusinessDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    businessStructure: value['businessStructure'],
    businessAddress: OnboardingAddressDtoToJSON(value['businessAddress']),
    businessPhone: value['businessPhone'],
    ein: value['ein'],
    businessName: value['businessName'],
    doingBusinessAs: value['doingBusinessAs'],
    industryClassificationCode: value['industryClassificationCode'],
  };
}
