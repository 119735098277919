import {
  Heading,
  Stack,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  TabList,
  Circle,
  Text,
  HStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { usePaymentRequestList } from 'hooks/api';
import { useSearchParams } from 'react-router-dom';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  OPEN_REQUESTS_SEARCH_PARAM,
  TRANSACTION_HISTORY_SEARCH_PARAM,
} from './activity-page-search-params';
import { OpenRequests } from './components';
import { TransactionHistory } from './TransactionHistory';

export default function Activity() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = usePaymentRequestList();
  const [isMobile] = useMediaQuery('(max-width: 480px)');

  function getDefaultTabIndex() {
    switch (searchParams.get(ACTIVITY_SEARCH_PARAM_KEY)) {
      case TRANSACTION_HISTORY_SEARCH_PARAM:
        return 0;
      case OPEN_REQUESTS_SEARCH_PARAM:
        return 1;
      default:
        return 0;
    }
  }
  return (
    <Stack spacing={[4, 6]}>
      <Heading as="h3" size="xl">
        Activity
      </Heading>
      <Tabs defaultIndex={getDefaultTabIndex()}>
        <TabList color="textSecondary">
          <Tab
            py={3}
            flex={!isMobile ? undefined : 16}
            fontWeight="semibold"
            _selected={{ color: 'blue.400', borderColor: 'blue.400' }}
            _focus={{}}
            _active={{}}
            onClick={() => {
              searchParams.set(
                ACTIVITY_SEARCH_PARAM_KEY,
                TRANSACTION_HISTORY_SEARCH_PARAM
              );
              setSearchParams(searchParams);
            }}
          >
            Transaction History
          </Tab>
          <Tab
            py={3}
            flex={!isMobile ? undefined : 14}
            fontWeight="semibold"
            _selected={{ color: 'blue.400', borderColor: 'blue.400' }}
            _focus={{}}
            _active={{}}
            onClick={() => {
              searchParams.set(
                ACTIVITY_SEARCH_PARAM_KEY,
                OPEN_REQUESTS_SEARCH_PARAM
              );
              setSearchParams(searchParams);
            }}
          >
            <HStack spacing="6px">
              {data?.paymentRequests.length && (
                <Circle bgColor="red.400" minH={2} minW={2} />
              )}

              <Text>Open Requests</Text>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels pt={[4, 6]} pb={8}>
          <TabPanel p={0}>
            <TransactionHistory />
          </TabPanel>
          <TabPanel p={0}>
            <OpenRequests />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
