import { memo, useEffect, useState } from 'react';
import {
  FormLabel,
  Flex,
  Input,
  HStack,
  Text,
  Stack,
  FormControl,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { WarningIcon } from '@chakra-ui/icons';
import { sub, isBefore, isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const monthInputName = 'month';
const dayInputName = 'day';
const yearInputName = 'year';

function DateOfBirthInputToBeMemoized() {
  const { t } = useTranslation();
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
    watch,
  } = methods;
  const [dobErrorMessage, setDobErrorMessage] = useState<string | undefined>(
    undefined
  );

  // Casting as string means the intended use in an actual react-hook-form Form is
  // for the inputs to be of type string, not number.
  const month = watch(monthInputName) as string;
  const day = watch(dayInputName) as string;
  const year = watch(yearInputName) as string;

  useEffect(() => {
    if (year.length === 4 && day.length > 0 && month.length > 0) {
      const fullDateOfBirth = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
      const minYear = sub(new Date(), {
        years: 18,
      });
      const maxYear = sub(new Date(), {
        years: 125,
      });

      if (!isBefore(fullDateOfBirth, minYear)) {
        setDobErrorMessage(i18next.t('form.mustBe18YearsOfAge').toString());
      } else if (!isAfter(fullDateOfBirth, maxYear)) {
        setDobErrorMessage(
          i18next.t('form.mustBeunder125YearsOfAge').toString()
        );
      } else {
        setDobErrorMessage(undefined);
      }
    } else {
      if (year.length < 4 && day.length < 1 && month.length < 1) {
        setDobErrorMessage(undefined);
      } else {
        return;
      }
    }
  }, [year, month, day]);

  return (
    <FormControl as={Flex} flexDir="column" id="fieldset" isRequired>
      <FormLabel fontWeight="bold">{t('form.DOB')}</FormLabel>
      <HStack>
        <Flex>
          <Input
            {...register(monthInputName, {
              required: { value: true, message: t('form.monthRequired') },
              max: { value: 12, message: t('form.monthMustBe1Through12') },
              pattern: {
                value: /^[0-9]*$/,
                message: t('form.monthMustBeOnlyNumber'),
              },
            })}
            minLength={1}
            maxLength={2}
            placeholder="MM"
            type="tel"
            isInvalid={
              !!errors[monthInputName] || dobErrorMessage !== undefined
            }
          />
        </Flex>
        <Flex>
          <Input
            {...register(dayInputName, {
              required: { value: true, message: t('form.dateRequired') },
              max: { value: 31, message: t('form.enterValidDay') },
              pattern: {
                value: /^[0-9]*$/,
                message: t('form.dayMustBeOnlyNumber'),
              },
            })}
            minLength={1}
            maxLength={2}
            placeholder="DD"
            type="tel"
            isInvalid={!!errors[dayInputName] || dobErrorMessage !== undefined}
          />
        </Flex>
        <Flex>
          <Input
            {...register(yearInputName, {
              required: { value: true, message: t('form.yearRequired') },
              minLength: {
                value: 4,
                message: t('form.yearMustBe4Numbers'),
              },
              maxLength: {
                value: 4,
                message: t('form.yearMustBe4Numbers'),
              },
              pattern: {
                value: /^[0-9]*$/,
                message: t('form.yearMustBeOnlyNumber'),
              },
            })}
            minLength={4}
            maxLength={4}
            placeholder="YYYY"
            type="tel"
            isInvalid={!!errors[yearInputName] || dobErrorMessage !== undefined}
          />
        </Flex>
      </HStack>
      <Stack spacing={1} pt={2}>
        {Object.entries(errors).map(([key, value], index) => {
          if (
            key === monthInputName ||
            key == dayInputName ||
            key == yearInputName
          ) {
            return (
              <HStack key={index} fontSize="sm" color="red.500">
                <WarningIcon />
                <Text>{value?.message?.toString()}</Text>
              </HStack>
            );
          }
        })}
        {dobErrorMessage && (
          <HStack fontSize="sm" color="red.500">
            <WarningIcon />
            <Text>{dobErrorMessage}</Text>
          </HStack>
        )}
      </Stack>
    </FormControl>
  );
}

export const DateOfBirthInput = memo(DateOfBirthInputToBeMemoized);
