import { Icon, Flex, Text } from '@chakra-ui/react';
import { TitleBadge } from './TitleBadge';
import { IconType } from 'react-icons';
import { IoTrashOutline } from 'react-icons/io5';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';

export enum BadgeValue {
  'verified' = 'verified',
  'readyToSubmit' = 'ready to submit',
  'needsInfo' = 'needs info',
  'notStarted' = 'not started',
  'furtherDocumentation' = 'further documentation',
}

export type IOverviewCard = {
  title?: string;
  value: BadgeValue;
  handler?: () => void;
  icon?: IconType;
  iconText?: string;
  id?: string;
  canDelete?: boolean;
};

const getBorder = (value: BadgeValue) => {
  switch (value) {
    case BadgeValue.verified:
      return 'solid 1px #00FF00';
    case BadgeValue.readyToSubmit:
      return 'solid 1px green';
    case BadgeValue.needsInfo:
      return 'dashed 1px orange';
    case BadgeValue.notStarted:
      return 'solid 1px #2D374826';
    case BadgeValue.furtherDocumentation:
      return 'dotted 1px red';
    default:
      return 'solid 1px #2D374826';
  }
};

export const Card = (props: IOverviewCard) => {
  const {
    id,
    title,
    value,
    handler,
    icon,
    iconText,
    canDelete = false,
  } = props;
  const border = getBorder(value);
  const onboardingActor = OnboardingContext.useActorRef();

  const removePerson = () => {
    if (!id) return;
    onboardingActor.send({ type: 'removePerson', value: id });
  };

  return (
    <Flex
      border={border}
      borderRadius={3}
      p={2}
      direction="column"
      mb={2}
      background="gray.50"
      data-id={id}
    >
      <Flex mb={2}>
        <TitleBadge value={value} />
      </Flex>
      <Text>{title}</Text>
      <Flex>
        <Flex
          cursor="pointer"
          alignItems="center"
          fontWeight="bold"
          justifyContent="flex-end"
          width="100%"
        >
          {canDelete && (
            <Flex
              color="red.400"
              mr="1rem"
              fontSize={14}
              onClick={removePerson}
            >
              <Icon as={IoTrashOutline} w={5} h={5} alignSelf="flex-start" />{' '}
              Remove
            </Flex>
          )}
          <Flex color="blue.400" alignItems="center" onClick={handler}>
            {icon && <Icon as={icon} mr={2} onClick={handler} />}
            {iconText && <Text>{iconText}</Text>}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
