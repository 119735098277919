import {
  Divider,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  IoInformationCircleOutline,
  IoCallOutline,
  IoLibraryOutline,
} from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import ContactUsModalBody from 'components/contact-us-modal-body';
import { SettingsInfoResponse } from 'api/wallet-app';
import {
  LinkItem,
  SettingsCard,
} from 'pages/WalletApp/settings/components/index';

export default function HelpAndSupport({
  data,
}: {
  data: SettingsInfoResponse | undefined;
}) {
  const { t } = useTranslation();
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <SettingsCard heading={t('wallet.settings.helpAndSupport')}>
      <Stack
        divider={<Divider borderColor="gray.300" />}
        color="gray.700"
        spacing={3}
      >
        <LinkItem
          title={t('wallet.settings.frequentlyAskedQuestions')}
          icon={IoInformationCircleOutline}
          linkTarget={data?.links.faq}
        />
        <HStack
          justifyContent="space-between"
          width="100%"
          _hover={{ cursor: 'pointer', color: 'blue.500' }}
          onClick={onOpen}
        >
          <HStack spacing={4}>
            <Icon as={IoCallOutline} boxSize={6} />
            <Flex>{t('wallet.settings.contactUs')}</Flex>
          </HStack>
          <Icon as={ChevronRightIcon} boxSize={6} />
        </HStack>
        <LinkItem
          title={t('wallet.settings.legalAgreements')}
          icon={IoLibraryOutline}
          linkTarget={data?.links.policies}
        />
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ContactUsModalBody onClose={onClose} />
        </ModalContent>
      </Modal>
    </SettingsCard>
  );
}
