/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
  CountryCodeFromJSON,
  CountryCodeFromJSONTyped,
  CountryCodeToJSON,
} from './CountryCode';

/**
 *
 * @export
 * @interface GetCountryCodesResponse
 */
export interface GetCountryCodesResponse {
  /**
   *
   * @type {Array<CountryCode>}
   * @memberof GetCountryCodesResponse
   */
  codes: Array<CountryCode>;
}

/**
 * Check if a given object implements the GetCountryCodesResponse interface.
 */
export function instanceOfGetCountryCodesResponse(
  value: object
): value is GetCountryCodesResponse {
  if (!('codes' in value) || value['codes'] === undefined) return false;
  return true;
}

export function GetCountryCodesResponseFromJSON(
  json: any
): GetCountryCodesResponse {
  return GetCountryCodesResponseFromJSONTyped(json, false);
}

export function GetCountryCodesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCountryCodesResponse {
  if (json == null) {
    return json;
  }
  return {
    codes: (json['codes'] as Array<any>).map(CountryCodeFromJSON),
  };
}

export function GetCountryCodesResponseToJSON(
  value?: GetCountryCodesResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    codes: (value['codes'] as Array<any>).map(CountryCodeToJSON),
  };
}
