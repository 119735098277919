/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Remove a failed funding source request
 * @export
 * @interface RemoveFailedFundingSourceRequest
 */
export interface RemoveFailedFundingSourceRequest {
  /**
   *
   * @type {string}
   * @memberof RemoveFailedFundingSourceRequest
   */
  fundingSourceId: string;
}

/**
 * Check if a given object implements the RemoveFailedFundingSourceRequest interface.
 */
export function instanceOfRemoveFailedFundingSourceRequest(
  value: object
): value is RemoveFailedFundingSourceRequest {
  if (!('fundingSourceId' in value) || value['fundingSourceId'] === undefined)
    return false;
  return true;
}

export function RemoveFailedFundingSourceRequestFromJSON(
  json: any
): RemoveFailedFundingSourceRequest {
  return RemoveFailedFundingSourceRequestFromJSONTyped(json, false);
}

export function RemoveFailedFundingSourceRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveFailedFundingSourceRequest {
  if (json == null) {
    return json;
  }
  return {
    fundingSourceId: json['fundingSourceId'],
  };
}

export function RemoveFailedFundingSourceRequestToJSON(
  value?: RemoveFailedFundingSourceRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundingSourceId: value['fundingSourceId'],
  };
}
