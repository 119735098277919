import {
  FundingSourceDetailsDto,
  FundingSourceDto,
  FundingSourceDtoStatusEnum,
  FundingSourceDtoTypeEnum,
  LenderDto,
  LenderDtoLoanAttachmentStrategiesEnum,
} from 'api/wallet-app';

export const fundingSourceList: FundingSourceDto[] = [
  {
    fundingSourceId: '11005c77-fd72-4359-a691-ce6175aa4650',
    accountNumber: '0000',
    name: 'Plaid Active',
    financialInstitutionName: '',
    activeTransactions: false,
    isVerified: true,
    needsUserValidation: false,
    verificationFailed: false,
    type: FundingSourceDtoTypeEnum.Plaid,
    status: FundingSourceDtoStatusEnum.Active,
    availableFunds: '100',
  },
  {
    fundingSourceId: '4b4444b3-0863-4aeb-a388-f385f5a7b81a',
    accountNumber: '0001',
    name: 'Plaid Pending',
    financialInstitutionName: '',
    activeTransactions: false,
    isVerified: false,
    needsUserValidation: false,
    verificationFailed: false,
    type: FundingSourceDtoTypeEnum.Plaid,
    status: FundingSourceDtoStatusEnum.Pending,
  },
  {
    fundingSourceId: '30a93301-7fc7-4084-8bc9-a141a3b0d7d2',
    accountNumber: '0002',
    name: 'Liability Active',
    financialInstitutionName: 'Financial Institution 1',
    activeTransactions: false,
    isVerified: true,
    needsUserValidation: false,
    verificationFailed: false,
    type: FundingSourceDtoTypeEnum.Liability,
    status: FundingSourceDtoStatusEnum.Active,
    availableFunds: '200',
  },
  {
    fundingSourceId: '21a3665e-42c1-4660-a3ef-87315b1617d7',
    accountNumber: '0003',
    name: 'Liability Pending',
    financialInstitutionName: 'Financial Institution 2',
    activeTransactions: false,
    isVerified: false,
    // needsUserValidation: true,
    needsUserValidation: false,
    verificationFailed: false,
    type: FundingSourceDtoTypeEnum.Liability,
    status: FundingSourceDtoStatusEnum.Pending,
  },
];

export const lenderList: LenderDto[] = [
  {
    id: '4fec0a74-906a-4db8-83bc-7705378fef97',
    loanAttachmentStrategies: [LenderDtoLoanAttachmentStrategiesEnum.Compeer],
    name: 'Compeer Lender 1',
    byline: 'By line 1',
  },
  {
    id: 'eeac332a-da2f-4767-b5c2-69214acfffd9',
    loanAttachmentStrategies: [LenderDtoLoanAttachmentStrategiesEnum.Compeer],
    name: 'Compeer Lender 2',
    byline: 'By line 2',
  },
  {
    id: '81e76973-b180-4588-a629-1c17a633a97e',
    loanAttachmentStrategies: [LenderDtoLoanAttachmentStrategiesEnum.Compeer],
    name: 'Compeer Lender 3',
    byline: 'By line 3',
  },
];

export const plaidFundingSourceDetails: FundingSourceDetailsDto = {
  type: 'PLAID',
  name: 'Plaid Funding Source 1',
  accountNumberLastFour: '1234',
  financialInstitutionName: 'Financial Institution 1',
  isVerified: true,
  needsUserValidation: false,
  verificationFailed: false,
};

export const compeerFundingSourceDetails: FundingSourceDetailsDto = {
  type: 'COMPEER',
  name: 'Compeer Line of Credit',
  nickname: 'Penguin Financial',
  accountNumberLastFour: '5678',
  providerName: 'Compeer',
  recentTransactions: [],
};
