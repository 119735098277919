import { useKeycloak } from '@react-keycloak/web';
import { getUserInfo } from 'api/keycloak';
import { useQuery } from '@tanstack/react-query';

export const KEYCLOAK_USER_INFO_QUERY_KEY = 'keycloak-user-info';

export default function useKeycloakUserInfo() {
  const { keycloak } = useKeycloak();
  return useQuery({
    queryKey: [KEYCLOAK_USER_INFO_QUERY_KEY],
    queryFn: async () => {
      return getUserInfo({
        baseUrl: keycloak.authServerUrl,
        realm: keycloak.realm,
        token: keycloak.token,
      });
    },
  });
}
