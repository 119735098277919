import BioStar from 'images/biostar.png';
import { Flex, Button, Heading, Text, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecordAppView } from 'hooks';
import { RecordScreenViewRequestScreenNameEnum } from 'api/wallet-app';

export const LineOfCredit = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const { mutate: recordAppView } = useRecordAppView();

  const handleDismiss = () => {
    recordAppView(RecordScreenViewRequestScreenNameEnum.CompeerWelcomeScreen);
    onClose();
  };

  const addFundingSource = async () => {
    onClose();
    navigate('/accounts?addLineOfCredit=true');
  };

  return (
    <Flex flex="1" flexDirection="column" p="0 0.6rem" color="#2D3748">
      <Flex flexDirection="column" alignItems="center" my="1.3rem">
        <Image src={BioStar} width={['100px', '80px']} />
      </Flex>
      <Heading fontSize="24px" mt="0.5rem" lineHeight="2rem">
        Access your Operating Line of Credit with Bushel Wallet
      </Heading>
      <Heading fontSize="18px" mt="1.5rem">
        🤝 Link Your Line of Credit with Ease:
      </Heading>
      <Text my="1rem" fontSize="1rem">
        Effortlessly connect your farm operating loan to your Wallet account
        with participating Farm Credit Services partners. This streamlined
        process brings visibility of your loan details to see available credit,
        accrued interest, and loan terms. Most importantly, you will have the
        ability to draw from your balance and issue payments against your loan!
      </Text>
      <Heading fontSize="18px" my="1rem" lineHeight="1.5rem">
        🚜 Join us in pioneering the future of agricultural finance.
      </Heading>
      <Button
        mt={[0, '1rem']}
        size="lg"
        fontSize="1rem"
        textTransform="none"
        onClick={addFundingSource}
      >
        Link Now
      </Button>
      <Button variant="ghost" mt="1rem" onClick={handleDismiss}>
        Remind Me Later
      </Button>
    </Flex>
  );
};
