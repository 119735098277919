/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AttachCompeerLoanRequest,
  CreateFundingSourceRequest,
  DefaultErrorResponse,
  GetActiveFundingSourceResponse,
  GetFundingSourceResponse,
  GetLendersDetailsResponse,
  GetLendersListResponse,
  GetLinkTokenResponse,
  PlaidLinkTokenRequest,
  RemoveFailedFundingSourceRequest,
  RemoveFundingSourceRequest,
  RemoveFundingSourcesResponse,
  RenameFundingSourceRequest,
  RenameFundingSourceResponse,
  ReorderFundingSourcesRequest,
  ReorderFundingSourcesResponse,
  ViolationsError,
} from '../models/index';
import {
  AttachCompeerLoanRequestFromJSON,
  AttachCompeerLoanRequestToJSON,
  CreateFundingSourceRequestFromJSON,
  CreateFundingSourceRequestToJSON,
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  GetActiveFundingSourceResponseFromJSON,
  GetActiveFundingSourceResponseToJSON,
  GetFundingSourceResponseFromJSON,
  GetFundingSourceResponseToJSON,
  GetLendersDetailsResponseFromJSON,
  GetLendersDetailsResponseToJSON,
  GetLendersListResponseFromJSON,
  GetLendersListResponseToJSON,
  GetLinkTokenResponseFromJSON,
  GetLinkTokenResponseToJSON,
  PlaidLinkTokenRequestFromJSON,
  PlaidLinkTokenRequestToJSON,
  RemoveFailedFundingSourceRequestFromJSON,
  RemoveFailedFundingSourceRequestToJSON,
  RemoveFundingSourceRequestFromJSON,
  RemoveFundingSourceRequestToJSON,
  RemoveFundingSourcesResponseFromJSON,
  RemoveFundingSourcesResponseToJSON,
  RenameFundingSourceRequestFromJSON,
  RenameFundingSourceRequestToJSON,
  RenameFundingSourceResponseFromJSON,
  RenameFundingSourceResponseToJSON,
  ReorderFundingSourcesRequestFromJSON,
  ReorderFundingSourcesRequestToJSON,
  ReorderFundingSourcesResponseFromJSON,
  ReorderFundingSourcesResponseToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
} from '../models/index';

export interface AddFundingSourceRequest {
  xWalletId: string;
  createFundingSourceRequest: CreateFundingSourceRequest;
  idempotencyKey?: string;
}

export interface AttachCompeerLoanOperationRequest {
  xWalletId: string;
  attachCompeerLoanRequest: AttachCompeerLoanRequest;
  idempotencyKey?: string;
}

export interface GeneratePlaidLinkTokenRequest {
  xWalletId: string;
  plaidLinkTokenRequest?: PlaidLinkTokenRequest;
}

export interface GetActiveFundingSourcesRequest {
  xWalletId: string;
  transactional?: boolean;
  includeLiabilityFundsAvailable?: boolean;
  type?: GetActiveFundingSourcesTypeEnum;
}

export interface GetFundingSourceDetailsRequest {
  xWalletId: string;
  fundingSourceId: string;
}

export interface GetLendersDetailsRequest {
  xWalletId: string;
  id: string;
}

export interface GetLendersListRequest {
  xWalletId: string;
}

export interface RemoveFailedFundingSourceOperationRequest {
  xWalletId: string;
  removeFailedFundingSourceRequest: RemoveFailedFundingSourceRequest;
}

export interface RemoveFundingSourceOperationRequest {
  xWalletId: string;
  removeFundingSourceRequest: RemoveFundingSourceRequest;
  idempotencyKey?: string;
}

export interface RenameFundingSourceOperationRequest {
  xWalletId: string;
  renameFundingSourceRequest: RenameFundingSourceRequest;
}

export interface ReorderFundingSourcesOperationRequest {
  xWalletId: string;
  reorderFundingSourcesRequest: ReorderFundingSourcesRequest;
  idempotencyKey?: string;
}

/**
 *
 */
export class FundingSourcesApi extends runtime.BaseAPI {
  /**
   * Creates a new Plaid funding source.
   */
  async addFundingSourceRaw(
    requestParameters: AddFundingSourceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling addFundingSource().'
      );
    }

    if (requestParameters['createFundingSourceRequest'] == null) {
      throw new runtime.RequiredError(
        'createFundingSourceRequest',
        'Required parameter "createFundingSourceRequest" was null or undefined when calling addFundingSource().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/add-funding-source`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateFundingSourceRequestToJSON(
          requestParameters['createFundingSourceRequest']
        ),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates a new Plaid funding source.
   */
  async addFundingSource(
    requestParameters: AddFundingSourceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.addFundingSourceRaw(requestParameters, initOverrides);
  }

  /**
   * Attaches a Compeer line of credit.
   */
  async attachCompeerLoanRaw(
    requestParameters: AttachCompeerLoanOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling attachCompeerLoan().'
      );
    }

    if (requestParameters['attachCompeerLoanRequest'] == null) {
      throw new runtime.RequiredError(
        'attachCompeerLoanRequest',
        'Required parameter "attachCompeerLoanRequest" was null or undefined when calling attachCompeerLoan().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/attach-compeer-loan`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AttachCompeerLoanRequestToJSON(
          requestParameters['attachCompeerLoanRequest']
        ),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Attaches a Compeer line of credit.
   */
  async attachCompeerLoan(
    requestParameters: AttachCompeerLoanOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.attachCompeerLoanRaw(requestParameters, initOverrides);
  }

  /**
   * Generates a Plaid Link Token. This is used as part of creating a new funding source.
   */
  async generatePlaidLinkTokenRaw(
    requestParameters: GeneratePlaidLinkTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetLinkTokenResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling generatePlaidLinkToken().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/generate-plaid-link-token`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaidLinkTokenRequestToJSON(
          requestParameters['plaidLinkTokenRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetLinkTokenResponseFromJSON(jsonValue)
    );
  }

  /**
   * Generates a Plaid Link Token. This is used as part of creating a new funding source.
   */
  async generatePlaidLinkToken(
    requestParameters: GeneratePlaidLinkTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetLinkTokenResponse> {
    const response = await this.generatePlaidLinkTokenRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Gets all Active Funding Sources associated with the specified Wallet.
   */
  async getActiveFundingSourcesRaw(
    requestParameters: GetActiveFundingSourcesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetActiveFundingSourceResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getActiveFundingSources().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['transactional'] != null) {
      queryParameters['transactional'] = requestParameters['transactional'];
    }

    if (requestParameters['includeLiabilityFundsAvailable'] != null) {
      queryParameters['includeLiabilityFundsAvailable'] =
        requestParameters['includeLiabilityFundsAvailable'];
    }

    if (requestParameters['type'] != null) {
      queryParameters['type'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/funding-sources`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetActiveFundingSourceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Gets all Active Funding Sources associated with the specified Wallet.
   */
  async getActiveFundingSources(
    requestParameters: GetActiveFundingSourcesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetActiveFundingSourceResponse> {
    const response = await this.getActiveFundingSourcesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieve funding source details.
   */
  async getFundingSourceDetailsRaw(
    requestParameters: GetFundingSourceDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetFundingSourceResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getFundingSourceDetails().'
      );
    }

    if (requestParameters['fundingSourceId'] == null) {
      throw new runtime.RequiredError(
        'fundingSourceId',
        'Required parameter "fundingSourceId" was null or undefined when calling getFundingSourceDetails().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/funding-sources/{fundingSourceId}`.replace(
          `{${'fundingSourceId'}}`,
          encodeURIComponent(String(requestParameters['fundingSourceId']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetFundingSourceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Retrieve funding source details.
   */
  async getFundingSourceDetails(
    requestParameters: GetFundingSourceDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetFundingSourceResponse> {
    const response = await this.getFundingSourceDetailsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves lender details.
   */
  async getLendersDetailsRaw(
    requestParameters: GetLendersDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetLendersDetailsResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getLendersDetails().'
      );
    }

    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getLendersDetails().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/lenders/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetLendersDetailsResponseFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves lender details.
   */
  async getLendersDetails(
    requestParameters: GetLendersDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetLendersDetailsResponse> {
    const response = await this.getLendersDetailsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Retrieves lender list.
   */
  async getLendersListRaw(
    requestParameters: GetLendersListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetLendersListResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getLendersList().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/lenders`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetLendersListResponseFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves lender list.
   */
  async getLendersList(
    requestParameters: GetLendersListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetLendersListResponse> {
    const response = await this.getLendersListRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Removes a funding source in the failed state.
   */
  async removeFailedFundingSourceRaw(
    requestParameters: RemoveFailedFundingSourceOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling removeFailedFundingSource().'
      );
    }

    if (requestParameters['removeFailedFundingSourceRequest'] == null) {
      throw new runtime.RequiredError(
        'removeFailedFundingSourceRequest',
        'Required parameter "removeFailedFundingSourceRequest" was null or undefined when calling removeFailedFundingSource().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/remove-failed-funding-source`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RemoveFailedFundingSourceRequestToJSON(
          requestParameters['removeFailedFundingSourceRequest']
        ),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Removes a funding source in the failed state.
   */
  async removeFailedFundingSource(
    requestParameters: RemoveFailedFundingSourceOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.removeFailedFundingSourceRaw(requestParameters, initOverrides);
  }

  /**
   * Command to remove funding sources.
   */
  async removeFundingSourceRaw(
    requestParameters: RemoveFundingSourceOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RemoveFundingSourcesResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling removeFundingSource().'
      );
    }

    if (requestParameters['removeFundingSourceRequest'] == null) {
      throw new runtime.RequiredError(
        'removeFundingSourceRequest',
        'Required parameter "removeFundingSourceRequest" was null or undefined when calling removeFundingSource().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/remove-funding-source`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RemoveFundingSourceRequestToJSON(
          requestParameters['removeFundingSourceRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RemoveFundingSourcesResponseFromJSON(jsonValue)
    );
  }

  /**
   * Command to remove funding sources.
   */
  async removeFundingSource(
    requestParameters: RemoveFundingSourceOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RemoveFundingSourcesResponse> {
    const response = await this.removeFundingSourceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Rename a funding source.
   */
  async renameFundingSourceRaw(
    requestParameters: RenameFundingSourceOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RenameFundingSourceResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling renameFundingSource().'
      );
    }

    if (requestParameters['renameFundingSourceRequest'] == null) {
      throw new runtime.RequiredError(
        'renameFundingSourceRequest',
        'Required parameter "renameFundingSourceRequest" was null or undefined when calling renameFundingSource().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/rename-funding-source`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RenameFundingSourceRequestToJSON(
          requestParameters['renameFundingSourceRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RenameFundingSourceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Rename a funding source.
   */
  async renameFundingSource(
    requestParameters: RenameFundingSourceOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RenameFundingSourceResponse> {
    const response = await this.renameFundingSourceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Reorder funding sources.
   */
  async reorderFundingSourcesRaw(
    requestParameters: ReorderFundingSourcesOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReorderFundingSourcesResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling reorderFundingSources().'
      );
    }

    if (requestParameters['reorderFundingSourcesRequest'] == null) {
      throw new runtime.RequiredError(
        'reorderFundingSourcesRequest',
        'Required parameter "reorderFundingSourcesRequest" was null or undefined when calling reorderFundingSources().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/reorder-funding-sources`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ReorderFundingSourcesRequestToJSON(
          requestParameters['reorderFundingSourcesRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReorderFundingSourcesResponseFromJSON(jsonValue)
    );
  }

  /**
   * Reorder funding sources.
   */
  async reorderFundingSources(
    requestParameters: ReorderFundingSourcesOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReorderFundingSourcesResponse> {
    const response = await this.reorderFundingSourcesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetActiveFundingSourcesTypeEnum {
  Plaid = 'PLAID',
  Liability = 'LIABILITY',
}
