/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SendMoneyRequest
 */
export interface SendMoneyRequest {
  /**
   *
   * @type {string}
   * @memberof SendMoneyRequest
   */
  payeeWalletId: string;
  /**
   *
   * @type {string}
   * @memberof SendMoneyRequest
   */
  fundingSourceId?: string;
  /**
   *
   * @type {string}
   * @memberof SendMoneyRequest
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof SendMoneyRequest
   */
  memo: string;
  /**
   *
   * @type {string}
   * @memberof SendMoneyRequest
   */
  paymentRequestId?: string;
}

/**
 * Check if a given object implements the SendMoneyRequest interface.
 */
export function instanceOfSendMoneyRequest(
  value: object
): value is SendMoneyRequest {
  if (!('payeeWalletId' in value) || value['payeeWalletId'] === undefined)
    return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  if (!('memo' in value) || value['memo'] === undefined) return false;
  return true;
}

export function SendMoneyRequestFromJSON(json: any): SendMoneyRequest {
  return SendMoneyRequestFromJSONTyped(json, false);
}

export function SendMoneyRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SendMoneyRequest {
  if (json == null) {
    return json;
  }
  return {
    payeeWalletId: json['payeeWalletId'],
    fundingSourceId:
      json['fundingSourceId'] == null ? undefined : json['fundingSourceId'],
    amount: json['amount'],
    memo: json['memo'],
    paymentRequestId:
      json['paymentRequestId'] == null ? undefined : json['paymentRequestId'],
  };
}

export function SendMoneyRequestToJSON(value?: SendMoneyRequest | null): any {
  if (value == null) {
    return value;
  }
  return {
    payeeWalletId: value['payeeWalletId'],
    fundingSourceId: value['fundingSourceId'],
    amount: value['amount'],
    memo: value['memo'],
    paymentRequestId: value['paymentRequestId'],
  };
}
