import { Header, Body, Footer } from 'components/layout/onboarding';
import { Flex, Button, useDisclosure } from '@chakra-ui/react';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useSelector } from '@xstate/react';
import { PersonDto, PersonDtoRolesEnum } from 'api/wallet-app';
import { BadgeValue } from 'pages/Onboarding/sharedComponents/OverviewCard/Card';
import {
  BusinessInfo,
  BeneficialOwnerCardInfo,
  ControllerCardInfo,
} from 'pages/Onboarding/sharedComponents/OverviewCard';
import { ConfirmModal } from 'pages/Onboarding/sharedComponents/ConfirmModal';

export const LLCOverview = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const context = useSelector(onboardingActor, (state) => state.context);
  const handlePersonal = () => {
    onboardingActor.send({ type: 'editPersonalInfo', value: {} });
  };
  const handleBusiness = () => {
    onboardingActor.send({ type: 'editBusinessInfo', value: {} });
  };

  const hasBusinessInfo = Object.keys(context.business).length > 0;

  const hasController =
    context.people &&
    context.people.find((person) =>
      person.roles?.includes(PersonDtoRolesEnum.Controller)
    );

  const controller = context.people?.find((person) =>
    person.roles?.includes(PersonDtoRolesEnum.Controller)
  );

  const isController =
    context.isController ||
    sessionStorage.getItem('wallet.isController') === 'yes'
      ? true
      : false;

  const isBeneficialOwner =
    context.isBeneficialOwner ||
    sessionStorage.getItem('wallet.isBeneficialOwner') === 'yes'
      ? true
      : false;

  /*
  1. No BOs but is Controller:
    - Business info
    - Controller info

  2. Not personally a BO but is Controller:
    - Business info
    - Controller info
    - Beneficial Owners

  3. Is BO:
    - Business info
    - Beneficial Owners
   */

  const getBusinessSubmissionState = () => {
    const valuesArr = Object.values(context.business).filter((i) => i);
    const size = valuesArr.length;
    let value = BadgeValue.notStarted;

    if (size >= 3) {
      value = BadgeValue.readyToSubmit;
    }

    if (size < 3 && size > 1) {
      value = BadgeValue.needsInfo;
    }

    if (size <= 1) {
      value = BadgeValue.notStarted;
    }

    return value;
  };

  const getControllerSubmissionState = () => {
    if (!controller) return BadgeValue.notStarted;

    const valuesArr = Object.values(controller).filter((i) => i);
    const size = valuesArr.length || 0;
    let value;

    if (size >= 8) {
      value = BadgeValue.readyToSubmit;
    }

    if (size < 8 && size > 1) {
      value = BadgeValue.needsInfo;
    }
    if (!controller.address) {
      value = BadgeValue.needsInfo;
    }

    if (size <= 1) {
      value = BadgeValue.notStarted;
    }

    return value;
  };

  const handleClick = () => {
    onOpen();
  };
  const handleSubmit = () => {
    onboardingActor.send({ type: 'submit', value: {} });
  };

  const CardsToRender = () => {
    const businessSubmissionState = getBusinessSubmissionState();
    const controllerSubmissionState =
      getControllerSubmissionState() || BadgeValue.notStarted;

    return (
      <Flex direction="column">
        <ControllerCardInfo
          clickHandler={handlePersonal}
          controller={controller as PersonDto}
          submissionState={controllerSubmissionState}
          data-id={controller?.id}
        />
        <BusinessInfo
          clickHandler={handleBusiness}
          business={context.business}
          submissionState={businessSubmissionState}
        />
        {(isBeneficialOwner && <BeneficialOwnerCardInfo />) ||
          (isController && <BeneficialOwnerCardInfo />)}
      </Flex>
    );
  };

  return (
    <>
      <Header title="Wallet Account Creation" logoutButton={true} />
      <Body>
        <Flex flexDirection="column">
          <CardsToRender />
        </Flex>
      </Body>
      <Footer>
        <Button
          disabled={!hasController || !hasBusinessInfo}
          onClick={handleClick}
        >
          Submit
        </Button>
      </Footer>
      <ConfirmModal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit} />
    </>
  );
};
