/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Response for making a liability payment.
 * @export
 * @interface MakeLiabilityPaymentResponse
 */
export interface MakeLiabilityPaymentResponse {
  /**
   *
   * @type {string}
   * @memberof MakeLiabilityPaymentResponse
   */
  fundsFlowId: string;
}

/**
 * Check if a given object implements the MakeLiabilityPaymentResponse interface.
 */
export function instanceOfMakeLiabilityPaymentResponse(
  value: object
): value is MakeLiabilityPaymentResponse {
  if (!('fundsFlowId' in value) || value['fundsFlowId'] === undefined)
    return false;
  return true;
}

export function MakeLiabilityPaymentResponseFromJSON(
  json: any
): MakeLiabilityPaymentResponse {
  return MakeLiabilityPaymentResponseFromJSONTyped(json, false);
}

export function MakeLiabilityPaymentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MakeLiabilityPaymentResponse {
  if (json == null) {
    return json;
  }
  return {
    fundsFlowId: json['fundsFlowId'],
  };
}

export function MakeLiabilityPaymentResponseToJSON(
  value?: MakeLiabilityPaymentResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    fundsFlowId: value['fundsFlowId'],
  };
}
