import React from 'react';
import {
  FormLabel,
  Flex,
  Input,
  Text,
  Stack,
  HStack,
  FormControl,
} from '@chakra-ui/react';
import {
  UseFormRegister,
  FieldError,
  FieldErrorsImpl,
  Merge,
  FieldValues,
  UseFormSetValue,
  UseFormResetField,
} from 'react-hook-form';
import { WarningIcon } from '@chakra-ui/icons';
import { sub, isBefore, isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

type FormProps = {
  day?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  month?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  year?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  register: UseFormRegister<FieldValues>;
  maskedDateOfBirth?: boolean;
  resetField: UseFormResetField<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
};

const BirthdateComponent = ({
  day,
  month,
  year,
  register,
  maskedDateOfBirth = false,
  resetField,
  setValue,
}: FormProps) => {
  const { t } = useTranslation();

  const [shouldMask, setShouldMask] = React.useState(maskedDateOfBirth);

  React.useEffect(() => {
    if (maskedDateOfBirth) {
      setValue('month', '00');
      setValue('day', '00');
      setValue('year', '0000');
    }
  }, [maskedDateOfBirth, setValue]);

  const handleFocus = () => {
    if (shouldMask) {
      for (const field of ['month', 'day', 'year']) {
        resetField(field);
      }
    }
    setShouldMask(false);
  };

  return (
    <FormControl as={Flex} flexDir="column" id="fieldset">
      <Flex>
        <Text color="red.500" mr={1}>
          *
        </Text>
        <FormLabel display="inline-block">{t('form.DOB')}</FormLabel>
      </Flex>
      <HStack>
        <Flex>
          <Input
            {...register('month', {
              required: { value: true, message: t('form.monthRequired') },
              max: { value: 12, message: t('form.monthMustBe1Through12') },
              pattern: {
                value: /^[0-9]*$/,
                message: t('form.monthMustBeOnlyNumber').toString(),
              },
            })}
            minLength={1}
            maxLength={2}
            placeholder="MM"
            type={shouldMask ? 'password' : 'tel'}
            isInvalid={!!month}
            required={shouldMask ? false : true}
            onFocus={handleFocus}
            id="month"
          />
        </Flex>
        <Flex>
          <Input
            {...register('day', {
              required: { value: true, message: 'Day is required' },
              max: { value: 31, message: t('form.enterValidDay') },
              pattern: {
                value: /^[0-9]*$/,
                message: t('form.dayMustBeOnlyNumber'),
              },
            })}
            minLength={1}
            maxLength={2}
            placeholder="DD"
            type={shouldMask ? 'password' : 'tel'}
            isInvalid={!!day}
            required={shouldMask ? false : true}
            onFocus={handleFocus}
            id="day"
          />
        </Flex>
        <Flex>
          <Input
            {...register('year', {
              required: { value: true, message: t('form.yearRequired') },
              minLength: {
                value: 4,
                message: t('form.yearMustBe4Numbers'),
              },
              maxLength: {
                value: 4,
                message: t('form.yearMustBe4Numbers'),
              },
              pattern: {
                value: /^[0-9]*$/,
                message: t('form.yearMustBeOnlyNumber'),
              },
              validate: {
                minAge: (year) => {
                  if (shouldMask) return true;

                  // Can do this because the pattern catches errors for when non-numbers exist in the string
                  const yearAsNum = parseInt(year);
                  const minimumYear = sub(new Date(), {
                    years: 18,
                  }).getFullYear();
                  if (isBefore(yearAsNum, minimumYear)) {
                    return true;
                  } else {
                    return i18next.t('form.mustBe18YearsOfAge');
                  }
                },
                maxAge: (year) => {
                  if (shouldMask) return true;
                  // Can do this because the pattern catches errors for when non-numbers exist in the string
                  const yearAsNum = parseInt(year);
                  const maximumYear = sub(new Date(), {
                    years: 125,
                  }).getFullYear();
                  if (isAfter(yearAsNum, maximumYear)) {
                    return true;
                  } else {
                    return i18next.t('form.mustBeunder125YearsOfAge');
                  }
                },
              },
            })}
            minLength={4}
            maxLength={4}
            placeholder="YYYY"
            id="year"
            type={shouldMask ? 'password' : 'tel'}
            isInvalid={!!year}
            required={shouldMask ? false : true}
            onFocus={handleFocus}
          />
        </Flex>
      </HStack>
      <Stack spacing={1} py={2} fontSize="sm" color="red.500">
        {[month, day, year].map((item, index) => {
          if (!item) return;
          return (
            <Flex key={index} alignItems="center" width="100%">
              <WarningIcon />
              <Text ml="1">{item?.message?.toString()}</Text>
            </Flex>
          );
        })}
      </Stack>
    </FormControl>
  );
};

export const Birthdate = React.memo(BirthdateComponent);
