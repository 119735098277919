import { MoneyInput } from 'pages/WalletApp/Transfers/components';
import { Button, Stack } from '@chakra-ui/react';
import { NumberFormatValues } from 'react-number-format';
import { WalletProfileResponse } from 'api/wallet-app';
import { Dispatch, useState } from 'react';
import { RequestEvent } from './requestReducer';

export const EnterValue = ({
  requestee,
  send,
  defaultAmount,
}: {
  requestee: WalletProfileResponse;
  defaultAmount: NumberFormatValues;
  send: Dispatch<RequestEvent>;
}) => {
  const [amount, setAmount] = useState<NumberFormatValues>(defaultAmount);

  return (
    <Stack spacing={4}>
      <MoneyInput
        amount={amount.value}
        onChange={setAmount}
        showBorder={false}
      />
      <Button
        isDisabled={parseFloat(amount.value) <= 0}
        onClick={() => {
          send({ type: 'NEXT', amount: amount, requestee: requestee });
        }}
      >
        Continue
      </Button>
    </Stack>
  );
};
