import React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';

export const BackButton = (): JSX.Element => {
  const onboardingContext = OnboardingContext.useActorRef();
  const handleClick = (): void => {
    onboardingContext?.send({ type: 'back', value: '' });
  };

  const handleKeyDown = (e: React.KeyboardEvent<SVGAElement>) => {
    if (e.key == 'Enter') handleClick();
  };

  return (
    <ArrowBackIcon
      tabIndex={0}
      onClick={(): void => handleClick()}
      onKeyDown={handleKeyDown}
      alignSelf="left"
      fontSize="1.5rem"
      cursor="pointer"
      data-testid="backbutton"
      mb="1.2rem"
    />
  );
};
