import { Image, Button, Stack, Text, HStack, Box } from '@chakra-ui/react';
import { NavItems } from './navItems';
import { Link } from 'react-router-dom';
import DollarSign from 'images/dollarsign.svg';
import { useTranslation } from 'react-i18next';

export function DesktopMenu({
  isOpen,
}: {
  isOpen: boolean;
  toggleMenu: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Stack
      w={isOpen ? '240px' : { base: '0px', lg: '56px' }}
      bgColor="white"
      borderRight="solid 1px"
      borderColor="border"
      position={{ base: 'absolute', lg: 'relative' }}
      transition="all 0.5s"
      spacing={20}
      pt={{ base: 6, lg: 12 }}
      data-testid="desktop-menu"
    >
      <NavItems isOpen={isOpen} />
      <Box px={isOpen ? 3 : 0} transition="all 0.3s">
        <Button
          as={Link}
          to="/transfers"
          // The minW and minWidth is set like this due to how the Chakra UI button styles are defined for buttons
          // in src/theme/components/Button.ts. We should fix button styles in the future
          minW="56px"
          minWidth="56px"
          borderRadius={isOpen ? '4px' : 0}
        >
          <HStack spacing={isOpen ? 3 : 0}>
            <Text
              width={isOpen ? 'auto' : '0'}
              lineHeight={isOpen ? 'auto' : 0}
              overflow={isOpen ? 'auto' : 'hidden'}
            >
              {t('wallet.transfers.transferAndPay')}
            </Text>
            <Image src={DollarSign} width="14px" height="21px" />
          </HStack>
        </Button>
      </Box>
    </Stack>
  );
}
