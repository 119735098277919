/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingAddressDto } from './OnboardingAddressDto';
import {
  OnboardingAddressDtoFromJSON,
  OnboardingAddressDtoFromJSONTyped,
  OnboardingAddressDtoToJSON,
} from './OnboardingAddressDto';

/**
 *
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
  /**
   *
   * @type {string}
   * @memberof PersonDto
   */
  id?: string;
  /**
   * The person's first name
   * @type {string}
   * @memberof PersonDto
   */
  firstName?: string;
  /**
   * The person's last name
   * @type {string}
   * @memberof PersonDto
   */
  lastName?: string;
  /**
   * The person's date of birth.
   * @type {string}
   * @memberof PersonDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {OnboardingAddressDto}
   * @memberof PersonDto
   */
  address?: OnboardingAddressDto;
  /**
   * The person's social security number (Required if a US Citizen).
   * @type {string}
   * @memberof PersonDto
   */
  ssn?: string;
  /**
   * The person's 10 digit phone number. No hyphens or other separators.
   * @type {string}
   * @memberof PersonDto
   */
  phone?: string;
  /**
   * The person's email Address
   * @type {string}
   * @memberof PersonDto
   */
  email?: string;
  /**
   * The person's roles
   * @type {Array<string>}
   * @memberof PersonDto
   */
  roles: Array<PersonDtoRolesEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum PersonDtoRolesEnum {
  SoleProprietor = 'SOLE_PROPRIETOR',
  BeneficialOwner = 'BENEFICIAL_OWNER',
  Controller = 'CONTROLLER',
  CardHolder = 'CARD_HOLDER',
}

/**
 * Check if a given object implements the PersonDto interface.
 */
export function instanceOfPersonDto(value: object): value is PersonDto {
  if (!('roles' in value) || value['roles'] === undefined) return false;
  return true;
}

export function PersonDtoFromJSON(json: any): PersonDto {
  return PersonDtoFromJSONTyped(json, false);
}

export function PersonDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PersonDto {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'] == null ? undefined : json['id'],
    firstName: json['firstName'] == null ? undefined : json['firstName'],
    lastName: json['lastName'] == null ? undefined : json['lastName'],
    dateOfBirth: json['dateOfBirth'] == null ? undefined : json['dateOfBirth'],
    address:
      json['address'] == null
        ? undefined
        : OnboardingAddressDtoFromJSON(json['address']),
    ssn: json['ssn'] == null ? undefined : json['ssn'],
    phone: json['phone'] == null ? undefined : json['phone'],
    email: json['email'] == null ? undefined : json['email'],
    roles: json['roles'],
  };
}

export function PersonDtoToJSON(value?: PersonDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    firstName: value['firstName'],
    lastName: value['lastName'],
    dateOfBirth: value['dateOfBirth'],
    address: OnboardingAddressDtoToJSON(value['address']),
    ssn: value['ssn'],
    phone: value['phone'],
    email: value['email'],
    roles: value['roles'],
  };
}
