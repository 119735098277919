export const ToggleIcon = {
  parts: ['iconContainer', 'icon'],
  baseStyle: {
    iconContainer: {
      borderRadius: '100px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      height: '100%',
      width: '100%',
      color: 'white',
    },
  },
  sizes: {
    md: {
      iconContainer: {
        width: '1.25rem',
        height: '1.25rem',
        mr: '18px',
      },
    },
    lg: {
      iconContainer: {
        mb: '0.5rem',
      },
    },
  },
  variants: {
    checked: {
      iconContainer: {
        bg: 'green.500',
        padding: 0,
      },
    },
    unchecked: {
      iconContainer: {
        bg: 'blue.500',
      },
    },
  },
  defaultProps: { size: 'md' },
};
