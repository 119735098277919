/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The response for invite validation.
 * @export
 * @interface ValidateUserInviteResponse
 */
export interface ValidateUserInviteResponse {
  /**
   *
   * @type {string}
   * @memberof ValidateUserInviteResponse
   */
  businessName: string;
  /**
   *
   * @type {string}
   * @memberof ValidateUserInviteResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ValidateUserInviteResponse
   */
  phoneNumber: string;
  /**
   *
   * @type {boolean}
   * @memberof ValidateUserInviteResponse
   */
  requiresOnboarding: boolean;
}

/**
 * Check if a given object implements the ValidateUserInviteResponse interface.
 */
export function instanceOfValidateUserInviteResponse(
  value: object
): value is ValidateUserInviteResponse {
  if (!('businessName' in value) || value['businessName'] === undefined)
    return false;
  if (!('email' in value) || value['email'] === undefined) return false;
  if (!('phoneNumber' in value) || value['phoneNumber'] === undefined)
    return false;
  if (
    !('requiresOnboarding' in value) ||
    value['requiresOnboarding'] === undefined
  )
    return false;
  return true;
}

export function ValidateUserInviteResponseFromJSON(
  json: any
): ValidateUserInviteResponse {
  return ValidateUserInviteResponseFromJSONTyped(json, false);
}

export function ValidateUserInviteResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ValidateUserInviteResponse {
  if (json == null) {
    return json;
  }
  return {
    businessName: json['businessName'],
    email: json['email'],
    phoneNumber: json['phoneNumber'],
    requiresOnboarding: json['requiresOnboarding'],
  };
}

export function ValidateUserInviteResponseToJSON(
  value?: ValidateUserInviteResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    businessName: value['businessName'],
    email: value['email'],
    phoneNumber: value['phoneNumber'],
    requiresOnboarding: value['requiresOnboarding'],
  };
}
