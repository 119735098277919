/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The create unload request
 * @export
 * @interface UnloadRequest
 */
export interface UnloadRequest {
  /**
   *
   * @type {string}
   * @memberof UnloadRequest
   */
  toFundingSourceId: string;
  /**
   *
   * @type {string}
   * @memberof UnloadRequest
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof UnloadRequest
   */
  currencyType?: UnloadRequestCurrencyTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UnloadRequest
   */
  memo?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UnloadRequestCurrencyTypeEnum {
  Usd = 'USD',
}

/**
 * Check if a given object implements the UnloadRequest interface.
 */
export function instanceOfUnloadRequest(value: object): value is UnloadRequest {
  if (
    !('toFundingSourceId' in value) ||
    value['toFundingSourceId'] === undefined
  )
    return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  return true;
}

export function UnloadRequestFromJSON(json: any): UnloadRequest {
  return UnloadRequestFromJSONTyped(json, false);
}

export function UnloadRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UnloadRequest {
  if (json == null) {
    return json;
  }
  return {
    toFundingSourceId: json['toFundingSourceId'],
    amount: json['amount'],
    currencyType:
      json['currencyType'] == null ? undefined : json['currencyType'],
    memo: json['memo'] == null ? undefined : json['memo'],
  };
}

export function UnloadRequestToJSON(value?: UnloadRequest | null): any {
  if (value == null) {
    return value;
  }
  return {
    toFundingSourceId: value['toFundingSourceId'],
    amount: value['amount'],
    currencyType: value['currencyType'],
    memo: value['memo'],
  };
}
