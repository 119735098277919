import { useKeycloak } from '@react-keycloak/web';
import { useMutation } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useWalletsClient from './useWalletsClient';
import { v4 as uuid } from 'uuid';

export default function useDeactivateWallet() {
  const { walletId } = useWalletStore();
  const { keycloak } = useKeycloak();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const walletsClient = useWalletsClient();
  return useMutation({
    mutationKey: ['deactivate-wallet'],
    mutationFn: (note: string) => {
      return walletsClient.deactivateWallet({
        xWalletId: walletId,
        idempotencyKey: uuid(),
        deactivateWalletRequest: { note },
      });
    },
    onSuccess: () => {
      keycloak.logout({ redirectUri: window.location.origin });
    },
  });
}
