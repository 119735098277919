import { createContext, ReactElement, useMemo } from 'react';
import {
  useDisclosure,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { MicrodepositNotification } from 'pages/WalletApp/Notifications/Microdeposit';
import { LineOfCredit } from 'pages/WalletApp/Notifications/LineOfCredit';
import { useWalletStore } from 'stores';
import { useFundingSourceList, useNormalAppState } from 'hooks';

type NotificationProps = {
  walletId: string | undefined;
};

enum NotificationModalEnum {
  VERIFY_MANUAL_MICRO = 'verify-manual-micro',
  LINE_OF_CREDIT = 'line-of-credit',
}

export const NotificationContext = createContext<NotificationProps>({
  walletId: '',
});

export function NotificationProvider({ children }: { children: ReactElement }) {
  const { walletId } = useWalletStore();
  const { data: fundingSources } = useFundingSourceList();
  const { data: normalAppState, isLoading: normalAppStateIsLoading } =
    useNormalAppState();

  const hasManualMicroToVerify = useMemo(
    () =>
      fundingSources?.fundingSources &&
      !!fundingSources?.fundingSources?.find(
        (fundingSource) => fundingSource.needsUserValidation
      ),
    [fundingSources]
  );

  const displayLineOfCreditModal = useMemo(() => {
    return (
      !normalAppStateIsLoading &&
      !normalAppState?.appState.actionItems?.length &&
      !normalAppState?.appState.viewedScreens.find(
        (viewedScreen) => viewedScreen.screenName === 'compeer-welcome-screen'
      )
    );
  }, [normalAppState]);

  return (
    <NotificationContext.Provider value={{ walletId }}>
      {children}
      {displayLineOfCreditModal && (
        <NotificationModal
          type={NotificationModalEnum.LINE_OF_CREDIT}
          allowClose={false}
        />
      )}
      {hasManualMicroToVerify && (
        <NotificationModal type={NotificationModalEnum.VERIFY_MANUAL_MICRO} />
      )}
    </NotificationContext.Provider>
  );
}

function NotificationModal({
  type,
  allowClose = true,
  backgroundColor = 'white',
}: {
  type: NotificationModalEnum;
  allowClose?: boolean;
  backgroundColor?: string;
}) {
  const { onClose, isOpen } = useDisclosure({ defaultIsOpen: true });

  function notificationModalContent() {
    switch (type) {
      case NotificationModalEnum.VERIFY_MANUAL_MICRO:
        return <MicrodepositNotification handleClose={onClose} />;
      case NotificationModalEnum.LINE_OF_CREDIT:
        return <LineOfCredit onClose={onClose} />;
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      trapFocus={false}
      onClose={onClose}
      closeOnOverlayClick={allowClose ? true : false}
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor={backgroundColor}
        p={['0', '1rem']}
        width={['95%', '100%']}
      >
        {allowClose && (
          <Icon
            as={CloseIcon}
            onClick={onClose}
            cursor="pointer"
            fontSize="12px"
          />
        )}
        {notificationModalContent()}
      </ModalContent>
    </Modal>
  );
}
