/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NormalAppStateDto } from './NormalAppStateDto';
import {
  NormalAppStateDtoFromJSON,
  NormalAppStateDtoFromJSONTyped,
  NormalAppStateDtoToJSON,
} from './NormalAppStateDto';

/**
 * Normal application state
 * @export
 * @interface NormalAppState
 */
export interface NormalAppState {
  /**
   *
   * @type {NormalAppStateDto}
   * @memberof NormalAppState
   */
  appState: NormalAppStateDto;
}

/**
 * Check if a given object implements the NormalAppState interface.
 */
export function instanceOfNormalAppState(
  value: object
): value is NormalAppState {
  if (!('appState' in value) || value['appState'] === undefined) return false;
  return true;
}

export function NormalAppStateFromJSON(json: any): NormalAppState {
  return NormalAppStateFromJSONTyped(json, false);
}

export function NormalAppStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NormalAppState {
  if (json == null) {
    return json;
  }
  return {
    appState: NormalAppStateDtoFromJSON(json['appState']),
  };
}

export function NormalAppStateToJSON(value?: NormalAppState | null): any {
  if (value == null) {
    return value;
  }
  return {
    appState: NormalAppStateDtoToJSON(value['appState']),
  };
}
