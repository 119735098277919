/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A request to link a wallet to an integration partner.
 * @export
 * @interface LinkWalletRequest
 */
export interface LinkWalletRequest {
  /**
   * The link token.
   * @type {string}
   * @memberof LinkWalletRequest
   */
  token: string;
}

/**
 * Check if a given object implements the LinkWalletRequest interface.
 */
export function instanceOfLinkWalletRequest(
  value: object
): value is LinkWalletRequest {
  if (!('token' in value) || value['token'] === undefined) return false;
  return true;
}

export function LinkWalletRequestFromJSON(json: any): LinkWalletRequest {
  return LinkWalletRequestFromJSONTyped(json, false);
}

export function LinkWalletRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LinkWalletRequest {
  if (json == null) {
    return json;
  }
  return {
    token: json['token'],
  };
}

export function LinkWalletRequestToJSON(value?: LinkWalletRequest | null): any {
  if (value == null) {
    return value;
  }
  return {
    token: value['token'],
  };
}
