/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionStatementData } from './TransactionStatementData';
import {
  TransactionStatementDataFromJSON,
  TransactionStatementDataFromJSONTyped,
  TransactionStatementDataToJSON,
} from './TransactionStatementData';
import type { StatementAddress } from './StatementAddress';
import {
  StatementAddressFromJSON,
  StatementAddressFromJSONTyped,
  StatementAddressToJSON,
} from './StatementAddress';

/**
 * Transaction statement data for a given month.
 * @export
 * @interface TransactionStatementResponse
 */
export interface TransactionStatementResponse {
  /**
   *
   * @type {TransactionStatementData}
   * @memberof TransactionStatementResponse
   */
  data: TransactionStatementData;
  /**
   *
   * @type {string}
   * @memberof TransactionStatementResponse
   */
  statementDateCode: string;
  /**
   *
   * @type {string}
   * @memberof TransactionStatementResponse
   */
  businessName?: string;
  /**
   *
   * @type {StatementAddress}
   * @memberof TransactionStatementResponse
   */
  businessAddress?: StatementAddress;
}

/**
 * Check if a given object implements the TransactionStatementResponse interface.
 */
export function instanceOfTransactionStatementResponse(
  value: object
): value is TransactionStatementResponse {
  if (!('data' in value) || value['data'] === undefined) return false;
  if (
    !('statementDateCode' in value) ||
    value['statementDateCode'] === undefined
  )
    return false;
  return true;
}

export function TransactionStatementResponseFromJSON(
  json: any
): TransactionStatementResponse {
  return TransactionStatementResponseFromJSONTyped(json, false);
}

export function TransactionStatementResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TransactionStatementResponse {
  if (json == null) {
    return json;
  }
  return {
    data: TransactionStatementDataFromJSON(json['data']),
    statementDateCode: json['statementDateCode'],
    businessName:
      json['businessName'] == null ? undefined : json['businessName'],
    businessAddress:
      json['businessAddress'] == null
        ? undefined
        : StatementAddressFromJSON(json['businessAddress']),
  };
}

export function TransactionStatementResponseToJSON(
  value?: TransactionStatementResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    data: TransactionStatementDataToJSON(value['data']),
    statementDateCode: value['statementDateCode'],
    businessName: value['businessName'],
    businessAddress: StatementAddressToJSON(value['businessAddress']),
  };
}
