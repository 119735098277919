import { useInfiniteQuery } from '@tanstack/react-query';
import useTransactionsClient from './useTransactionsClient';
import { useWalletStore } from 'stores';

export const INFINITE_TRANSACTION_LIST_QUERY_KEY = 'infinite-transaction-list';

export default function useInfiniteTransactionList(limit?: number) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const transactionClient = useTransactionsClient();
  return useInfiniteQuery({
    queryKey: [INFINITE_TRANSACTION_LIST_QUERY_KEY, limit],
    queryFn: ({ pageParam }) => {
      return transactionClient.getTransactionHistory({
        limit,
        cursor: pageParam,
        xWalletId: walletId,
      });
    },
    getNextPageParam: (data) => data.metadata.nextCursor,
    getPreviousPageParam: (data) => data.metadata.prevCursor,
    initialPageParam: '',
  });
}
