import { useQuery } from '@tanstack/react-query';
import useFundingSourcesClient from './useFundingSourcesClient';
import { useWalletStore } from 'stores';

export const LENDER_LIST_QUERY_KEY = 'lender-list';

export default function useLenderList() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const fundingSourcesClient = useFundingSourcesClient();
  return useQuery({
    queryKey: [LENDER_LIST_QUERY_KEY],
    queryFn: () => {
      return fundingSourcesClient.getLendersList({ xWalletId: walletId });
    },
  });
}
