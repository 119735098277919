import { Flex, Heading } from '@chakra-ui/react';
import { Card } from './Card';
import { IoPencilSharp } from 'react-icons/io5';
import { PersonDto } from 'api/wallet-app';
import { BadgeValue } from './Card';

export const SolePropOwnerInfo = ({
  clickHandler,
  owner,
  submissionState,
}: {
  clickHandler: () => void;
  owner: PersonDto;
  submissionState: BadgeValue;
}) => {
  const getOwnerTitle = () => {
    if (!owner) {
      return 'Personal Information';
    } else {
      if (owner.firstName && owner.lastName) {
        return `${owner.firstName} ${owner.lastName}`;
      }
    }
  };

  const title = getOwnerTitle();

  return (
    <Flex direction="column" mb={6}>
      <Heading size="sm" mb={3}>
        Personal Info
      </Heading>
      <Flex direction="column">
        <Card
          title={title}
          value={submissionState}
          handler={clickHandler}
          icon={IoPencilSharp}
          iconText="Edit"
        />
      </Flex>
    </Flex>
  );
};
