import {
  CloseButton,
  Icon,
  useDisclosure,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { IoInformationCircleOutline } from 'react-icons/io5';

export const InfoButton = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleClick = () => {
    onOpen();
  };

  return (
    <Icon fontSize={24} cursor="pointer" onClick={handleClick}>
      <IoInformationCircleOutline />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <CloseButton ml={-2} onClick={onClose} />
          </ModalHeader>
          <ModalBody pb={5}>
            <Heading fontSize={24} mb={7}>
              What is a Social Security Number?
            </Heading>
            <Text fontSize={15}>
              In the United States, a Social Security number is a nine-digit
              number issued to U.S. citizens, permanent residents, and temporary
              residents under section 205 of the Social Security Act, codified
              as 42 U.S.C. § 405.
            </Text>
            <Heading fontSize={20} my={5}>
              Where can I find it?
            </Heading>
            <Text mb={4}>You can find your Social Security Number on:</Text>
            <UnorderedList>
              <ListItem>Your Social Security card,</ListItem>
              <ListItem>Tax returns,</ListItem>
              <ListItem>W-2s,</ListItem>
              <ListItem>Some bank statements.</ListItem>
            </UnorderedList>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Icon>
  );
};
