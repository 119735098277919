//@ts-nocheck
// import { useEffect, useRef } from 'react'

type MatomoTracker = {
  push(args: any[]): void;
};

/**
 * Main initialization function for Matomo. This function should be called
 * once in in _app.tsx.
 * @param urlBase
 * @param siteId
 * @returns
 */
export const createMatomoTracker = (
  urlBase: string,
  siteId: string
): MatomoTracker => {
  const _paq = (window._paq = window._paq || []);

  const trackerUrl = `${urlBase}/matomo.php`;
  const scriptSrc = `${urlBase}/matomo.js`;

  _paq.push(['setTrackerUrl', trackerUrl]);
  _paq.push(['setSiteId', siteId]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);

  const script: HTMLScriptElement = document.createElement('script');
  script.async = true;
  script.src = scriptSrc;

  const head: HTMLHeadElement | null =
    document.head || document.getElementsByTagName('head')[0];
  head?.appendChild(script);

  return {
    push: function (args: any[]) {
      _paq.push(args);
    },
  };
};

// const trackPageView = (title?: string) => {
//   if (!window._paq) return;
//   title && window._paq.push(['setDocumentTitle', title]);
//   window._paq.push(['trackPageView']);
// };

export const trackEvent = (
  category: string,
  action: string,
  name?: string,
  value?: any
) => {
  if (!window._paq) return;
  window._paq.push(['trackEvent', category, action, name, value]);
};

const setUserId = (userId: string) => {
  if (!window._paq) return;
  window._paq.push(['setUserId', userId]);
};

export const resetUserId = () => {
  if (!window._paq) return;
  window._paq.push(['resetUserId']);
  window._paq.push(['trackPageView']);
};

const setCustomUrl = (url: string) => {
  if (!window._paq) return;
  window._paq.push(['setCustomUrl', url]);
};

const setReferrerUrl = (url: string) => {
  if (!window._paq) return;
  window._paq.push(['setReferrerUrl', url]);
};

/**
 * Custom hook that tracks all page views, including initial page load,
 * and next router changes. Includes tracking the user id if the user is
 * present in the session. Should be imported in a single place in the app
 * to avoid duplicate tracking and unnecessary overhead.
 */
// export const useTrackPageViews = () => {
//   const { events } = useRouter()
//   const { data: session, status } = useSession()
//   const isInitialPageLoad = useRef(true)

//   useEffect(() => {
//     if (process.env.NEXT_PUBLIC_MATOMO_ENABLED !== 'true') return

//     let previousPath = location.pathname

//     const handleRouteStart = (url: string) => {
//       isInitialPageLoad.current = false
//       if (previousPath) {
//         setReferrerUrl(previousPath)
//       }
//       setCustomUrl(url)
//       previousPath = url
//     }

//     const handleRouteChange = () => {
//       if (session?.user?.bushelId) {
//         setUserId(session.user.bushelId)
//       }

//       setTimeout(() => {
//         trackPageView(document.title)
//       }, 1)
//     }

//     events.on('routeChangeStart', handleRouteStart)
//     events.on('routeChangeComplete', handleRouteChange)

//     if (isInitialPageLoad.current) {
//       if (status !== 'loading') {
//         if (session?.user?.bushelId) {
//           setUserId(session.user.bushelId)
//         }
//         setTimeout(() => {
//           trackPageView(document.title)
//         }, 1)
//         isInitialPageLoad.current = false
//       }
//     }

//     return () => {
//       events.off('routeChangeStart', handleRouteStart)
//       events.off('routeChangeComplete', handleRouteChange)
//     }
//   }, [events, session, status])
// }
