export const Input = {
  baseStyle: {
    field: {
      border: 'solid 1px',
      borderColor: 'gray.300',
      borderRadius: '3px',
      boxShadow: 'none!important',
      _placeholder: {
        color: 'gray.300',
      },
    },
  },
  variants: {},
};
