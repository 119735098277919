import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useWalletProfilesClient from './useWalletProfilesClient';

export const RECENT_PROFILE_LIST_QUERY_KEY = 'recent-profile-list';

export default function useRecentProfileList() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const walletProfilesClient = useWalletProfilesClient();
  return useQuery({
    queryKey: [RECENT_PROFILE_LIST_QUERY_KEY],
    queryFn: () => {
      return walletProfilesClient.listRecentProfiles({ xWalletId: walletId });
    },
  });
}
