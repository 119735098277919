import { Body, Header, Footer } from 'components/layout/onboarding';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { SubmitButton } from 'components/submitButton';
import { TextInput } from 'components/formComponents';
import { useForm, UseControllerProps } from 'react-hook-form';
import { BusinessDto } from 'api/wallet-app';
import { useHookFormMask } from 'use-mask-input';
import { useSelector } from '@xstate/react';
import { useIndustryClassificationCodeList } from 'hooks/api';
import { WarningIcon } from '@chakra-ui/icons';

export const LLCBusinessDetails = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const handleClick = (data: Partial<BusinessDto>) => {
    onboardingActor?.send({ type: 'submitBusinessDetails', value: data });
  };

  const context = useSelector(onboardingActor, (state) => state?.context);
  const business = context?.business || {};

  const { data: industryClassificationCodes } =
    useIndustryClassificationCodeList();

  type Inputs = {
    businessPhone: string;
    businessName: string;
    ein: string;
    industryClassificationCode: string;
  };

  const {
    handleSubmit: hookFormSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onBlur',
    defaultValues: business,
  });

  const registerWithMask = useHookFormMask(register);

  const WrappedSelect = (props: UseControllerProps<Inputs>) => {
    return (
      <Select
        placeholder="Select option"
        {...register('industryClassificationCode', {
          required: {
            value: true,
            message: 'Industry Classification is required',
          },
        })}
        sx={
          errors.industryClassificationCode && {
            borderWidth: '0px',
          }
        }
        defaultValue={context?.business?.industryClassificationCode}
      >
        {industryClassificationCodes &&
          industryClassificationCodes.industryClassifications.map((item) => (
            <option key={item.code} value={item.code}>
              {item.description}
            </option>
          ))}
      </Select>
    );
  };

  return (
    <>
      <Header title="Business Details" />
      <Text fontSize={14} mb={6}>
        Please enter your legal business name and Employer Identification Number
        (EIN) as registered with the IRS.
      </Text>
      <Body>
        <form
          onSubmit={hookFormSubmit(handleClick)}
          id="business-details"
          data-testid="business-details"
          noValidate
        >
          <TextInput
            label="Business Phone Number"
            {...registerWithMask('businessPhone', ['(999) 999-9999'], {
              autoUnmask: true,
              required: { value: true, message: 'Phone number is required' },
              minLength: {
                value: 10,
                message: 'Phone number must be at least 10 numbers',
              },
            })}
            errors={errors}
            required={true}
          />
          <TextInput
            label="Legal Business Name"
            {...register('businessName', {
              required: { value: true, message: 'Business name is required' },
            })}
            errors={errors}
            required={true}
          />
          <TextInput
            label="Employer Identification Number (EIN)"
            type="text"
            inputMode="numeric"
            subtitle="This is your business's unique 9-digit tax identification number. You can find this on your IRS paperwork or tax documents."
            placeholder="12-3456789"
            {...registerWithMask('ein', ['99-9999999'], {
              autoUnmask: true,
              required: { value: true, message: 'EIN is required' },
              minLength: {
                value: 9,
                message: 'EIN must be 9 numbers',
              },
            })}
            errors={errors}
            required={true}
          />
          <FormControl isInvalid={!!errors.industryClassificationCode}>
            <FormLabel>
              <Text as="span" color="red.500">
                *{' '}
              </Text>
              Industry Classification
            </FormLabel>
            <WrappedSelect
              control={control}
              name="industryClassificationCode"
            />

            {errors.industryClassificationCode && (
              <FormErrorMessage>
                <WarningIcon mr="5px" />
                {errors.industryClassificationCode.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </form>
      </Body>
      <Footer>
        <SubmitButton form="business-details" />
      </Footer>
    </>
  );
};
