import { useMutation, useQueryClient } from '@tanstack/react-query';
import useFundingSourcesClient from './useFundingSourcesClient';
import { RenameFundingSourceRequest } from 'api/wallet-app';
import { useWalletStore } from 'stores';
import { FUNDING_SOURCE_LIST_QUERY_KEY } from './useFundingSourceList';

export default function useRenameFundingSource() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const queryClient = useQueryClient();
  const fundingSourcesClient = useFundingSourcesClient();
  return useMutation({
    mutationKey: ['rename-funding-source'],
    mutationFn: (payload: RenameFundingSourceRequest) => {
      return fundingSourcesClient.renameFundingSource({
        renameFundingSourceRequest: payload,
        xWalletId: walletId,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [FUNDING_SOURCE_LIST_QUERY_KEY],
      });
    },
  });
}
