/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ViewedScreen } from './ViewedScreen';
import {
  ViewedScreenFromJSON,
  ViewedScreenFromJSONTyped,
  ViewedScreenToJSON,
} from './ViewedScreen';
import type { ActionItem } from './ActionItem';
import {
  ActionItemFromJSON,
  ActionItemFromJSONTyped,
  ActionItemToJSON,
} from './ActionItem';

/**
 *
 * @export
 * @interface NormalAppStateDto
 */
export interface NormalAppStateDto {
  /**
   *
   * @type {string}
   * @memberof NormalAppStateDto
   */
  walletId: string;
  /**
   *
   * @type {Array<ActionItem>}
   * @memberof NormalAppStateDto
   */
  actionItems: Array<ActionItem>;
  /**
   *
   * @type {Array<ViewedScreen>}
   * @memberof NormalAppStateDto
   */
  viewedScreens: Array<ViewedScreen>;
}

/**
 * Check if a given object implements the NormalAppStateDto interface.
 */
export function instanceOfNormalAppStateDto(
  value: object
): value is NormalAppStateDto {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  if (!('actionItems' in value) || value['actionItems'] === undefined)
    return false;
  if (!('viewedScreens' in value) || value['viewedScreens'] === undefined)
    return false;
  return true;
}

export function NormalAppStateDtoFromJSON(json: any): NormalAppStateDto {
  return NormalAppStateDtoFromJSONTyped(json, false);
}

export function NormalAppStateDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NormalAppStateDto {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
    actionItems: (json['actionItems'] as Array<any>).map(ActionItemFromJSON),
    viewedScreens: (json['viewedScreens'] as Array<any>).map(
      ViewedScreenFromJSON
    ),
  };
}

export function NormalAppStateDtoToJSON(value?: NormalAppStateDto | null): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
    actionItems: (value['actionItems'] as Array<any>).map(ActionItemToJSON),
    viewedScreens: (value['viewedScreens'] as Array<any>).map(
      ViewedScreenToJSON
    ),
  };
}
