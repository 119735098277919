import { Link as NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text, Heading, Stack, Link, Divider } from '@chakra-ui/react';
import { BackButton } from '../components/back-button';
import { useStatementList } from 'hooks';
import { CenteredSpinner } from 'components/CenteredSpinner';

export default function Statements() {
  const { t } = useTranslation();
  const { data, isLoading } = useStatementList();

  return (
    <Stack spacing={4}>
      <BackButton />
      <Stack
        p={6}
        boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
        borderRadius="lg"
        background="white"
      >
        {isLoading ? (
          <CenteredSpinner centerProps={{ width: '100%', h: '15vh' }} />
        ) : (
          <>
            <Heading fontSize="2xl">
              {t('wallet.settings.monthlyStatements')}
            </Heading>
            <Stack divider={<Divider borderColor="gray.300" />}>
              {data?.statementsList.reverse().map((month) => (
                <Link
                  as={NavLink}
                  to={`/settings/statements/${month.yearCode}/${month.monthCode}`}
                  target="_blank"
                  key={month.statementName}
                  py={1}
                  display="flex"
                  flexDir="row"
                  justifyContent="space-between"
                  _hover={{ color: 'blue.400' }}
                >
                  <Text>
                    {`${month.statementName[0]}${month.statementName.substring(1).toLowerCase()}`}
                  </Text>
                  <Text color="blue.400" fontSize="16px" fontWeight="600">
                    {t('wallet.settings.view')}
                  </Text>
                </Link>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}
