import React from 'react';
import { Flex } from '@chakra-ui/react';
import { head, get, last } from 'lodash';

export const Panel = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: any;
}) => {
  //@ts-ignore
  const firstChild = children.length ? head(children) : children;
  const component = last(get(firstChild, '_source.fileName', '').split('/'));

  return (
    <Flex
      zIndex="9"
      className={`content__panel ${component}`}
      width={['100%', `488px`]}
      maxWidth="488px"
      bg="white"
      flexDirection="column"
      alignSelf="center"
      p={['20px 25px', '28px 48px 48px']}
      m="env(save-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
      height={['100%', 'auto']}
      boxShadow={[
        'null',
        '0px 10px 20px rgba(45, 55, 72, 0.24), 0px 2px 10px rgba(45, 55, 72, 0.17)',
      ]}
      pb="4rem"
      borderRadius="4px"
      overflow={['scroll', 'initial']}
      {...style}
    >
      {children}
    </Flex>
  );
};
