import { Flex } from '@chakra-ui/react';
import { NavItems } from './navItems';

export function MobileMenu() {
  return (
    <Flex
      position="fixed"
      bgColor="white"
      pt={4}
      px={3}
      pb={7}
      bottom={0}
      left={0}
      right={0}
      boxShadow="0px 6px 12px rgba(45, 55, 72, 0.22), 0px 2px 6px rgba(45, 55, 72, 0.15);"
      data-testid="mobile-menu"
      borderTop="1px solid"
      borderColor="gray.300"
    >
      <NavItems />
    </Flex>
  );
}
