import React from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Icon,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputProps,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  Text,
} from '@chakra-ui/react';
import { IoInformationCircleOutline } from 'react-icons/io5';

export interface ITextInput extends InputProps {
  errors?: any;
  errorMessage?: any;
  testid?: string;
  label?: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  subtitle?: string;
  type?: string;
  format?: string;
  mask?: string;
  info?: JSX.Element;
  hidden?: boolean;
}

export const TextInput = React.forwardRef<HTMLInputElement, ITextInput>(
  (
    { type = 'text', hidden = false, maxLength = 256, ...props },
    forwardedRef
  ): JSX.Element => {
    const {
      errors,
      errorMessage,
      label,
      testid,
      placeholder,
      disabled,
      required,
      readOnly,
      subtitle,
      name,
    } = props;

    const hasValidationError =
      errors && errors.hasOwnProperty(name) ? true : false;
    const hasError = hasValidationError || errorMessage;

    // yuck. Allow user to provide custom error message outside of yup validation. Else, rely on defaults.
    const eMessage = errorMessage
      ? errorMessage
      : hasError && errors && errors[name as string]
      ? errors[name as string].message
      : '';

    return (
      <FormControl
        hidden={hidden}
        isInvalid={hasError}
        data-testid={testid}
        isDisabled={disabled}
        isReadOnly={readOnly}
        mb="1rem"
      >
        <Box>
          {required && (
            <Text as="span" color="red.500">
              *{' '}
            </Text>
          )}
          {label && (
            <FormLabel mb="0.5rem" display="inline-block">
              {label}
            </FormLabel>
          )}
          {!required && label && (
            <Text as="span" color="gray.500" fontSize={13}>
              Optional
            </Text>
          )}
          {subtitle && (
            <Text mb=".8rem" fontSize={12} color="gray.500">
              {subtitle}
            </Text>
          )}
          {props.info && (
            <Box display="inline-block">
              <Popover placement="top-start">
                <PopoverTrigger>
                  <Icon
                    as={IoInformationCircleOutline}
                    color="#4A90E2"
                    ml="8px"
                    textAlign="center"
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>This input is not required</PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          )}
        </Box>
        <Input
          ref={forwardedRef}
          type={type}
          placeholder={placeholder}
          name={name}
          maxLength={maxLength}
          height="40px"
          onBlur={(e) => {
            e.currentTarget.value = e.currentTarget.value.trim();
          }}
          {...props}
        />

        {hasError && (
          <FormErrorMessage>
            <WarningIcon mr="5px" /> {eMessage}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
