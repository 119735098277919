/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  DefaultErrorResponse,
  GetStatementsListResponse,
  GetTransactionHistoryResponse,
  LoadRequest,
  MakeLiabilityPaymentRequest,
  MakeLiabilityPaymentResponse,
  MicroDepositSubmittedRequest,
  MicroDepositSubmittedResponse,
  SendMoneyRequest,
  SendMoneyResponse,
  TransactionMetadataResponse,
  TransactionStatementResponse,
  TransferResponse,
  UnloadRequest,
  ViolationsError,
} from '../models/index';
import {
  DefaultErrorResponseFromJSON,
  DefaultErrorResponseToJSON,
  GetStatementsListResponseFromJSON,
  GetStatementsListResponseToJSON,
  GetTransactionHistoryResponseFromJSON,
  GetTransactionHistoryResponseToJSON,
  LoadRequestFromJSON,
  LoadRequestToJSON,
  MakeLiabilityPaymentRequestFromJSON,
  MakeLiabilityPaymentRequestToJSON,
  MakeLiabilityPaymentResponseFromJSON,
  MakeLiabilityPaymentResponseToJSON,
  MicroDepositSubmittedRequestFromJSON,
  MicroDepositSubmittedRequestToJSON,
  MicroDepositSubmittedResponseFromJSON,
  MicroDepositSubmittedResponseToJSON,
  SendMoneyRequestFromJSON,
  SendMoneyRequestToJSON,
  SendMoneyResponseFromJSON,
  SendMoneyResponseToJSON,
  TransactionMetadataResponseFromJSON,
  TransactionMetadataResponseToJSON,
  TransactionStatementResponseFromJSON,
  TransactionStatementResponseToJSON,
  TransferResponseFromJSON,
  TransferResponseToJSON,
  UnloadRequestFromJSON,
  UnloadRequestToJSON,
  ViolationsErrorFromJSON,
  ViolationsErrorToJSON,
} from '../models/index';

export interface GetStatementDetailsRequest {
  xWalletId: string;
  periodYear: string;
  periodMonth: string;
}

export interface GetStatementsListRequest {
  xWalletId: string;
}

export interface GetTransactionDetailsRequest {
  xWalletId: string;
  transactionId: string;
}

export interface GetTransactionHistoryRequest {
  xWalletId: string;
  cursor?: string;
  limit?: number;
  excludePaymentRequest?: boolean;
}

export interface LoadAccountRequest {
  xWalletId: string;
  loadRequest: LoadRequest;
  idempotencyKey?: string;
}

export interface MicroDepositSubmittedOperationRequest {
  xWalletId: string;
  microDepositSubmittedRequest: MicroDepositSubmittedRequest;
  idempotencyKey?: string;
}

export interface PayLiabilityRequest {
  xWalletId: string;
  makeLiabilityPaymentRequest: MakeLiabilityPaymentRequest;
  idempotencyKey?: string;
}

export interface SendMoneyOperationRequest {
  xWalletId: string;
  sendMoneyRequest: SendMoneyRequest;
  idempotencyKey?: string;
}

export interface UnloadAccountRequest {
  xWalletId: string;
  unloadRequest: UnloadRequest;
  idempotencyKey?: string;
}

/**
 *
 */
export class TransactionsApi extends runtime.BaseAPI {
  /**
   * Gets transaction statement data given a month/year.
   */
  async getStatementDetailsRaw(
    requestParameters: GetStatementDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TransactionStatementResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getStatementDetails().'
      );
    }

    if (requestParameters['periodYear'] == null) {
      throw new runtime.RequiredError(
        'periodYear',
        'Required parameter "periodYear" was null or undefined when calling getStatementDetails().'
      );
    }

    if (requestParameters['periodMonth'] == null) {
      throw new runtime.RequiredError(
        'periodMonth',
        'Required parameter "periodMonth" was null or undefined when calling getStatementDetails().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/transactions/statement/{periodYear}/{periodMonth}`
          .replace(
            `{${'periodYear'}}`,
            encodeURIComponent(String(requestParameters['periodYear']))
          )
          .replace(
            `{${'periodMonth'}}`,
            encodeURIComponent(String(requestParameters['periodMonth']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TransactionStatementResponseFromJSON(jsonValue)
    );
  }

  /**
   * Gets transaction statement data given a month/year.
   */
  async getStatementDetails(
    requestParameters: GetStatementDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TransactionStatementResponse> {
    const response = await this.getStatementDetailsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Returns a list of months for available statements for the given wallet.
   */
  async getStatementsListRaw(
    requestParameters: GetStatementsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetStatementsListResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getStatementsList().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/transactions/statement/available`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetStatementsListResponseFromJSON(jsonValue)
    );
  }

  /**
   * Returns a list of months for available statements for the given wallet.
   */
  async getStatementsList(
    requestParameters: GetStatementsListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetStatementsListResponse> {
    const response = await this.getStatementsListRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Gets details for a specific transaction.
   */
  async getTransactionDetailsRaw(
    requestParameters: GetTransactionDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TransactionMetadataResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getTransactionDetails().'
      );
    }

    if (requestParameters['transactionId'] == null) {
      throw new runtime.RequiredError(
        'transactionId',
        'Required parameter "transactionId" was null or undefined when calling getTransactionDetails().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/transactions/{transactionId}`.replace(
          `{${'transactionId'}}`,
          encodeURIComponent(String(requestParameters['transactionId']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TransactionMetadataResponseFromJSON(jsonValue)
    );
  }

  /**
   * Gets details for a specific transaction.
   */
  async getTransactionDetails(
    requestParameters: GetTransactionDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TransactionMetadataResponse> {
    const response = await this.getTransactionDetailsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Gets a list of transactions.
   */
  async getTransactionHistoryRaw(
    requestParameters: GetTransactionHistoryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetTransactionHistoryResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling getTransactionHistory().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['cursor'] != null) {
      queryParameters['cursor'] = requestParameters['cursor'];
    }

    if (requestParameters['limit'] != null) {
      queryParameters['limit'] = requestParameters['limit'];
    }

    if (requestParameters['excludePaymentRequest'] != null) {
      queryParameters['excludePaymentRequest'] =
        requestParameters['excludePaymentRequest'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/wallet/transactions`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetTransactionHistoryResponseFromJSON(jsonValue)
    );
  }

  /**
   * Gets a list of transactions.
   */
  async getTransactionHistory(
    requestParameters: GetTransactionHistoryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetTransactionHistoryResponse> {
    const response = await this.getTransactionHistoryRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Creates a new load transaction.
   */
  async loadAccountRaw(
    requestParameters: LoadAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TransferResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling loadAccount().'
      );
    }

    if (requestParameters['loadRequest'] == null) {
      throw new runtime.RequiredError(
        'loadRequest',
        'Required parameter "loadRequest" was null or undefined when calling loadAccount().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/load-account`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LoadRequestToJSON(requestParameters['loadRequest']),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TransferResponseFromJSON(jsonValue)
    );
  }

  /**
   * Creates a new load transaction.
   */
  async loadAccount(
    requestParameters: LoadAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TransferResponse> {
    const response = await this.loadAccountRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Submit a micro deposit.
   */
  async microDepositSubmittedRaw(
    requestParameters: MicroDepositSubmittedOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MicroDepositSubmittedResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling microDepositSubmitted().'
      );
    }

    if (requestParameters['microDepositSubmittedRequest'] == null) {
      throw new runtime.RequiredError(
        'microDepositSubmittedRequest',
        'Required parameter "microDepositSubmittedRequest" was null or undefined when calling microDepositSubmitted().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/micro-deposit-submitted`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: MicroDepositSubmittedRequestToJSON(
          requestParameters['microDepositSubmittedRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MicroDepositSubmittedResponseFromJSON(jsonValue)
    );
  }

  /**
   * Submit a micro deposit.
   */
  async microDepositSubmitted(
    requestParameters: MicroDepositSubmittedOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MicroDepositSubmittedResponse> {
    const response = await this.microDepositSubmittedRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Make a liability payment.
   */
  async payLiabilityRaw(
    requestParameters: PayLiabilityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MakeLiabilityPaymentResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling payLiability().'
      );
    }

    if (requestParameters['makeLiabilityPaymentRequest'] == null) {
      throw new runtime.RequiredError(
        'makeLiabilityPaymentRequest',
        'Required parameter "makeLiabilityPaymentRequest" was null or undefined when calling payLiability().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/liability-payment`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: MakeLiabilityPaymentRequestToJSON(
          requestParameters['makeLiabilityPaymentRequest']
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MakeLiabilityPaymentResponseFromJSON(jsonValue)
    );
  }

  /**
   * Make a liability payment.
   */
  async payLiability(
    requestParameters: PayLiabilityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MakeLiabilityPaymentResponse> {
    const response = await this.payLiabilityRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Send money to another wallet.
   */
  async sendMoneyRaw(
    requestParameters: SendMoneyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SendMoneyResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling sendMoney().'
      );
    }

    if (requestParameters['sendMoneyRequest'] == null) {
      throw new runtime.RequiredError(
        'sendMoneyRequest',
        'Required parameter "sendMoneyRequest" was null or undefined when calling sendMoney().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/send-money`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendMoneyRequestToJSON(requestParameters['sendMoneyRequest']),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SendMoneyResponseFromJSON(jsonValue)
    );
  }

  /**
   * Send money to another wallet.
   */
  async sendMoney(
    requestParameters: SendMoneyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SendMoneyResponse> {
    const response = await this.sendMoneyRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates a new unload transaction.
   */
  async unloadAccountRaw(
    requestParameters: UnloadAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TransferResponse>> {
    if (requestParameters['xWalletId'] == null) {
      throw new runtime.RequiredError(
        'xWalletId',
        'Required parameter "xWalletId" was null or undefined when calling unloadAccount().'
      );
    }

    if (requestParameters['unloadRequest'] == null) {
      throw new runtime.RequiredError(
        'unloadRequest',
        'Required parameter "unloadRequest" was null or undefined when calling unloadAccount().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xWalletId'] != null) {
      headerParameters['X-Wallet-Id'] = String(requestParameters['xWalletId']);
    }

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(
        requestParameters['idempotencyKey']
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'bushel-id-auth',
        []
      );
    }

    const response = await this.request(
      {
        path: `/api/v1/commands/unload-account`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UnloadRequestToJSON(requestParameters['unloadRequest']),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TransferResponseFromJSON(jsonValue)
    );
  }

  /**
   * Creates a new unload transaction.
   */
  async unloadAccount(
    requestParameters: UnloadAccountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TransferResponse> {
    const response = await this.unloadAccountRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
