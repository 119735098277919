import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useFundingSourceClient from './useFundingSourcesClient';

export const FUNDING_SOURCE_DETAILS_QUERY_KEY = 'funding-source-details';

export default function useFundingSourceDetails(fundingSourceId: string) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const fundingSourcesClient = useFundingSourceClient();
  return useQuery({
    queryKey: [FUNDING_SOURCE_DETAILS_QUERY_KEY, fundingSourceId],
    queryFn: () => {
      return fundingSourcesClient.getFundingSourceDetails({
        xWalletId: walletId,
        fundingSourceId,
      });
    },
  });
}
