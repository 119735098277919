import {
  Flex,
  Icon,
  Text,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { UserListItemTypeEnum } from 'api/wallet-app';
import { AuthorizedUserTooltip } from 'components/AuthorizedUserTooltip';
import { useUserInfo } from 'hooks/api';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import {
  IoEllipsisVerticalSharp,
  IoPencil,
  IoSparklesOutline,
  IoTrashOutline,
} from 'react-icons/io5';

export const FundingSourceContextMenu = ({
  handleRemoveSource,
  handleEditName,
  handleSetDefault,
  isPreferred,
  isOnlyFundingSource,
}: {
  handleRemoveSource: () => void;
  handleEditName: () => void;
  handleSetDefault: () => void;
  isPreferred: boolean;
  isOnlyFundingSource: boolean;
}) => {
  const { data: userInfo } = useUserInfo();

  const { isOpen, onClose, onToggle } = useDisclosure();

  const setDefault = () => {
    handleSetDefault();
    onClose();
  };

  const isAuthorizedUser = useMemo(
    () => userInfo?.type === UserListItemTypeEnum.AuthorizedUser,
    [userInfo?.type]
  );

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Flex>
          <AuthorizedUserTooltip placement="left">
            <IconButton
              aria-label="Funding Source Options button"
              icon={<IoEllipsisVerticalSharp size="18px" />}
              onClick={onToggle}
              variant="unstyled"
              minW="18px"
              minWidth="18px"
              size="icon"
              display="inline-block"
              isDisabled={isAuthorizedUser ?? true}
            />
          </AuthorizedUserTooltip>
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        borderRadius="4px"
        width="225px"
        padding=".5rem"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.16)"
        _focus={{
          outline: 'none',
        }}
      >
        <PopoverArrow />
        <PopoverBody display="flex" flexDirection="column" height="100%">
          <VStack alignItems="flex-start" spacing="14px">
            <ListItem
              title="Edit Nickname"
              icon={IoPencil}
              action={handleEditName}
            />
            <ListItem
              title="Set as Preferred"
              icon={IoSparklesOutline}
              action={setDefault}
              disabled={isPreferred}
            />
            <ListItem
              title="Unlink Account"
              icon={IoTrashOutline}
              action={handleRemoveSource}
              disabled={isOnlyFundingSource}
            />
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const ListItem = ({
  title,
  icon,
  action,
  disabled = false,
}: {
  title: string;
  icon: IconType;
  action: () => void;
  disabled?: boolean;
}) => {
  return (
    <Flex
      width="100%"
      fontFamily="inter"
      alignItems="center"
      _hover={!disabled ? { background: 'gray.50' } : {}}
      p="4px"
      color={disabled ? 'gray.400' : 'black'}
      cursor={disabled ? 'not-allowed' : 'pointer'}
    >
      <Icon as={icon} mr="10px" fontSize="24px" />
      <Text
        _hover={!disabled ? { textDecoration: 'underline' } : {}}
        fontSize="16px"
        onClick={disabled ? () => null : action}
      >
        {title}
      </Text>
    </Flex>
  );
};
