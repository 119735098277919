import { useQuery } from '@tanstack/react-query';
import useWalletsClient from './useWalletsClient';

export const WALLET_PROFILE_QUERY_KEY = 'wallet-profile';

export default function useWalletProfile(walletId?: string) {
  const walletsClient = useWalletsClient();
  return useQuery({
    queryKey: [WALLET_PROFILE_QUERY_KEY, walletId],
    queryFn: () => {
      return walletsClient.getWalletProfile({ xWalletId: walletId! });
    },
    enabled: !!walletId,
    retry: true,
  });
}
