import { Box, Flex, Image } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { desktopHeaderHeight, background, bushelLogo } from 'utils/helpers';

export function SinglePanelView({
  children,
  displayBackgroundImage = true,
}: PropsWithChildren<{ displayBackgroundImage?: boolean }>) {
  return (
    <Box
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      zIndex={2}
      backgroundColor="white"
      overflow="auto"
    >
      <Flex flex="1" minWidth="100%" minHeight="100vh" flexDirection="column">
        <Flex
          className="header"
          width="100%"
          height={desktopHeaderHeight}
          justifyContent="center"
          alignItems="center"
          display={['none', 'flex']}
        >
          <Image height="32px" src={bushelLogo} alt="Bushel Logo" mt="1rem" />
        </Flex>
        <Flex
          className="content"
          flexDirection="column"
          width={['auto', '100%']}
          backgroundImage={[
            'null',
            displayBackgroundImage ? background : 'null',
          ]}
          backgroundRepeat="no-repeat"
          backgroundPosition="center center"
          backgroundSize="contain"
          pb="3rem"
        >
          <Flex
            width={['100%', '488px']}
            maxWidth="488px"
            bg="white"
            flexDirection="column"
            alignSelf="center"
            p={['20px 25px', '38px 48px 48px']}
            height={['100%', 'auto']}
            boxShadow={['none', '0px 1px 20px 0px rgba(45,55,72,0.3)']}
            borderRadius="16px"
            mt={['auto', '2rem']}
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
