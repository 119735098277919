import { useQuery } from '@tanstack/react-query';
import useTransactionsClient from './useTransactionsClient';
import { useWalletStore } from 'stores';

export const TRANSACTION_DETAILS_QUERY_KEY = 'transaction-details';

export default function useTransactionDetails(transactionId: string) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const transactionClient = useTransactionsClient();
  return useQuery({
    queryKey: [TRANSACTION_DETAILS_QUERY_KEY, { transactionId }],
    queryFn: () => {
      return transactionClient.getTransactionDetails({
        transactionId,
        xWalletId: walletId,
      });
    },
  });
}
