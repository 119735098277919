/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Deposit statement details.
 * @export
 * @interface Deposit
 */
export interface Deposit {
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  deposit: string;
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  balance: string;
  /**
   *
   * @type {string}
   * @memberof Deposit
   */
  amount: string;
}

/**
 * Check if a given object implements the Deposit interface.
 */
export function instanceOfDeposit(value: object): value is Deposit {
  if (!('date' in value) || value['date'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  if (!('deposit' in value) || value['deposit'] === undefined) return false;
  if (!('balance' in value) || value['balance'] === undefined) return false;
  if (!('amount' in value) || value['amount'] === undefined) return false;
  return true;
}

export function DepositFromJSON(json: any): Deposit {
  return DepositFromJSONTyped(json, false);
}

export function DepositFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Deposit {
  if (json == null) {
    return json;
  }
  return {
    date: json['date'],
    description: json['description'],
    deposit: json['deposit'],
    balance: json['balance'],
    amount: json['amount'],
  };
}

export function DepositToJSON(value?: Deposit | null): any {
  if (value == null) {
    return value;
  }
  return {
    date: value['date'],
    description: value['description'],
    deposit: value['deposit'],
    balance: value['balance'],
    amount: value['amount'],
  };
}
