import {
  AcknowledgeAlertRequest,
  AlertsResponse,
  GetAlertsRequest,
} from 'api/wallet-app';
import { alertList } from 'mocks/fixtures/alerts-fixtures';
import { walletBasePath } from 'mocks/helpers';
import { http, HttpResponse } from 'msw';

const alertsHandlers = [
  /*
   * GET /api/v1/wallet/alerts
   * Generated API Endpoint Name: getAlerts
   */
  http.get<GetAlertsRequest, undefined, AlertsResponse>(
    walletBasePath('alerts'),
    () => {
      return HttpResponse.json({ alerts: alertList });
    }
  ),

  /*
   * POST /api/v1/wallet/alerts/{alertId}/acknowledge
   * Generated API Endpoint Name: acknowledgeAlert
   */
  http.post<AcknowledgeAlertRequest, undefined, undefined>(
    walletBasePath('alerts/:alertId/acknowledge'),
    ({ params }) => {
      const alertToAcknowledge = alertList.find(
        (alert) => alert.id === params.alertId
      );

      if (!alertToAcknowledge) {
        throw new HttpResponse(null, { status: 404 });
      } else {
        if (alertToAcknowledge.dismissible) {
          alertList.splice(
            alertList.findIndex((alert) => alert.id === alertToAcknowledge.id),
            1
          );
          return new HttpResponse(null, { status: 204 });
        }
        throw new HttpResponse(null, { status: 422 });
      }
    }
  ),
];

export default alertsHandlers;
