import React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';

export const SelectPathModal = ({
  isOpen,
  onClose,
  addBankAccount,
  addLoanAccount,
}: {
  isOpen: boolean;
  onClose: () => void;
  addBankAccount: () => void;
  addLoanAccount: () => void;
}) => {
  const handleAddBankAccount = () => {
    onClose();
    addBankAccount();
  };

  const handleAddLoanAccount = () => {
    onClose();
    addLoanAccount();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent p="1rem">
        <ModalHeader pb=".5rem">
          <CloseIcon cursor="pointer" fontSize="12px" onClick={onClose} />
        </ModalHeader>
        <ModalBody>
          <Heading pb="1rem">Add to Wallet</Heading>
          <VStack divider={<Divider />} mt=".5rem">
            <Flex
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              _hover={{
                background: 'gray.50',
              }}
              cursor="pointer"
              padding=".5rem"
              onClick={handleAddBankAccount}
            >
              <Flex direction="column" fontFamily="inter">
                <Box>Bank Account</Box>
                <Box fontSize="12px" color="#69788A" mt=".5rem">
                  Add a checking or savings account to your wallet
                </Box>
              </Flex>
              <Box>
                <ChevronRightIcon
                  fontSize="1.8rem"
                  fontWeight="normal"
                  color="#69788A"
                />
              </Box>
            </Flex>
            <Flex
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              cursor="pointer"
              _hover={{
                background: 'gray.50',
              }}
              padding=".5rem"
              onClick={handleAddLoanAccount}
            >
              <Flex direction="column" fontFamily="inter">
                <Box>Loan & Line of Credit</Box>
                <Box fontSize="12px" color="#69788A" mt=".5rem">
                  Link an existing loan account to your wallet
                </Box>
              </Flex>
              <Box>
                <ChevronRightIcon fontSize="1.8rem" color="#69788A" />
              </Box>
            </Flex>
          </VStack>
          <ModalFooter mt="3rem" padding="0">
            <Button
              variant="secondary"
              minWidth="100%"
              onClick={onClose}
              _focus={{ outline: 'none' }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
