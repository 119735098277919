import React from 'react';
import { Body } from 'components/layout/onboarding';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useSelector } from '@xstate/react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Icon,
  Input,
  Text,
  Box,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import {
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormGetValues,
  FieldValues,
  FieldErrors,
} from 'react-hook-form';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

const SSN_DUMMY_VAL = '*********';
export const SSN_TEMP_VAL = '00000000000';

export const SocialSecurityForm = ({
  isEditing,
  register,
  errors,
  setValue,
  getValues,
}: {
  isEditing: boolean;
  register: UseFormRegister<{ ssn: string }>;
  errors: FieldErrors;
  setValue: UseFormSetValue<{ ssn: string }>;
  getValues: UseFormGetValues<{ ssn: string }>;
}) => {
  const [isMasked, setIsMasked] = React.useState(true);
  const { ssn } = getValues();

  // SSN is PII. If we get the dummy val from the API, we won't submit it again unless
  // the user edits it. Need to jump through a few hoops to make it appear as fluid as possible.

  const handleFormatting = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    let val = target.value.replace(/\D/g, '');
    let newVal = '';
    const sizes = [3, 2, 4];

    for (const i in sizes) {
      if (val.length > sizes[i]) {
        newVal += val.substring(0, sizes[i]) + '-';
        val = val.substring(sizes[i]);
      } else break;
    }

    newVal += val;
    target.value = newVal;
  };

  const handleToggleMask = () => {
    setIsMasked(!isMasked);
  };

  React.useEffect(() => {
    if (ssn === SSN_DUMMY_VAL) {
      setValue('ssn', SSN_TEMP_VAL);
    }
  }, []);

  React.useEffect(() => {
    if (!isMasked && ssn === SSN_TEMP_VAL) {
      setValue('ssn', '');
    }
  }, [isMasked]);

  return (
    <>
      <Text>
        Your money is held in an FDIC insured financial institution, which
        requires collecting your Social Security number.
      </Text>
      <Body>
        <Box
          border="solid 1px #ddd"
          borderRadius="4px"
          p="1.5rem"
          mt="1rem"
          backgroundColor="gray.50"
        >
          <FormControl isInvalid={!!errors.ssn}>
            <FormLabel>
              <Text as="span" mr={1} color="red">
                *
              </Text>
              Social Security Number
            </FormLabel>
            <InputGroup>
              <Input
                placeholder="000-00-0000"
                required={isEditing ? false : true}
                backgroundColor="white"
                data-testid="ssn"
                maxLength={11}
                type={isMasked || isEditing ? 'password' : 'text'}
                onKeyUp={handleFormatting}
                {...register('ssn', {
                  required: {
                    value: isEditing ? false : true,
                    message: 'This field is required',
                  },
                  minLength: {
                    value: 11,
                    message: 'Please enter a valid Social Security Number',
                  },
                  maxLength: 11,
                })}
                isInvalid={errors.ssn ? true : false}
              />
              <InputRightAddon background="white" cursor="pointer">
                <Icon
                  color="black"
                  as={isMasked ? IoEyeOffOutline : IoEyeOutline}
                  onClick={handleToggleMask}
                  fontSize={18}
                />
              </InputRightAddon>
            </InputGroup>
            <FormErrorMessage>{errors.ssn?.message as string}</FormErrorMessage>
          </FormControl>
        </Box>
      </Body>
    </>
  );
};
