/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserInviteListItem } from './UserInviteListItem';
import {
  UserInviteListItemFromJSON,
  UserInviteListItemFromJSONTyped,
  UserInviteListItemToJSON,
} from './UserInviteListItem';
import type { UserListItem } from './UserListItem';
import {
  UserListItemFromJSON,
  UserListItemFromJSONTyped,
  UserListItemToJSON,
} from './UserListItem';

/**
 * The response for querying users.
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
  /**
   *
   * @type {Array<UserListItem>}
   * @memberof UserListResponse
   */
  users: Array<UserListItem>;
  /**
   *
   * @type {Array<UserInviteListItem>}
   * @memberof UserListResponse
   */
  invites: Array<UserInviteListItem>;
}

/**
 * Check if a given object implements the UserListResponse interface.
 */
export function instanceOfUserListResponse(
  value: object
): value is UserListResponse {
  if (!('users' in value) || value['users'] === undefined) return false;
  if (!('invites' in value) || value['invites'] === undefined) return false;
  return true;
}

export function UserListResponseFromJSON(json: any): UserListResponse {
  return UserListResponseFromJSONTyped(json, false);
}

export function UserListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserListResponse {
  if (json == null) {
    return json;
  }
  return {
    users: (json['users'] as Array<any>).map(UserListItemFromJSON),
    invites: (json['invites'] as Array<any>).map(UserInviteListItemFromJSON),
  };
}

export function UserListResponseToJSON(value?: UserListResponse | null): any {
  if (value == null) {
    return value;
  }
  return {
    users: (value['users'] as Array<any>).map(UserListItemToJSON),
    invites: (value['invites'] as Array<any>).map(UserInviteListItemToJSON),
  };
}
