/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FundingSourceDetailsDto } from './FundingSourceDetailsDto';
import {
  FundingSourceDetailsDtoFromJSON,
  FundingSourceDetailsDtoFromJSONTyped,
  FundingSourceDetailsDtoToJSON,
} from './FundingSourceDetailsDto';

/**
 *
 * @export
 * @interface GetFundingSourceResponse
 */
export interface GetFundingSourceResponse {
  /**
   *
   * @type {string}
   * @memberof GetFundingSourceResponse
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof GetFundingSourceResponse
   */
  fundingSourceId: string;
  /**
   *
   * @type {boolean}
   * @memberof GetFundingSourceResponse
   */
  hasActiveTransactions: boolean;
  /**
   *
   * @type {FundingSourceDetailsDto}
   * @memberof GetFundingSourceResponse
   */
  details: FundingSourceDetailsDto;
}

/**
 * Check if a given object implements the GetFundingSourceResponse interface.
 */
export function instanceOfGetFundingSourceResponse(
  value: object
): value is GetFundingSourceResponse {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  if (!('fundingSourceId' in value) || value['fundingSourceId'] === undefined)
    return false;
  if (
    !('hasActiveTransactions' in value) ||
    value['hasActiveTransactions'] === undefined
  )
    return false;
  if (!('details' in value) || value['details'] === undefined) return false;
  return true;
}

export function GetFundingSourceResponseFromJSON(
  json: any
): GetFundingSourceResponse {
  return GetFundingSourceResponseFromJSONTyped(json, false);
}

export function GetFundingSourceResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetFundingSourceResponse {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
    fundingSourceId: json['fundingSourceId'],
    hasActiveTransactions: json['hasActiveTransactions'],
    details: FundingSourceDetailsDtoFromJSON(json['details']),
  };
}

export function GetFundingSourceResponseToJSON(
  value?: GetFundingSourceResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
    fundingSourceId: value['fundingSourceId'],
    hasActiveTransactions: value['hasActiveTransactions'],
    details: FundingSourceDetailsDtoToJSON(value['details']),
  };
}
