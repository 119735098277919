import {
  Stack,
  Heading,
  Divider,
  Button,
  Text,
  HStack,
  Image,
} from '@chakra-ui/react';
import { AlertDtoAlertTypeEnum } from 'api/wallet-app';
import { useAcknowledgeAlert, useAlertList } from 'hooks';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import NoAlertsBellIcon from 'images/noAlertsBell.svg';
import currency from 'currency.js';
import { HorizontalSkeletons } from 'components/HorizontalSkeletons';
import {
  ACTIVITY_SEARCH_PARAM_KEY,
  OPEN_REQUESTS_SEARCH_PARAM,
} from 'pages/WalletApp/Activity/activity-page-search-params';

function alertMessage(alertType: AlertDtoAlertTypeEnum) {
  switch (alertType) {
    case AlertDtoAlertTypeEnum.CanceledPayer:
      return 'canceled your request for';
    case AlertDtoAlertTypeEnum.CreatedPayer:
      return 'requested payment for';
    case AlertDtoAlertTypeEnum.DeclinedPayee:
      return 'declined your request for';
  }
}

export default function Alerts() {
  const alertIdBeingAcknowledgedRef = useRef('');
  const navigate = useNavigate();

  const { data: alertListData, isLoading: alertsIsLoading } = useAlertList();
  const {
    mutate: acknowledgeAlert,
    isPending: acknowledgeAlertIsPending,
    isSuccess: acknowledgeAlertIsSuccess,
  } = useAcknowledgeAlert();

  return (
    <Stack
      align="flex-end"
      background="white"
      boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
      borderRadius="lg"
      h="fit-content"
      p={4}
      flex={{ base: 1, md: 2 }}
      spacing={6}
      w="100%"
    >
      <Stack align="center" spacing={4} w="100%">
        <Heading as="h6" fontSize="xl" alignSelf="flex-start">
          Alerts
        </Heading>
        {alertsIsLoading ? (
          <HorizontalSkeletons
            numOfSkeletons={4}
            skeletonProps={{ h: '60px' }}
            stackProps={{ w: '100%' }}
          />
        ) : !!alertListData?.alerts.length ? (
          <Stack spacing={4} divider={<Divider />} w="100%">
            {alertListData.alerts.slice(0, 4).map((alert) => {
              return (
                <Stack w="100%" spacing={0} key={alert.id}>
                  <HStack spacing={1}>
                    <Text>
                      <span style={{ fontWeight: 'bold' }}>
                        {alert.dba}&nbsp;
                      </span>
                      <span>{alertMessage(alert.alertType)}&nbsp;</span>
                      <span style={{ fontWeight: 'bold' }}>
                        {currency(alert.amount).format()}
                      </span>
                    </Text>
                  </HStack>
                  <Button
                    variant="ghost"
                    size="sm"
                    w="fit-content"
                    alignSelf="flex-end"
                    minW="fit-content"
                    minWidth="fit-content"
                    isLoading={
                      (acknowledgeAlertIsPending &&
                        alertIdBeingAcknowledgedRef.current === alert.id) ||
                      (acknowledgeAlertIsSuccess &&
                        alertIdBeingAcknowledgedRef.current === alert.id)
                    }
                    onClick={() => {
                      if (
                        alert.alertType === AlertDtoAlertTypeEnum.CreatedPayer
                      ) {
                        navigate(`activity/request/${alert.resourceId}`);
                      } else {
                        alertIdBeingAcknowledgedRef.current = alert.id;
                        acknowledgeAlert(alert.id);
                      }
                    }}
                  >
                    {alert.alertType === AlertDtoAlertTypeEnum.CreatedPayer
                      ? 'View'
                      : 'Dismiss'}
                  </Button>
                </Stack>
              );
            })}
          </Stack>
        ) : (
          <>
            <Image src={NoAlertsBellIcon} w="100px" h="80px" />
            <Text textAlign="center">
              You're all caught up. Future payment requests will appear in this
              area.
            </Text>
          </>
        )}
      </Stack>
      {alertListData?.alerts && alertListData.alerts.length > 4 && (
        <Button
          variant="secondary"
          maxW="fit-content"
          size="sm"
          onClick={() => {
            navigate(
              `activity?${ACTIVITY_SEARCH_PARAM_KEY}=${OPEN_REQUESTS_SEARCH_PARAM}`
            );
          }}
        >
          View More
        </Button>
      )}
    </Stack>
  );
}
