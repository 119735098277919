import { ProfilesResponse } from 'api/wallet-app';
import { http, HttpResponse, PathParams } from 'msw';
import { walletBasePath } from 'mocks/helpers';
import {
  recentProfileList,
  searchProfileList,
} from 'mocks/fixtures/wallet-profiles-fixtures';

const walletProfilesHandlers = [
  /*
   * GET /api/v1/wallet/profiles
   * Generated API Endpoint Name: listRecentProfiles
   */
  http.get<PathParams, undefined, ProfilesResponse>(
    walletBasePath('profiles'),
    () => {
      return HttpResponse.json({ profiles: recentProfileList });
    }
  ),

  /*
   * GET /api/v1/wallet/profiles/search
   * Generated API Endpoint Name: searchWalletProfiles
   */
  http.get<PathParams, undefined, ProfilesResponse>(
    walletBasePath('profiles/search'),
    ({ request }) => {
      const url = new URL(request.url);

      const searchValue = url.searchParams.get('searchValue');

      if (searchValue) {
        const matchedProfiles = searchProfileList.filter(
          (profile) =>
            profile.handle.includes(searchValue) ||
            profile.doingBusinessAs?.includes(searchValue)
        );

        return HttpResponse.json({ profiles: matchedProfiles });
      } else {
        return HttpResponse.json({ profiles: [] });
      }
    }
  ),
];

export default walletProfilesHandlers;
