/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Industry } from './Industry';
import {
  IndustryFromJSON,
  IndustryFromJSONTyped,
  IndustryToJSON,
} from './Industry';

/**
 *
 * @export
 * @interface GetIndustryClassificationCodesResponse
 */
export interface GetIndustryClassificationCodesResponse {
  /**
   *
   * @type {Array<Industry>}
   * @memberof GetIndustryClassificationCodesResponse
   */
  industryClassifications: Array<Industry>;
}

/**
 * Check if a given object implements the GetIndustryClassificationCodesResponse interface.
 */
export function instanceOfGetIndustryClassificationCodesResponse(
  value: object
): value is GetIndustryClassificationCodesResponse {
  if (
    !('industryClassifications' in value) ||
    value['industryClassifications'] === undefined
  )
    return false;
  return true;
}

export function GetIndustryClassificationCodesResponseFromJSON(
  json: any
): GetIndustryClassificationCodesResponse {
  return GetIndustryClassificationCodesResponseFromJSONTyped(json, false);
}

export function GetIndustryClassificationCodesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetIndustryClassificationCodesResponse {
  if (json == null) {
    return json;
  }
  return {
    industryClassifications: (
      json['industryClassifications'] as Array<any>
    ).map(IndustryFromJSON),
  };
}

export function GetIndustryClassificationCodesResponseToJSON(
  value?: GetIndustryClassificationCodesResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    industryClassifications: (
      value['industryClassifications'] as Array<any>
    ).map(IndustryToJSON),
  };
}
