export default async function enableMfa({
  baseUrl,
  realm,
  token,
}: {
  baseUrl?: string;
  realm?: string;
  token?: string;
}) {
  const response = await fetch(
    `${baseUrl}/realms/${realm}/multi-factor/add-mfa-setup`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.json();
}
