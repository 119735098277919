import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { IoAddCircleOutline, IoPencilSharp } from 'react-icons/io5';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useSelector } from '@xstate/react';
import { PersonDto, PersonDtoRolesEnum } from 'api/wallet-app';
import { Card, BadgeValue } from './Card';

export const BeneficialOwnerCardInfo = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);
  const beneficialOwners =
    (context?.people &&
      context?.people.filter((person) =>
        person.roles?.includes(PersonDtoRolesEnum.BeneficialOwner)
      )) ||
    [];

  const beneficialOwnersCount = beneficialOwners.length;

  const addOwner = () => {
    onboardingActor?.send({ type: 'addOwner', value: '' });
  };

  const handleClick = (id: string | undefined) => {
    if (!id) return;
    const person = context.people.find((i) => i.id == id);
    if (!person) {
      return;
    }
    onboardingActor?.send({
      type: 'editBeneficialOwner',
      value: person,
    });
  };

  return (
    <Flex direction="column" mb={5}>
      <Heading size="sm">
        {context?.isBeneficialOwner && 'Other'} Beneficial Owners
      </Heading>
      <Text fontSize={14} my={3}>
        All individuals who own 25% or more must be added to the business
      </Text>
      <Flex flexDirection="column">
        {beneficialOwnersCount > 0 &&
          beneficialOwners.map((owner: Partial<PersonDto>) => {
            if (!owner) return;
            return (
              <Box mb={2} key={owner.id}>
                <Card
                  title={`${owner.firstName} ${owner.lastName}`}
                  value={BadgeValue.readyToSubmit}
                  handler={() => handleClick(owner?.id)}
                  icon={IoPencilSharp}
                  iconText="Edit"
                  id={owner.id}
                  canDelete={true}
                />
              </Box>
            );
          })}
      </Flex>
      {beneficialOwnersCount < 5 && (
        <Button
          leftIcon={<IoAddCircleOutline fontSize="25px" />}
          variant="secondary"
          onClick={addOwner}
          width="auto"
          alignSelf="flex-start"
          textTransform="none"
          fontSize={14}
        >
          Add an Owner
        </Button>
      )}
    </Flex>
  );
};
