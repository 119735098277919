import { useEffect, useState } from 'react';

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const listItems = [
  'Gathering account information',
  'Creating your account',
  'Verifying account security',
];

export default function useCyclingListItems() {
  const [unorderedList, setUnorderedList] = useState<string[]>([]);

  useEffect(() => {
    const cycleListItems = async () => {
      while (true) {
        for (let i = 0; i < listItems.length; i++) {
          await sleep(2000);
          setUnorderedList((list) => [...list, listItems[i]]);
        }
        await sleep(2000);
        setUnorderedList([]);
      }
    };
    cycleListItems();
  }, []);

  return unorderedList;
}
