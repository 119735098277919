import React from 'react';
import { Flex, Input } from '@chakra-ui/react';
import NumberFormat, {
  NumberFormatValues,
  FormatInputValueFunction,
} from 'react-number-format';

export function MoneyInput({
  onChange,
  amount,
  isDisabled = false,
  insufficientFunds = false,
  showBorder = true,
}: {
  onChange: (value: NumberFormatValues) => void;
  amount?: string;
  isDisabled?: boolean;
  insufficientFunds?: boolean;
  showBorder?: boolean;
}) {
  const [realInputRef, setRealInputRef] = React.useState<HTMLInputElement>();

  const handleChange = (e: NumberFormatValues) => {
    const newVals = reformatValues(e);
    if (e.floatValue == 0) {
      realInputRef && realInputRef.setSelectionRange(4, 4);
    }
    onChange(newVals);
  };

  const reformatValues = (e: NumberFormatValues) => {
    /* https://github.com/s-yadav/react-number-format/issues/366 */
    // ESSENTIALLY: divide the value by 100 to simulate r-to-l number input
    // and then fix it again before passing it back up

    // if we were to add the ability to set an initial value, we'd have to stringify and multiply it by 100 to match the formatting.
    const val = (parseFloat(e.value) / 100).toFixed(2);
    const floatVal = e.floatValue && e.floatValue / 100;
    const newVals = { ...e, floatValue: floatVal, value: val };
    return newVals;
  };

  const inputRef = React.useRef<any>(null);

  React.useEffect(() => {
    // all this just to set focus on a wrapped input element....
    if (inputRef.current !== null) {
      const NumberInputClassname = inputRef.current.props?.className;
      const realInput =
        NumberInputClassname &&
        (document.getElementsByClassName(
          NumberInputClassname
        )[0] as HTMLInputElement);
      setRealInputRef(realInput);
      realInput && realInput.focus();
    }
  }, []);

  const currencyFormatter = (value: string) => {
    const amount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(parseInt(value) / 100);

    return `${amount}`;
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (amount == '0.00') {
      if (e.keyCode == 8 || e.keyCode == 37) {
        // delete or back arrow
        e.preventDefault();
      }
      realInputRef && realInputRef.setSelectionRange(6, 6);
    }
  };

  const activeStyle = {
    border: 'solid 2px',
    borderColor: 'blue.300',
    backgroundColor: '#f7fafc',
  };

  return (
    <Flex
      height="125px"
      alignItems="center"
      border={showBorder ? 'solid 1px' : ''}
      borderColor={showBorder ? 'gray.200' : ''}
      borderRadius="8px"
      _focusWithin={showBorder ? activeStyle : {}}
      _hover={isDisabled || !showBorder ? {} : activeStyle}
      data-testid="money-input"
    >
      <Input
        type="tel"
        ref={inputRef}
        height="100%"
        as={NumberFormat}
        value={amount}
        onValueChange={handleChange}
        onKeyDown={handleKeyDown}
        thousandSeparator={true}
        prefix={'$'}
        fixedDecimalScale={true}
        decimalScale={2}
        allowNegative={false}
        allowEmptyFormatting={false}
        format={currencyFormatter as FormatInputValueFunction}
        isAllowed={(values: NumberFormatValues) => {
          const newVals = reformatValues(values);
          return parseInt(newVals.value) < 1000001.01;
        }}
        border="none"
        fontSize="3rem"
        fontWeight="bold"
        textAlign="center"
        color={insufficientFunds ? 'red.500' : 'black'}
        isDisabled={isDisabled}
      />
    </Flex>
  );
}
