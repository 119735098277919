import React, { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  ArrowBackIcon,
  ChevronRightIcon,
  CloseIcon,
  LockIcon,
  WarningIcon,
  BellIcon,
} from '@chakra-ui/icons';
import Checkerboard from 'images/checkerboard.svg';
import Bank from 'images/bank.png';
import ContactUsModalBody from 'components/contact-us-modal-body';
import {
  useAttachCompeerLoan,
  useLenderDetails,
  useLenderList,
} from 'hooks/api';
import { LenderDto } from 'api/wallet-app';

export const LendingPartnersModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [selectedLender, setSelectedLender] = React.useState<
    LenderDto | undefined
  >();
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const { data: listLendersData, isLoading: listLendersIsLoading } =
    useLenderList();
  const {
    mutate: attachCompeerLoan,
    isSuccess: attachCompeerLoanIsSuccess,
    isPending: attachCompeerLoanIsPending,
    reset: resetAttachCompeerLoan,
  } = useAttachCompeerLoan();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay background="#F7FAFC" />
      <ModalContent
        p="1rem"
        mt={[0, '3rem']}
        padding="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
      >
        <ModalHeader pb=".5rem">
          {!isSubmitted && selectedLender ? (
            <HStack justifyContent="space-between">
              <ArrowBackIcon
                cursor="pointer"
                fontSize="24px"
                onClick={() => {
                  setSelectedLender(undefined);
                }}
                flex={1}
              />
              <Text
                flex={8}
                fontFamily="inter"
                fontWeight="normal"
                color="#69788A"
                textAlign="center"
              >
                Enter Your Loan Number
              </Text>
              <Spacer flex={1} />
            </HStack>
          ) : (
            <CloseIcon
              cursor="pointer"
              fontSize="12px"
              onClick={() => {
                onClose();
              }}
            />
          )}
        </ModalHeader>
        <ModalBody>
          {isSubmitted ? (
            <AccountNumberSent
              resetMutation={resetAttachCompeerLoan}
              submissionSucceeded={attachCompeerLoanIsSuccess}
              onClose={onClose}
              isLoading={attachCompeerLoanIsPending}
              setIsSubmitted={setIsSubmitted}
            />
          ) : selectedLender ? (
            <LoanNumberBody
              selectedLender={selectedLender}
              submitAccountNumber={(lenderId, loanNumber) => {
                setIsSubmitted(true);
                attachCompeerLoan({
                  lenderId,
                  loanNumber: parseInt(loanNumber),
                });
              }}
            />
          ) : (
            <>
              {listLendersIsLoading ? (
                <Center h="20vh">
                  <Spinner color="blue.400" size="lg" />
                </Center>
              ) : (
                <SelectLenderBody
                  lenders={listLendersData?.lenders}
                  handleSelectLender={(lender) => {
                    setSelectedLender(lender);
                  }}
                />
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const LoanNumberBody = ({
  selectedLender,
  submitAccountNumber,
}: {
  selectedLender: LenderDto;
  submitAccountNumber: (lenderId: string, loanNumber: string) => void;
}) => {
  const [accountNumber, setAccountNumber] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const { data: lenderDetailsData } = useLenderDetails(selectedLender.id);

  const handleAccountNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > 10) {
      setShowError(true);
    } else {
      setShowError(false);
    }
    setAccountNumber(e.currentTarget.value);
  };

  const handleSubmit = () => {
    submitAccountNumber(selectedLender.id, accountNumber);
  };

  return (
    <>
      <Flex direction="column" alignItems="center" fontFamily="inter">
        {lenderDetailsData?.details?.banner &&
        lenderDetailsData.details.banner !== 'undefined' ? (
          <Image
            my="2rem"
            maxHeight="50px"
            src={atob(lenderDetailsData.details.banner)}
          />
        ) : (
          <Text my="2rem">{selectedLender.name}</Text>
        )}
        <FormLabel width="100%">
          Loan Number
          <Input
            mt="1rem"
            type="number"
            value={accountNumber}
            onChange={handleAccountNumber}
            onInput={(e) =>
              (e.currentTarget.value = e.currentTarget.value.slice(0, 10))
            }
          />
          <Flex
            alignItems="center"
            mt="8px"
            fontFamily="inter"
            fontSize="12px"
            height="13px"
          >
            {showError && (
              <>
                <Icon color="red" as={WarningIcon} mr="8px" />
                <Text color="red">Please enter a 10 digit loan number</Text>
              </>
            )}
          </Flex>
        </FormLabel>
        <VStack mt="2rem" divider={<Divider />} width="100%">
          <Flex flexDirection="column" width="100%">
            <Text>Loan Number</Text>
            <Text color="gray.500" fontSize="14px">
              Your loan number is a 10-digit number unique to your line of
              credit.
            </Text>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <Text>Where to find your loan number</Text>
            <Text fontSize="14px" color="gray.500">
              You can find your loan number on your monthly statement.
            </Text>
          </Flex>
        </VStack>
      </Flex>
      <ModalFooter
        padding="0"
        mt="6rem"
        flexDirection="column"
        fontSize="12px"
        fontFamily="inter"
        color="#69788A"
      >
        By clicking Continue, you agree to share your loan information with
        Bushel and grant access to this data via Compeer’s API for the purpose
        of attaching your account to allow you the ability to transact on your
        account.
        <Button
          isDisabled={!accountNumber || accountNumber.length !== 10}
          width="100%"
          onClick={handleSubmit}
          mt="1rem"
        >
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};

const SelectLenderBody = ({
  lenders,
  handleSelectLender,
}: {
  lenders?: LenderDto[];
  handleSelectLender: (lender: LenderDto) => void;
}) => {
  return (
    <Stack spacing={6}>
      <Heading>Select Your Provider</Heading>
      <Stack divider={<Divider />}>
        {lenders
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((l, i) => {
            return (
              <ListItem
                key={i}
                lender={l}
                handleSelectLender={handleSelectLender}
              />
            );
          })}
      </Stack>
    </Stack>
  );
};

const ListItem = ({
  lender,
  handleSelectLender,
}: {
  lender: LenderDto;
  handleSelectLender: (lender: LenderDto) => void;
}) => {
  let lenderIcon = undefined;
  if (lender.icon) {
    lenderIcon = atob(lender.icon);
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      _hover={{ cursor: 'pointer', background: 'gray.50' }}
      p="12px 6px"
      onClick={() => handleSelectLender(lender)}
    >
      <Flex alignItems="center">
        {lenderIcon ? (
          <Image src={lenderIcon} height="24px" width="24px" mr="1rem" />
        ) : (
          <Image w="24px" mr="1rem" src={Checkerboard} alt="design" />
        )}
        <Text fontWeight="bold">{lender.name}</Text>
      </Flex>
      <Icon as={ChevronRightIcon} fontSize="26px" />
    </Flex>
  );
};

const AccountNumberSent = ({
  submissionSucceeded,
  onClose,
  resetMutation,
  isLoading,
  setIsSubmitted,
}: {
  submissionSucceeded: boolean | undefined;
  onClose: () => void;
  resetMutation: () => void;
  isLoading: boolean;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    isOpen: supportModalIsOpen,
    onClose: supportModalOnClose,
    onOpen: supportModalOnOpen,
  } = useDisclosure();

  return !isLoading ? (
    <Flex direction="column" alignItems="center" height={['100vh', 'auto']}>
      {submissionSucceeded ? (
        <>
          <Box backgroundColor="blue.400" p="0.5rem" borderRadius="100px">
            <BellIcon fontSize="2rem" color="#fff" />
          </Box>
          <Heading my="2rem">Link in Progress</Heading>
          <Text textAlign="center" mb="3rem" width="80%">
            You will receive a notification from us shortly. Stay tuned!
          </Text>
          <Button onClick={onClose}>Continue</Button>
        </>
      ) : (
        <>
          <WarningIcon fontSize="3rem" color="red.500" />
          <Heading mt="2rem">Link Error</Heading>
          <Text mt="2rem" textAlign="center">
            We’re not able to link your account right now. Try again or contact
            support for help
          </Text>
          <Box mt="3rem" width="100%">
            <Button
              onClick={() => {
                resetMutation();
                setIsSubmitted(false);
              }}
            >
              Try Again
            </Button>
            <Button mt="1rem" variant="ghost" onClick={supportModalOnOpen}>
              Contact Support
            </Button>
          </Box>
          <Modal isOpen={supportModalIsOpen} onClose={supportModalOnClose}>
            <ModalOverlay backgroundColor="transparent" />
            <ModalContent>
              <ContactUsModalBody onClose={supportModalOnClose} />
            </ModalContent>
          </Modal>
        </>
      )}
    </Flex>
  ) : (
    <Flex direction="column" minHeight={['100vh', '400px']}>
      <Box
        position="absolute"
        background="#fff"
        top="0"
        bottom="0"
        left="0"
        right="0"
        borderRadius="8px"
        padding="1rem"
      >
        <Flex direction="column" alignItems="center">
          <Flex color="#69788A" justifyContent="center" alignItems="center">
            <Icon as={LockIcon} fontSize="12px" mr=".3rem" />
            <Text fontSize="12px">Encrypted Connection</Text>
          </Flex>
          <Image src={Bank} mt="6rem" width="150px" />
          <Flex mt="2rem">
            <Spinner color="blue.400" mr="1rem" />
            <Text>Searching for your loan details</Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
