import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FundingSourceDto } from 'api/wallet-app';

export const IndividualFundingSource = ({
  fundSrc,
  selectedSource,
  handleSelectedSource,
  amount,
  index,
}: {
  fundSrc: FundingSourceDto;
  selectedSource: FundingSourceDto | undefined;
  handleSelectedSource: (source: string) => void;
  amount: string | undefined;
  index: number;
}) => {
  const isLiability = fundSrc?.type === 'LIABILITY';
  const isSelected =
    selectedSource?.fundingSourceId === fundSrc.fundingSourceId;
  const [liabilityIsOverLimit, setLiabilityIsOverLimit] = React.useState(false);

  React.useEffect(() => {
    if (!isLiability || !fundSrc.availableFunds || !amount) return;

    if (parseFloat(fundSrc.availableFunds) < parseFloat(amount)) {
      setLiabilityIsOverLimit(true);
    }
  }, [fundSrc]);

  return (
    <Flex direction="column" width="100%">
      <Flex
        opacity={liabilityIsOverLimit ? '0.6' : '1'}
        justifyContent="space-between"
        border="solid 1px"
        borderRadius="lg"
        borderColor={
          typeof selectedSource !== 'string' && isSelected
            ? 'blue.400'
            : 'gray.300'
        }
        background={
          typeof selectedSource !== 'string' && isSelected ? 'blue.50' : 'white'
        }
        padding={4}
        width="100%"
        data-account={fundSrc.accountNumber}
        cursor={liabilityIsOverLimit ? 'not-allowed' : 'pointer'}
        onClick={() =>
          !liabilityIsOverLimit && handleSelectedSource(fundSrc.fundingSourceId)
        }
        _hover={liabilityIsOverLimit ? {} : { borderColor: 'blue.400' }}
      >
        <Flex flexDirection="column" maxWidth="70%">
          <Text fontWeight="bold">{fundSrc.financialInstitutionName}</Text>
          <Text
            fontSize="sm"
            color="gray.500"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {fundSrc.name}
          </Text>
        </Flex>
        <Flex flexDirection="column">
          <Text color="gray.500">**** {fundSrc.accountNumber}</Text>
          <Text textAlign="end" color="blue.400" fontSize="sm">
            {index == 0 && 'Default'}
          </Text>
        </Flex>
      </Flex>
      {liabilityIsOverLimit && (
        <Text fontSize="14px" color="red.600" alignSelf="flex-start" mt="8px">
          Your line of credit does not cover this transaction
        </Text>
      )}
    </Flex>
  );
};
