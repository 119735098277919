import {
  useMediaQuery,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Stack,
  Text,
  Icon,
  Heading,
  StackDivider,
  HStack,
  DrawerFooter,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { WalletProfileDto, WalletProfileResponse } from 'api/wallet-app';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { useWalletProfile } from 'hooks/api';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';

/*
 *  This component displays the Profile in two distinct ways based on the screen width.
 *  Make sure you make changes to both the modal (for tablet and up) and the drawer (for mobile)
 *  if you make updates.
 */
export default function ProfileOverlay({
  isOpen,
  onClose,
  profile,
  walletId,
  displayButtons = true,
}: {
  isOpen: boolean;
  onClose: () => void;
  profile?: WalletProfileDto | WalletProfileResponse;
  walletId?: string;
  displayButtons?: boolean;
}) {
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const { data, isLoading, fetchStatus } = useWalletProfile(walletId);

  return (
    <>
      {isMobile ? (
        <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
          <DrawerOverlay />
          <DrawerContent borderTopRadius="2xl" pt={12} px={4} pb={6}>
            {(isLoading && !profile) ||
            (fetchStatus !== 'idle' && isLoading) ? (
              <CenteredSpinner centerProps={{ height: '200px' }} />
            ) : (
              <>
                <DrawerCloseButton position="absolute" top={4} left={4} />
                <DrawerBody p={0}>
                  <ProfileBody profile={profile ?? data!} />
                </DrawerBody>
                {displayButtons && (
                  <DrawerFooter px={0} pt={9} pb={0}>
                    <ProfileFooter profile={profile ?? data!} />
                  </DrawerFooter>
                )}
              </>
            )}
          </DrawerContent>
        </Drawer>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
          <ModalOverlay />
          <ModalContent borderRadius="2xl" p={12}>
            {(isLoading && !profile) ||
            (fetchStatus !== 'idle' && isLoading) ? (
              <CenteredSpinner centerProps={{ height: '200px' }} />
            ) : (
              <>
                <ModalCloseButton position="absolute" top={4} left={4} />
                <ModalBody p={0}>
                  <ProfileBody profile={profile ?? data!} />
                </ModalBody>
                {displayButtons && (
                  <ModalFooter px={0} pt={9} pb={0}>
                    <ProfileFooter profile={profile ?? data!} />
                  </ModalFooter>
                )}
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

function ProfileBody({
  profile,
}: {
  profile: WalletProfileDto | WalletProfileResponse;
}) {
  return (
    <Stack spacing={8}>
      <Stack align="center" justify="center" spacing={0}>
        <Icon as={IoPersonCircleOutline} boxSize={12} />
        <Heading as="h3" fontSize="32px" textAlign="center">
          {profile.name}
        </Heading>
        <Text color="blue.400" fontWeight="bold">
          {`@${profile.handle}`}
        </Text>
      </Stack>
      <Stack divider={<StackDivider borderColor="gray.200" />} spacing={3}>
        <HStack justify="space-between">
          <Text color="gray.500">Doing Business As</Text>
          <Text>
            {profile.doingBusinessAs ? profile.doingBusinessAs : profile.name}
          </Text>
        </HStack>
        <HStack justify="space-between">
          <Text color="gray.500">Location</Text>
          <Text>{profile.location}</Text>
        </HStack>
      </Stack>
    </Stack>
  );
}

function ProfileFooter({
  profile,
}: {
  profile: WalletProfileDto | WalletProfileResponse;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  if ('id' in profile) {
    searchParams.append('walletId', profile.id);
  } else if ('walletId' in profile) {
    searchParams.append('walletId', profile.walletId);
  }
  return (
    <HStack justify="space-between" w="100%" spacing={3}>
      <Button
        width="100%"
        onClick={() =>
          navigate(`/transfers/send-and-request/request?${searchParams}`)
        }
      >
        Request Money
      </Button>
      <Button
        width="100%"
        onClick={() =>
          navigate(`/transfers/send-and-request/send?${searchParams}`)
        }
      >
        Send Money
      </Button>
    </HStack>
  );
}
