import React from 'react';
import { Body, Header, Footer } from 'components/layout/onboarding';
import { Button, useDisclosure } from '@chakra-ui/react';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useSelector } from '@xstate/react';
import {
  BusinessInfo,
  SolePropOwnerInfo,
} from 'pages/Onboarding/sharedComponents/OverviewCard';
import { BadgeValue } from 'pages/Onboarding/sharedComponents/OverviewCard/Card';
import { PersonDto } from 'api/wallet-app';
import { ConfirmModal } from 'pages/Onboarding/sharedComponents/ConfirmModal';

export const Overview = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state.context);

  const ownerReady = React.useRef(false);
  const businessReady = React.useRef(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePersonal = () => {
    onboardingActor?.send({ type: 'editPersonalInfo', value: {} });
  };

  const handleBusiness = () => {
    onboardingActor?.send({ type: 'editBusinessInfo', value: {} });
  };

  const getBusinessSubmissionState = () => {
    const valuesArr = Object.values(context.business).filter((i) => i);
    const size = valuesArr.length;
    let value = BadgeValue.notStarted;

    if (size >= 3) {
      businessReady.current = true;
      value = BadgeValue.readyToSubmit;
    }

    if (size < 3 && size > 1) {
      value = BadgeValue.needsInfo;
    }

    if (size <= 1) {
      value = BadgeValue.notStarted;
    }

    return value;
  };

  const getOwnerSubmissionState = () => {
    let value = BadgeValue.notStarted;

    if (context.people.length === 0) return value;

    const person = context.people[0];

    if (person.id) {
      value = BadgeValue.readyToSubmit;
      ownerReady.current = true;
    }

    return value;
  };

  const handleClick = () => {
    onOpen();
  };

  const submitData = () => {
    onClose();
    onboardingActor?.send({ type: 'submit', value: '' });
  };

  return (
    <div data-testid="overview">
      <Header title="Wallet Account Creation" logoutButton={true} />
      <Body>
        <SolePropOwnerInfo
          clickHandler={handlePersonal}
          owner={context.people[0] as PersonDto}
          submissionState={getOwnerSubmissionState()}
        />
        <BusinessInfo
          clickHandler={handleBusiness}
          business={context.business}
          submissionState={getBusinessSubmissionState()}
        />
      </Body>
      <Footer>
        <Button
          variant="primary"
          size="lg"
          onClick={handleClick}
          isDisabled={
            ownerReady.current === false || businessReady.current === false
          }
        >
          Submit
        </Button>
      </Footer>
      <ConfirmModal isOpen={isOpen} onClose={onClose} onSubmit={submitData} />
    </div>
  );
};
