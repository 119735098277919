import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en/common.json';
import fr from './translations/fr/common.json';

i18n.use(initReactI18next).init({
  resources: { en: { translation: en }, fr: { translation: fr } },
  lng: 'en',
  debug: false,
  ns: '',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
