import { TextInput } from 'components/formComponents';
import { Flex } from '@chakra-ui/react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { OnboardingAddressDto } from 'api/wallet-app';
import { USStates } from 'components/formComponents/USStates';
import { assertNoPoBox, dwollaRegExp } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { useHookFormMask } from 'use-mask-input';

type FormLabels = {
  streetNumber1: string;
  streetNumber2?: string;
};

type AddressFormProps = {
  labels?: FormLabels;
  errors: FieldErrors;
  register: UseFormRegister<OnboardingAddressDto>;
};

export const AddressForm = ({ labels, errors, register }: AddressFormProps) => {
  const { t } = useTranslation();
  const registerWithMask = useHookFormMask(register);

  return (
    <>
      <TextInput
        label={labels?.streetNumber1 ? labels.streetNumber1 : 'Address Line 1'}
        {...register('streetNumber1', {
          required: {
            value: true,
            message: t('onboarding.address.message.enterStreetAddress'),
          },
          maxLength: {
            value: 50,
            message: t('onboarding.address.message.maxChar50'),
          },
          validate: {
            noPoBox: (val) => {
              return assertNoPoBox(val) || 'PO Boxes not allowed';
            },
          },
          pattern: {
            value: dwollaRegExp,
            message: 'No special characters allowed',
          },
        })}
        data-testid="streetNumber1"
        errors={errors}
        required={true}
      />
      <TextInput
        label={labels?.streetNumber2 ? labels.streetNumber2 : 'Address Line 2'}
        {...register('streetNumber2', {
          maxLength: {
            value: 50,
            message: t('onboarding.address.message.maxChar50'),
          },
          validate: {
            noPoBox: (val) => assertNoPoBox(val) || 'PO Boxes not allowed',
          },
          pattern: {
            value: dwollaRegExp,
            message: 'No special characters allowed',
          },
        })}
        data-testid="streetNumber2"
        errors={errors}
      />
      <Flex>
        <Flex flex="5" mr={4}>
          <TextInput
            label="City"
            {...register('city', {
              required: {
                value: true,
                message: t('onboarding.address.message.enterCity'),
              },
              pattern: {
                value: /^(?! )[a-zA-Z0-9 ]*$/,
                message: t('onboarding.address.errors.onlyLettersAllowed'),
              },
              minLength: {
                value: 3,
                message: t('onboarding.address.errors.cityMinLength'),
              },
              maxLength: {
                value: 50,
                message: t('onboarding.address.errors.cityMax50Char'),
              },
            })}
            data-testid="city"
            errors={errors}
            required={true}
          />
        </Flex>
        <Flex flex="3">
          <USStates register={register} errors={errors} />
        </Flex>
      </Flex>
      <Flex width="30%">
        <TextInput
          label="Zip Code"
          {...registerWithMask('postalCode', '99999[-9999]', {
            autoUnmask: true,
            required: {
              value: true,
              message: 'Please enter a zip code',
            },
            minLength: { value: 5, message: 'Zip Code must be 5 digits' },
          })}
          data-testid="postalCode"
          type="text"
          inputMode="numeric"
          errors={errors}
          required={true}
          flex="2"
        />
      </Flex>
      <TextInput
        hidden={true}
        label="Country"
        {...register('country')}
        value="US"
      />
    </>
  );
};
