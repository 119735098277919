import React from 'react';
import { Header, Body, Footer } from 'components/layout/onboarding';
import { Button, Flex, Text } from '@chakra-ui/react';
import { RadioButtonGroup } from 'components/formComponents';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { useSelector } from '@xstate/react';

export const TwentyFivePercentOrMore = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const machineState = useSelector(onboardingActor, (state) => state);
  const [selection, setSelection] = React.useState<string | undefined>(
    sessionStorage.getItem('wallet.hasBeneficialOwners') || undefined
  );

  React.useEffect(() => {
    if (!machineState) return;
    if (typeof machineState.context.hasBeneficialOwners === 'undefined') return;
    setSelection(machineState.context.hasBeneficialOwners ? 'yes' : 'no');
  }, [machineState]);

  const handleChange = (value: string) => {
    setSelection(value);
  };

  const handleSubmit = () => {
    if (!selection || !onboardingActor) {
      return;
    }

    sessionStorage.setItem('wallet.hasBeneficialOwners', selection);
    onboardingActor.send({
      type: selection === 'yes' ? 'yes' : 'no',
      value: '',
    });
  };

  const options = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  return (
    <Flex minHeight="500px" direction="column">
      <Header title="Ownership Details" />
      <Text>
        Does any human have 25% or more ownership at your organization?
      </Text>
      <Body>
        <RadioButtonGroup
          name="25-percent-or-more"
          onChange={handleChange}
          options={options}
          defaultValue={selection}
        />
      </Body>
      <Footer>
        <Button onClick={handleSubmit}>Next</Button>
      </Footer>
    </Flex>
  );
};
