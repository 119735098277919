/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface WalletProfileResponse
 */
export interface WalletProfileResponse {
  /**
   *
   * @type {string}
   * @memberof WalletProfileResponse
   */
  walletId: string;
  /**
   *
   * @type {string}
   * @memberof WalletProfileResponse
   */
  handle: string;
  /**
   *
   * @type {string}
   * @memberof WalletProfileResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof WalletProfileResponse
   */
  doingBusinessAs: string;
  /**
   *
   * @type {string}
   * @memberof WalletProfileResponse
   */
  location: string;
}

/**
 * Check if a given object implements the WalletProfileResponse interface.
 */
export function instanceOfWalletProfileResponse(
  value: object
): value is WalletProfileResponse {
  if (!('walletId' in value) || value['walletId'] === undefined) return false;
  if (!('handle' in value) || value['handle'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('doingBusinessAs' in value) || value['doingBusinessAs'] === undefined)
    return false;
  if (!('location' in value) || value['location'] === undefined) return false;
  return true;
}

export function WalletProfileResponseFromJSON(
  json: any
): WalletProfileResponse {
  return WalletProfileResponseFromJSONTyped(json, false);
}

export function WalletProfileResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WalletProfileResponse {
  if (json == null) {
    return json;
  }
  return {
    walletId: json['walletId'],
    handle: json['handle'],
    name: json['name'],
    doingBusinessAs: json['doingBusinessAs'],
    location: json['location'],
  };
}

export function WalletProfileResponseToJSON(
  value?: WalletProfileResponse | null
): any {
  if (value == null) {
    return value;
  }
  return {
    walletId: value['walletId'],
    handle: value['handle'],
    name: value['name'],
    doingBusinessAs: value['doingBusinessAs'],
    location: value['location'],
  };
}
