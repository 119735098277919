/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UnsubscribeRequest
 */
export interface UnsubscribeRequest {
  /**
   *
   * @type {string}
   * @memberof UnsubscribeRequest
   */
  token: string;
}

/**
 * Check if a given object implements the UnsubscribeRequest interface.
 */
export function instanceOfUnsubscribeRequest(
  value: object
): value is UnsubscribeRequest {
  if (!('token' in value) || value['token'] === undefined) return false;
  return true;
}

export function UnsubscribeRequestFromJSON(json: any): UnsubscribeRequest {
  return UnsubscribeRequestFromJSONTyped(json, false);
}

export function UnsubscribeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UnsubscribeRequest {
  if (json == null) {
    return json;
  }
  return {
    token: json['token'],
  };
}

export function UnsubscribeRequestToJSON(
  value?: UnsubscribeRequest | null
): any {
  if (value == null) {
    return value;
  }
  return {
    token: value['token'],
  };
}
