import { UserListItem } from 'api/wallet-app';
import { SettingsCard } from '../settings-card';
import { Stack, Divider } from '@chakra-ui/react';
import { SettingsDetailItem } from '../settings-detail-item';

export default function MyAccount({
  userInfo,
}: {
  userInfo: UserListItem | undefined;
}) {
  return (
    <SettingsCard heading="My Account">
      <Stack divider={<Divider />} spacing={3}>
        <SettingsDetailItem
          title="Phone Number"
          value={userInfo?.phoneNumber}
        />
        <SettingsDetailItem title="Email" value={userInfo?.email} />
      </Stack>
    </SettingsCard>
  );
}
