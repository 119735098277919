/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Contact info for support and admin related inquiries.
 * @export
 * @interface InfoLinks
 */
export interface InfoLinks {
  /**
   * Bushel Wallet application policies.
   * @type {string}
   * @memberof InfoLinks
   */
  policies: string;
  /**
   * Bushel Wallet support page.
   * @type {string}
   * @memberof InfoLinks
   */
  support: string;
  /**
   * Frequently asked questions.
   * @type {string}
   * @memberof InfoLinks
   */
  faq: string;
  /**
   * Debit card activations site.
   * @type {string}
   * @memberof InfoLinks
   */
  debitCardActivation: string;
}

/**
 * Check if a given object implements the InfoLinks interface.
 */
export function instanceOfInfoLinks(value: object): value is InfoLinks {
  if (!('policies' in value) || value['policies'] === undefined) return false;
  if (!('support' in value) || value['support'] === undefined) return false;
  if (!('faq' in value) || value['faq'] === undefined) return false;
  if (
    !('debitCardActivation' in value) ||
    value['debitCardActivation'] === undefined
  )
    return false;
  return true;
}

export function InfoLinksFromJSON(json: any): InfoLinks {
  return InfoLinksFromJSONTyped(json, false);
}

export function InfoLinksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InfoLinks {
  if (json == null) {
    return json;
  }
  return {
    policies: json['policies'],
    support: json['support'],
    faq: json['faq'],
    debitCardActivation: json['debitCardActivation'],
  };
}

export function InfoLinksToJSON(value?: InfoLinks | null): any {
  if (value == null) {
    return value;
  }
  return {
    policies: value['policies'],
    support: value['support'],
    faq: value['faq'],
    debitCardActivation: value['debitCardActivation'],
  };
}
