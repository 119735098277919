import React from 'react';
import { Header, Footer } from 'components/layout/onboarding';
import { SubmitButton } from 'components/submitButton';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { CenteredSpinner } from 'components/CenteredSpinner';
import {
  SocialSecurityForm,
  SSN_TEMP_VAL,
} from 'pages/Onboarding/sharedComponents/SocialSecurity';
import { InfoButton } from 'pages/Onboarding/sharedComponents/SocialSecurity/infoButton';
import { useForm, SubmitHandler } from 'react-hook-form';
import { cleanupSSN } from 'utils/helpers';
import { useSelector } from '@xstate/react';

type SocialSecurityFormValues = {
  ssn: string;
};

export const BeneficialOwnerSocialSecurity = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const context = useSelector(onboardingActor, (state) => state?.context);

  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<SocialSecurityFormValues>({
    mode: 'onBlur',
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit: SubmitHandler<SocialSecurityFormValues> = (data) => {
    if (!data.ssn) return;

    if (data.ssn === SSN_TEMP_VAL) {
      onboardingActor?.send({ type: 'skipSSN', value: '' });
      return;
    }

    setIsSubmitting(true);

    onboardingActor?.send({
      type: 'submitSSN',
      value: { payload: cleanupSSN(data.ssn) },
    });
  };

  const infoButton = <InfoButton />;

  return isSubmitting ? (
    <CenteredSpinner />
  ) : (
    <>
      <form
        id="beneficial-owner-ssn-form"
        data-testid="beneficial-owner-ssn-form"
        onSubmit={hookFormSubmit(handleSubmit)}
        noValidate
      >
        <Header
          title="Beneficial Owner Social Security"
          customButton={infoButton}
        />
        <SocialSecurityForm
          register={register}
          errors={errors}
          isEditing={context?.isEditing || false}
          getValues={getValues}
          setValue={setValue}
        />
      </form>
      <Footer>
        <SubmitButton form="beneficial-owner-ssn-form" />
      </Footer>
    </>
  );
};
