import React, { useMemo } from 'react';
import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  useDisclosure,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import { ArrowBackIcon, ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import {
  IoEllipsisHorizontal,
  IoPencil,
  IoSparklesOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import currency from 'currency.js';
import { LoanPaymentModal } from './LoanPayment/LoanPaymentModal';
import { LoanPaymentContext } from 'pages/WalletApp/Accounts';
import { LiabilityContextMenuContent } from './LiabilityContextMenuContent';
import { Capacitor } from '@capacitor/core';
import { FundingSourceDto, UserListItemTypeEnum } from 'api/wallet-app';
import {
  useFundingSourceDetails,
  useRemoveFundingSource,
  useRenameFundingSource,
  useReorderFundingSources,
  useUserInfo,
} from 'hooks/api';
import { AuthorizedUserTooltip } from 'components/AuthorizedUserTooltip';
import { getMMNumericDayYY } from 'utils/date-utils';

export const LiabilityContextMenu = ({
  fundingSource,
  onClose,
  isOpen,
}: {
  fundingSource: FundingSourceDto;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: fundingSourceDetails, refetch: refetchFundingSourceDetails } =
    useFundingSourceDetails(fundingSource.fundingSourceId);
  const { data: userInfo } = useUserInfo();

  const {
    mutate: removeFundingSource,
    isPending: removeFundingSourceIsPending,
  } = useRemoveFundingSource();
  const { mutate: reorderFundingSource } = useReorderFundingSources();
  const { mutate: renameFundingSource } = useRenameFundingSource();

  const {
    onOpen: loanDetailsOnOpen,
    isOpen: loanDetailsIsOpen,
    onClose: loanDetailsOnClose,
  } = useDisclosure();

  const {
    onOpen: onRenameOpen,
    onClose: onRenameClose,
    isOpen: isRenameOpen,
  } = useDisclosure();

  const {
    onOpen: onRemoveModalOpen,
    onClose: onRemoveModalClose,
    isOpen: isRemoveModalOpen,
  } = useDisclosure();

  const {
    onOpen: paymentsOnOpen,
    isOpen: paymentsIsOpen,
    onClose: paymentsOnClose,
  } = useDisclosure();

  const {
    isOpen: popoverIsOpen,
    onOpen: popoverOnOpen,
    onClose: popoverOnClose,
  } = useDisclosure();

  const [renamedSource, setRenamedSource] = React.useState<string>();
  const { send } = LoanPaymentContext.useActorRef();
  const { allFundingSources } = LoanPaymentContext.useSelector(
    (state) => state.context
  );

  const [isPreferred, setIsPreferred] = React.useState(false);
  const [isOnlyFundingSource, setIsOnlyFundingSource] = React.useState(false);

  React.useEffect(() => {
    if (fundingSourceDetails) {
      send({
        type: 'SET_LOAN_DETAILS',
        loanDetails: fundingSourceDetails,
      });
    }

    if (fundingSourceDetails?.fundingSourceId) {
      send({
        type: 'SET_FUNDING_SOURCE_ID',
        fundingSourceId: fundingSourceDetails?.fundingSourceId,
      });
    }

    if (allFundingSources && fundingSourceDetails?.fundingSourceId) {
      const allFundingSourceIds = allFundingSources.map(
        (fundingSource) => fundingSource.fundingSourceId
      );
      if (
        allFundingSourceIds.indexOf(fundingSourceDetails.fundingSourceId) == 0
      ) {
        setIsPreferred(true);
      }

      if (allFundingSourceIds.length === 1) {
        setIsOnlyFundingSource(true);
      }
    }
  }, [
    fundingSourceDetails?.fundingSourceId,
    fundingSourceDetails,
    allFundingSources,
  ]);

  const confirmRemoveAccount = () => {
    onRemoveModalOpen();
  };

  const handleUnlinkAccount = () => {
    confirmRemoveAccount();
  };

  const handleRenameAccount = () => {
    onRenameOpen();
  };

  const handlePaymentModalClose = () => {
    paymentsOnClose();
  };

  const handleRemoveModalClose = () => {
    onRemoveModalClose();
    onClose();
  };

  const setAsPreferred = () => {
    const allFundingSourceIds = allFundingSources.map(
      (fundingSource) => fundingSource.fundingSourceId
    );
    const index = allFundingSourceIds.findIndex(
      (fundingSourceId) => fundingSourceId === fundingSource.fundingSourceId
    );
    const splice = allFundingSourceIds.splice(index, 1);
    allFundingSourceIds.unshift(splice[0]);
    reorderFundingSource(
      { fundingSourceIds: allFundingSourceIds },
      {
        onSuccess: () => {
          popoverOnClose();
          onClose();
        },
      }
    );
  };

  const handleUpdateName = () => {
    renameFundingSource(
      {
        fundingSourceId: fundingSource.fundingSourceId!,
        friendlyName: renamedSource!,
      },
      {
        onSettled: () => {
          onRenameClose();
          refetchFundingSourceDetails();
        },
      }
    );
  };

  const isNative = Capacitor.isNativePlatform();

  const isAuthorizedUser = useMemo(
    () => userInfo?.type === UserListItemTypeEnum.AuthorizedUser,
    [userInfo?.type]
  );

  if (fundingSourceDetails?.details.type !== 'COMPEER') {
    return <Text>Error</Text>;
  }

  return (
    <>
      <Icon as={ChevronRightIcon} fontSize="24px" />
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay
          backgroundColor="gray.50"
          _focusVisible={{ outline: 'none' }}
        />
        {isOpen && (
          <ModalContent
            mt={[0, '3rem']}
            padding="env(safe-area-inset-top) 2rem env(safe-area-inset-bottom) 2rem"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              p={!isNative ? '2rem 0 1rem' : ''}
            >
              <Icon as={ArrowBackIcon} onClick={onClose} cursor="pointer" />
              <Text
                maxWidth="250px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {fundingSource.name}
              </Text>
              <Popover onClose={popoverOnClose} isOpen={popoverIsOpen}>
                <PopoverTrigger>
                  <Flex>
                    <AuthorizedUserTooltip placement="bottom">
                      <IconButton
                        aria-label="LoC Options button"
                        icon={<IoEllipsisHorizontal size="18px" />}
                        variant="unstyled"
                        size="icon"
                        w="fit-content"
                        onClick={popoverOnOpen}
                        minW="18px"
                        minWidth="18px"
                        isDisabled={isAuthorizedUser ?? true}
                      />
                    </AuthorizedUserTooltip>
                  </Flex>
                </PopoverTrigger>
                <PopoverContent
                  borderRadius="4px"
                  width="225px"
                  padding=".5rem"
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.16)"
                  _focus={{
                    outline: 'none',
                  }}
                  mr="20px"
                >
                  <PopoverArrow />
                  <PopoverBody
                    display="flex"
                    flexDirection="column"
                    height="100%"
                  >
                    <VStack>
                      <Flex
                        width="100%"
                        fontFamily="inter"
                        alignItems="center"
                        _hover={
                          isOnlyFundingSource
                            ? { background: 'gray.50' }
                            : { background: 'inherit' }
                        }
                        p="4px"
                        cursor={isOnlyFundingSource ? 'not-allowed' : 'pointer'}
                        color={isOnlyFundingSource ? 'gray.400' : 'black'}
                      >
                        <Icon as={IoTrashOutline} mr="10px" fontSize="24px" />
                        <Text
                          _hover={
                            !isOnlyFundingSource
                              ? {
                                  textDecoration: 'underline',
                                }
                              : { textDecoration: 'none' }
                          }
                          fontSize="16px"
                          onClick={() =>
                            isOnlyFundingSource
                              ? () => null
                              : handleUnlinkAccount()
                          }
                        >
                          Unlink Account
                        </Text>
                      </Flex>
                      <Flex
                        width="100%"
                        fontFamily="inter"
                        alignItems="center"
                        _hover={
                          !isPreferred
                            ? { background: 'gray.50' }
                            : { background: 'inherit' }
                        }
                        p="4px"
                        cursor={isPreferred ? 'not-allowed' : 'pointer'}
                        mt="0.8rem"
                        color={isPreferred ? 'gray.400' : 'black'}
                      >
                        <Icon
                          as={IoSparklesOutline}
                          mr="10px"
                          fontSize="24px"
                        />
                        <Text
                          _hover={
                            !isPreferred
                              ? { textDecoration: 'underline' }
                              : { textDecoration: 'none' }
                          }
                          fontSize="16px"
                          onClick={() =>
                            isPreferred ? () => null : setAsPreferred()
                          }
                        >
                          Set as Preferred
                        </Text>
                      </Flex>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>

            <ModalBody padding="0">
              <LiabilityContextMenuContent
                fundingSourceDetails={fundingSourceDetails?.details}
                paymentsOnOpen={paymentsOnOpen}
                loanDetailsOnOpen={loanDetailsOnOpen}
                lastUpdated={getMMNumericDayYY(
                  new Date(fundingSourceDetails?.details?.lastSeenAt ?? '')
                )}
              />
            </ModalBody>
          </ModalContent>
        )}
      </Modal>
      <Modal
        isOpen={isRemoveModalOpen}
        onClose={onRemoveModalClose}
        closeOnOverlayClick={!removeFundingSourceIsPending}
      >
        <ModalOverlay />
        <ModalContent p="2rem">
          <Heading fontSize="1.2rem">Unlink Account</Heading>
          <Text mt="1rem">Would you like to unlink this account?</Text>
          <Flex justifyContent="flex-end" width="100%" mt="2rem">
            <Button
              variant="secondary"
              mr="1rem"
              onClick={onRemoveModalClose}
              isDisabled={removeFundingSourceIsPending}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                removeFundingSource(fundingSource.fundingSourceId, {
                  onSettled: () => {
                    handleRemoveModalClose();
                  },
                });
              }}
              isLoading={removeFundingSourceIsPending}
            >
              Unlink
            </Button>
          </Flex>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={loanDetailsIsOpen}
        onClose={loanDetailsOnClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay backgroundColor="gray.50" />
        <ModalContent
          padding={
            !isNative ? '1rem 2rem 2rem' : 'env(safe-area-inset-top) 2rem 2rem'
          }
          mt={[0, '6rem']}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Icon
              as={ArrowBackIcon}
              onClick={loanDetailsOnClose}
              cursor="pointer"
            />
            <Flex>Loan Details</Flex>
            <Flex></Flex>
          </Flex>
          {loanDetailsIsOpen && (
            <ModalBody fontFamily="inter">
              <Flex alignItems="center" justifyContent="center">
                {fundingSourceDetails?.details?.providerBanner ? (
                  <Image
                    src={atob(fundingSourceDetails?.details?.providerBanner)}
                    mt="1rem"
                    maxWidth="240px"
                  />
                ) : (
                  <Text>{fundingSourceDetails?.details?.providerName}</Text>
                )}
              </Flex>
              <Flex
                mt="1.2rem"
                width="100%"
                color="#69788A"
                fontSize="14px"
                justifyContent="center"
                fontFamily="inter"
              >
                Loan data updated{' '}
                {getMMNumericDayYY(
                  new Date(fundingSourceDetails?.details?.lastSeenAt ?? '')
                )}
              </Flex>
              <Flex
                mt="1.5rem"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex direction="column" width="100%">
                  <Text color="#69788a">Loan Nickname</Text>
                  <Text>{fundingSourceDetails?.details?.nickname}</Text>
                </Flex>
                <Icon
                  cursor="pointer"
                  onClick={handleRenameAccount}
                  as={IoPencil}
                  fontSize="16px"
                />
              </Flex>
              <Divider mt=".5555rem" />
              <Flex direction="column" mt=".5rem">
                <Text color="#69788a">Loan Name</Text>
                <Text>{fundingSourceDetails?.details?.name}</Text>
              </Flex>

              <Flex direction="column" mt="2rem">
                <Heading size="sm">Details</Heading>
                <VStack divider={<Divider />}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    mt="1rem"
                  >
                    <Text color="#69788a">Available Credit</Text>
                    <Text>
                      {currency(
                        fundingSourceDetails?.details?.availableCredit?.toString() ??
                          ''
                      ).format()}
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Text color="#69788a">Original Loan Amount</Text>
                    <Text>
                      {currency(
                        fundingSourceDetails?.details?.originalAmount?.toString() ??
                          ''
                      ).format()}
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Text color="#69788a">Credit Used</Text>
                    <Text>
                      {currency(
                        fundingSourceDetails?.details?.creditUsed?.toString() ??
                          ''
                      ).format()}
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Text color="#69788a">Interest Accrued</Text>
                    <Text>
                      {currency(
                        fundingSourceDetails?.details?.interestAccruedToDate?.toString() ??
                          ''
                      ).format()}
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Text color="#69788a">Loan Term</Text>
                    <Text>
                      {fundingSourceDetails?.details?.loanTermMonths} Months
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Text color="#69788a">Rate</Text>
                    <Text>{fundingSourceDetails?.details?.rate}%</Text>
                  </Flex>
                </VStack>
              </Flex>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      <Modal isOpen={isRenameOpen} onClose={onRenameClose}>
        <ModalOverlay backgroundColor="gray.50" />
        <ModalContent padding="1rem 2rem 2rem" mt="6rem">
          <Flex justifyContent="space-between" alignItems="center">
            <Icon
              as={CloseIcon}
              onClick={onRenameClose}
              cursor="pointer"
              fontSize="10px"
            />
          </Flex>
          <ModalBody padding="0">
            <Heading size="md" mt="1rem">
              Edit Nickname
            </Heading>
            <Flex direction="column" mt="1rem">
              <Text>Nickname</Text>
              <Input
                type="text"
                mt=".5rem"
                value={renamedSource}
                defaultValue={fundingSourceDetails?.details?.nickname}
                maxLength={50}
                onChange={(e) => setRenamedSource(e.currentTarget.value)}
              />
              <Button mt="2rem" onClick={handleUpdateName}>
                Save
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <LoanPaymentModal
        onClose={handlePaymentModalClose}
        isOpen={paymentsIsOpen}
      />
    </>
  );
};
