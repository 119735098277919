import React from 'react';
import { Body, Header, Footer } from 'components/layout/onboarding';
import { Text } from '@chakra-ui/react';
import { OnboardingContext } from 'pages/Onboarding/Onboarding';
import { OnboardingAddressDto } from 'api/wallet-app';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SubmitButton } from 'components/submitButton';
import { AddressForm } from 'pages/Onboarding/sharedComponents/AddressForm';
import { useSelector } from '@xstate/react';
import { CenteredSpinner } from 'components/CenteredSpinner';

export const BusinessAddress = () => {
  const onboardingActor = OnboardingContext.useActorRef();
  const state = useSelector(onboardingActor, (state) => state?.context);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors },
  } = useForm<OnboardingAddressDto>({
    mode: 'onBlur',
    defaultValues: state?.business?.businessAddress,
  });

  const handleSubmit: SubmitHandler<OnboardingAddressDto> = (data) => {
    setIsSubmitting(true);
    onboardingActor?.send({ type: 'submitBusinessAddress', value: data });
  };

  return isSubmitting ? (
    <CenteredSpinner />
  ) : (
    <div>
      <Header title="Registered Business Address" />
      <Text mb={6} fontSize={14}>
        Please enter your business address as listed within your business's
        official documents
      </Text>
      <Body>
        <>
          <form
            id="business-address"
            data-testid="business-address"
            onSubmit={hookFormSubmit(handleSubmit)}
            noValidate
          >
            <AddressForm
              register={register}
              errors={errors}
              labels={{
                streetNumber1: 'Business Address Line 1',
                streetNumber2: 'Business Address Line 2',
              }}
            />
          </form>
        </>
      </Body>
      <Footer>
        <SubmitButton form="business-address" />
      </Footer>
    </div>
  );
};
