import { useQuery } from '@tanstack/react-query';
import useFundingSourcesClient from './useFundingSourcesClient';
import { useWalletStore } from 'stores';

export const LENDER_DETAILS_QUERY_KEY = 'lender-details';

export default function useLenderDetails(lenderId: string) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const fundingSourcesClient = useFundingSourcesClient();
  return useQuery({
    queryKey: [LENDER_DETAILS_QUERY_KEY, lenderId],
    queryFn: () => {
      return fundingSourcesClient.getLendersDetails({
        id: lenderId,
        xWalletId: walletId,
      });
    },
  });
}
