import { PropsWithChildren } from 'react';
import { Stack, StackProps } from '@chakra-ui/react';

export default function CardContainer(props: PropsWithChildren<StackProps>) {
  const { children, ...rest } = props;
  return (
    <Stack
      backgroundColor="white"
      padding={6}
      borderRadius="lg"
      boxShadow="0px 2px 3px rgba(45, 55, 72, 0.2), 0px 0px 2px rgba(45, 55, 72, 0.15)"
      {...rest}
    >
      {children}
    </Stack>
  );
}
