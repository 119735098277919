import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import usePaymentsClient from './usePaymentRequestClient';

export const PAYMENT_REQUEST_DETAILS_QUERY_KEY = 'payment-request-details';

export default function usePaymentRequestDetails(paymentRequestId?: string) {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const paymentsClient = usePaymentsClient();
  return useQuery({
    queryKey: [PAYMENT_REQUEST_DETAILS_QUERY_KEY, { paymentRequestId }],
    queryFn: () => {
      return paymentsClient.getPaymentRequestDetails({
        xWalletId: walletId,
        paymentRequestId: paymentRequestId!,
      });
    },
    enabled: !!paymentRequestId,
  });
}
