/* tslint:disable */
/* eslint-disable */
/**
 * Wallet App REST API
 * Wallet App REST API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserListItem
 */
export interface UserListItem {
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  type: UserListItemTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UserListItemTypeEnum {
  WalletOwner = 'WALLET_OWNER',
  AuthorizedUser = 'AUTHORIZED_USER',
}

/**
 * Check if a given object implements the UserListItem interface.
 */
export function instanceOfUserListItem(value: object): value is UserListItem {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('firstName' in value) || value['firstName'] === undefined) return false;
  if (!('lastName' in value) || value['lastName'] === undefined) return false;
  if (!('email' in value) || value['email'] === undefined) return false;
  if (!('phoneNumber' in value) || value['phoneNumber'] === undefined)
    return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  return true;
}

export function UserListItemFromJSON(json: any): UserListItem {
  return UserListItemFromJSONTyped(json, false);
}

export function UserListItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserListItem {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    firstName: json['firstName'],
    lastName: json['lastName'],
    email: json['email'],
    phoneNumber: json['phoneNumber'],
    type: json['type'],
  };
}

export function UserListItemToJSON(value?: UserListItem | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    firstName: value['firstName'],
    lastName: value['lastName'],
    email: value['email'],
    phoneNumber: value['phoneNumber'],
    type: value['type'],
  };
}
