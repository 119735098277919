import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type SubmitButtonProps = {
  isDisabled?: boolean;
  isPending?: boolean;
  handleSubmit?: () => void;
  form?: string;
};

export const SubmitButton = ({
  handleSubmit = () => {
    return false;
  },
  isDisabled = false,
  isPending = false,
  form,
}: SubmitButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="submit"
      type={form ? 'submit' : 'button'}
      onClick={handleSubmit}
      isDisabled={isPending || isDisabled}
      form={form ? form : undefined}
      isLoading={isPending}
    >
      {t('button.next')}
    </Button>
  );
};
