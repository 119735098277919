import {
  SENT_QUERY_PARAM,
  TAB_LIST_QUERY_KEY,
} from 'pages/WalletApp/settings/invite-center';
import {
  LinkItem,
  SettingsCard,
} from 'pages/WalletApp/settings/components/index';

export default function InviteABusiness() {
  return (
    <SettingsCard heading="Invite a Business">
      <LinkItem
        linkTarget={`/settings/invite-center?${TAB_LIST_QUERY_KEY}=${SENT_QUERY_PARAM}`}
        title="Invite Center"
        newTab={false}
      />
    </SettingsCard>
  );
}
