import { keycloakRestApiBasePath } from 'mocks/helpers';
import { http, HttpResponse } from 'msw';

// The endpoints here are made to ID Auth, not the Wallet BE
const keycloakHandlers = [
  /*
   * GET /auth/realms/exchange/protocol/openid-connect/userinfo
   */
  http.get(keycloakRestApiBasePath('userinfo'), () => {
    return HttpResponse.json({
      sub: '38bc81d4-baeb-4644-b468-641b054e4739',
      email_verified: true,
      bushel_id: '38bc81d4-baeb-4644-b468-641b054e4739',
      preferred_username: '9999990001',
      email: 'user@mock.com',
    });
  }),
];

export default keycloakHandlers;
