import { useQuery } from '@tanstack/react-query';
import { useWalletStore } from 'stores';
import useAlertsClient from './useAlertsClient';

export const ALERT_LIST_QUERY_KEY = 'alert-list';

export default function useAlertList() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('Wallet Id is not defined');
  }
  const alertsClient = useAlertsClient();
  return useQuery({
    queryKey: [ALERT_LIST_QUERY_KEY],
    queryFn: () => {
      return alertsClient.getAlerts({
        xWalletId: walletId,
      });
    },
  });
}
