import {
  Circle,
  Flex,
  Icon,
  Image,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  IoHomeOutline,
  IoCashOutline,
  IoSettingsOutline,
  IoHome,
  IoCash,
  IoSettings,
  IoWalletOutline,
  IoWallet,
} from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import DollarSign from 'images/dollarsign.svg';
import { usePaymentRequestList } from 'hooks';

function TransfersIcon() {
  return (
    <Flex
      position="absolute"
      top="-50px"
      minWidth="64px"
      backgroundColor="blue.400"
      p={5}
      boxShadow="0px 2px 10px 0px #2D374826,
        0px 10px 20px 0px #2D374833,
        0px 0px 0.5px 0px #FFFFFF00 inset,
        0px 0.5px 0px 0px #FFFFFF00 inset"
      border="2px"
      borderColor="blue.500"
      borderRadius="lg"
    >
      <Image src={DollarSign} boxSize={6} />
    </Flex>
  );
}

const menuOptions = [
  {
    id: 'home',
    icon: IoHomeOutline,
    selectedIcon: IoHome,
    text: 'Home',
    link: '/',
  },
  {
    id: 'activity',
    icon: IoCashOutline,
    selectedIcon: IoCash,
    text: 'Activity',
    link: '/activity',
  },
  {
    id: 'transfers',
    icon: TransfersIcon,
    link: '/transfers',
    text: 'Transfer',
  },
  {
    id: 'accounts',
    icon: IoWalletOutline,
    selectedIcon: IoWallet,
    text: 'Accounts',
    link: '/accounts',
  },
  {
    id: 'settings',
    icon: IoSettingsOutline,
    selectedIcon: IoSettings,
    text: 'Settings',
    link: '/settings',
  },
];

type NavProps = {
  isOpen?: boolean;
  onClick?: () => void;
};

export function NavItems({ isOpen, onClick }: NavProps) {
  const [isMobile] = useMediaQuery('(min-width: 480px)');
  const { data } = usePaymentRequestList();

  return (
    <Stack
      spacing={isMobile ? 4 : 0}
      direction={isMobile ? 'column' : 'row'}
      justify="space-between"
      w="100%"
    >
      {menuOptions.map((item) => {
        const isTransfers = item.id === 'transfers';
        const isActivity = item.id === 'activity';
        if (isTransfers && isMobile) return;
        return (
          <NavLink
            to={item.link}
            key={item.id}
            onClick={onClick}
            style={{ minWidth: !isMobile ? '63px' : 'inherit' }}
          >
            {({ isActive }) => {
              return (
                <Stack
                  align="center"
                  spacing={1}
                  direction={!isMobile ? 'column' : 'row'}
                  color={isActive ? 'blue.500' : 'black'}
                  borderLeft={!isMobile ? 'none' : 'solid 3px'}
                  borderColor={isActive && isOpen ? 'blue.500' : 'transparent'}
                  cursor="pointer"
                  height="56px"
                  _hover={{
                    bgColor: isMobile ? 'gray.100' : 'transparent',
                    borderColor:
                      isActive && isOpen
                        ? 'blue.500'
                        : isMobile
                          ? 'gray.100'
                          : 'transparent',
                  }}
                  justify={isMobile ? 'inherit' : 'space-between'}
                  data-id={item?.id}
                  data-testid={item.id}
                >
                  <Stack
                    direction={!isMobile ? 'column' : 'row'}
                    align="center"
                    spacing={isMobile ? 3 : 1}
                    pl={isMobile ? 3 : 0}
                    position="relative"
                  >
                    {isActivity && data && data.paymentRequests.length > 0 && (
                      <Circle
                        position="absolute"
                        bgColor="red.400"
                        left={isMobile ? 6 : 7}
                        top={-1}
                        minH={4}
                        minW={4}
                      />
                    )}
                    <Icon
                      boxSize={6}
                      as={isActive ? item.selectedIcon : item.icon}
                      transition="all 0.3s"
                      data-testid={`icon-${item.id}`}
                      mt={
                        isActivity && data && data.paymentRequests.length > 0
                          ? '0 !important'
                          : 'inherit'
                      }
                      ml={'0 !important'}
                    />
                    {(isOpen || !isMobile) && (
                      <Text
                        transition="all 0.3s"
                        mb={!isMobile && isActive ? 2 : 0}
                        mt={isTransfers ? '28px !important' : 0}
                        fontSize={!isMobile ? 'sm' : 'md'}
                      >
                        {item.text}
                      </Text>
                    )}
                  </Stack>
                  {!isMobile && isActive && <Circle size={1} bg="blue.500" />}
                </Stack>
              );
            }}
          </NavLink>
        );
      })}
    </Stack>
  );
}
