import React from 'react';
import { Flex } from '@chakra-ui/react';

export const Body = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      className="content__panel__body"
      justifyContent="space-around"
      flexDirection="column"
      my="1rem"
    >
      {children}
    </Flex>
  );
};
