export const Colors = {
  blue: {
    50: '#E0F3FF',
    100: '#B8D7F9',
    200: '#8EBCF0',
    300: '#63A1E7',
    400: '#4A90E2',
    500: '#206CC6',
    600: '#15549B',
    700: '#0B3C70',
    800: '#022446',
    900: '#000D1D',
  },
  yellow: {
    50: '#FFF8DC',
    100: '#FCE9B0',
    200: '#FADA83',
    300: '#F6CB53',
    400: '#F4BD27',
    500: '#DAA30B',
    600: '#AA7F05',
    700: '#795A01',
    800: '#4A3600',
    900: '#1B1200',
  },
  navy: {
    50: '#E0F3FF',
    100: '#B8D7F9',
    200: '#8EBCF0',
    300: '#63A1E7',
    400: '#4A90E2',
    500: '#206CC6',
    600: '#15549B',
    700: '#0B3C70',
    800: '#022446',
    900: '#000D1D',
  },
  orange: {
    50: '#FFEFDD',
    100: '#FED7D7',
    200: '#F9BA85',
    300: '#F69E55',
    400: '#F27F20',
    500: '#D9690D',
    600: '#AA5208',
    700: '#793904',
    800: '#4A2200',
    900: '#000D1D',
  },
  green: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
  },
  red: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
  black: {
    500: '#000000',
  },
  base: {
    primary: 'blue.400',
    success: 'green.500',
    warning: 'orange.400',
    error: 'red.500',
    border: 'gray.200',
  },
  background: {
    primary: 'gray.50',
    secondary: 'gray.100',
    tertiary: 'gray.200',
    overlay: 'blackAlpha.500',
  },
  type: {
    primary: 'gray.700',
    secondary: 'gray.400',
  },
  border: '#E2E8F0',
  textSecondary: '#69788A',
};
