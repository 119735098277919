import { useQuery } from '@tanstack/react-query';
import useWalletsClient from './useWalletsClient';
import { useWalletStore } from 'stores';

export const SETTINGS_INFO_QUERY_KEY = 'settings-info';

export default function useSettingsInfo() {
  const { walletId } = useWalletStore();
  if (!walletId) {
    throw new Error('WalletId is not defined');
  }
  const walletsClient = useWalletsClient();
  return useQuery({
    queryKey: [SETTINGS_INFO_QUERY_KEY],
    queryFn: () => {
      return walletsClient.getSettingsInfo({ xWalletId: walletId });
    },
  });
}
